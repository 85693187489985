import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios } from "../utils/common";
import { toast } from "react-toastify";



const ExperienceService = {
    createNewExperiencePackage: async (callback = () => { }, errCallback = () => { }) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: 'createNewExperiencePackage',
                // experience_seq:experience_seq,
                // with_structure:1
            }
        })
            .then((response) => {
                response = parseResponseAxios(response);
                if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                    const data = extractDataFromArray(response, ['data'], []);
                    // console.log("Data from backend", data)
                    callback(data);
                } else {
                    // toast.error(extractDataFromArray(response,['errorMsg'],'Something went wrong, Please try again lator'), {
                    //     theme: "colored",
                    // })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    },
    getExperiencePackageData: async (existingExperienceSeq, callback = () => { }, errCallback = () => { }) => {
        await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: 'getPublicExperienceData',
                experience_seq: existingExperienceSeq,
                // with_structure:1
            }
        })
            .then((response) => {
                response = parseResponseAxios(response);
                if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                    const data = extractDataFromArray(response, ['data'], []);
                    // console.log("Data from backend", data)
                    callback(data);
                } else {
                    // toast.error(extractDataFromArray(response,['errorMsg'],'Something went wrong, Please try again lator'), {
                    //     theme: "colored",
                    // })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    },
    getAddComponentData: async (callback = () => { }, errCallback = () => { }) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: 'getAddComponentData',
            }
        })
            .then((response) => {
                response = parseResponseAxios(response);
                if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                    const data = extractDataFromArray(response, ['data'], []);
                    callback(data);
                } else {
                    // toast.error(extractDataFromArray(response,['errorMsg'],'Something went wrong, Please try again lator'), {
                    //     theme: "colored",
                    // })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    },
    addExperienceComponent: async (experience_seq, component_type, callback = () => { }, errCallback = () => { }) => {
        const formData = new FormData();
        formData.append("cmd", "addExperienceComponent");
        formData.append("experience_seq", experience_seq);
        formData.append("component_type", component_type);
        await axios
            .post(config.apiUrl + "/services/api", formData)
            .then((response) => {
                // console.log("data sent", response)
                response = parseResponseAxios(response);
                if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                    callback(extractDataFromArray(response, ["data"], []));
                    // toast.success("Saved changes successfully",{
                    // 	theme:"colored"
                    // })
                } else {
                    toast.error(extractDataFromArray(response, ['errorMsg'], 'Something went wrong, Please try again lator'), {
                        theme: "colored",
                    })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    },
    updateComponentsStructure: async (experience_seq, updatedExperienceJsonArray, callback = () => { }, errCallback = () => { }) => {
        // console.log("updatedExperienceJsonArray for sending", updatedExperienceJsonArray);
        const formData = new FormData();
        formData.append("cmd", "updateComponentsStructure");
        formData.append("experience_seq", experience_seq);
        formData.append("components_structure", JSON.stringify(updatedExperienceJsonArray));
        // console.log("formData", formData);
        await axios
            .post(config.apiUrl + "/services/api", formData)
            .then((response) => {
                response = parseResponseAxios(response);
                // console.log("data sent", response)
                if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                    callback(extractDataFromArray(response, ["data"], []));
                    // toast.success("Saved changes successfully",{
                    // 	theme:"colored"
                    // })
                } else {
                    toast.error(extractDataFromArray(response, ['errorMsg'], 'Something went wrong, Please try again lator'), {
                        theme: "colored",
                    })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    },

    updateExperienceComponent: async (experience_seq, componentId, componentData, experienceStructure, callback = () => { }, errCallback = () => { }) => {
        // console.log("updatedExperienceJsonArray for sending", updatedExperienceJsonArray);
        const formData = new FormData();
        formData.append("cmd", "updateExperienceComponent");
        formData.append("experience_seq", experience_seq);
        formData.append("component_id", componentId);
        formData.append("component_data", JSON.stringify(componentData));
        formData.append("experience_structure", JSON.stringify(experienceStructure));
        // console.log("formData", formData);
        await axios
            .post(config.apiUrl + "/services/api", formData)
            .then((response) => {
                response = parseResponseAxios(response);
                // console.log("data sent", response)
                if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                    callback(extractDataFromArray(response, ["data"], []));
                    // toast.success("Saved changes successfully",{
                    // 	theme:"colored"
                    // })
                } else {
                    toast.error(extractDataFromArray(response, ['errorMsg'], 'Something went wrong, Please try again lator'), {
                        theme: "colored",
                    })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    },
    updateComponentDisplaySetting: async (experience_seq, componentId, updatedDisplayValue, callback = () => { }, errCallback = () => { }) => {
        const formData = new FormData();
        formData.append("cmd", "updateComponentDisplaySetting");
        formData.append("experience_seq", experience_seq);
        formData.append("component_id", componentId);
        formData.append("display", updatedDisplayValue);

        // console.log("formData", formData);
        await axios
            .post(config.apiUrl + "/services/api", formData)
            .then((response) => {
                response = parseResponseAxios(response);
                // console.log("data sent", response)
                if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                    callback(extractDataFromArray(response, ["data"], []));
                    // toast.success("Saved changes successfully",{
                    // 	theme:"colored"
                    // })
                } else {
                    errCallback(extractDataFromArray(response, ['data'], []))
                    toast.error(extractDataFromArray(response, ['errorMsg'], 'Something went wrong, Please try again lator'), {
                        theme: "colored",
                    })
                }
            });
    },
    removeExperienceComponent: async (experience_seq, component_id, callback = () => { }, errCallback = () => { }) => {
        // console.log("updatedExperienceJsonArray for sending", updatedExperienceJsonArray);
        const formData = new FormData();
        formData.append("cmd", "removeExperienceComponent");
        formData.append("experience_seq", experience_seq);
        formData.append("component_id", component_id);
        // console.log("formData", formData);
        await axios
            .post(config.apiUrl + "/services/api", formData)
            .then((response) => {
                response = parseResponseAxios(response);
                // console.log("data sent", response)
                if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                    callback(extractDataFromArray(response, ["data"], []));
                    // toast.success("Saved changes successfully",{
                    // 	theme:"colored"
                    // })
                } else {
                    toast.error(extractDataFromArray(response, ['errorMsg'], 'Something went wrong, Please try again lator'), {
                        theme: "colored",
                    })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    },
    validateAllComponents: async (experience_seq, callback = () => { }, errCallback = () => { }) => {
        const formData = new FormData();
        formData.append("cmd", "validateAllComponents");
        formData.append("experience_seq", experience_seq);
        // console.log("formData", formData);
        await axios
            .post(config.apiUrl + "/services/api", formData)
            .then((response) => {
                response = parseResponseAxios(response);
                // console.log("data sent", response)
                if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                    callback(extractDataFromArray(response, ["data"], []));
                    // toast.success("Saved changes successfully",{
                    // 	theme:"colored"
                    // })
                } else {
                    // toast.error(extractDataFromArray(response,['errorMsg'],'Something went wrong, Please try again lator'), {
                    // 	theme: "colored",
                    // })
                    errCallback(extractDataFromArray(response, ["data"], []))
                }
            });
    },
    saveExperiencePackage: async (experience_json, experienceSequence, callback = () => { }, errCallback = () => { }) => {
        const formData = new FormData();
        formData.append("cmd", "saveExperiencePackage");
        formData.append("experience_json", JSON.stringify(experience_json));
        formData.append("experience_seq", experienceSequence);

        // console.log("formData", formData);
        await axios
            .post(config.apiUrl + "/services/api", formData)
            .then((response) => {
                response = parseResponseAxios(response);
                // console.log("data sent", response)
                if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                    callback(extractDataFromArray(response, ["data"], []));
                    toast.success("Saved changes successfully", {
                        theme: "colored"
                    })
                } else {
                    toast.error(extractDataFromArray(response, ['errorMsg'], 'Something went wrong, Please try again lator'), {
                        theme: "colored",
                    })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    }, updateExperiencePackageStatus: async (experienceSequence, status, callback = () => { }, errCallback = () => { }) => {
        // console.log("status", status);
        const formData = new FormData();
        formData.append("cmd", "updateExperiencePackageStatus");
        formData.append("experience_seq", experienceSequence);
        formData.append("status", status);

        console.log("formData", formData);
        await axios
            .post(config.apiUrl + "/services/api", formData)
            .then((response) => {
                response = parseResponseAxios(response);
                // console.log("data sent", response)
                if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                    callback(extractDataFromArray(response, ["data"], []));
                    toast.success("Saved changes successfully", {
                        theme: "colored"
                    })
                } else {
                    toast.error(extractDataFromArray(response, ['errorMsg'], 'Something went wrong, Please try again lator'), {
                        theme: "colored",
                    })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    },
    updateExpListingStatus: async (expSeq, status, callback = () => { }) => {
        const formData = new FormData();
        formData.append('cmd', 'updateExperiencePackageStatus');
        formData.append('experience_seq', expSeq);
        formData.append('status', status);

        await axios.post(config.apiUrl + "/services/api", formData)
            .then((response) => {
                response = parseResponseAxios(response);
                if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                    const data = extractDataFromArray(response, ['data'], []);
                    callback(data);
                } else {
                    toast.error(extractDataFromArray(response, ['errorMsg'], "Error"), {
                        theme: "colored",
                    })
                }
            });
    },
    getExpBookedData: async (expSeq, callback = () => { }, errCallback = () => { }) => {
        await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: 'getExpBookedData',
                experience_seq: expSeq,
            }
        })
            .then((response) => {
                response = parseResponseAxios(response);
                if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                    const data = extractDataFromArray(response, ['data'], []);
                    callback(data);
                } else {
                    // toast.error(extractDataFromArray(response,['errorMsg'],'Something went wrong, Please try again lator'), {
                    //     theme: "colored",
                    // })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    },
    getBookedAddonCount: async (expSeq, slot_id, date, callback = () => { }, errCallback = () => { }) => {
        await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: 'getBookedAddonCount',
                experience_seq: expSeq,
                slot_id: slot_id,
                date: date,
            }
        })
            .then((response) => {
                response = parseResponseAxios(response);
                if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                    const data = extractDataFromArray(response, ['data'], []);
                    callback(data);
                } else {
                    // toast.error(extractDataFromArray(response,['errorMsg'],'Something went wrong, Please try again lator'), {
                    //     theme: "colored",
                    // })
                    errCallback(extractDataFromArray(response, ['errorMsg'], []))
                }
            });
    },
}

export default ExperienceService;