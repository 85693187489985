import { Box, Button, Dialog, Divider, IconButton, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useEffect, useState } from "react";
import UserService from "../../services/user";
import { loadStripe } from "@stripe/stripe-js";
import { extractDataFromArray } from "../../utils/common";
import { toast } from "react-toastify";

const IDVerificationPopup = ({ open, handleClose, callback, source = "", setStripeLoadingButton }) => {
    const [stripe, setStripe] = useState(null)
    const [token, setToken] = useState(null)

    const verifyIdentity = async () => {
        const { error } = await stripe.verifyIdentity(token);

        if (error) {
            toast.success(error, {
                theme: 'colored'
            })
            // handleClose();
            setStripeLoadingButton(false)

        } else {
            toast.success("ID verification submitted.", {
                theme: 'colored'
            })
            UserService.updateVerificationStatus('P', (data) => {
                callback()
            })
            // handleClose();
            setStripeLoadingButton(false)
        }
    }

    useEffect(() => {
        // console.log("id fetch")
        UserService.getIDVerificationToken(async (data) => {
            let load = await loadStripe(extractDataFromArray(data, ['fw_key']))
            setStripe(load)
            setToken(extractDataFromArray(data, ['v_token']))
        })
    }, [source])
    return (
        <Dialog open={open}
            // onClose={handleClose} // commented out so that verify email button loader is not stooped after clicking out side of pop up
            maxWidth="md">
            <Box >
                <Box sx={{ py: 1.5, px: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>ID Verification Required</Typography>
                    <IconButton onClick={() => { handleClose(); setStripeLoadingButton(false) }}><Icon.Close /> </IconButton>
                </Box>
                <Divider />
                <Box sx={{ p: 2.5 }} >
                    <Typography sx={{ mb: 2 }}>
                        All user, hosts, and guests are required to undergo an ID verification process on FarWide to enhance safety and activate insurance coverage. Please submit a valid photo ID to proceed with your booking.
                        <br /> <br />
                        It's a one-time requirement that only takes a few minutes.
                    </Typography>
                    <Button fullWidth variant="contained" color="primary" sx={{ mb: 2 }} onClick={() => { handleClose(); verifyIdentity(); }}>Verify My ID</Button>
                    <Typography variant="body2" sx={{ lineHeight: '16px', color: 'var(--grey900)' }}>
                    </Typography>
                </Box>

            </Box>
        </Dialog>
    )
}

export default IDVerificationPopup