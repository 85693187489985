import { Avatar, Box, Button, Chip, Dialog, DialogTitle, Divider, Grid, IconButton, Skeleton, Typography } from "@mui/material"
import * as Icon from '@mui/icons-material';
import { convertTo24HourFormat, convertUtcToLocal, decodeHTML, empty, extractDataFromArray, getImageUrl, isEmpty, showZendeskLauncher, } from "../../utils/common"
import CancellationPopup, { PackageCard } from "./cancellationPopup"
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useRef, useState } from "react";
import BookingService from "../../services/bookings";
import { toast } from "react-toastify";
import config from "../../config";
import ConfirmationPopup from "./confirmationPopup";
import { Link } from "react-router-dom";
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { CommonContext } from "../../store/context/commonContext";
import dayjs from "dayjs";
import PackageCardMobile from "../PackageCardMobile";
import { ROUTE_SUBMIT_REVIEW, ROUTE_BOOKINGS, ROUTE_EXPERIENCE_REVIEW } from "../../constants/page";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Review from "../../services/review";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoaderButton from "../LoaderButton"
import { LocalizationProvider, SingleInputDateRangeField, StaticDateTimePicker } from "@mui/x-date-pickers-pro";
import { ACCESS, EXPERIENCE } from "../CommonConstants/commonKeys";

export const getBookingStatusChip = (status) => {
	switch (status) {
		case 'R':
			return <Chip size="small" label="Requested" variant="filled" color="secondary" sx={{ width: 'fit-content' }} />
		case 'C':
			return <Chip size="small" label="Confirmed" variant="filled" color="primary" sx={{ width: 'fit-content' }} />
		case 'F':
			return <Chip size="small" label="Failed" variant="filled" color="error" sx={{ width: 'fit-content' }} />
		case 'N':
			return <Chip size="small" label="Cancelled" variant="filled" color="error" sx={{ width: 'fit-content' }} />
		case 'D':
			return <Chip size="small" label="Declined" variant="filled" color="error" sx={{ width: 'fit-content' }} />
		case 'M':
			return <Chip size="small" label="Completed" variant="filled" color="primary" sx={{ width: 'fit-content' }} />
		case 'CI':
			return <Chip size="small" label="Checked-In" variant="outlined" color="primary" sx={{ width: 'fit-content' }} />
		default:
	}
}


mapboxgl.accessToken = 'pk.eyJ1IjoiZmFyd2lkZSIsImEiOiJjajloOHc4MGozNWFnMnFsc2FueXJvbnZ3In0.7Cek0jLTdXkikf2ioduEvg';


const Location = ({ lat, lon }) => {

	const mapContainer = useRef(null);
	const map = useRef(null);
	useEffect(() => {
		if (map.current) return; // initialize map only once
		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v12',
			center: [lon, lat],
			zoom: 9,
			attributionControl: false,
			dragPan: false,
			scrollZoom: false,
			doubleClickZoom: false
		});

		// map.current.scrollZoom.enable();
		map.current.on('load', function () {
			const customMarker = document.createElement('div');
			customMarker.className = 'custom-marker';
			customMarker.style.backgroundImage = 'url("/assets/images/property_detail_page/map_pointer.svg")';
			customMarker.style.width = '50px';
			customMarker.style.height = '100px';
			customMarker.style.backgroundRepeat = 'no-repeat';

			// Create a marker using the custom marker element
			const marker = new mapboxgl.Marker({ element: customMarker }).setLngLat([lon, lat]).addTo(map.current);
		});

	}, []);
	return (
		<>
			<Box ref={mapContainer} sx={{ height: '200px', borderRadius: 4 }} />
		</>
	)
}


const BookingInfo = ({ bookingData, bookingId, openPopup, openConfirmPopup, status, loaderConfirmButton, loaderDeclineButton, setLoaderConfirmButton, starLoaderConfirmButton, startLoaderDeclineButton }) => {
	console.log("booking data", bookingData);
	let currentDate = dayjs()
	currentDate = dayjs(currentDate.format('YYYY-MM-DD'))
	const amount = extractDataFromArray(bookingData, ['booking', 'booking_amount'], 0)
	const checkInDate = extractDataFromArray(bookingData, ['booking', 'check_in_date'], '')
	const checkOutDate = extractDataFromArray(bookingData, ['booking', 'check_out_date'], '')
	const checkInTimeHrFormat = `${convertTo24HourFormat(extractDataFromArray(bookingData, ['booking', 'check_in_time']))}`
	const checkOutTimeHrFormat = `${convertTo24HourFormat(extractDataFromArray(bookingData, ['booking', 'check_out_time']))}`
	const checkInDateTime = `${checkInDate.split(' ')[0]} ${checkInTimeHrFormat}`
	const checkOutDateTime = `${checkOutDate.split(' ')[0]} ${checkOutTimeHrFormat}`
	const { isMobile, isTablet, reviewPresent, setReviewPresent, isNineHundred, screenWidth } = CommonContext();
	const [loaderButton, setLoaderButton] = useState(false)
	const [poiImages, setPoiImages] = useState([])
	const [poiLinks, setPoiLinks] = useState([])
	const [isPoiImage, setIsPoiImage] = useState(false)
	const [isPoiLinks, setIsPoiLinks] = useState(false)
	const [showInfo, setShowInfo] = useState(false);
	const [checkInOpen, setCheckInOpen] = useState(false)
	const [checkOutOpen, setCheckOutOpen] = useState(false)
	const [customCheckInDate, setCustomCheckInDate] = useState(dayjs())
	const [customCheckOutDate, setCustomCheckOutDate] = useState(dayjs())
	const [shouldScrollToRefundDetails, setShouldScrollToRefundDetails] = useState(false);
	const [bookingPackageType, setBookingPackageType] = useState(extractDataFromArray(bookingData, ['package_type'], ''))
	const [latlon, setLatLon] = useState([0, 0])
	useEffect(() => {
		if (!empty(bookingData)) {
			setPoiImages(extractDataFromArray(bookingData, ['listing', 'media_library'], []))
			setPoiLinks(extractDataFromArray(bookingData, ['listing', 'poi_links'], []))

		}
		if (bookingData.package_type === ACCESS.value) {
			setLatLon([parseFloat(extractDataFromArray(bookingData, ['listing', 'lat'], '0')), parseFloat(extractDataFromArray(bookingData, ['listing', 'lon'], '0'))])
		} else if (bookingData.package_type === EXPERIENCE.value) {
			let lat = 0
			let lon = 0
			let data = bookingData.package.experience_data
			for (let key in data) {
				if (data[key].type === 'location') {
					lat = data[key].lat
					lon = data[key].lon
				}
			}
			setLatLon([lat, lon])
		}

	}, [bookingData])



	const [addOns, setAddOns] = useState([])

	useEffect(() => {
		if (!empty(poiImages)) {
			setIsPoiImage(true)
		} else {
			setIsPoiImage(false)
		}

		if (!empty(poiLinks)) {
			setIsPoiLinks(true)
		} else {
			setIsPoiLinks(false)
		}
	}, [poiImages, poiLinks])


	// scroll to refund details if coming from messages cancellation or declined card
	useEffect(() => {
		const url = window.location.href
		const scrollToRefundDetails = url.includes('#refunddetails');

		if (scrollToRefundDetails) {
			setShouldScrollToRefundDetails(true);
		}
	}, []);

	useEffect(() => {
		if (shouldScrollToRefundDetails) {
			const refundDetails = extractDataFromArray(bookingData, ['refund'], {});
			if (!isEmpty(refundDetails)) {
				const refundDetailsElement = document.getElementById('refundDetails');
				if (refundDetailsElement) {
					refundDetailsElement.scrollIntoView({ behavior: 'smooth' });
					setShouldScrollToRefundDetails(false);
				}
			}
		}
		setAddOns(extractDataFromArray(bookingData, ['booking', 'addons'], []))
	}, [bookingData, shouldScrollToRefundDetails]);

	// console.log(isMobile, isTablet)

	// Check if the current date is greater than the check-in date
	const isCheckInPassed = currentDate.isSame(dayjs(checkInDate.split(' ')[0])) || currentDate.isAfter(dayjs(checkInDate.split(' ')[0]));

	// Date till cancellation option should be available
	const cancellableDate = dayjs(checkInDate.split(' ')[0]).add(-1, 'day')

	// check for cancellation button should be visible or not
	const isCancelAvailable = (currentDate.isBefore(cancellableDate));

	// Enable Checkin button logic
	const checkInBtnAvailableDate = dayjs(checkInDate.split(' ')[0]).add(-2, 'day')
	const enableCheckInBtn = currentDate.isSame(checkInBtnAvailableDate) || currentDate.isAfter(checkInBtnAvailableDate)

	const checkedOutDate = extractDataFromArray(bookingData, ['booking', 'checked_out_date'], '')
	const isCheckedOut = checkedOutDate === "" ? false : true;
	const checkedInDate = extractDataFromArray(bookingData, ['booking', 'checked_in_date'], '')
	const isCheckedIn = checkedInDate === "" ? false : true;
	const guestChatUrl = window.location.origin + '/chat/' + (extractDataFromArray(bookingData, ['lister', 'name'], "")).split(" ")[0] + '/' + (extractDataFromArray(bookingData, ['lister', 'seq']))
	const listerChatUrl = window.location.origin + '/landowner/chat/' + (extractDataFromArray(bookingData, ['guest', 'name'])).split(" ")[0] + '/' + (extractDataFromArray(bookingData, ['guest', 'seq']))

	// console.log('chaturls',guestChatUrl,listerChatUrl)

	useEffect(() => {
		config.module === 'guest' && Review.getReviewDetails(bookingId, (data) => {
			setReviewPresent(data)
		}, (error) => {
			toast.error(error, {
				theme: 'colored',
			})
		})
	}, [])


	const handleDownloadpdf = () => {
		// console.log("clicked")
		BookingService.DownloadInvoice(bookingId, (data) => {
			try {
				const blob = new Blob([data.data], { type: 'application/pdf' });
				const url = URL.createObjectURL(blob);

				// Create a temporary anchor element
				const a = document.createElement('a');
				a.href = url;
				a.download = `invoice_${bookingId}.pdf`; // Set the download attribute with desired filename
				a.click(); // Simulate click to trigger download
				URL.revokeObjectURL(url); // Release the object URL
			} catch (error) {
				console.error('Error downloading PDF:', error);
			}
		}).catch(error => {
			console.error('Error fetching PDF data:', error);
		});
	}



	const showButton = reviewPresent && Object.keys(reviewPresent).length > 0 ? true : false;

	useEffect(() => {
		// Parse the checkedInDate using dayjs
		const checkedInDate = dayjs(checkInDate);
		const currentDate = dayjs();

		const daysDifference = checkedInDate.diff(currentDate, 'day');

		if (daysDifference <= 7 && daysDifference >= 0) {
			setShowInfo(true);

		} else {
			setShowInfo(false);

		}
	}, [showInfo]);

	// console.log(bookingData)

	const location = useLocation();
	const packageType = new URLSearchParams(location.search).get('package_type');

	let packageCardMobileData = {
		image: '',
		thumbnail_url: '',
		title: '',
		listingTitle: '',
		area: '',
		address: '',
		propertyName: '',
		listingSlug: '',
		listing_seq: '',
		slug: '',
		seq: '',
		nearest_town: '',
		showBorder: false,
		activity_type: '',
		type: '',
	}

	if (bookingData.package_type === EXPERIENCE.value) {
		let data = extractDataFromArray(bookingData, ['package', 'experience_data'], {})
		for (let key in data) {
			if (data[key].type === 'photos') {
				packageCardMobileData.image = data[key].photo_list[0]
			}
			if (data[key].type === 'general_info') {
				packageCardMobileData.title = data[key].title
				packageCardMobileData.slug = data[key].slug
				packageCardMobileData.seq = extractDataFromArray(bookingData, ['package', 'seq'], '')
				console.log(packageCardMobileData.seq);
			}
			if (data[key].type === 'location') {
				packageCardMobileData.address = data[key].address
				if (packageCardMobileData.address === 'Don’t see your address? Add GPS coordinates.') {
					packageCardMobileData.address = 'Nearest Town, ' + data[key].city
				}
			}
		}
	} else if (bookingData.package_type === ACCESS.value) {
		packageCardMobileData.image = extractDataFromArray(bookingData, ['package', 'media_url'], '')
		packageCardMobileData.title = extractDataFromArray(bookingData, ['package', 'title'], '')
		packageCardMobileData.area = extractDataFromArray(bookingData, ['listing', 'area'], '')
		packageCardMobileData.address = extractDataFromArray(bookingData, ['listing', 'address'], '')
		packageCardMobileData.propertyName = extractDataFromArray(bookingData, ['listing', 'title'], '')
		packageCardMobileData.listingSlug = extractDataFromArray(bookingData, ['listing', 'slug'], '')
		packageCardMobileData.listing_seq = extractDataFromArray(bookingData, ['listing', 'seq'], '')
		packageCardMobileData.seq = extractDataFromArray(bookingData, ['package', 'seq'], '')
		packageCardMobileData.slug = extractDataFromArray(bookingData, ['package', 'slug'], '')
		packageCardMobileData.nearest_town = extractDataFromArray(bookingData, ['listing', 'nearest_town'], '')
		packageCardMobileData.activity_type = extractDataFromArray(bookingData, ['package', 'activity_type'], '')

	}

	return (
		<Box sx={!isTablet && { border: '1px solid var(--grey500)', p: 3, borderRadius: theme => `${theme.card.borderRadius}` }}>
			<Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>Package Details</Typography>
			{!isNineHundred ?
				<PackageCard bookingData={bookingData} clickable={true} />
				:
				<PackageCardMobile
					image={packageCardMobileData.image}
					title={packageCardMobileData.title}
					area={packageCardMobileData.area}
					address={packageCardMobileData.address}
					propertyName={packageCardMobileData.propertyName}
					listingSlug={packageCardMobileData.listingSlug}
					listing_seq={packageCardMobileData.listing_seq}
					slug={packageCardMobileData.slug}
					seq={packageCardMobileData.seq}
					nearest_town={packageCardMobileData.nearest_town}
					showBorder={false}
					activity_type={packageCardMobileData.activity_type}
					listingType={bookingData.package_type}
				/>
			}
			<Typography variant="h6" sx={{ fontWeight: 'bold', my: 2 }}>Booking Details</Typography>
			<Box sx={{ border: '1px solid var(--grey500)', p: { xs: 2, sm: 2, md: 3, lg: 3 }, borderRadius: theme => `${theme.card.borderRadius}`, display: 'flex', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flexWrap: 'wrap' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, width: "100%", flexWrap: 'wrap', minWidth: 0 }}>
					<Typography sx={{ color: 'var(--grey900)', }}>
						Booking ID
					</Typography>
					<Typography sx={{ fontWeight: 600, wordBreak: 'break-all', fontSize: isTablet && '0.7rem' }}>
						{bookingId}
					</Typography>
				</Box>

				<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
					<Typography sx={{ color: 'var(--grey900)', }}>{extractDataFromArray(bookingData, ['booking', 'guest_count'], '') == 1 ? 'Guest' : 'Guests'}</Typography>
					<Typography sx={{ fontWeight: 600 }}>{extractDataFromArray(bookingData, ['booking', 'guest_count'], '')}</Typography>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
					<Typography sx={{ color: 'var(--grey900)', }}>{extractDataFromArray(bookingData, ['booking', 'booking_days'], '') == 1 ? 'Day' : 'Days'}</Typography>
					<Typography sx={{ fontWeight: 600 }}>{extractDataFromArray(bookingData, ['booking', 'booking_days'], '')}</Typography>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
					<Typography sx={{ color: 'var(--grey900)', }}>Status</Typography>
					{getBookingStatusChip(status)}
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
					<Typography sx={{ color: 'var(--grey900)', }}>Amount</Typography>
					<Typography sx={{ fontWeight: 600 }}>${extractDataFromArray(bookingData, ['booking', 'booking_amount'], '')}</Typography>
					<Chip variant="outlined" color="primary" label="Full Payment" sx={{ width: '150px' }} />
				</Box>
				{
					status === 'C' &&
					<>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Check-In</Typography>
							<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{dayjs(checkInDateTime).format("ddd D MMM YYYY, hh:mm A")}</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Check Out</Typography>
							<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{dayjs(checkOutDateTime).format("ddd D MMM YYYY, hh:mm A")}</Typography>
						</Box>
					</>

				}
				{
					status === 'R' &&
					<>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Check-In</Typography>
							<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{dayjs(checkInDateTime).format("ddd D MMM YYYY, hh:mm A")}</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Check Out</Typography>
							<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{dayjs(checkOutDateTime).format("ddd D MMM YYYY, hh:mm A")}</Typography>
						</Box>
					</>

				}
				{
					status === 'CI' &&
					<>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Checked-In</Typography>
							{/* {dayjs(checkoutDateTime).format("ddd D MMM YYYY, hh:mm A")} */}
							<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{dayjs(extractDataFromArray(bookingData, ['booking', 'checked_in_date'], '')).format("ddd D MMM YYYY, hh:mm A")}</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Check Out</Typography>
							<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{dayjs(checkOutDateTime).format("ddd D MMM YYYY, hh:mm A")}</Typography>
						</Box>
					</>

				}
				{
					status === 'M' &&
					<>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Checked-In</Typography>
							{/* {dayjs(checkoutDateTime).format("ddd D MMM YYYY, hh:mm A")} */}
							<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{dayjs(extractDataFromArray(bookingData, ['booking', 'checked_in_date'], '')).format("ddd D MMM YYYY, hh:mm A")}</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Checked Out</Typography>
							<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{dayjs((extractDataFromArray(bookingData, ['booking', 'checked_out_date'], ''))).format("ddd D MMM YYYY, hh:mm A")}</Typography>
						</Box>
					</>
				}
				<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
					<Typography sx={{ color: 'var(--grey900)', }}>Booked By</Typography>
					<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{decodeHTML(extractDataFromArray(bookingData, ['guest', 'name'], ''))}</Typography>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
					<Typography sx={{ color: 'var(--grey900)', }}>Booking Date</Typography>
					<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{convertUtcToLocal(dayjs(extractDataFromArray(bookingData, ['booking', 'booking_date'], '')).format("ddd D MMM YYYY, hh:mm A"))}</Typography>
				</Box>
				{
					!empty(addOns) &&
					<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%", pr: 1 }} >
						<Typography sx={{ color: 'var(--grey900)', }}>Add-on</Typography>
						{
							addOns.map((addOn, index) => {
								return (
									<Box>
										<Typography sx={{ fontWeight: 600, textWrap: "wrap" }}>{extractDataFromArray(addOn, ['title'], '') + ' x ' + extractDataFromArray(addOn, ['selected_quantity'], '')}{index < addOns.length - 1 && ','}</Typography>
									</Box>
								)
							})
						}
					</Box>
				}
				{(status === 'C' || status === 'CI' || status === 'M') && <Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "100%", width: "100%" }} >
					<Divider sx={{}} />
				</Box>}

				<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', gap: 1 }}>
					{
						(status === 'C' || status === 'CI' || status === 'M') && <Box>

							{/* <Button sx={{ width: screenWidth <= 430 ? '100%' : 'unset' }} variant="light" startIcon={<Icon.SimCardDownloadOutlined />} onClick={() => { handleDownloadpdf() }}>Download PDF</Button> */}
						</Box>
					}
					{
						status === "M" && config.module === 'guest' &&
						<Box sx={{ width: screenWidth <= 430 ? '100%' : 'unset' }}>
							<Link to={(bookingPackageType === 'access' ? ROUTE_SUBMIT_REVIEW : ROUTE_EXPERIENCE_REVIEW) + `?bookingId=${bookingId}`}><Button startIcon={showButton ? <Icon.EditOutlined /> : <Icon.StarBorder />} variant="contained" sx={{ paddingBlock: 1, paddingInline: 1.5, backgroundColor: 'var(--primary)', fontWeight: 400, lineHeight: 1.5, width: screenWidth <= 430 ? '100%' : 'unset' }} >{showButton ? "Edit Review" : "Write Review"}</Button></Link>
						</Box>
					}
				</Box>
			</Box>
			<Box sx={{ display: 'flex', gap: 1, width: '100%', flexDirection: screenWidth <= 1199 ? 'column' : 'row' }}>
				{/* Buttons for accept decline and cancel */}
				{
					status === 'R' && config.module === 'guest' && isCancelAvailable &&
					<Box className="cancelButton" sx={{ width: '100%', display: "flex", justifyContent: 'flex-end' }} >
						<LoaderButton
							variantType='outlined'
							buttonColor='secondary'
							onClick={() => {
								openPopup({ type: 'cancel' });
								startLoaderDeclineButton();
							}}
							isLoading={loaderDeclineButton}
							buttonText="Cancel Booking"
							isFullWidth={false}

						/>
					</Box>

				}
				{
					status === 'C' && config.module === 'guest' && isCancelAvailable &&
					<Box sx={{ width: '100%', display: "flex", justifyContent: 'flex-end' }} >
						<LoaderButton
							variantType='outlined'
							buttonColor='secondary'
							onClick={() => {
								openPopup({ type: 'cancel' });
								startLoaderDeclineButton();
							}}
							isLoading={loaderDeclineButton}
							buttonText="Cancel Booking"
							isFullWidth={false}

						/>
					</Box>

				}
				{
					status === 'C' && isCancelAvailable && config.module === 'lister' &&
					<Box sx={{}}>
						{/* <Button variant="outlined" color="secondary" onClick={() => { openPopup({ type: 'cancel' }) }}>Cancel Booking</Button> */}
						<LoaderButton
							variantType='outlined'
							buttonColor='secondary'
							onClick={() => {
								openPopup({ type: 'cancel' });
								startLoaderDeclineButton();
							}}
							sx={{ width: screenWidth <= 1199 ? '100%' : 'max-content' }}
							isLoading={loaderDeclineButton}
							buttonText="Cancel Booking"
							isFullWidth={false}

						/>
					</Box>

				}
				{
					status === 'R' && config.module === 'lister' &&
					<Box sx={{ textAlign: 'right', gap: 1, display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }} >
						{/* <Button variant="outlined" color="error" onClick={() => { openPopup({ type: 'decline' }) }} sx={{ mr: 1 }}>Decline Booki</Button> */}
						<LoaderButton
							variantType='outlined'
							buttonColor="error"
							onClick={() => {
								openPopup({ type: 'decline' });
								startLoaderDeclineButton();
							}}
							size={isTablet ? 'small' : 'medium'}
							isLoading={loaderDeclineButton}
							buttonText="Decline Booking"
							isFullWidth={false}
						/>
						{/* <Button variant="contained" color="primary" onClick={openConfirmPopup}>Confirm Booki</Button> */}
						<LoaderButton
							variantType='contained'
							buttonColor='primary'
							onClick={() => { openConfirmPopup(); starLoaderConfirmButton(); }}
							size={isTablet ? 'small' : 'medium'}
							isLoading={loaderConfirmButton}
							buttonText="Confirm Booking"
							isFullWidth={false}
						/>
					</Box>
				}
				{/* Logic and UI to show Checkin and CheckOut button. */}
				{status != 'R' && config.module === 'lister' && <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: { xs: 1, sm: 1, md: 1, lg: 1 }, flexDirection: screenWidth <= 1199 ? 'column' : 'row' }}>
					{
						status === 'C' &&
						<>
							{
								enableCheckInBtn ?
									<LoaderButton
										variantType='outlined'
										buttonColor='secondary'
										onClick={() => {
											// BookingService.checkIn(bookingId, setLoaderButton)
											setLoaderButton(true)
											setCheckInOpen(true)
										}}
										isLoading={loaderButton}
										buttonText="Check In"
										isFullWidth={false}
									/>
									:
									<Button sx={{ height: '42px' }} variant="outlined" disabled>Check In</Button>
							}
						</>
					}
					{
						status === 'C' &&
						<Button sx={{ height: '42px' }} variant="outlined" disabled>Check Out</Button>

					}
					{
						status === 'CI' &&
						<>
							<Button sx={{ height: '42px' }} variant="outlined" disabled>Check In</Button>
							<LoaderButton
								variantType='outlined'
								buttonColor='secondary'
								onClick={() => {
									setCheckOutOpen(true)
									// BookingService.checkOut(bookingId, setLoaderButton)
									setLoaderButton(true)
								}}
								isLoading={loaderButton}
								buttonText="Check Out"
								isFullWidth={false}
							/>
						</>
					}
				</Box>}
			</Box>
			{
				status === 'C' && config.module === 'guest' && showInfo &&
				<Box sx={{}} >
					<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, mt: { sm: 2, xs: 2, md: 3, lg: 3 } }}>
						<Typography variant="h6" sx={{ fontWeight: 700 }}>Location</Typography>
						<Link target={isMobile ? "" : "_blank"} to={"https://www.google.com/maps/dir//" + latlon[0] + "," + latlon[1]}><Button variant="light" size="small">Directions</Button></Link>
					</Box>
					<Location lat={latlon[0]} lon={latlon[1]} />

					{
						extractDataFromArray(bookingData, ['listing', 'address'], '') === "-" ?
							<Link style={{ textDecoration: "underline solid black 1px", color: "black" }} to={"https://www.google.com/maps/dir//" + extractDataFromArray(bookingData, ['listing', 'lat'], '0') + "," + extractDataFromArray(bookingData, ['listing', 'lon'], '0')}>
								<Typography variant="body1" sx={{ mt: 2, mb: 4 }}>Location({extractDataFromArray(bookingData, ['listing', 'lat'], '')},{extractDataFromArray(bookingData, ['listing', 'lon'], '')})</Typography>
							</Link>
							:
							<Typography variant="body1" sx={{ mt: 2, mb: 4 }}>{extractDataFromArray(bookingData, ['listing', 'address'], '')}</Typography>
					}

					{
						isPoiImage ?
							<Box>
								<Typography variant="h6" sx={{ fontWeight: 700, mb: 1.5 }}>Points of Interest</Typography>
								<Box>
									{
										poiImages.map((imageData, index) => {
											return (
												<Box>
													<Box
														key={index}
														sx={{
															display: "flex",
															gap: 3
														}}
													>
														{
															imageData.type === 'VIDEO' ?
																<div className='' style={{ position: 'relative', display: 'flex' }}>
																	<div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
																		<PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '3rem', width: '3rem' }} />
																	</div>
																	<Box
																		sx={{
																			backgroundImage: "url('" + imageData.thumbnail_url + "')",
																			width: 110,
																			height: 90,
																			backgroundPosition: "center center",
																			backgroundRepeat: "no-repeat",
																			backgroundSize: "cover",
																			borderRadius: 4
																		}}>
																	</Box>
																</div>
																:
																<Box
																	sx={{
																		backgroundImage: "url('" + imageData.url + "')",
																		width: 110,
																		height: 90,
																		backgroundPosition: "center center",
																		backgroundRepeat: "no-repeat",
																		backgroundSize: "cover",
																		borderRadius: 4
																	}}>
																</Box>
														}
														<Box>
															<Typography variant="body1" >{imageData.caption}</Typography>
															<Typography variant="body1" ></Typography>
														</Box>
													</Box>
													{index !== poiImages.length - 1 && <Divider sx={{ my: 1 }} />}
												</Box>

											)
										})
									}
								</Box>
							</Box> : ""
					}
					{
						isPoiLinks ?

							<Box>
								<Typography variant="h6" sx={{ fontWeight: 700, mb: 1, mt: 5 }}>Links</Typography>
								<Box>
									{
										poiLinks.map((links, index) => {
											return (
												<Box
													key={index}
													sx={{ mb: index !== links.length - 1 ? 3 : 0 }}
												>
													<Typography variant="body1" color="var(--textGrey)" >{links.name}</Typography>
													<Link to={links.url} target={isMobile ? "" : "_blank"}>
														<Typography variant="body1" sx={{ fontWeight: 600, wordBreak: 'break-word' }}  >{links.url}</Typography>
													</Link>

												</Box>

											)
										})
									}
								</Box>
							</Box> : ""
					}
					<Divider sx={{ my: 3 }} />
				</Box>
			}

			{
				status === 'N' &&
				<>
					<Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: '#E94A4A' }}>Cancellation Details</Typography>
					<Box sx={{ border: '1px solid var(--grey500)', p: 3, borderRadius: theme => `${theme.card.borderRadius}`, display: 'flex', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flexDirection: 'column' }}>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Date</Typography>
							<Typography sx={{ fontWeight: 600 }}>{convertUtcToLocal(dayjs(extractDataFromArray(bookingData, ['cancellation', 'date'], '')).format("ddd D MMM YYYY, hh:mm A"))}</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Reason</Typography>
							<Typography sx={{ fontWeight: 600 }}>{extractDataFromArray(bookingData, ['cancellation', 'reason'], '')}</Typography>
						</Box>
						{extractDataFromArray(bookingData, ['cancellation', 'reason'], '') === 'Others' &&
							<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
								<Typography sx={{ color: 'var(--grey900)', }}>Custom Reason</Typography>
								<Typography sx={{ fontWeight: 600 }}>{extractDataFromArray(bookingData, ['cancellation', 'custom_reason'], '')}</Typography>
							</Box>
						}
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Message</Typography>
							<Typography sx={{ fontWeight: 600 }}>{extractDataFromArray(bookingData, ['cancellation', 'message'], '')}</Typography>
						</Box>
						{config.module === 'guest' ?
							<Link to={guestChatUrl}>
								<Button variant="light" size="small" sx={{ width: '87px', fontSize: '13px', lineHeight: '20px', mb: { xs: 2, sm: 2, md: 3, lg: 3 } }}><Icon.QuestionAnswerOutlined />Chat</Button>
							</Link>
							:
							<Link to={listerChatUrl}>
								<Button variant="light" size="small" sx={{ width: '87px', fontSize: '13px', lineHeight: '20px', mb: { xs: 2, sm: 2, md: 3, lg: 3 } }}><Icon.QuestionAnswerOutlined />Chat</Button>
							</Link>
						}
						{
							<Box id="refundDetails"
								sx={{
									display: 'flex',
									flexDirection: 'column',
									mb: { xs: 2, sm: 2, md: 3, lg: 3 },
									flex: "50%",
									borderRadius: 2,
									width: "100%",
								}}>
								<Typography sx={{ color: 'var(--grey900)', }}>Refund Details</Typography>
								<Box>
									<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										<Typography>Total Amount Paid</Typography>
										<Typography>${parseFloat(extractDataFromArray(bookingData, ['refund', 'payment'], '')).toFixed(2)
										}</Typography>
									</Box>
									<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '14px' }}>
										<Typography  >Deductions</Typography>
										<Typography >${parseFloat(extractDataFromArray(bookingData, ['refund', 'balance'], '')).toFixed(2)}</Typography>
									</Box>
									<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										<Typography fontWeight={'600'}>Refund Amount</Typography>
										<Typography fontWeight={'bold'}>${parseFloat(extractDataFromArray(bookingData, ['refund', 'refund'], '')).toFixed(2)
										}</Typography>
									</Box>
								</Box>
							</Box>
						}
					</Box>
				</>
			}
			{
				status === 'D' &&
				<>
					<Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: '#E94A4A' }}>Declined Details</Typography>
					<Box sx={{ border: '1px solid var(--grey500)', p: { xs: 2, sm: 2, md: 3, lg: 3 }, borderRadius: theme => `${theme.card.borderRadius}`, display: 'flex', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flexDirection: 'column' }}>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Date</Typography>
							<Typography sx={{ fontWeight: 600 }}>{convertUtcToLocal(dayjs(extractDataFromArray(bookingData, ['declination', 'date'], '')).format("ddd D MMM YYYY, hh:mm A"))}</Typography>
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Reason</Typography>
							<Typography sx={{ fontWeight: 600 }}>{extractDataFromArray(bookingData, ['declination', 'reason'], '')}</Typography>
						</Box>
						{
							extractDataFromArray(bookingData, ['declination', 'reason'], '') === 'Others' &&
							<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
								<Typography sx={{ color: 'var(--grey900)', }}>Custom Message</Typography>
								<Typography sx={{ fontWeight: 600 }}>{extractDataFromArray(bookingData, ['declination', 'custom_reason'], '')}</Typography>
							</Box>
						}
						<Box sx={{ display: 'flex', flexDirection: 'column', mb: { xs: 2, sm: 2, md: 3, lg: 3 }, flex: "50%", width: "100%" }} >
							<Typography sx={{ color: 'var(--grey900)', }}>Message</Typography>
							<Typography sx={{ fontWeight: 600 }}>{extractDataFromArray(bookingData, ['declination', 'message'], '')}</Typography>
						</Box>
						{config.module === 'guest' ?
							<Link to={guestChatUrl}>
								<Button variant="light" size="small" sx={{ width: '87px', fontSize: '13px', lineHeight: '20px', mb: { xs: 2, sm: 2, md: 3, lg: 3 } }}><Icon.QuestionAnswerOutlined />Chat</Button>
							</Link>
							:
							<Link to={listerChatUrl}>
								<Button variant="light" size="small" sx={{ width: '87px', fontSize: '13px', lineHeight: '20px', mb: { xs: 2, sm: 2, md: 3, lg: 3 } }}><Icon.QuestionAnswerOutlined />Chat</Button>
							</Link>
						}
						{
							<Box id="refundDetails"
								sx={{
									display: 'flex',
									flexDirection: 'column',
									mb: { xs: 2, sm: 2, md: 3, lg: 3 },
									flex: "50%",
									borderRadius: 2,
									width: "100%",
								}}>
								<Typography sx={{ color: 'var(--grey900)', }}>Refund Details</Typography>
								<Box>
									<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										<Typography>Total Amount Paid</Typography>
										<Typography>${parseFloat(extractDataFromArray(bookingData, ['refund', 'payment'], '')).toFixed(2)}</Typography>
									</Box>
									<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '14px' }}>
										<Typography>Deductions</Typography>
										<Typography>${parseFloat(extractDataFromArray(bookingData, ['refund', 'balance'], '')).toFixed(2)}</Typography>
									</Box>
									<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										<Typography fontWeight={'600'} >Refund Amount</Typography>
										<Typography fontWeight={'bold'}>${parseFloat(extractDataFromArray(bookingData, ['refund', 'refund'], '')).toFixed(2)}</Typography>
									</Box>
								</Box>
							</Box>
						}
					</Box>
				</>
			}

			{/* Dialog to set Check in Date. */}
			<Dialog open={checkInOpen} onClose={() => { setCheckInOpen(false); setLoaderButton(false); }} sx={{ '& .MuiPaper-root': { maxWidth: '768px' } }}>
				<Box sx={{
					display: 'flex', alignItems: 'center',
					justifyContent: 'center', width: '100%', justifyContent: 'space-between'
				}}>
					<DialogTitle sx={{ fontWeight: 'bold' }}>Set Check In Date & Time</DialogTitle>
					<IconButton sx={{ height: '42px' }} onClick={() => { setCheckInOpen(false); setLoaderButton(false) }}>
						<Icon.Close />
					</IconButton>
				</Box>
				<Divider />
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<StaticDateTimePicker
						slots={{ field: SingleInputDateRangeField }}
						onOpen={() => setCheckInOpen(true)}
						disableHighlightToday
						defaultValue={dayjs(customCheckInDate)}
						orientation={!isNineHundred && "landscape"}
						onChange={(date) => {
							// console.log(date)
							setCustomCheckInDate(date)
						}}
						slotProps={{
							actionBar: { actions: [] }
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								backgroundColor: 'white',
							},
							"& .MuiPickersLayout-landscape": { minWidth: '100%' },
							"&. MuiInputBase-root": {
								display: 'none'
							},
							"& .MuiFormControl-root": {
								display: 'none'
							}
						}}
					/>
				</LocalizationProvider>
				<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', px: 3, pb: 3 }}>
					<Button variant="text" sx={{ color: 'var(--black)' }}
						onClick={() => {
							setCheckInOpen(false)
							setLoaderButton(false)
						}} >
						Cancel
					</Button>
					<Button variant="contained" onClick={() => {
						// console.log(customCheckInDate.format('YYYY-MM-DD hh:mm:ss'), 'customCheckInDate')
						const checkedInDate = customCheckInDate.format('YYYY-MM-DD HH:mm:ss')
						BookingService.checkIn(bookingId, checkedInDate, setLoaderButton)
						setCheckInOpen(false);
					}}>
						Save
					</Button>
				</Box>
			</Dialog >


			{/* Dialog to set checkout date. */}
			<Dialog open={checkOutOpen} onClose={() => { setCheckOutOpen(false); setLoaderButton(false); }} sx={{ '& .MuiPaper-root': { maxWidth: '768px' } }}>
				<Box sx={{
					display: 'flex', alignItems: 'center',
					justifyContent: 'center', width: '100%', justifyContent: 'space-between'
				}}>
					<DialogTitle sx={{ fontWeight: 'bold' }}>Set Check Out Date & Time</DialogTitle>
					<IconButton sx={{ height: '42px' }} onClick={() => { setCheckOutOpen(false); setLoaderButton(false) }}>
						<Icon.Close />
					</IconButton>
				</Box>
				<Divider />
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<StaticDateTimePicker
						slots={{ field: SingleInputDateRangeField }}
						onOpen={() => setCheckOutOpen(true)}
						disableHighlightToday
						orientation={!isNineHundred && "landscape"}
						value={dayjs(customCheckOutDate)}
						onChange={(date) => {
							setCustomCheckOutDate(date)
						}}
						slotProps={{
							actionBar: { actions: [] }
						}}
						sx={{
							'& .MuiOutlinedInput-root': {
								backgroundColor: 'white',
							},
							"& .MuiPickersLayout-landscape": { minWidth: '100%' },
							"&. MuiInputBase-root": {
								display: 'none'
							},
							"& .MuiFormControl-root": {
								display: 'none'
							}
						}}
					/>
				</LocalizationProvider>
				<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', px: 3, pb: 3 }}>
					<Button variant="text" sx={{ color: 'var(--black)' }}
						onClick={() => {
							setCheckOutOpen(false)
							setLoaderButton(false)
						}} >
						Cancel
					</Button>
					<Button variant="contained" onClick={() => {
						// console.log(customCheckOutDate.format('YYYY-MM-DD hh:mm:ss'), 'customcheckindate')
						const checkedOutDate = customCheckOutDate.format('YYYY-MM-DD HH:mm:ss')
						BookingService.checkOut(bookingId, checkedOutDate, setLoaderButton)
						setCheckOutOpen(false);
					}}>
						Save
					</Button>
				</Box>
			</Dialog >
		</Box >
	)
}


const ListerInfo = ({ name, image, seq, bookingData }) => {
	const guestChatUrl = window.location.origin + '/chat/' + (extractDataFromArray(bookingData, ['guest', 'name'], "")).split(" ")[0] + '/' + (extractDataFromArray(bookingData, ['lister', 'seq'])) + '?contact'
	const listerChatUrl = window.location.origin + '/landowner/chat/' + (extractDataFromArray(bookingData, ['lister', 'name'])).split(" ")[0] + '/' + (extractDataFromArray(bookingData, ['guest', 'seq']))
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: { xs: 2, sm: 2, md: 3, lg: 3 }, borderRadius: theme => `${theme.card.borderRadius}`, backgroundColor: 'var(--grey300)' }}>
			<Avatar src={image} alt="Lister Image" sx={{ width: 75, height: 75, mb: 1 }} />
			<Typography variant="caption" sx={{ color: 'var(--grey900)' }}>{config.module === "guest" ? "Hosted by" : "Booked by"}</Typography>
			<Typography variant="h6" sx={{ fontWeight: 'bold', }}>{name}</Typography>
			{/* <Link to={ROUTE_CHAT + "-" + seq}><Button variant="light" size="small">Contact Henry</Button></Link> */}
			{config.module === 'guest' ?
				<Link to={guestChatUrl}>
					<Button variant="light" size="small" sx={{ fontSize: '13px', lineHeight: '20px', mb: { xs: 2, sm: 2, md: 3, lg: 3 } }}>Contact {name.split(" ")[0]}</Button>
				</Link>
				:
				<Link to={listerChatUrl}>
					<Button variant="light" size="small" sx={{ fontSize: '13px', lineHeight: '20px', mb: { xs: 2, sm: 2, md: 3, lg: 3 } }}>Contact {name.split(" ")[0]}</Button>
				</Link>
			}
		</Box>
	)
}


const BookingDetails = () => {
	const [loading, setLoading] = useState(true)
	const [loaderConfirmButton, setLoaderConfirmButton] = useState(false)
	const [loaderDeclineButton, setLoaderDeclineButton] = useState(false)
	const [loaderCancelButton, setLoaderCancelButton] = useState(false)

	const [booking, setBooking] = useState({})
	// console.log(booking)
	const [popupConfig, setPopupConfig] = useState({ open: false })
	const [confirmPopupConfig, setConfirmPopupConfig] = useState({ open: false })
	const { bookingId } = useParams()
	const { isTablet } = CommonContext()
	const [status, setStatus] = useState(extractDataFromArray(booking, ["booking", "booking_status"], "R")
	)
	const location = useLocation();
	const packageType = new URLSearchParams(location.search).get('package_type');
	// console.log(status,'status in booking details')



	const navigate = useNavigate()
	const getBookingDetails = () => {
		BookingService.getBookingDetails(bookingId, (data) => {
			setLoading(false)
			// setLoaderButton(false)
			setBooking(data)
			setStatus(extractDataFromArray(data, ["booking", "booking_status"], "R"))
		}, (errorMessage) => {
			toast.error(errorMessage, {
				theme: 'colored',
			})
			navigate('/unauthorized', { state: { errorMessage } });
			// navigate("/404")
		})
	}

	const closePopup = () => {
		setPopupConfig({ open: false })
	}
	const openPopup = (config) => {
		setPopupConfig({ open: true, ...config })
	}

	//    const starLoaderButton = () => {
	// 	setLoaderButton(true)
	//    }

	useEffect(() => {
		// console.log('useeffect triggered')
		getBookingDetails()
		// eslint-disable-next-line react-hooks/exhaustive-deps
		// setStatus(extractDataFromArray(booking, ['booking', 'booking_status'], ''));
	}, [bookingId, status])

	let user_name, user_pic, user_seq;
	if (config.module === 'lister') {
		user_name = extractDataFromArray(booking, ['guest', 'name'], '');
		user_pic = extractDataFromArray(booking, ['guest', 'profile_pic'], '');
		user_seq = extractDataFromArray(booking, ['guest', 'seq'], '');
	} else {
		user_name = extractDataFromArray(booking, ['lister', 'name'], '');
		user_pic = extractDataFromArray(booking, ['lister', 'profile_pic'], '');
		user_seq = extractDataFromArray(booking, ['lister', 'seq'], '');
	}

	useEffect(() => {
		showZendeskLauncher(true)
	}, [])

	return (
		<>
			<Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: { xs: 0, sm: 0, md: 3, lg: 3 }, }}>
				<IconButton sx={{ width: "42px", height: "42px" }} onClick={() => { navigate(`${ROUTE_BOOKINGS}`) }}> <Icon.ArrowBack /></IconButton>
				<Typography variant="h4" >
					Booking Details
				</Typography>
				{/* {getBookingStatusChip(status)} */}
			</Box>
			<Box sx={{ p: { xs: 2, sm: 2, md: 3, lg: 3 }, }}>
				<Grid container spacing={!isTablet ? 3 : 2}>
					<Grid item md={7} sm={12} xs={12}>
						{
							loading && <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: 800 }} />
						}
						{
							!loading && <BookingInfo bookingData={booking} status={status} bookingId={bookingId} openPopup={openPopup} openConfirmPopup={() => { setConfirmPopupConfig({ open: true }) }}
								starLoaderConfirmButton={() => { setLoaderConfirmButton(true) }}
								startLoaderDeclineButton={() => { setLoaderDeclineButton(true) }}
								loaderConfirmButton={loaderConfirmButton}
								loaderDeclineButton={loaderDeclineButton}
								loaderCancelButton={loaderCancelButton}
							/>
						}
					</Grid>
					<Grid item md={5} sm={12} xs={12}>
						{
							loading && <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: 400 }} />
						}
						{
							!loading && <ListerInfo name={decodeHTML(user_name)} image={user_pic} seq={user_seq} bookingData={booking} />
						}
					</Grid>
				</Grid>
			</Box>
			{
				!loading &&
				<>
					<CancellationPopup open={popupConfig.open} handleClose={closePopup} bookingData={booking} type={popupConfig.type} status={status} setStatus={setStatus} getBookingDetails={getBookingDetails} setLoaderDeclineButton={setLoaderDeclineButton} />
					<ConfirmationPopup open={confirmPopupConfig.open} handleClose={() => { setConfirmPopupConfig({ open: false }) }} bookingID={bookingId} loaderConfirmButton={loaderConfirmButton} setLoaderConfirmButton={setLoaderConfirmButton} getBookingDetails={getBookingDetails} />

				</>
			}
		</>
	);
};


export default BookingDetails;