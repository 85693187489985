const config = {
    module: process.env.REACT_APP_MODULE,
    apiUrl: process.env.REACT_APP_ADMIN_API_URL,
    loginURL: process.env.REACT_APP_LOGIN_API_URL,
    magazineURL: process.env.REACT_APP_MAGAZINE,
    searchURL : process.env.REACT_APP_SEARCH_URL,
    landownerPdf : process.env.REACT_APP_LANDOWNDER_PDF,
    privacyTermsLink : process.env.REACT_APP_PRIVACY_TERMS_AND_CONDITION,
    bookingPageUrl: process.env.REACT_APP_BOOKING_PAGE_URL,
    searchFarwideApiUrl: process.env.REACT_APP_SEARCH_FARWIDE_API_URL,
    farwideMserviceUrl : process.env.REACT_APP_FARWIDE_M_SERVICE_URL,
    farwideBaseUrl: process.env.REACT_APP_FARWIDE_BASE_URL
};

export default config;