import { Avatar, Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CommonContext } from "../../../../store/context/commonContext";
import { UserAuth } from "../../../../store/context/userContext";
import config from "../../../../config";
import { decodeHTML, extractDataFromArray } from "../../../../utils/common";
import moment from "moment";
import { MessageContext } from "../../../../store/context/messageContext";
import MessageChip from "../MessageChip"

const ConnectionList = ({
  setResetScroll,
  fetchUserMessages,
  conversation,
  setActive,
  i,
  active,
  setSearch,
  fetchConnections,
  setLoadingUser
}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();
  const [toggle, setToggle] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [seqUser, setSeqUser] = useState('')
  const [conversationSeq, setConversationSeq] = useState()
  let isToday
  const { formattedDate, formattedTime } = formatDateTime(conversation);

  const { setLastListReadMessageSeq, setIntervalTime, isMobile, setIsMessageDashboard, setIsShowMessages, setIsProfile, setSenderSeqToSendChat, scrollableDivRef, setToBottom, setSenderTypeToSendChat, seqValue,   } = MessageContext();


  useEffect(() => {
    if (location.pathname === `/chat/${extractDataFromArray(conversation, ['from', 'seq'], '') === seqValue ?  extractDataFromArray(conversation, ['to', 'fname'], '') :  extractDataFromArray(conversation, ['from', 'fname'], '')}` && !selectedUser) {
      navigate(`/chat/${extractDataFromArray(conversation, ['from', 'seq'], '') === seqValue ? extractDataFromArray(conversation, ['to', 'fname'], '') : extractDataFromArray(conversation, ['from', 'fname'], '')}`);
    }
    setLoadingUser(false)
  }, [])

  
  useEffect(() => {
    setSeqUser(extractDataFromArray(conversation, ['conversation', 'seq'], '')) 
  }, [seqUser])



  function formatDateTime(conversation) {
    const dateTime = extractDataFromArray(conversation, ['create_time'], ''); 
    let local_date = moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss');
  
    const [date, time] = local_date.split(" ");
  
    const currentDate = new Date();
     isToday = date === currentDate.toISOString().split("T")[0];
  
    const [hours, minutes, seconds] = time.split(":");
  
    const formattedTime = isToday
      ? `${parseInt(hours, 10) % 12 || 12}:${minutes}${parseInt(hours, 10) >= 12 ? " PM" : " AM"}`
      : time;
  
    const providedDate = moment(date);
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedDate =
      providedDate.toISOString().split("T")[0] === yesterday.toISOString().split("T")[0]
        ? "Yesterday"
        : providedDate.format('MM/DD/YY');
  
    return { formattedDate, formattedTime };
  }
  


  let messageCount;

  switch (true) {
    case extractDataFromArray(conversation,['unread_message_count'], '') == 0:
      messageCount = 0;
      break;
    case extractDataFromArray(conversation,['unread_message_count'], '') > 9:
      messageCount = '9+';
      break;
    default:
      messageCount = extractDataFromArray(conversation,['unread_message_count'], '');
  }


  const handleConversationClick = () => {
          setIntervalTime(300)
          setSeqUser(conversation.conversation.seq)
          setConversationSeq(extractDataFromArray(conversation, ['conversation', 'seq'], ''))
          fetchUserMessages(extractDataFromArray(conversation, ['conversation', 'seq'], ''))
          setResetScroll(true)
          setLastListReadMessageSeq(conversation?.seq)
          setTimeout(() => {
            fetchConnections()
            setIntervalTime(20000)
            setSearch("")
            scrollableDivRef?.current?.scrollIntoView();
          }, 400)
          setSenderSeqToSendChat(extractDataFromArray(conversation, ['conversation', 'seq']))
          if(config.module === 'lister'){
             setSenderTypeToSendChat("G");
          } else{
            setSenderTypeToSendChat("L");
          }
     
          setToggle(false);
   
          if (isMobile) {
            setIsMessageDashboard(false);
            setIsShowMessages(true);
            setIsProfile(false);
          }
          setToBottom(true)

          navigate(`/chat/${extractDataFromArray(conversation, ['conversation', 'fname'], '')}/${extractDataFromArray(conversation, ['conversation', 'seq'], '')}`);
  };

  return (
    <Box>

      <Box
        key={i}
        sx={
          isMobile
            ? {
              display: "flex",
              padding: 2,
              gap: 1.25,
              alignItems: "flex-start",
              borderBottom: "1px solid #E5E7EB",
              cursor: "pointer",
              backgroundColor: "white",
            }
            : param.seq === extractDataFromArray(conversation,['conversation','seq'],'')
              ? {
                display: "flex",
                padding: 2,
                gap: 1.25,
                alignItems: "flex-start",
                borderBottom: "1px solid #E5E7EB",
                cursor: "pointer",
                backgroundColor: "#F5F5F6",
              }
              : {
                display: "flex",
                padding: 2,
                gap: 1.25,
                alignItems: "flex-start",
                borderBottom: "1px solid #E5E7EB",
                cursor: "pointer",
                backgroundColor: "white",
              }
        }
        onClick={() => {
          handleConversationClick()
        }}
      >
        <Box>
          <Avatar
            alt="Remy Sharp"
            src={
               extractDataFromArray(conversation, ['conversation', 'profile_pic'], '')
            }
            sx={{ width: 40 }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0.375,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" className="ellipsis" sx={{ fontWeight: 600 }}>
              {decodeHTML( extractDataFromArray(conversation,['conversation', 'fname'], '') + " " + extractDataFromArray(conversation,['conversation', 'lname'], ''))}
            </Typography>
              <Typography variant="caption" sx={{ color: "#85878A" }}>
                {isToday ? formattedTime : formattedDate}
              </Typography>
          </Box>

          <MessageChip messageType={extractDataFromArray(conversation,['messages', 'message_type'], '')} />
          
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
            variant="caption"
              sx={conversation.status === "R" ? {
               
                color: "#85878A",
                width:225,
                whiteSpace: "nowrap",
                overflow: "hidden ",
                textOverflow: "ellipsis !important"
              }
                : {
                 
                  color: "#85878A",
                  width: 200,
                  whiteSpace: "nowrap",
                  overflow: "hidden ",
                  textOverflow: "ellipsis !important",
                }}
            >
               {extractDataFromArray(conversation,['messages', 'message'], '')}
            </Typography>

            { extractDataFromArray(conversation,['unread_message_count'], '') == 0 ?
              ""
              :
              <Box
                sx={{
                  fontSize: "12px",
                  backgroundColor: "#26C77A",
                  borderRadius: "50%",
                  width: 21,
                  height: 21,
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {messageCount}
              </Box>
            }

          </Box>

        </Box>
      </Box>

    </Box>
  );
};

export default ConnectionList;
