import { Box, Button, Typography } from "@mui/material";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Page404 = () => {
  const history = useNavigate();
  const location = useLocation();

//   useEffect(() => {

    if (location.pathname.includes('/lister')) {

      const search = location.search
    
      const newPathname = location.pathname.replace('/lister', '/landowner');
        
      window.location.href = window.location.origin + newPathname + search
      return<></>

    } 
    // else if(location.pathname.includes('/landowner/')) {
    //     const search = location.search

    //     window.location.href = window.location.origin + location.pathname + search
    // }  
    else {
        console.log("second");
        window.history.replaceState(null, null, window.location.origin + "/404");
        return (
            <>
                <Box sx={{textAlign:'center', mt:10, p:3}}>
                    <img src="/assets/images/404.svg" alt="404" style={{ maxWidth: '580px' }} />
                    <Typography variant="h4">Page Not Found</Typography>
                    <p>You can search for the page you want here or return to the homepage.</p>
                    <Button component={Link} to="/"  variant="contained" sx={{mt:3}}>Homepage</Button>
                </Box>
            </>
        )
    }

//   }, [history, location.pathname]);



}


export default Page404;

// import { Box, Button, Typography } from "@mui/material";
// import { useEffect } from "react";
// import { Link, useLocation, useNavigate } from "react-router-dom";

// const Page404 = () => {
//   const history = useNavigate();
//   const location = useLocation();

//   // useEffect(() => {

//   if (location.pathname.includes('/lister')) {
//     const search = location.search;
//     const newPathname = location.pathname.replace('/lister', '/landowner');
//     window.location.href = window.location.origin + newPathname + search;
//     return <></>;
//   } else if (location.pathname.toLowerCase().includes('/landowner/')) {
//     const search = location.search;
//     const lowercasePathname = location.pathname.replace(/\/landowner\//i, '/landowner/');
//     window.location.href = window.location.origin + lowercasePathname + search;
//   } else {
//     window.history.replaceState(null, null, window.location.origin + "/404");
//     return (
//       <>
//         <Box sx={{ textAlign: 'center', mt: 10, p: 3 }}>
//           <img src="/assets/images/404.svg" alt="404" style={{ maxWidth: '580px' }} />
//           <Typography variant="h4">Page Not Found</Typography>
//           <p>You can search for the page you want here or return to the homepage.</p>
//           <Button component={Link} to="/" variant="contained" sx={{ mt: 3 }}>Homepage</Button>
//         </Box>
//       </>
//     );
//   }

//   //   }, [history, location.pathname]);

// }

// export default Page404;
