import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios } from "../utils/common";
import { toast } from "react-toastify";

const WishlistService = {


	getWishlist: async (type, type_seq, sort, kw, start, limit, callback = () => { }) => {
		await axios.get(config.apiUrl + "/services/api", {
			params: {
				cmd: "getWishlist",
				type: type,
				type_seq: type_seq,
				sort: sort,
				kw: kw,
				start: start,
				limit: limit,

			}
		}).then((response) => {
			response = parseResponseAxios(response)
			if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
				callback(extractDataFromArray(response, ['data'], []))
			}
		});
	},


	saveWishlist: async (type, type_seq, callback = () => { }) => {
		await axios({
			url: config.apiUrl + "/services/api",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			method: 'post',
			data: {
				cmd: "saveWishlist",
				type_seq: type_seq,
				type: type,

			}
		}).then((response) => {
			response = parseResponseAxios(response)
			if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
				callback(extractDataFromArray(response, ['data'], []), extractDataFromArray(response, ['errorMsg'], ''))
			}
		});
	},
	removeFromWishlist: async (type, type_seq, callback = () => { }) => {
		await axios({
			url: config.apiUrl + "/services/api",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			method: 'post',
			data: {
				cmd: "removeFromWishlist",
				type: type,
				type_seq: type_seq,

			}
		}).then((response) => {
			response = parseResponseAxios(response)
			if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
				callback(extractDataFromArray(response, ['data'], []), extractDataFromArray(response, ['errorMsg'], []))
			}
		});
	},
	clearWishlist: async (type, callback = () => { }) => {
		await axios({
			url: config.apiUrl + "/services/api",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			method: 'post',
			data: {
				cmd: "clearWishlist",
				type: type,
			}
		}).then((response) => {
			response = parseResponseAxios(response);
			if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
				callback(extractDataFromArray(response, ["data"], []));
			}
		});
	},
	isWishlist: async (type_seq, type, callback = () => { }) => {
		await axios
			.get(config.apiUrl + "/services/api?cmd=isWishlist", {
				params: {
					type_seq,
					type
				}
			})
			.then((response) => {
				// console.log(response)
				response = parseResponseAxios(response);
				if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
					callback(extractDataFromArray(response, ["data"], []));
				}
			});
	},
};

export default WishlistService;
