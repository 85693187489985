import React, { useEffect, useState, useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';

import SwiperCore from "swiper/core";


// Initialize Swiper modules

//photo swipe/lightbox
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

// Custom Component
import EnquiryPopUp from "../EnquiryPopUp"

import PackageService from "../../services/package";
import ListingService from "../../services/listing";
import WishlistService from "../../services/wishlist";
import ReviewService from "../../services/review";
import { extractDataFromArray } from "../../utils/common";

import config from "../../config";
import Header from "../previewPageComponents/Header";
import About from "../previewPageComponents/About";
import PackageList from "../previewPageComponents/PackageList";
import Lodging from "../previewPageComponents/Lodging";
import GroundRules from "../previewPageComponents/GroundRules";
import SkeletonPropertyPreview from "../placeholders/SkeletonPropertyPreview";
import { useNavigate, useParams } from "react-router";
import { Avatar, Box, Button, Card, Grid, Typography } from "@mui/material";
import HeaderGallery from "../previewPageComponents/HeaderGallery";
import Location from "../previewPageComponents/Location";
import LandOwner from "../previewPageComponents/LandOwner";
import { Navigation } from "swiper/modules";
import { Girl } from "@mui/icons-material";
import { CommonContext } from "../../store/context/commonContext";
import AdminBanner from "../previewPageComponents/AdminBanner";
import ReviewRecievedCard from "../revieRecievedCard";
import { Link } from "react-router-dom";


SwiperCore.use([Navigation]);


const PropertyDetails = (props) => {
	const [loading, setLoading] = useState(true)
	const { errorMessage, setErrorMessage } = CommonContext()
	const { property_id } = useParams()
	let listingSeq = property_id.split("-")
	listingSeq = listingSeq[listingSeq.length - 1]
	const [listingData, setListingData] = useState(null);
	const [packages, setPackages] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		ListingService.getListingData(listingSeq, (data) => {
			setLoading(false)
			setListingData(data);
			window.history.replaceState(null, null, window.location.origin + (config.module === 'lister' ? "/landowner" : '') + "/property/" + data.slug + "-" + listingSeq);
		}, (errorMessage) => {
			navigate('/unauthorized', { state: { errorMessage } });
		}

		);

		async function getAllPackagesData() {
			await PackageService.getAllListingPackagesData(listingSeq, '', (data) => {
				// Filter the packages based on the listing_seq
				const filteredPackages = data.filter(
					(pkg) => parseInt(pkg.listing_seq) === parseInt(listingSeq)
				);

				setPackages(filteredPackages);
			});
		}

		getAllPackagesData();
	}, []);


	useEffect(() => {
		let lightbox = new PhotoSwipeLightbox({
			gallery: "#headerGallery",
			children: "a",
			pswpModule: () => import("photoswipe"),
		});
		lightbox.init();

		return () => {
			// lightbox.destroy();
			lightbox = null;
		};
	}, []);
	const [openEnquiryPopup, setOpenEnquiryPopup] = useState();
	const [openModal, setOpenModal] = useState(false)
	const [open, setOpen] = useState(true);
	const [listerSeq, setListerSeq] = useState('')
	const [paymentDetails, setPaymentDetails] = useState({})
	const { isMobile, isTablet, isNineHundred } = CommonContext();

	const handleOpen = () => {
		// setOpen(true);
		setOpenEnquiryPopup(true);
	};

	const handleClose = () => {
		setOpen(false);
		setOpenEnquiryPopup(false);
	};
	const sectionRef = useRef(null);

	const handleButtonClick = () => {
		sectionRef.current.scrollIntoView({ behavior: "smooth" });
	};


	useEffect(() => {

		setListerSeq(extractDataFromArray(listingData, ['lister_seq'], ''))

	}, [listingData])

	useEffect(() => {

		config.module !== 'admin' && PackageService.getCancellationPolicyData('lister', listerSeq, (data) => {
			setPaymentDetails(data)
			// setCancelReasons(data.cancelationResaons)
		})
	}, [listerSeq])

	if (loading) {
		return <SkeletonPropertyPreview />
	}


	return (
		<>
			<div>
				{openEnquiryPopup && (
					<EnquiryPopUp
						open={openEnquiryPopup}
						packages={packages}
						handleClose={handleClose}
						listingData={listingData}
						setOpenEnquiryPopup={setOpenEnquiryPopup}
					/>
				)}
			</div>
			<Grid container spacing={8} sx={{ p: 2 }}>
				{
					config.module === 'admin' &&
					<Grid item md={12} xs={12}>
						<AdminBanner
							lister_name={extractDataFromArray(listingData, ['fname'], 'Test') + " " + extractDataFromArray(listingData, ['lname'], 'Test')}
							lister_pic={extractDataFromArray(listingData, ['profile_pic'], '')}
							lister_seq={extractDataFromArray(listingData, ['lister_seq'], '')}
							listing_seq={extractDataFromArray(listingData, ['listing_seq'], '')}
							status={extractDataFromArray(listingData, ['verification_status'], '')}
							listing_type={'access'}
						/>
					</Grid>
				}
				<Grid item md={12} xs={12} sm={12}>
					<Box sx={{ display: 'flex', flexDirection: { sm: 'column', xs: 'column-reverse' }, width: '100%', mb: { xs: 0, sm: 0, md: 6 } }}>
						<Header
							type="property"
							seq={extractDataFromArray(listingData, ['listing_seq'], 0)}
							listing_seq={extractDataFromArray(listingData, ['listing_seq'], 0)}
							title={extractDataFromArray(listingData, ['title'], '')}
							rating={extractDataFromArray(listingData, ['average_rating'], '4.5')}
							reviewCount={extractDataFromArray(listingData, ['review_count'], '0')}
							address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
							areaSize={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
							nearestTown={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
							ltln={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_ltln'], '')}
						/>
						<HeaderGallery images={extractDataFromArray(listingData, ['listing_media', 'media_library'], [])} type="property" seq={extractDataFromArray(listingData, ['listing_seq'], 0)} />
					</Box>
				</Grid>
			</Grid>

			<Grid container spacing={!isMobile && 8} sx={{ p: 2 }}>
				<Grid item xs={12} sm={12} md={7} >
					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						<About desc={extractDataFromArray(listingData, ['description'], '')} />
					</Grid>

					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						<PackageList packages={packages} listingData={listingData} sectionRef={sectionRef} />
					</Grid>
					{
						extractDataFromArray(listingData, ["listing_json", "lodging_details", "available"], 'No') === "Yes" ?
							<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
								<Lodging
									data={extractDataFromArray(listingData, ["listing_json", "lodging_details"], {})}
								/>
							</Grid> : <></>
					}
					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						<GroundRules
							arrivalInstructions={extractDataFromArray(listingData, ["listing_json", "arrival_instructions"], {})}
							groundRules={extractDataFromArray(listingData, ["listing_json", "ground_rule"], {})}
						/>
					</Grid>

					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						{extractDataFromArray(listingData, ['listing_json', 'arrival_instructions', 'land_owner_tips'], '') ?
							<Box>
								<Typography sx={{ color: '#1b1b1b', fontWeight: '500' }}>Landowner Rules : </Typography>
								<Typography color="grey" sx={{ mb: 2 }}>
									{
										extractDataFromArray(listingData, ['listing_json', 'arrival_instructions', 'land_owner_tips'], '')
									}
								</Typography>
							</Box> : ""
						}
						<Typography sx={{ fontWeight: '500', textDecoration: 'underline' }} >Cancellation Policy:</Typography>
						<Typography color="grey" sx={{ mb: 5 }}>
							{extractDataFromArray(paymentDetails, ['messageToguest'], '')}
						</Typography>
					</Grid>

					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						<ReviewRecievedCard listingData={listingData} type={"L"} listingSeq={listingSeq}/>
					</Grid>

					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						{/* <LandOwner /> */}
						<LandOwner listingData={listingData} setOpenEnquiryPopup={setOpenEnquiryPopup} />
					</Grid>
					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						<Location lat={parseFloat(extractDataFromArray(listingData, ['lat'], 0))} lon={parseFloat(extractDataFromArray(listingData, ['lon'], 0))} />
					</Grid>


				</Grid>
				{
					isNineHundred &&
					<Grid item xs={12} sm={12} md={5}>
						<Box sx={{ background: '#fff', position: 'fixed', bottom: '0', left: '0', width: '100%', padding: '0 16px 16px 16px', boxShadow: '0 0px 18px 0 #00000020;', zIndex: '99' }}>
							<Button
								fullWidth
								sx={{ mt: 2, borderRadius: '8px', backgroundColor: '#E0F7EC', textTransform: 'none' }}


								onClick={handleButtonClick}
							>
								View ({packages.length}) Packages
							</Button>
						</Box>
					</Grid>
				}
				{
					!isNineHundred &&
					<Grid item xs={12} sm={12} md={5}>

						{config.module
							=== 'guest' && packages ? (
							<Card
								sx={{
									p: 3,
									borderRadius: (theme) => `${theme.card.borderRadius} `,
								}}
								variant="outlined"
								className="propdetails_right_card"
							>
								<Box sx={{ display: "flex" }}>
									<Typography>
										Price starts at &nbsp;
									</Typography>
									<Typography variant="body1" sx={{ fontWeight: 700 }}> $
										{Math.min(
											...packages.map((item) => item.package_json.price_per_guest)
										)}
									</Typography>
								</Box>
								<Button
									fullWidth
									size="large"
									sx={{ mt: 2, borderRadius: '8px', backgroundColor: '#E0F7EC', textTransform: 'none', fontSize: "1rem" }}


									onClick={handleButtonClick}
								>
									View ({packages.length}) Packages
								</Button>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										mt: 3,
										alignItems: "center"
									}}
								>
									<div>
										<Typography variant="caption" className="grey900">
											Hosted by
										</Typography>
										<Typography variant="h6" sx={{ fontWeight: 700 }} >{extractDataFromArray(listingData, ['fname'], '')} {extractDataFromArray(listingData, ['lname'], '')}</Typography>

										{config.module === "guest" && <Link to='' >
											<Button size="small" variant="light" sx={{ fontWeight: 600 }} onClick={handleOpen}>
												{"Contact " + extractDataFromArray(listingData, ['fname'], '')}
											</Button>
										</Link>}

									</div>
									<div>
										<Avatar
											className="ft_hdr_avtar"
											sx={{ width: 75, height: 75 }}
											alt="Danny Sharp"
											src={extractDataFromArray(listingData, ['profile_pic'], '')}
										/>
									</div>
								</Box>
							</Card>
						) : (
							<p></p>
						)}
					</Grid>
				}
			</Grid>
		</>
	);
};

export default PropertyDetails;
