import * as React from "react";
import { useEffect, useState } from "react";
import { Avatar, Badge, Box, Button, Chip, Dialog, DialogTitle, Divider, Grid, IconButton, MenuItem, Pagination, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import PackageCard from "../../commonComponents/PackageCard";
import * as Icon from '@mui/icons-material';
import SearchMap from "../../projectComponents/Map";
import { CommonContext } from "../../store/context/commonContext";
import FilterPopup from "./FilterPopup";
import ExpAcitvitiesFilterPopUp from "./ExpActivitieFilterPopUp"
import SearchService from "../../services/search";
import { empty, extractDataFromArray, getPlaceName, isEmpty, hasEmptyStrings, hasStrings } from "../../utils/common";
import { Link, useSearchParams } from "react-router-dom";
import SkeletonPackageCard from "../../commonComponents/placeholders/SkeletonPackageCard";
import CustomDateRangePicker from "../../projectComponents/CustomDateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileDateRangePicker, SingleInputDateRangeField, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { date } from "yup";
import dayjs from "dayjs";
import { ROUTE_PROPERTY } from "../../constants/page";
import DurationFilter from "./DurationFilter";
import PriceFilter from "./PriceFilter";
import { useRef } from "react";



const SearchListing = () => {
    const [loading, setLoading] = useState(true)
    const { searchFilters, setSearchFilters, showSearchType, setShowSearchType, clikedBanner, setClikedBanner, durationFromContext, setDurationFromContext, priceFromContext, setPriceFromContext, datesFromContext, setDatesFromContext, screenWidth } = CommonContext()
    const [results, setResults] = useState([])
    const [speciesData, setSpeciesData] = useState({})
    const [activitiesData, setActivitiesData] = useState({})
    const [pageCount, setPageCount] = useState(0)
    const [count, setCount] = useState(0)
    const [popupConfig, setPopupConfig] = useState({ open: false, type: '' })
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedActivity, setSelectedActivity] = useState('');
    const { isNineHundred } = CommonContext();
    const [isActive, setIsActive] = useState(false);
    const [isActivesp, setIsActivesp] = useState(false);
    const [isActiveduration, setIsActiveduration] = useState(false);
    const [isActivitySeleted, setIsActivitySeleted] = useState(false);
    const [isSortSeleted, setIsSortSeleted] = useState(false);
    const [isActivePrice, setIsActivePrice] = useState(false);
    const [mapView, setMapView] = useState(false)
    const [listingSeq, setListingSeq] = useState('')
    const handleFilterOpen = (type) => {
        setPopupConfig({ open: true, type });
    };
    const [openExpActivity, setOpenExpActivity] = useState(false)
    const [openDurationFilter, setOpenDurationFilter] = useState(false)
    const [openPricingFilter, setOpenPricingFilter] = useState(false)
    const [durationRange, setDurationRange] = useState([1, 480]);
    const [durationRangeToShow, setDurationRangeToShow] = useState(!hasEmptyStrings(durationFromContext) ? durationFromContext : [1, 480]);
    const [priceRangeToShow, setPriceRangeToShow] = useState(!hasEmptyStrings(priceFromContext) ? priceFromContext : [10, 100000]);
    const [dateRangeToShow, setDateRangeToShow] = useState(!hasEmptyStrings(datesFromContext) ? [dayjs(datesFromContext[0]), dayjs(datesFromContext[1])] : ['', '']);
    const [dateRange, setDateRange] = useState([null, null])
    const [initialDateRange, setInitialDateRange] = useState([null, null]);
    const [pricingRange, setPricingRange] = useState([10, 100000]);
    const [selectedActivities, setSelectedActivities] = useState([])
    const [urlParams, setUrlParams] = useState(new URLSearchParams(window.location.search))
    const [callGetCount, setCallGetCount] = useState(false)
    const [applyFilterValues, setApplyFilterValues] = useState(false)
    const [isFilterInUrlParams, setIsFilterInUrlParams] = useState({
        mnd: false,
        mxd: false,
        mnp: false,
        mxp: false,
        eat: false,
        sb: false,
        sp: false,
        d1: false,
        d2: false,
    })

    useEffect(() => {

        // Added this to check if values for filter in URL are present or not

        if (urlParams.has('mnd')) {
            // console.log("mnd");
            setIsFilterInUrlParams({ ...isFilterInUrlParams, mnd: true })
            setIsFilterInUrlParams({ ...isFilterInUrlParams, mxd: true })
            setShowClearFilterButton(true)

        } else {
            // setIsFilterInUrlParams({ ...isFilterInUrlParams, mnd: false })
            // setDurationRange([1, 480])
        }
        if (urlParams.has('mxd')) {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, mxd: true })
            setShowClearFilterButton(true)

        } else {
            // setIsFilterInUrlParams({ ...isFilterInUrlParams, mxd: false })
        }


        if (urlParams.has('mnp')) {
            // console.log("mnd");
            setIsFilterInUrlParams({ ...isFilterInUrlParams, mnp: true })
            setShowClearFilterButton(true)


        } else {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, mnp: false })
            // setDurationRange([1, 480])
        }

        if (urlParams.has('mxp')) {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, mxp: true })
            setShowClearFilterButton(true)

        } else {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, mxp: false })
        }

        if (urlParams.has('eat')) {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, eat: true })
            setShowClearFilterButton(true)

        } else {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, eat: false })
        }

        if (urlParams.has('sb')) {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, sb: true })
            let sortValue = urlParams.get('sb')
            if (sortValue !== 'R') {
                setShowClearFilterButton(true)
            }

        } else {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, sb: false })
        }
        if (urlParams.has('d1')) {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, d1: true })
            setShowClearFilterButton(true)

        } else {
            // setIsFilterInUrlParams({ ...isFilterInUrlParams, d1: false })
        }

        if (urlParams.has('d2')) {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, d2: true })
            setShowClearFilterButton(true)

        } else {
            // setIsFilterInUrlParams({ ...isFilterInUrlParams, d2: false })
        }

        if (urlParams.has('sp')) {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, sp: true })
            setShowClearFilterButton(true)

        } else {
            setIsFilterInUrlParams({ ...isFilterInUrlParams, sp: false })
        }


    }, [urlParams])

    useEffect(() => {

        // Added this to update values of filters from url

        // console.log("is MND", urlParams.has('mnd'), urlParams.get('mnd'));
        // console.log("is MXD", urlParams.has('mxd'), urlParams.get('mxd'));
        if (isFilterInUrlParams.mnd === true || isFilterInUrlParams.mxd === true) {
            setDurationRange([urlParams.get('mnd'), urlParams.get('mxd')])
            setDurationRangeToShow([parseInt(urlParams.get('mnd')), parseInt(urlParams.get('mxd'))])
        }



        if (isFilterInUrlParams.mnp === true || isFilterInUrlParams.mxp === true) {
            setPricingRange([urlParams.get('mnp'), urlParams.get('mxp')])
            setPriceRangeToShow([parseInt(urlParams.get('mnp')), parseInt(urlParams.get('mxp'))])
            // setSearchFilters({ ...searchFilters, mnp: parseInt(urlParams.get('mnp')) });
            // setSearchFilters({ ...searchFilters, mxp: parseInt(urlParams.get('mxp')) });
        }

        if (isFilterInUrlParams.eat === true) {
            const eatParam = urlParams.get('eat');
            const selectedFiltersFromUrl = eatParam.split(',');
            setSelectedActivities(selectedFiltersFromUrl);
            // setSearchFilters({ ...searchFilters, mnp: parseInt(urlParams.get('mnp')) });
            // setSearchFilters({ ...searchFilters, mxp: parseInt(urlParams.get('mxp')) });
        }

        if (isFilterInUrlParams.sp === true) {
            const spParam = urlParams.get('sp');
            const selectedFiltersFromUrl = spParam.split(',');
            setSelectedSpecies(selectedFiltersFromUrl);
            // setSearchFilters({ ...searchFilters, mnp: parseInt(urlParams.get('mnp')) });
            // setSearchFilters({ ...searchFilters, mxp: parseInt(urlParams.get('mxp')) });
        }

        setDateRange([urlParams.get('d1'), urlParams.get('d2')])
        if (isFilterInUrlParams.d1 === true || isFilterInUrlParams.d2 === true) {
            setDateRangeToShow([urlParams.get('d1'), urlParams.get('d2')])
            // setSearchFilters({ ...searchFilters, mnp: parseInt(urlParams.get('mnp')) });
            // setSearchFilters({ ...searchFilters, mxp: parseInt(urlParams.get('mxp')) });
        }




    }, [isFilterInUrlParams])

    const [open, setOpen] = useState(false)
    // console.log(dateRange, 'daterange')
    const { isMobile, isTablet, value, setValue, filterChanged, setFilterChanged, clearFiltersToggle, setClearFilterToggle } = CommonContext()
    const [searchType, setSearchType] = useState('access')

    const handleClose = (value) => {
        setPopupConfig({ open: false, type: '' });
    };

    const [popupOpenActivity, setPopupOpenActivity] = useState(false)

    const [selectedSpecies, setSelectedSpecies] = useState([]);
    const [selectedExpAcivities, setSelectedExpAcivities] = useState([]);
    const [showClearFilterButton, setShowClearFilterButton] = useState(false);
    const [calledClearfilter, setCalledClearfilter] = useState(false);

    const [triggerDate, setTriggerDate] = useState(false)
    const [wishlistType, setWishlistType] = useState('')

    const active = {
        width: '7px',
        height: '7px',
        backgroundColor: 'var(--black)',
        borderRadius: '50%',
        position: 'absolute',
        top: '-3px',
        right: '-3px',
    }

    useEffect(() => {
        if (open) {
            setInitialDateRange([...dateRangeToShow]);
        }
    }, [open]);


    const handleDateChange = (dates) => {
        // console.log('dates', dates)
        // check if both formatted end dates are equal
        setCallGetCount(true)
        if (dayjs(dates[1]).format('YYYY-MM-DD') === dayjs(dateRange[1]).format('YYYY-MM-DD')) {
            dates[1] = null
            setDateRangeToShow(dates)
            return
        }
        if (dates[0] != null && dates[1] != null) {
            setDateRangeToShow(dates)
            // setTriggerDate(!triggerDate)
            // setOpen(false)
        } else if (dates[0] == null && dates[1] == null) {
            setDateRangeToShow(false)
            // setTriggerDate(!triggerDate)
            // setOpen(false)
        }



    }

    //disable current date
    const today = dayjs()
    const disableCustomDates = (date) => {
        const newDate = dayjs(date);;
        // console.log("date in function", newDate);
        // console.log("today in function", today);
        // return newDate.isSame(today, 'day') // Removed it after suggestion by MD Faizan
    }

    const clearAllFilters = () => {
        setClearFilterToggle(!clearFiltersToggle)
        setCalledClearfilter(true)
        // console.log("search filters", searchFilters);
        removeFilterFromUrlParams('price')
        removeFilterFromUrlParams('duration')
        removeFilterFromUrlParams('activities')
        removeFilterFromUrlParams('species')
        removeFilterFromUrlParams('dates');
        removeFilterFromUrlParams('kw');
        setSelectedActivities([])
        setSelectedSpecies([])

        setDateRange([null, null]);
        setDateRangeToShow([null, null]);
        // setSearchFilters({ ...searchFilters, kw: '' })

        setSearchFilters({ ...searchFilters, eat: '', start_date: '', end_date: '', kw: '', mnd: '', mnp: '', mxd: '', mxp: '', sort: 'R', species: '', d1: '', d2: '' });
        addSortToUrlParams('R')
        setShowClearFilterButton(false)
    }
    // const DateBtn = <Button color="secondary" size="small" variant="outlined" onClick={() => { handleFilterOpen('date') }} >Dates</Button>
    const DateBtn =
        <Box
            sx={{
                ":hover": {
                    '& .MuiTypography-root': {
                        color: 'var(--black)',
                    },
                }
            }}
        >
            <div
                className="searchDates"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 500,
                    // width: '95px',
                    height: '100%',
                    borderRadius: '8px',
                    transition: 'border-color 0.3s',
                    color: 'var(--black)',
                    border: '1px solid #c5c5c7',
                    padding: '0 8px',
                    cursor: 'pointer',
                    borderColor: isActive ? 'var(--black)' : '#c5c5c7',
                    position: 'relative',
                    flexShrink: 0
                }}
                onClick={() => {
                    setOpen(!open);
                    // setIsActive(true);
                }}
            >
                <Typography variant="body2" sx={{ color: isActive ? '#1B1B1B' : '#85878A', '&:hover': { color: 'var(--black)' } }} >Dates</Typography>

                <span style={isActive ? active : {}}></span>
            </div>

        </Box>

    const SpeciesBtn =
        <Button sx={{
            ':hover': {
                backgroundColor: 'white', '& .MuiTypography-root': {
                    color: 'var(--black)',
                },
            }, borderColor: !isActivesp ? '#c5c5c7' : '#1b1b1b', flexShrink: 0
        }} color="secondary" size="small" variant="outlined" disabled={empty(speciesData)} onClick={() => { handleFilterOpen('species'); }}>
            <Typography variant="body2" sx={{ color: isActivesp ? '#1B1B1B' : '#85878A', '&:hover': { color: 'var(--black)' } }} >Species</Typography>
            <span style={isActivesp ? active : {}}></span>
        </Button>

    const ActivitiesBtn =

        <Button
            sx={{
                ':hover': {
                    backgroundColor: 'white',
                    '& .MuiTypography-root': {
                        color: 'var(--black)',
                    },
                },
                borderColor: !isActivitySeleted ? '#c5c5c7' : '#1b1b1b',
                // width: '115px',
                maxWidth: '115px',
                flexShrink: 0,
                textWrap: 'nowrap'
            }}
            color="secondary" size="small"
            variant="outlined"
            disabled={empty(activitiesData)}
            onClick={() => { setOpenExpActivity(true) }}
        >
            <Typography variant="body2" sx={{ color: isActivitySeleted ? '#1B1B1B' : '#85878A', '&:hover': { color: 'var(--black)' } }} >Activity Type</Typography>
            <span style={isActivitySeleted ? active : {}}></span>
        </Button>

    const DurationBtn =
        <Box
            sx={{
                ":hover": {
                    '& .MuiTypography-root': {
                        color: 'var(--black)',
                    }
                }
            }}
        >
            <div
                className="searchDates"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 500,
                    // width: '95px',
                    height: '100%',
                    borderRadius: '8px',
                    transition: 'border-color 0.3s',
                    color: 'var(--black)',
                    border: '1px solid #c5c5c7',
                    padding: '0 8px',
                    cursor: 'pointer',
                    borderColor: isActiveduration ? 'var(--black)' : '#c5c5c7',
                    position: 'relative',
                    flexShrink: 0
                }}
                onClick={() => {
                    setOpenDurationFilter(!openDurationFilter);
                    // setIsActive(true);
                }}
            >
                <Typography variant="body2" sx={{ color: isActiveduration ? '#1B1B1B' : '#85878A', '&:hover': { color: 'var(--black)' } }} >Duration</Typography>  <span style={isActiveduration ? active : {}}></span>
            </div>

        </Box>

    const PricingBtn =
        <Box
            sx={{
                ":hover": {
                    '& .MuiTypography-root': {
                        color: 'var(--black)',
                    },
                }
            }}
        >
            <div
                className="searchDates"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 500,
                    // width: '95px',
                    height: '100%',
                    borderRadius: '8px',
                    transition: 'border-color 0.3s',
                    color: 'var(--black)',
                    border: '1px solid #c5c5c7',
                    padding: '0 8px',
                    cursor: 'pointer',
                    borderColor: isActivePrice ? 'var(--black)' : '#c5c5c7',
                    position: 'relative',
                    flexShrink: 0
                }}
                onClick={() => {
                    setOpenPricingFilter(!openPricingFilter);
                    // setIsActive(true);
                }}
            >
                <Typography variant="body2" sx={{ color: isActivePrice ? '#1B1B1B' : '#85878A', '&:hover': { color: 'var(--black)' } }} >Price</Typography> <span style={isActivePrice ? active : {}}></span>
            </div>

        </Box>

    const Clearfilters =
        <Box>
            <div
                className="searchDates"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 500,
                    // width: '95px',
                    height: '100%',
                    borderRadius: '8px',
                    transition: 'border-color 0.3s',
                    // color: 'var(--primary)',
                    // border: '1px solid #c5c5c7',
                    padding: '0 8px',
                    cursor: 'pointer',
                    // borderColor: isActive ? 'var(--black)' : '#c5c5c7',
                    position: 'relative',
                    flexShrink: 0
                }}
                onClick={() => {
                    clearAllFilters()
                    // setOpen(!open);
                    // setIsActive(true);
                }}
            >
                <Typography variant="body2" sx={{ color: 'var(--primary)', textWrap: 'nowrap' }} >Clear Filters</Typography>

                {/* <span style={isActive ? active : {}}></span> */}
            </div>

        </Box>

    // Pagination 
    const [page, setPage] = React.useState(1);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    // Set Search filters
    const handleSetSearch = (value) => {
        setSearchFilters(value);
    };

    const speciesFilterChange = (selectedSpeciesString) => {
        // Reset page number to 1 when reselect species filter
        setPage(1)
        // Update the searchFilters with the selected species array
        setSearchFilters({ ...searchFilters, species: selectedSpeciesString });
    };
    const expAcitivitiesFilterChange = (selectedActivitieString) => {
        // Reset page number to 1 when reselect species filter
        setPage(1)
        // Update the searchFilters with the selected species array
        setSearchFilters({ ...searchFilters, eat: selectedActivitieString });
    };

    useEffect(() => {
        // console.log("searchParams", searchParams.get('type'));

        // Check if 'type' is present in the URL , added it to set access for initial page render 
        if (!searchParams.has("type")) {
            // If not present, set it to 'access'
            searchParams.set("type", "access");
            setSearchParams(searchParams);
            setShowSearchType('access')
            setSearchFilters({ ...searchFilters, eat: '', mnd: '', mxd: '', mnp: '', mxp: '' });
            removeFilterFromUrlParams('price')
            removeFilterFromUrlParams('duration')
            removeFilterFromUrlParams('activities')
        }

        let temp = {}
        if (searchParams.has("kw")) {
            temp['kw'] = searchParams.get('kw')
        }
        if (searchParams.has("sp")) {
            temp['sp'] = searchParams.get('sp').split(",")
        }
        if (searchParams.has("at")) {
            if (empty(searchParams.get('at'))) {
                temp['activity'] = 'A'
            } else {
                temp['activity'] = searchParams.get('at')
            }
        }
        setSearchFilters(temp)


    }, [])

    useEffect(() => {
        // console.log("seaerch filters", searchFilters);
    }, [searchFilters])

    const addFilterToUrlParams = (filterType) => {
        // console.log("filter type", filterType);
        let url = ''
        url = new URL(window.location.href);

        // console.log("URL ADD", dateRangeToShow);

        let d1 = ''
        let d2 = ''

        if (dateRangeToShow[0] !== null) {
            d1 = dateRangeToShow[0].format('YYYY-MM-DD')
            d2 = dateRangeToShow[1].format('YYYY-MM-DD')
        } else {
            d1 = ''
            d2 = ''
        }

        // console.log("date range", dateRangeToShow[0].format('YYYY-MM-DD'), dateRangeToShow[1].format('YYYY-MM-DD'));

        url.searchParams.set('d1', d1)
        url.searchParams.set('d2', d2)

        window.history.pushState({}, '', url);
    }

    const addSortToUrlParams = (value) => {
        // console.log("value", value);
        let url = ''
        url = new URL(window.location.href);

        url.searchParams.set('sb', value)


        window.history.pushState({}, '', url);
    }


    // This code removes params from url for filter

    const removeFilterFromUrlParams = (filterType) => {
        // console.log("filter type", filterType);
        let url = ''
        url = new URL(window.location.href);

        switch (filterType) {
            case 'duration':
                url.searchParams.delete('mnd')
                url.searchParams.delete('mxd')
                break;
            case 'price':
                url.searchParams.delete('mnp')
                url.searchParams.delete('mxp')
                break;
            case 'activities':
                url.searchParams.delete('eat')
                break;
            case 'dates':
                url.searchParams.delete('d1')
                url.searchParams.delete('d2')
                break;
            case 'species':
                url.searchParams.delete('sp')
                break;
            case 'sort':
                url.searchParams.delete('sb')
                break;
            case 'kw':
                url.searchParams.delete('kw')
                break;
            default:
                break;
        }

        window.history.pushState({}, '', url);
    }






    // This function will handle the filter change and receive the min and max values
    const handleFilterChange = (newRange) => {
        // Update the state or send data to the backend here
        // console.log("filter changed calledd");
        setDurationRange([newRange.min, newRange.max]);
        // You can make an API call or update other parts of the application
    };
    const handleDatesChange = (newRange) => {
        // console.log("new rangeee", newRange);
        setTriggerDate(!triggerDate)
        // Update the state or send data to the backend here
        // console.log("filter changed calledd");
        setDateRange([newRange[0], newRange[1]]);
        // You can make an API call or update other parts of the application
    };
    const handlePriceFilterChange = (newRange) => {
        // Update the state or send data to the backend here
        setPricingRange([newRange.min, newRange.max]);
        // You can make an API call or update other parts of the application
    };

    useEffect(() => {
        setSearchFilters({ ...searchFilters, mnd: durationRange[0], mxd: durationRange[1] });

    }, [durationRange])
    useEffect(() => {
        setSearchFilters({ ...searchFilters, mnp: pricingRange[0], mxp: pricingRange[1] });

    }, [pricingRange])

    useEffect(() => {
        // console.log("pricing range", pricingRange);
        setSearchFilters({ ...searchFilters, eat: selectedExpAcivities.join(',') });

    }, [selectedExpAcivities])

    const [filterParams, setFilterParams] = useState({
        mnd: '',
        mxd: '',
        mnp: '',
        mxp: '',
        activityType: '',
        eat: '',
        sb: 'R',
        sp: '',
        d1: null,
        d2: null
    });

    const updateFilterParams = (type, params) => {
        setFilterParams(prevParams => {
            const newParams = { ...prevParams };
            if (type === 'duration') {
                newParams.mnd = params[0];
                newParams.mxd = params[1];
            } else if (type === 'price') {
                newParams.mnp = params[0];
                newParams.mxp = params[1];
            } else if (type === 'activities') {
                // console.log("activitesss paramss", params);
                newParams.eat = params.join(',');
            } else if (type === 'dates') {
                newParams.d1 = params[0];
                newParams.d2 = params[1];
            } else if (type === 'species') {
                // console.log("activitesss paramss", params);
                newParams.sp = params.join(',');
            } else if (type === 'sort') {
                // console.log("activitesss paramss", params);
                newParams.sb = params;
            }
            return newParams;
        });
    };

    useEffect(() => {
        if (callGetCount) {
            getResultsCount();
        }
    }, [filterParams]);

    const getResultsCount = (() => {

        let activityType = '';

        // Commented out to avoid API call for getting count

        // switch (searchFilters.activity) {
        //     case 'access':
        //         activityType = '';
        //         // newSearchType = 'property';
        //         // newShowSearchType = 'property';
        //         // newValue = 0;
        //         break;
        //     case 'experience':
        //         activityType = '';
        //         // newSearchType = 'experience';
        //         // newShowSearchType = 'experience';
        //         // newValue = 1;
        //         break;
        //     default:
        //         activityType = searchFilters.activity;
        // }
        // SearchService.search(
        //     showSearchType,
        //     searchFilters.kw,
        //     activityType,
        //     filterParams.sp,
        //     filterParams.sb,
        //     (page - 1) * 100,
        //     100,
        //     "",
        //     "",
        //     "",
        //     !(filterParams.d1 === null || filterParams.d2 === null) ? filterParams.d1.format('YYYY-MM-DD') : '',
        //     !(filterParams.d1 === null || filterParams.d2 === null) ? filterParams.d2.format('YYYY-MM-DD') : '',
        //     filterParams.mnd,
        //     filterParams.mxd,
        //     filterParams.mnp,
        //     filterParams.mxp,
        //     filterParams.eat,
        //     (data) => {
        //         // console.log("data in search", data);

        //         setCount(extractDataFromArray(data, ["count"], []))
        //         setCallGetCount(false)
        //     }
        // );

    });


    useEffect(() => {
        setLoading(true)
        // console.log("search filters before api call", searchFilters);
        // Added below logic to check activity and type match from url match with states from common context
        const urlActivityType = searchParams.get('at');
        const urlShowSearchType = searchParams.get('type');

        const isActivityTypeMatched = urlActivityType === searchFilters.activity;
        const isShowSearchTypeMatched = urlShowSearchType === showSearchType;

        if (dateRange[0] != null || dateRange[1] != null) {
            setIsActive(true)
        } else if (dateRange[0] === null || dateRange[1] === null) {
            setIsActive(false)
        }

        if (searchFilters.species === '') {
            setIsActivesp(false)
        } else if (searchFilters.species !== '') {
            setIsActivesp(true)
        }
        if (searchFilters.mnd === '' && searchFilters.mxd === '') {
            setIsActiveduration(false)
        } else if (searchFilters.mnd !== '' && searchFilters.mxd !== '') {
            setIsActiveduration(true)
        }
        if (searchFilters.mnp === '' && searchFilters.mxp === '') {
            setIsActivePrice(false)
        } else if (searchFilters.mnp !== '' && searchFilters.mxp !== '') {
            setIsActivePrice(true)
        }
        if (searchFilters.eat === '') {
            setIsActivitySeleted(false)
        } else if (searchFilters.eat !== '') {
            setIsActivitySeleted(true)
        }
        if (searchFilters.sort === '' || searchFilters.sort === 'R') {
            setIsSortSeleted(false)
        } else if (searchFilters.sort !== '' && searchFilters.sort !== 'R') {
            setIsSortSeleted(true)
        }

        // console.log("searchFilters.activity", searchFilters.activity);

        let activityType = '';
        // let newSearchType = searchType; // Initialize with current searchType
        // let newShowSearchType = showSearchType; // Initialize with current showSearchType
        // let newValue = value; // Initialize value

        switch (searchFilters.activity) {
            case 'access':
                activityType = '';
                // newSearchType = 'property';
                // newShowSearchType = 'property';
                // newValue = 0;
                break;
            case 'experience':
                activityType = '';
                // newSearchType = 'experience';
                // newShowSearchType = 'experience';
                // newValue = 1;
                break;
            default:
                activityType = searchFilters.activity;
        }
        // console.log("changed values", newSearchType," ", newShowSearchType );
        // console.log("original values", searchType," ", showSearchType );
        // Update states only if they've changed
        // if (newSearchType !== searchType) setSearchType(newSearchType);
        // if (newShowSearchType !== showSearchType) setShowSearchType(newShowSearchType);
        // if (newValue !== value) setValue(newValue);

        let shouldMakeApiCall = false;

        if (clikedBanner) {
            // New logic to make api call only when clickedBanner is true and activity and type match with url and state from common context
            shouldMakeApiCall = isActivityTypeMatched && isShowSearchTypeMatched && showSearchType !== '';
        } else {
            // Previous logic when clickedBanner is false and type is empty
            shouldMakeApiCall = showSearchType !== '';
        }
        // console.log("Search Filters", searchFilters);
        // console.log("dates", dateRange);
        // Check if any filter has a non-empty value (excluding 'activity' and 'sort')

        // Added below logic to show/hide clear filters button if filters are not applied/empty strings

        const hasNonEmptyFilter = Object.entries(searchFilters).some(([key, value]) => {
            if (key === 'activity' || key === 'sort' || key === 'kw') return false;
            if (Array.isArray(value)) return value.length > 0;
            return value !== '';
        });

        // Check if sort is not 'R'
        const isSortNotDefault = searchFilters.sort !== 'R';
        const areDatesApplied = !(dateRange[0] === null || dateRange[1] === null)

        // Set the clear filters button visibility
        setShowClearFilterButton(hasNonEmptyFilter || isSortNotDefault || areDatesApplied);

        // // console.log("filterChanged", filterChanged);
        // if (filterChanged === true) {
        //     shouldMakeApiCall = true
        // }

        if (shouldMakeApiCall) { // ADDED THIS CONDITION TO AVOID UNNECESSARY API CALL
            SearchService.search(
                showSearchType,
                searchFilters.kw,
                activityType,
                searchFilters.species,
                searchFilters.sort,
                (page - 1) * 100,
                100,
                "",
                "",
                "",
                hasStrings(dateRange) ? dateRange[0] : !(dateRange[0] === null || dateRange[1] === null) ? dateRange[0].format('YYYY-MM-DD') : '',
                hasStrings(dateRange) ? dateRange[1] : !(dateRange[0] === null || dateRange[1] === null) ? dateRange[1].format('YYYY-MM-DD') : '',
                searchFilters.mnd,
                searchFilters.mxd,
                searchFilters.mnp,
                searchFilters.mxp,
                searchFilters.eat,
                (data) => {
                    // console.log("data in search", data);
                    setResults(extractDataFromArray(data, ["records"], []));
                    setCount(extractDataFromArray(data, ["count"], []))
                    setSpeciesData(extractDataFromArray(data, ["filters", "species"], []))
                    setActivitiesData(extractDataFromArray(data, ["filters", "activities"], []))
                    extractDataFromArray(data, ["count"], []) % 100 == 0 ?
                        setPageCount(parseInt(extractDataFromArray(data, ["count"], [])))
                        :
                        setPageCount((parseInt(extractDataFromArray(data, ["count"], []) / 100)) + 1)
                    setLoading(false);
                    setClikedBanner(false)
                    setCalledClearfilter(false)
                }
            );
        }

        if (showSearchType === 'access') {
            setWishlistType('L')
        } else if (showSearchType === 'experience') {
            setWishlistType('E')
        }

    }, [searchFilters, page, triggerDate, searchType])



    //  ADDED BELOW LOGIC TO SHOW PREVIOUSLY SELECTED SEARCH TAB AND FILTER IN HEADER AFTER USER REFRSHES PAGE

    let url = new URL(window.location.href);
    let activitySelected = url.searchParams.get('at')
    let listingType = url.searchParams.get('type')
    const prevListingTypeRef = useRef(listingType);
    useEffect(() => {
        // console.log("url.searchParams.get('at')", url.searchParams.get('type'));
        // console.log("listingType", listingType);
        // console.log("prevListingTypeRef", prevListingTypeRef);


        // Added below logic to remove date range and reset date range to show to empty when switching between access to experience listing type from drop down
        
        // This effect will run whenever listingType changes
        if (
            (prevListingTypeRef.current === 'access' && listingType === 'experience') ||
            (prevListingTypeRef.current === 'experience' && listingType === 'access')
        ) {
            setDateRange([null, null]);
            setDateRangeToShow([null, null])
            removeFilterFromUrlParams('dates')
        }

        // Update the ref with the current listingType
        prevListingTypeRef.current = listingType;

        // Added below logic of switch to check what type is present in url and depending on it setting values
        switch (listingType) {
            case 'access':
                setValue(0)
                setSearchType('access')
                setShowSearchType('access')
                setSearchFilters({ ...searchFilters, eat: '', mnd: '', mxd: '', mnp: '', mxp: '', sort: 'R' });
                removeFilterFromUrlParams('price')
                removeFilterFromUrlParams('duration')
                removeFilterFromUrlParams('activities')
                break;
            case 'experience':
                setValue(1)
                setSearchType('experience')
                setShowSearchType('experience')
                setSearchFilters({ ...searchFilters, species: '', sort: 'R' });
                removeFilterFromUrlParams('species')
                break;
            case 'academy':
                setValue(1)
                setSearchType('academy')
                setShowSearchType('academy')
                setSearchFilters({ ...searchFilters, eat: '', mnd: '', mxd: '', mnp: '', mxp: '', sort: 'R', sp: '' });
                removeFilterFromUrlParams('price')
                removeFilterFromUrlParams('duration')
                removeFilterFromUrlParams('activities')
                removeFilterFromUrlParams('species')
                break;
            default:
                // setShowSearchType('experience')
                break;
        }

        switch (activitySelected) {
            case 'access':
            case 'RV':
            case 'BW':
            case 'H':
            case 'FR':
                setSearchFilters({ 'activity': activitySelected });
                // newSearchType = 'property';
                // newShowSearchType = 'property';
                // newValue = 0;
                break;
            case 'experience':
            case 'GT':
            case 'RE':
            case 'OA':
                setSearchFilters({ 'activity': activitySelected });
                // newSearchType = 'experience';
                // newShowSearchType = 'experience';
                // newValue = 1;
                break;
            default:


        }
    }, [activitySelected])

    const getActivityType = (results) => {
        let type = extractDataFromArray(results, ['package_json', 'activity_type'], '')
        switch (type) {
            case 'H':
                return "Hunting"
            case 'F':
                return "Fishing"
            case 'FR':
                return "Farm"
            case 'BW':
                return "Bird Watching"
            default:
                return ""
        }
    }

    // console.log("result", results)
    const hoverHandler = (value) => {
        setListingSeq(value)
    }
    const hoverEnd = () => {
        setListingSeq('')
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
        let url = ''  //  ADDED url LOGIC TO SHOW PREVIOUSLY SELECTED SEARCH TAB AND FILTER IN HEADER AFTER USER REFRSHES PAGE
        switch (newValue) {
            case 0:
                setSearchType('access')
                setShowSearchType('access')
                setSearchFilters({ 'activity': 'access' });
                setSearchFilters({ ...searchFilters, eat: '', mnd: '', mxd: '', mnp: '', mxp: '', sort: 'R', kw: '', d1: '', d2: '' });
                setDateRange([null, null]);
                setDateRangeToShow([null, null]);
                addSortToUrlParams('R')
                // setDurationRangeToShow([1,480])
                // setPriceRangeToShow([10,100000])
                // setSelectedExpAcivities([])
                removeFilterFromUrlParams('price')
                removeFilterFromUrlParams('duration')
                removeFilterFromUrlParams('activities')
                removeFilterFromUrlParams('dates');
                url = new URL(window.location.href);
                url.searchParams.set('at', 'access');
                url.searchParams.set('type', 'access');
                url.searchParams.set('kw', '');
                window.history.pushState({}, '', url);
                break;
            case 1:
                setSearchType('experience')
                setShowSearchType('experience')
                setSearchFilters({ 'activity': 'experience' });
                setSearchFilters({ ...searchFilters, species: '', sort: 'R', kw: '', d1: '', d2: '' });
                addSortToUrlParams('R')
                removeFilterFromUrlParams('species')
                removeFilterFromUrlParams('dates');
                setDateRange([null, null]);
                setDateRangeToShow([null, null]);
                url = new URL(window.location.href);
                url.searchParams.set('at', 'experience');
                url.searchParams.set('type', 'experience');
                url.searchParams.set('kw', '');
                window.history.pushState({}, '', url);
                break;
            case 2:
                setSearchType('academy')
                setShowSearchType('academy')
                setSearchFilters({ 'activity': 'academy' });
                setSearchFilters({ ...searchFilters, eat: '', mnd: '', mxd: '', mnp: '', mxp: '', species: '', sort: 'R', kw: '' });
                removeFilterFromUrlParams('price')
                removeFilterFromUrlParams('duration')
                removeFilterFromUrlParams('activities')
                removeFilterFromUrlParams('species')
                url = new URL(window.location.href);
                url.searchParams.set('at', 'academy');
                url.searchParams.set('type', 'academy');
                url.searchParams.set('kw', '');
                window.history.pushState({}, '', url);
                break;
            default:
                setSearchType('access')
                break;
        }
    }


    useEffect(() => {
        // console.log("duration range changed", durationRangeToShow);
        // setDurationRange(durationRangeToShow)
        if (callGetCount === true) {
            updateFilterParams('duration', durationRangeToShow);
        }
    }, [durationRangeToShow])

    useEffect(() => {
        // console.log("price range changed", priceRangeToShow);
        if (callGetCount === true) {
            updateFilterParams('price', priceRangeToShow);
        }
    }, [priceRangeToShow])

    useEffect(() => {
        // console.log("price range changed", priceRangeToShow);
        if (callGetCount === true) {
            updateFilterParams('dates', dateRangeToShow);
        }
    }, [dateRangeToShow])

    useEffect(() => {
        if (callGetCount === true) {
            updateFilterParams('activities', selectedActivities)
        }
    }, [selectedActivities])



    const handleApplyFilter = () => {
        // setShowClearFilterButton(true)
        const hasDateChanged = (
            initialDateRange[0] !== dateRangeToShow[0] ||
            initialDateRange[1] !== dateRangeToShow[1]
        );

        if (hasDateChanged) {
            addFilterToUrlParams(); // Update URL params
            handleDatesChange([dateRangeToShow[0], dateRangeToShow[1]]); // API call
        }

        setOpen(false);
    };

    const handleClearAll = () => {
        setShowClearFilterButton(false)

        if (dateRangeToShow[0] || dateRangeToShow[1]) {
            removeFilterFromUrlParams('dates');  // Remove date filters from URL
            setDateRange([null, null]);
            setDateRangeToShow([null, null]);
            handleDatesChange([null, null]);  // API call with no dates
        }
        setOpen(false);
    };

    return (<>
        <Box sx={{ height: 'calc( 100vh - 72px )', overflow: 'hidden' }} > {/* After Access and Experience tabs are added then. this box added for height over flow issue */}
            <Grid container sx={{ mt: 0 }}>

                <FilterPopup
                    filterData={speciesData}
                    type={popupConfig.type}
                    open={popupConfig.open}
                    handleClose={handleClose}
                    selectedFilters={selectedSpecies}
                    setSelectedFilters={setSelectedSpecies}
                    handleFilterChange={speciesFilterChange}
                    removeFilterFromUrlParams={removeFilterFromUrlParams}
                    setShowClearFilterButton={setShowClearFilterButton}
                    calledClearfilter={calledClearfilter}
                />

                <ExpAcitvitiesFilterPopUp
                    filterData={activitiesData}
                    type={popupConfig.type}
                    open={openExpActivity}
                    handleClose={handleClose}
                    selectedFilters={selectedExpAcivities}
                    setSelectedFilters={setSelectedExpAcivities}
                    handleFilterChange={expAcitivitiesFilterChange}
                    setOpenExpActivity={setOpenExpActivity}
                    count={count}
                    removeFilterFromUrlParams={removeFilterFromUrlParams}
                    selectedActivities={selectedActivities}
                    setSelectedActivities={setSelectedActivities}
                    setCallGetCount={setCallGetCount}
                    setShowClearFilterButton={setShowClearFilterButton}
                // calledClearfilter={calledClearfilter}
                />

                <DurationFilter
                    open={openDurationFilter}
                    handleClose={handleClose}
                    handleFilterChange={handleFilterChange}
                    setOpenDurationFilter={setOpenDurationFilter}
                    setDurationRange={setDurationRange}
                    count={count}
                    initialValue={durationRange}
                    removeFilterFromUrlParams={removeFilterFromUrlParams}
                    durationRangeToShow={durationRangeToShow}
                    setDurationRangeToShow={setDurationRangeToShow}
                    setIsActiveduration={setIsActiveduration}
                    setCallGetCount={setCallGetCount}
                    setShowClearFilterButton={setShowClearFilterButton}
                    calledClearfilter={calledClearfilter}
                />

                <PriceFilter
                    open={openPricingFilter}
                    handleClose={handleClose}
                    handleFilterChange={handlePriceFilterChange}
                    setOpenPricingFilter={setOpenPricingFilter}
                    count={count}
                    initialValue={pricingRange}
                    removeFilterFromUrlParams={removeFilterFromUrlParams}
                    priceRangeToShow={priceRangeToShow}
                    setPriceRangeToShow={setPriceRangeToShow}
                    setIsActivePrice={setIsActivePrice}
                    setCallGetCount={setCallGetCount}
                    setShowClearFilterButton={setShowClearFilterButton}
                    calledClearfilter={calledClearfilter}
                />


                <Grid item xs={12} sm={12} md={6} className="exp_listing_left" sx={{ width: isNineHundred && '100% !important', flexBasis: isNineHundred && '100%  !important', maxWidth: isNineHundred && '100% !important' }} >
                    <Box sx={{
                        "& .MuiTab-iconWrapper": {
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            marginBottom: "0px !important",
                        },
                        " & .Mui-selected>.MuiTab-iconWrapper": {
                            backgroundColor: 'var(--primary)'
                        },
                        '& .Mui-selected>.MuiTab-iconWrapper img': {
                            filter: 'invert(100%) brightness(100%)',
                            padding: "3px"
                        }
                    }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ marginLeft: 2 }}>
                            <Tab
                                sx={{
                                    flexDirection: 'row',
                                    gap: 0.5,
                                    minHeight: 'unset !important',
                                    height: '50px !important'

                                }}
                                icon={<Avatar sx={{ height: 20, width: 20 }} alt="test avatar" src="../assets/images/icon_images/access.svg" />}
                                label="Access"
                            />
                            <Tab
                                sx={{
                                    flexDirection: 'row',
                                    gap: 0.5,
                                    minHeight: 'unset !important',
                                    height: '50px !important'
                                }}
                                icon={<Avatar sx={{ height: 20, width: 20 }} alt="test avatar" src="../assets/images/icon_images/experience.svg" />}
                                label="Experience"
                            />
                            {/* <Tab sx={{flexDirection:'row', gap:0.5}} icon={<Avatar sx={{height:20, width:20}} alt="test avatar" src="../assets/images/icon_images/access.svg" />} label="Academy"/> */}
                        </Tabs>
                    </Box>
                    <Divider />
                    {
                        isNineHundred && showSearchType === 'access' &&
                        <Box className="search_filter_wrapper" sx={{ gap: 1, display: 'flex', overflow: 'auto', px: 2, py: 2, }}>
                            <Chip
                                className={"search_filter " + searchFilters}
                                style={{ backgroundColor: selectedActivity === '' ? '#1b1b1b' : '', color: selectedActivity === '' ? 'white' : '' }}
                                avatar={<Avatar alt="all" src="../assets/images/icon_images/all_icon.svg" sx={{ padding: 0.6, filter: selectedActivity === '' ? 'invert(1)' : 'invert(0)' }} />}
                                label="All"
                                sx={{
                                    backgroundColor: '#E5E7EB',
                                    border: 'none',
                                    fontWeight: '600'
                                }}
                                variant="outlined"
                                onClick={() => {
                                    setSearchFilters({ ...searchFilters, activity: '' });
                                    setSelectedActivity('');
                                }}
                            />
                            <Chip
                                className="search_filter"
                                style={{ backgroundColor: selectedActivity === 'H' ? '#1b1b1b' : '', color: selectedActivity === 'H' ? 'white' : '' }}
                                avatar={<Avatar alt="all" src="../assets/images/icon_images/hunting.svg" sx={{ padding: 0.6, filter: selectedActivity === 'H' ? 'invert(1)' : 'invert(0)' }} />}
                                sx={{
                                    backgroundColor: '#E5E7EB',
                                    border: 'none',
                                    fontWeight: '600'
                                }}
                                label="Hunting"
                                variant="outlined"
                                onClick={() => {
                                    setSearchFilters({ ...searchFilters, activity: 'H' });
                                    setSelectedActivity('H');
                                }}
                            />
                            <Chip
                                className="search_filter"
                                style={{ backgroundColor: selectedActivity === 'F' ? '#1b1b1b' : '', color: selectedActivity === 'F' ? 'white' : '' }}
                                avatar={<Avatar alt="all" src="../assets/images/icon_images/fishing.svg" sx={{ padding: 0.6, filter: selectedActivity === 'F' ? 'invert(1)' : 'invert(0)' }} />}
                                sx={{
                                    backgroundColor: '#E5E7EB',
                                    border: 'none',
                                    fontWeight: '600'
                                }}
                                label="Fishing"
                                variant="outlined"
                                onClick={() => {
                                    setSearchFilters({ ...searchFilters, activity: 'F' });
                                    setSelectedActivity('F');
                                }}
                            />
                            <Chip
                                className="search_filter"
                                avatar={<Avatar alt="all" src="../assets/images/icon_images/birdwatch.svg" sx={{ padding: 0.6, filter: selectedActivity === 'BW' ? 'invert(1)' : 'invert(0)' }} />}
                                sx={{
                                    backgroundColor: '#E5E7EB',
                                    border: 'none',
                                    fontWeight: '600'
                                }}
                                style={{ backgroundColor: selectedActivity === 'BW' ? '#1b1b1b' : '', color: selectedActivity === 'BW' ? 'white' : '' }}
                                label="Bird Watching"
                                variant="outlined"
                                onClick={() => {
                                    setSearchFilters({ ...searchFilters, activity: 'BW' })
                                    setSelectedActivity('BW');
                                }}
                            />
                            <Chip
                                className="search_filter"
                                avatar={<Avatar alt="all" src="../assets/images/icon_images/rv_camping.svg" sx={{ padding: 0.6, filter: selectedActivity === 'RV' ? 'invert(1)' : 'invert(0)' }} />}
                                sx={{
                                    backgroundColor: '#E5E7EB',
                                    border: 'none',
                                    fontWeight: '600'
                                }}
                                style={{ backgroundColor: selectedActivity === 'RV' ? '#1b1b1b' : '', color: selectedActivity === 'RV' ? 'white' : '' }}
                                label="Farm and Ranch"
                                variant="outlined"
                                onClick={() => {
                                    setSearchFilters({ ...searchFilters, activity: '' })
                                    setSelectedActivity('RV');
                                }}
                            />
                            {/* <Chip
                            className="search_filter"
                            avatar={<Avatar alt="all" src="../assets/images/icon_images/horse_riding.svg" sx={{ padding: 0.6, filter: selectedActivity === 'HR' ? 'invert(1)' : 'invert(0)' }} />}
                            sx={{
                                backgroundColor: '#E5E7EB',
                                border: 'none',
                                fontWeight : '600'
                            }}
                            style={{
                                backgroundColor: selectedActivity === 'HR' ? '#1b1b1b' : '', color: selectedActivity === 'HR' ? 'white' : '',
                                '& . MuiAvatarCircular': { filter: selectedActivity === 'HR' ? 'invert(1)' : 'invert(0)' }
                            }}
                            label="Horseback Riding"
                            variant="outlined"
                            onClick={() => {
                                setSearchFilters({ ...searchFilters, activity: '' })
                                setSelectedActivity('HR');
                            }}
                        /> */}
                        </Box>
                    }

                    <Box
                        sx={{
                            width: '100%',
                            overflow: 'auto'
                        }}
                    >
                        <Box sx={{ display: 'flex', p: 2, gap: 1 }}>

                            {/* {
                        empty(searchFilters.start_date) && DateBtn
                    }
                    {
                        !empty(searchFilters.start_date) === 1 &&
                        <Badge
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            badgeContent={"."}
                            color="secondary"
                            sx={{ mr: 2 }}
                        >
                        </Badge>
                    } */}
                            {
                                DateBtn
                            }
                            {
                                showSearchType === 'experience' &&
                                DurationBtn
                            }
                            {
                                showSearchType === 'access' &&
                                SpeciesBtn
                            }
                            {
                                showSearchType === 'experience' &&
                                PricingBtn
                            }
                            {
                                showSearchType === 'experience' &&
                                ActivitiesBtn
                            }
                            {/* {
                        searchFilters.species.length === 1 &&
                        <Badge

                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            badgeContent={searchFilters.species.length}
                            color="secondary"
                            sx={{ mr: 2 }}
                        >
                            {
                                SpeciesBtn
                            }
                        </Badge>
                    } */}
                            {
                                showSearchType === 'access' ?
                                    <Select
                                        value={(searchFilters.sort === 'HPD' || searchFilters.sort === 'LPD') ? 'R' : searchFilters.sort}
                                        onChange={(e) => { setSearchFilters({ sort: e.target.value }); addSortToUrlParams(e.target.value); setShowClearFilterButton(true) }}
                                        IconComponent={Icon.KeyboardArrowDownOutlined}
                                        size="small"
                                        sx={{
                                            transition: 'border-color 0.3s', // Add transition for smooth effect
                                            fontSize: '0.875rem !important',
                                            borderColor: !isSortSeleted ? '#c5c5c7' : '#1b1b1b',
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: !isSortSeleted ? '#c5c5c7' : '#1b1b1b',
                                            },
                                            '& .MuiSelect-icon': {
                                                color: !isSortSeleted ? '#85878A' : '#1b1b1b',
                                            },
                                            "&:hover": {
                                                color: 'var(--black)'
                                            },
                                            color: !isSortSeleted ? '#85878A' : '#1b1b1b',

                                        }}
                                    >
                                        <MenuItem selected value='R'>Most Recent</MenuItem>
                                        <MenuItem value='LA'>Low to High Acre</MenuItem>
                                        <MenuItem value='HA'>High to Low Acre</MenuItem>
                                        <MenuItem value='L'>Low to High Price</MenuItem>
                                        <MenuItem value='H'>High to Low Price</MenuItem>
                                    </Select>
                                    :
                                    <Select
                                        value={(searchFilters.sort === 'LA' || searchFilters.sort === 'HA') ? 'R' : searchFilters.sort}
                                        onChange={(e) => { setSearchFilters({ sort: e.target.value }); addSortToUrlParams(e.target.value); setShowClearFilterButton(true) }}
                                        IconComponent={Icon.KeyboardArrowDownOutlined}
                                        size="small"
                                        sx={{
                                            transition: 'border-color 0.3s', // Add transition for smooth effect
                                            fontSize: '0.875rem !important',
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: !isSortSeleted ? '#c5c5c7' : '#1b1b1b',
                                            },
                                            '& .MuiSelect-icon': {
                                                color: !isSortSeleted ? '#85878A' : '#1b1b1b',
                                            },
                                            "&:hover": {
                                                color: 'var(--black)'
                                            },
                                            color: !isSortSeleted ? '#85878A' : '#1b1b1b',
                                        }}
                                    >
                                        <MenuItem selected value='R'>Most Recent</MenuItem>
                                        <MenuItem value='HPD'>Max to Min Duration</MenuItem>
                                        <MenuItem value='LPD'>Min to Max Duration</MenuItem>
                                        <MenuItem value='L'>Low to High Price</MenuItem>
                                        <MenuItem value='H'>High to Low Price</MenuItem>
                                    </Select>
                            }
                            {showClearFilterButton &&
                                Clearfilters
                            }
                        </Box>
                    </Box>
                    <Divider />
                    {
                        !mapView && <>
                            <Box className="test" sx={{ p: 2, height: isNineHundred ? 'calc( 100vh - 242px )' : 'calc(100vh - 185px)', overflow: 'auto' }}>
                                {
                                    loading && <Box>
                                        <SkeletonPackageCard />
                                        <SkeletonPackageCard />
                                        <SkeletonPackageCard />
                                    </Box>
                                }

                                {
                                    !loading && results.length === 0 &&
                                    <Box sx={{ py: 2, textAlign: 'center' }}>
                                        <Box sx={{ py: 5 }}>
                                            <img src="../assets/images/placeholders/result-not-found.svg" alt="no_result" />
                                        </Box>
                                        <Typography variant="h5">No Perfect matches for your search</Typography>
                                        <Typography variant="body1">Please use a different combination of filters and try again for better results.</Typography>
                                    </Box>
                                }
                                {
                                    !loading && results.length !== 0 &&
                                    <Box sx={{ mb: 2, display: 'flex' }}><Typography variant="caption" sx={{ fontWeight: 600, lineHeight: 1 }}>{count} Results</Typography></Box>
                                }
                                {
                                    !loading && results.length !== 0 && results.map((result, i) => {
                                        if (result.type === 'listing') {
                                            // console.log("results", result);
                                            let address = extractDataFromArray(result, ['listing', 'address'], '')
                                            let listingSlug = extractDataFromArray(result, ['listing', 'slug'], '')
                                            let listing_seq = extractDataFromArray(result, ['listing', 'seq'], '')
                                            let propertyName = extractDataFromArray(result, ['listing', 'title'], '')
                                            let renderTitle = getActivityType(results[i])
                                            address = extractDataFromArray(result, ['listing', 'area'], 0) + " Acres, " + renderTitle + " at "
                                            let address2 = " near " + extractDataFromArray(result, ['listing', 'nearest_town'], '')
                                            return (
                                                <>
                                                    <Box sx={{ mb: 2 }} key={"result_" + i}
                                                        onMouseOver={() => { hoverHandler(showSearchType === 'access' ? extractDataFromArray(result, ['listing', 'seq'], '') : extractDataFromArray(result, ['experience_seq'], '')) }}
                                                        onMouseOut={hoverEnd}
                                                    >
                                                        <PackageCard
                                                            cardType='package'
                                                            image={extractDataFromArray(result, ['package_json', 'media_library'], '')}
                                                            title={extractDataFromArray(result, ['package_title'], '')}
                                                            guest={extractDataFromArray(result, ['package_json', 'min_guests'], 1)}
                                                            days={extractDataFromArray(result, ['package_json', 'min_days'], 1)}
                                                            address={address}
                                                            address2={address2}
                                                            propertyName={propertyName}
                                                            listingSlug={extractDataFromArray(result, ['listing', 'slug'], '')}
                                                            listing_seq={extractDataFromArray(result, ['listing', 'seq'], '')}
                                                            package_seq={extractDataFromArray(result, ['package_seq'], 0)}
                                                            slug={extractDataFromArray(result, ['package_slug'], 0)}
                                                            size={extractDataFromArray(result, ['listing', 'area'], 0)}
                                                            price={Math.round(extractDataFromArray(result, ['price'], ''))}
                                                            viewTarget="_blank"
                                                            hideOptions={true}
                                                            search={true}
                                                            type={extractDataFromArray(result, ["type"], "")}
                                                            nearestTown={extractDataFromArray(result, ['listing', 'nearest_town'], '')}
                                                            averageRating={extractDataFromArray(result, ['average_rating'], '')}
                                                            reviewCount={extractDataFromArray(result, ['review_count'], '')}
                                                            lodging={extractDataFromArray(result, ['listing', 'lodging_available'], '')}
                                                            rv={extractDataFromArray(result, ['listing', 'rv_available'], '')}
                                                        />
                                                    </Box>
                                                </>
                                            )
                                        } else if (result.type === 'experience') {
                                            let data = result.experience_data
                                            let photoList = []
                                            let title = ''
                                            let address = ''
                                            let lat = 0
                                            let lon = 0
                                            let slug = ''
                                            let starting_price = 'N/A'
                                            let durationObj = {}
                                            let experienceType = ''
                                            let availability_id = ''
                                            let link = `/createExperience?experience_seq=${result.experience_seq}/edit`
                                            for (let key in data) {
                                                if (data[key].type === 'photos') {
                                                    photoList = data[key].photo_list;
                                                }
                                                else if (data[key].type === 'general_info') {
                                                    title = data[key].title;
                                                    experienceType = data[key].experience_type
                                                    slug = data[key].slug
                                                }
                                                else if (data[key].type === 'location') {
                                                    address = data[key].address;
                                                    address = getPlaceName(address)
                                                    if (address === 'Don’t see your address? Add GPS coordinates.') {
                                                        address = data[key].city
                                                        address = getPlaceName(address)
                                                    }
                                                    lat = data[key].lat;
                                                    lon = data[key].lon;
                                                }
                                                else if (data[key].type === 'pricing') {
                                                    durationObj = data[key].package_duration
                                                    const slotList = data[key].slot_list;

                                                    if (slotList.length === 0) {
                                                        starting_price = 'N/A';
                                                    } else {
                                                        starting_price = Math.min(...slotList.map(slot => parseInt(slot.price, 10)));
                                                    }
                                                }
                                                else if (data[key].type === 'availability') {
                                                    availability_id = data[key].id
                                                }
                                            }
                                            return (
                                                <Box sx={{ mb: 2 }} key={"result_" + i}
                                                    onMouseOver={() => { hoverHandler(showSearchType === 'access' ? extractDataFromArray(result, ['listing', 'seq'], '') : extractDataFromArray(result, ['experience_seq'], '')) }}
                                                    onMouseOut={hoverEnd}
                                                >
                                                    <PackageCard
                                                        image={photoList}
                                                        title={title}
                                                        listing_seq={extractDataFromArray(result, ["experience_seq"], 0)}
                                                        size={extractDataFromArray(result?.listing_json?.location_info, ['property_area'], '')}
                                                        type={result.type}
                                                        city={extractDataFromArray(result?.listing_json?.location_info, ['nearest_town'], '')}
                                                        completeLink={link}
                                                        lat={lat}
                                                        long={lon}
                                                        address={address}
                                                        slug={slug}
                                                        state={extractDataFromArray(result, ['state'], '')}
                                                        totalPackages={extractDataFromArray(result, ['total_packages'], '')}
                                                        days={extractDataFromArray(result, ['days'], '')}
                                                        price={starting_price}
                                                        status={extractDataFromArray(result, ['status'], '')}
                                                        durationObj={durationObj}
                                                        nearest_town={extractDataFromArray(result, ['nearest_town'], '')}
                                                        verificationStatus={extractDataFromArray(result, ['verification_status'], '')}
                                                        listing_declined_reasons={extractDataFromArray(result, ['listing_declined_reasons'], '')}
                                                        cardType="experience"
                                                        viewTarget="_blank"
                                                        hideOptions={true}
                                                        search={true}
                                                        showMoreOptions={false}
                                                        showPropertyStatus={false}
                                                        // changeListingStatus={() => {
                                                        //     changeExpListingStatus(index)
                                                        // }}
                                                        experienceType={experienceType}
                                                        availability_id={availability_id}
                                                        averageRating={extractDataFromArray(result, ['average_rating'], '')}
                                                        reviewCount={extractDataFromArray(result, ['review_count'], '')}
                                                        package_seq={extractDataFromArray(result, ["experience_seq"], 0)}
                                                    />
                                                </Box>
                                            )
                                        }

                                    })

                                }
                                {
                                    <Box sx={{
                                        height: '64px',
                                        width: '100%',
                                        display: pageCount === 0 ? 'none' : 'flex',
                                        alignItems: 'center',
                                        zIndex: '9999',
                                        mb: isNineHundred && 7,
                                    }}>
                                        <Pagination
                                            page={page}
                                            onChange={handleChangePage}
                                            count={pageCount}
                                            sx={{
                                                mx: 'auto',
                                                widows: '100%',
                                                justifyContent: 'center',
                                                alignSelf: 'center'
                                            }}
                                        />
                                    </Box>
                                }
                            </Box>
                            {/* {!isNineHundred &&
                            <Box sx={{
                                height: '64px',
                                width: { sm: '100%', md: '50%' },
                                display: 'flex',
                                alignItems: 'center',
                                position: 'fixed',
                                mb: 8,
                            }}>
                                <Pagination
                                    page={page}
                                    onChange={handleChangePage}
                                    count={pageCount}
                                    sx={{
                                        mx: 'auto',
                                        widows: '100%',
                                        justifyContent: 'center',
                                        alignSelf: 'center'
                                    }}
                                />
                            </Box>
                        } */}
                        </>
                    }



                    {/* No result found code */}

                </Grid>
                <Grid item xs={12} sm={12} md={6} sx={{ display: 'block' }} >
                    {
                        (!isNineHundred || mapView) &&
                        <Box>
                            <SearchMap results={results} listingSequence={listingSeq} wishlistType={wishlistType} />
                        </Box>
                    }
                </Grid>
                {
                    isNineHundred && <>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            sx={{
                                borderRadius: 10, width: '100px', border: 'solid 1px #fff', position: 'absolute', bottom: '16px', left: 0, right: 0, margin: 'auto', boxShadow: 'box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);',
                                "&:hover": {
                                    bgcolor: '#1b1b1b'
                                }, zIndex: 1
                            }}
                            onClick={() => { setMapView(!mapView) }}>
                            {!mapView ? <><Icon.MapOutlined sx={{ mr: 1 }} /> Map</> : <><Icon.FormatListBulletedOutlined sx={{ mr: 1 }} /> List</>}
                        </Button>
                    </>
                }
            </Grid>
        </Box>
        <Dialog open={open} onClose={() => { setOpen(false); }} sx={{ '& .MuiPaper-root': { maxWidth: '768px' } }}>
            <Box sx={{
                display: 'flex', alignItems: 'center',
                justifyContent: 'center', width: '100%', justifyContent: 'space-between'
            }}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>Filters</DialogTitle>
                <IconButton sx={{ height: '42px' }} onClick={() => { setOpen(false); }}>
                    <Icon.Close />
                </IconButton>
            </Box>
            <Divider />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateRangePicker
                    slots={{ field: SingleInputDateRangeField }}
                    disablePast
                    value={dateRangeToShow}
                    disableHighlightToday
                    orientation={!isNineHundred && "landscape"}
                    shouldDisableDate={disableCustomDates}
                    slotProps={{
                        textField: {
                            placeholder: 'Dates'
                        },
                        actionBar: { actions: [] },
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: 'white',
                        },
                        "& .MuiPickersLayout-landscape": { minWidth: '100%' },
                        "&. MuiInputBase-root": {
                            display: 'none'
                        },
                        "& .MuiFormControl-root": {
                            display: 'none'
                        }
                    }}
                    onChange={(dates) => setDateRangeToShow(dates)}
                />
            </LocalizationProvider>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', px: 3, pb: 3 }}>
                <Button variant="text" sx={{ color: 'var(--black)' }}
                    onClick={handleClearAll} >
                    Clear All
                </Button>
                <Button
                    variant="contained"
                    onClick={handleApplyFilter}
                    sx={{
                        paddingInline: screenWidth < 398 ? '16px !important' : '22px !important'
                    }}
                >
                    {/* Show {count} Properties */}
                    Show Results
                </Button>
            </Box>
        </Dialog>
    </>)
}

export default SearchListing;