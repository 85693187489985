import { Divider } from "@mui/material";
import { extractDataFromArray } from "../../../utils/common";
import Overview from "../../../commonComponents/Experience/ExpPackageComponents/overview"
import ExpActivites from "../../../commonComponents/Experience/ExpPackageComponents/activites"
import Faqs from "../../../commonComponents/Experience/ExpPackageComponents/Faqs"
import ExpAmenties from "../../../commonComponents/Experience/ExpPackageComponents/expAmenties"
import ExpLocation from "../../../commonComponents/Experience/ExpPackageComponents/Location"
import AboutHost from "../../../commonComponents/Experience/ExpPackageComponents/AboutHost"
import TripSchedule from "../../../commonComponents/Experience/ExpPackageComponents/TripSchedule"
import CancellationPolicyPreview from "../../../commonComponents/Experience/ExpPackageComponents/CancellationPolicy"
import ExpText from "../../../commonComponents/Experience/ExpPackageComponents/Text"
import ExpPhotos from "../../../commonComponents/Experience/ExpPackageComponents/Photos"
import ExpList from "../../../commonComponents/Experience/ExpPackageComponents/List"
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";


export let PreviewConfig = {
    overview: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            let formData = data[0]
            // if (extractDataFromArray(formData, ['display'], 0) == 0) {
            //     return null
            // }
            if (extractDataFromArray(formData, ['description'], '') === '' || extractDataFromArray(formData, ['description'], '') === '<p></p>' || extractDataFromArray(formData, ['description'], '') === '<p><br></p>' || extractDataFromArray(formData, ['display'], 0) == 0) {
                return null
            }
            return (
                <>
                    <Overview formData={data[0]} descKey={'description'} isMobileButtonClicked={isMobileButtonClicked} />
                    <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
                </>

            )
        }
    },
    activities: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            let formData = data[0]
            if (extractDataFromArray(formData, ['display'], 0) == 0) {
                return null
            }
            return (
                <>
                    <ExpActivites formData={data[0]} keyName={'activity_list'} exptitle={'title'} isMobileButtonClicked={isMobileButtonClicked} />
                    <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
                </>

            )
        }
    },
    amenities: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            let formData = data[0]
            if (extractDataFromArray(formData, ['display'], 0) == 0) {
                return null
            }
            return (
                <>
                    <ExpAmenties formData={data[0]} isMobileButtonClicked={isMobileButtonClicked} />
                    <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
                </>

            )
        }
    },
    location: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            return (
                <>
                    <ExpLocation formData={data[0]} isMobileButtonClicked={isMobileButtonClicked} />
                    <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
                </>

                // <></>
            )
        }
    },
    host: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            return (
                <>
                    <AboutHost formData={data[0]} isMobileButtonClicked={isMobileButtonClicked} />
                    <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
                </>

            )
        }
    },
    schedule: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            let formData = data[0]
            if (extractDataFromArray(formData, ['display'], 0) == 0 || extractDataFromArray(formData, ['schedule_list'], []).length === 0) {
                return null
            }
            return (
                <>
                    <TripSchedule formData={data[0]} isMobileButtonClicked={isMobileButtonClicked} />
                </>

            )
        }
    },
    cancellation_policy: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            // console.log(isMobileButtonClicked);
            
            return (
                <>
                    <CancellationPolicyPreview formData={data[0]} isMobileButtonClicked={isMobileButtonClicked} />
                    <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
                </>

            )
        }
    },
    text: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            let formData = data[0]
            if (extractDataFromArray(formData, ['other_details'], '') === '' || extractDataFromArray(formData, ['other_details'], '') === '<p></p>' || extractDataFromArray(formData, ['other_details'], '') === '<p><br></p>' || extractDataFromArray(formData, ['display'], 0) == 0) {
                return null
            }


            return (
                <>
                    <Overview formData={formData} descKey={'other_details'} isMobileButtonClicked={isMobileButtonClicked} />
                    <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
                </>

            )
        }
    },
    photo_list: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            let formData = data[0]
            if (extractDataFromArray(formData, ['display'], 0) == 0 || extractDataFromArray(formData, ['photo_list'], []).length == 0) {
                return null
            }
            return (
                <>
                    <ExpPhotos formData={formData} isMobileButtonClicked={isMobileButtonClicked} />
                    <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
                </>

            )
        }
    },
    list: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            let formData = data[0]
            if (extractDataFromArray(formData, ['display'], 0) == 0) {
                return null
            }
            return (
                <>
                    <ExpList formData={data[0]} isMobileButtonClicked={isMobileButtonClicked} />
                    <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
                </>

            )
        }
    },
    faqs: {
        renderPreviewComponent: (data, isMobileButtonClicked) => {
            return (
                <>
                    <Faqs formData={data[0]} isMobileButtonClicked={isMobileButtonClicked} />
                </>

            )
        }
    },
};