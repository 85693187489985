import { Box, Typography } from "@mui/material"
import ApplyStyles from "../../previewPageComponents/htmlParser/ApplyStyles";
import ExpereinceOverviewImages from './expOverviewImages'
import { extractDataFromArray } from "../../../utils/common";
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";

const Overview = ({ formData, descKey, isMobileButtonClicked = false }) => {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }}>
            <Typography variant="h5" sx={{ fontWeight: '600', fontSize: isMobileButtonClicked && '1.25rem' }}>{extractDataFromArray(formData, ['title'], '')}</Typography>
            <Box sx={{
                "& .applyStyles": {
                    // color: 'var(--textGrey)'
                }
            }}>
                <ApplyStyles htmlString={extractDataFromArray(formData, [descKey], '')} />
            </Box>
            {extractDataFromArray(formData, ['photo_list'], []).length > 0 && <ExpereinceOverviewImages images={extractDataFromArray(formData, ['photo_list'], [])} />}
        </Box>
    )
}
export default Overview;