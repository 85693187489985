import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios } from "../utils/common";

const FeaturedPropertiesService = {

    getFeaturedProperties: async (limit, type, callback = () => { }) => {
        await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: "getFeaturedProperties",
                limit: limit,
                type: type
            }
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            }
        });
    },


}

export default FeaturedPropertiesService;