import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import * as Icon from "@mui/icons-material";
import { IconButton, ListItemButton, ListItemIcon, ListItemText, Toolbar, Tooltip, Typography } from '@mui/material';
import { UserAuth } from '../../../store/context/userContext';
import AuthPopup from '../../../commonComponents/authComponents/AuthPopup';
import LinkHref from '@mui/material/Link'
import { ROUTE_LISTER_HOME } from '../../../constants/page';
import { empty, extractDataFromArray } from '../../../utils/common';
import { CommonContext } from '../../../store/context/commonContext';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { InfoOutlined } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import config from '../../../config';



const sideBarItems = [
    {
        link: config.farwideBaseUrl,
        label: 'Home',
        icon: <Icon.HomeOutlined />
    },
    {
        link: config.searchURL + "Missouri&isExplore=true&fetchLR=true&lat=38.3046615&lon=-92.437099&zl=4",
        label: 'Map & Tools',
        icon: <Icon.MapOutlined />,
        target:'_blank'
    },
    {
        link: config.magazineURL,
        label: 'Blog',
        icon: <Icon.DescriptionOutlined />,
        target:'_blank'
    },
    {
        link: 'https://www.farwide.org/',
        label: 'Conservation Trust',
        icon: <Icon.VolunteerActivismOutlined />,
        target:'_blank'
    }
]

const contactUs = [
    {
        link: window.location.origin + "/contactus",
        label: 'Contact FarWide',
        icon: <Icon.EmailOutlined />
    },
    {
        link: config.privacyTermsLink,
        label: 'Terms & Conditions',
        icon: <Icon.DescriptionOutlined />,
        target:'_blank'
    }
]

const LandingPageDrawer = () => {

    const { user, authPopup, setAuthPopup } = UserAuth()
    const { isTablet, toggleNavigationDrawer, openNavigationDrawer } = CommonContext();
    const location = useLocation();
    let currentItem = location.pathname

    // console.log("window location", window.location.origin);

    const isSelected = (itemLink) => {
        const currentPath = location.pathname;
        const currentOrigin = window.location.origin;

        // For the home page
        if (itemLink === config.farwideBaseUrl) {
            return currentPath === '/' || (
                currentPath !== '/contactus' &&
                !sideBarItems.some(item => 
                    item.link !== config.farwideBaseUrl && 
                    item.link.startsWith(currentOrigin) && 
                    currentPath.startsWith(new URL(item.link).pathname)
                )
            );
        }

        // For the contact page
        if (itemLink === `${currentOrigin}/contactus`) {
            return currentPath.startsWith('/contactus');
        }

        // For other internal pages (if any)
        if (itemLink.startsWith(currentOrigin)) {
            return currentPath.startsWith(new URL(itemLink).pathname);
        }

        // For external links (blog, conservation trust, etc.)
        return false;
    };




    const openAuthPopup = (type) => {
        setAuthPopup({ ...authPopup, type: type, open: true })
    }
    const closeAuthPopup = () => {
        setAuthPopup({ ...authPopup, open: false })
    }

    const handleItemClick = () => {
        // console.log('handleitemclick')
        if (!isTablet)
            return
        else
            toggleNavigationDrawer()
    }

    const DrawerList = (
        <Box sx={{ minWidth: 292, mt: 3, marginInline: 3, mb: 3, height: '100%' }} role="presentation" onClick={toggleNavigationDrawer(false)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    // height: 'calc( 100vh - 273px )'
                    height: '100%',
                    gap: 5
                }}
            >
                <Box>

                    {
                        empty(user) &&
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, mb: 3 }}>
                            <LinkHref href={ROUTE_LISTER_HOME} sx={{ color: 'var(--black)', width: "100%", textAlign: "center" }}><Button variant="link" color='var(--black)' sx={{ textDecoration: 'none' }}>List on FarWide </Button></LinkHref>
                            <Button fullWidth size='small' variant="outlined" onClick={() => { openAuthPopup('signin') }} sx={{ fontSize: "16px !important" }}>Sign In</Button>
                            <Button fullWidth size='small' variant="contained" onClick={() => { openAuthPopup('signup') }} sx={{ fontSize: "16px !important" }}>Sign Up</Button>
                        </Box>
                    }
                    <Box>
                        {sideBarItems.map((item, index) => {
                            return (
                                <Link to={item.link} style={{ textDecoration: "none", }} key={index} target={extractDataFromArray(item, ['target'], '')} >

                                    {/* <Tooltip title={item.label} placement="right"> */}
                                        <ListItemButton
                                            selected={isSelected(item.link)}
                                            onClick={handleItemClick}
                                            sx={{
                                                mb: 0.5,
                                                height: 40,
                                                paddingLeft: 1,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                '& .MuiListItemButton-root': {
                                                    backgroundColor: 'red !important'
                                                },
                                                "&:hover": {
                                                    backgroundColor: '#F5F5F6 !important'
                                                },
                                                "&.Mui-selected": { backgroundColor: '#F5F5F6 !important' },
                                                backgroundColor: '#FFFFFF !important'
                                            }}
                                        >
                                            <ListItemIcon sx={{ mr: 0, color: isSelected(item.link) ? '#26C77A' : '#949494', "&.Mui-selected": { color: 'red !important' }, }}>{item.icon}</ListItemIcon>
                                            {< ListItemText primary={item.label}
                                                sx={{
                                                    ml: 1,
                                                    // "& .MuiListItemText-primary": { fontWeight: 600, color: '#1B1B1B' },
                                                    '& .MuiTypography-root': {
                                                        fontWeight: 600,
                                                        color: isSelected(item.link) ? '#26C77A' : '#1B1B1B'
                                                    }
                                                }} />}
                                        </ListItemButton>
                                    {/* </Tooltip> */}

                                </Link>
                            )
                        })}
                    </Box>
                </Box>
                <Box
                    className="WRAPPER"
                    sx={{
                        // display: 'flex',
                        // flexDirection: 'column',
                        // justifyContent: 'space-between',
                        // // height: 'calc( 100vh - 273px )'
                        // height: '100%'
                    }}
                >

                    <Box
                        sx={{
                            // position: 'fixed',
                            // bottom: 0,
                            marginBottom: 3
                        }}
                    >
                        {contactUs.map((item, index) => {
                            return (
                                <Link to={item.link} style={{ textDecoration: "none", }} key={index} target={extractDataFromArray(item, ['target'], '')} >

                                    {/* <Tooltip title={item.label} placement="right"> */}
                                        <ListItemButton
                                            selected={isSelected(item.link)}
                                            onClick={handleItemClick}
                                            sx={{
                                                mb: 0.5,
                                                height: 40,
                                                display: 'flex',
                                                paddingLeft: 1,
                                                justifyContent: 'center',
                                                '& .MuiListItemButton-root': {
                                                    backgroundColor: 'red !important'
                                                },
                                                "&:hover": {
                                                    backgroundColor: '#F5F5F6 !important'
                                                },
                                                "&.Mui-selected": { backgroundColor: '#F5F5F6 !important' },
                                                backgroundColor: '#FFFFFF !important'
                                            }}
                                        >
                                            <ListItemIcon sx={{ mr: 0, color: isSelected(item.link) ? '#26C77A' : '#949494', "&.Mui-selected": { color: 'red !important' }, }}>{item.icon}</ListItemIcon>
                                            {< ListItemText primary={item.label}
                                                sx={{
                                                    ml: 1,
                                                    // "& .MuiListItemText-primary": { fontWeight: 600, color: '#1B1B1B' },
                                                    '& .MuiTypography-root': {
                                                        fontWeight: 600,
                                                        color: isSelected(item.link) ? '#26C77A' : '#1B1B1B'
                                                    }
                                                }} />}
                                        </ListItemButton>
                                    {/* </Tooltip> */}

                                </Link>
                            )
                        })}
                        <Box
                            sx={{
                                mb: 4
                            }}
                        >
                            <Divider sx={{ width: '100%' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 3,
                                mb: 4
                            }}
                        >
                            <Link className="logo image" to="https://www.instagram.com/farwide_outdoors/" target='_blank' >
                                <Box><img src='../assets/images/instagram.svg' alt='FarWide' /></Box>
                                {/* <Box className="fw_logo_mob" sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: '40px' }}><img src='../assets/images/homepage/farwide-logo.svg' alt='FarWide' /></Box> */}
                            </Link>
                            <Link className="logo image" to="https://www.facebook.com/farwideoutdoors/" target='_blank'>
                                <Box ><img src='../assets/images/facebook-black.svg' alt='FarWide' /></Box>
                                {/* <Box className="fw_logo_mob" sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: '40px' }}><img src='../assets/images/homepage/farwide-logo.svg' alt='FarWide' /></Box> */}
                            </Link>
                        </Box>
                        <Box>
                            <Typography variant="caption" sx={{ color: '#949494' }}>© 2024 FarWide Technologies. All rights reserved.</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <AuthPopup open={authPopup.open} type={authPopup.type} handleClose={() => { closeAuthPopup(false) }} handleOpen={openAuthPopup} />
        </Box>
    );

    return (
        <Box>

            <Drawer open={openNavigationDrawer} onClose={toggleNavigationDrawer(false)} sx={{ zIndex: 999999 }}>
                <Toolbar

                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 1
                        // px: 2,
                        // height: '72px',
                    }}
                >
                    <Box sx={{ ml: "0px !important" }}><img src='/landowner/assets/images/farwide-new-logo.svg' alt='FarWide' /></Box>
                    <IconButton onClick={toggleNavigationDrawer(false)} sx={{ p: 0 }}>
                        <Icon.CloseOutlined sx={{ color: '#000000', p: 0 }} />
                    </IconButton>
                </Toolbar>
                <Divider />
                {DrawerList}
            </Drawer>
        </Box>
    );
}
export default LandingPageDrawer;