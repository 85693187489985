import React from 'react';
import { Box, CardMedia, Chip, Typography } from '@mui/material';
import * as Icon from '@mui/icons-material';
import { CommonContext } from '../../store/context/commonContext';
import { empty, generatePhrase, getActivityType, getImageUrl, roundNumber } from '../../utils/common';
import { useLocation } from 'react-router';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { Link } from 'react-router-dom';
import MediaCard from '../previewPageComponents/mediaCard';
import { ACCESS, EXPERIENCE } from '../CommonConstants/commonKeys';
import { ROUTE_EXPERIENCE, ROUTE_PACKAGE, ROUTE_PROPERTY } from '../../constants/page';

const PackageCardMobile = ({ image, title, area, address, nearest_town, showBorder = true, slug, seq, activity_type, propertyName, listingSlug, listing_seq, listingType = '', pkgTitle }) => {
    const { isMobile } = CommonContext()
    const location = useLocation();
    const isValidAddress = address === '-' ? false : true;

    let chipText = ''
    let route = ''
    let sequence = ''

    if (listingType === EXPERIENCE.value) {
        chipText = EXPERIENCE.string
        route = ROUTE_EXPERIENCE
        sequence = seq
    } else if (listingType === ACCESS.value) {
        chipText = ACCESS.string
        route = ROUTE_PACKAGE
        sequence = seq
    }

    return (
        <Box
            sx={{
                p: showBorder && 2,
                border: showBorder && '1px solid var(--grey500)',
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
            onClick={
                (event) => {
                    // console.log(seq);
                    // console.log(`${route}/${slug}-${sequence}`);
                    const isPropertyNameClicked = event.target.classList.contains('propertyName');
                    if (isPropertyNameClicked) {
                        let locationToOpen = isMobile ? '_self' : '_blank'
                        window.open(`/property/${listingSlug}-${listing_seq}`, locationToOpen)
                        return
                    } else if (!empty(slug)) {
                        let locationToOpen = isMobile ? "_self" : "_blank"
                        // console.log(`${route}/${slug}-${sequence}`);
                        window.open(`${route}/${slug}-${sequence}`, locationToOpen)
                    } else {

                    }
                }}
        >
            <MediaCard
                imageObj={image}
                width={'100px'}
                height={'80px'}
                borderRadius={'8px'}
            />
            <Box
                sx={{
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    ml: 1,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 0.5
                }}
            >
                <Typography noWrap sx={{
                    display: 'block',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: '100%'
                }}>
                    {title}
                </Typography>
                {area !== '' && <Box sx={isMobile ? { display: 'flex', width: '100%', gap: 1, fontSize: '14px', mb: 0.5 } : { display: 'flex', gap: 1, fontSize: '14px', mb: 0.5 }}>
                    <Icon.MapOutlined />
                    <Typography sx={{ mr: 1, color: 'var(--grey900)', fontSize: '14px' }}>
                        {generatePhrase(roundNumber(area), 'acre')}
                    </Typography>
                </Box>}
                {chipText !== '' && <Chip
                size='small'
                sx={{
                    backgroundColor: 'white',
                    border: '1px solid #949494',
                }} label={chipText} />}
                <Box sx={{ display: 'flex', alignItems: 'flex-start', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    <Icon.LocationOnOutlined sx={{ mr: 1 }} />
                    {listingType !== EXPERIENCE.value && <Typography sx={{}}>
                        <Typography variant='body2' component={'span'} sx={{ color: 'var(--grey900)' }}>
                            {getActivityType(activity_type) + ' at '}
                        </Typography>
                        <Link
                            // target={isMobile ? '_self' : '_blank'} 
                            // to={`/property/${listingSlug}-${listing_seq}`}
                            className="propertyName" style={{ color: 'var(--black)', textDecoration: 'underline', fontSize: '14px' }} component={'span'} >
                            {" " + propertyName + " "}
                        </Link>
                        <Typography component={'span'} sx={{ color: 'var(--grey900)' }} variant='body2' >
                            near {nearest_town}
                        </Typography>

                    </Typography>}
                    {
                        listingType === EXPERIENCE.value && <Typography variant='body2' sx={{ color: 'var(--grey900)' }}>
                            {address}
                        </Typography>
                    }
                </Box>
                {/* {chipText !== '' && <Chip sx={{
                    backgroundColor: 'white',
                    border: '1px solid #949494',
                }} label={chipText} />} */}
            </Box>
        </Box >
    );
};

export default PackageCardMobile;
