import { empty } from "./common"

const LocalDB = {
    get: (key, format = '') => {
        let val = window.localStorage.getItem(key)
        if (!empty(val)) {
            if (format === 'JSON') {
                val = JSON.parse(val)
            }
        }
        return val
    },
    set: (key, val) => {
        if (typeof val === 'object') {
            val = JSON.stringify(val)
        }
        window.localStorage.setItem(key, val)
    }
}

export default LocalDB