import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import * as Icon from "@mui/icons-material"
import { TextField, Typography } from '@mui/material';
import { extractDataFromArray } from '../../../utils/common';
import { formatBytes } from '../../../utils/common';


export default function TemporaryDrawer({drawerSettings, toggleDrawer}) {
   

    return (
        <div>
            <Drawer
                anchor={"right"}
                open={drawerSettings.open}
                onClose={()=>{toggleDrawer(false)}}
                className='filedetails_drawer'
            >
                <Box sx={{ width: '360px' }}>
                    <Typography variant='body1' sx={{ p: 3, fontWeight: '600' }}>Information</Typography>
                    <Divider />
                    <Box sx={{ p: 3 }}>
                        <Box className="fm_detail_img" sx={{ borderRadius: theme => `${theme.card.borderRadius} ` }} style={{ backgroundImage: "url('"+extractDataFromArray(drawerSettings.selectedFile, ['url'], '')+"')" }}>
                        </Box>
                        <Box sx={{ py: 2 }}>
                            <Typography variant='body2'>{extractDataFromArray(drawerSettings.selectedFile, ['name'], '')}</Typography>
                        </Box>
                        <Divider />
                        <Box sx={{ mt: 2, pb: 2 }}>
                            <Typography variant='body1' sx={{ fontWeight: 600, mb: 1 }}>Tags</Typography>
                            <TextField fullWidth id="outlined-basic" label="Tags" variant="outlined" sx={{ mb: 0 }} />
                        </Box>
                        <Divider />
                        <Box sx={{ mt: 2, pb: 2 }}>

                            <Typography variant='body1' sx={{ fontWeight: 600 }}>Properties</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box><Typography variant='caption'>Size</Typography></Box>
                            <Box><Typography variant='caption'>{formatBytes(extractDataFromArray(drawerSettings.selectedFile, ['size'], 0))}</Typography></Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box><Typography variant='caption'>Modified</Typography></Box>
                                <Box><Typography variant='caption'>24 Apr 2023 7:00 PM</Typography></Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box><Typography variant='caption'>Type</Typography></Box>
                                <Box><Typography variant='caption'>Image</Typography></Box>
                            </Box>
                        </Box>
                        <Divider />
                        <Box sx={{ mt: 2 }}>

                            <Typography variant='body1' sx={{ fontWeight: 600 }}>Copy</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box><Typography variant='caption'>File URL</Typography></Box>
                                <Box sx={{ width: '50%' }}>
                                    <Typography variant='caption' sx={{ wordBreak: "break-word" }}>
                                        {"https:"+extractDataFromArray(drawerSettings.selectedFile, ['url'], '')}
                                    </Typography>
                                    <Button variant="outlined" startIcon={<Icon.ContentCopyOutlined />} sx={{ mt: 2 }}>
                                        Copy Link
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            </Drawer>
        </div >
    );
}