import { Box, Button, Divider, IconButton, Typography } from "@mui/material"
import * as Icon from '@mui/icons-material';
import ListingService from "../../services/listing";
import { toast } from "react-toastify";

const MoveToPendingContent = ({ handleClose, listing_seq, listing_type }) => {
    const handleClick = () => {

        handleClose()
        ListingService.setPendingListing(listing_seq, listing_type, (data) => {
            toast.success("Listing moved to pending.", { theme: 'colored' })
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        })
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ py: 1.5, px: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}> Move to Pending</Typography>
                <IconButton onClick={handleClose}><Icon.Close /> </IconButton>
            </Box>
            <Divider />
            <Box sx={{ p: 2.5, width: "600px" }}>
                <Typography sx={{ mb: 4 }}>
                    Are you sure you want to move this listing to pending?
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'right', gap: 1 }}>

                    <Button variant="outlined" color="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="secondary" onClick={handleClick}>Move to Pending</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default MoveToPendingContent;