
import Page404 from "../pages/404";

const DefaultRoutes = {
    path: '*',
    element: <Page404 />,
    children: []
}


export default DefaultRoutes;


