import React, { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker, MobileDateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import * as Icon from "@mui/icons-material";
import { Box, TextField, Typography } from "@mui/material";
import { CommonContext } from "../../store/context/commonContext";
import { empty, generatePhrase } from "../../utils/common";


const CustomDateRangePicker = ({ onChange, defaultDateRange, minDays, maxDays, notAvailableDates = [], isPopup }) => {

    const [openDate, setOpenDate] = useState(false)
    const { isMobile } = CommonContext()

    // console.log(defaultDateRange)
    // console.log(notAvailableDates)

    const { isNineHundred } = CommonContext()
    // console.log(selectedDays)
    // console.log(selectedDays)

    const [errorText, setErrorText] = useState('')
    const [dateError, setDateError] = useState(false)

    const today = dayjs()



    function dateRangeContainsUnavailable(dateRange, unavailableDates) {

        const start = dayjs(dateRange[0])
        const end = dayjs(dateRange[1])

        for (let date of unavailableDates) {
            if (date.isBetween(start - 1, end + 1)) {
                return true
            }
        }

        return false
    }

    const setDates = (date) => {
        // date[0] = null
        // date[1] = null
        // setDateRange([null, null])
        // console.log(date)
        // console.log(minDays===maxDays)
        // console.log(minDays,maxDays)
        // console.log(str)
        // console.log(date)
        // console.log(defaultDateRange)
        if (date[0] == null || date[1] == null) {
            // console.log('nullinvoked')
            setDateSelectText("Select Dates")
            setErrorText('')
            onChange([null, null])
        }

        if (minDays == maxDays && date[1] != null) {

            if ((date[0] ? date[0].format('YYYY-MM-DD') : null) !== (defaultDateRange[0] ? defaultDateRange[0].format('YYYY-MM-DD') : null)) {
                if (date[0]) {
                    date[1] = dayjs(date[0]).add(minDays - 1, 'day');
                    onChange(date)
                    setOpenDate(false)
                    return
                }
            }


            // if(date[1].format('YYYY-MM-DD') != dateRange[1].format('YYYY-MM-DD')){
            //     date[1] = dayjs(date[0]).add(minDays - 1, 'day');
            //     setOpenDate(false)
            //     return
            // }


            // console.log(date[0], 'date0')
            // console.log('notnull invoked')
            date[0] = date[1]
            date[1] = dayjs(date[0]).add(minDays - 1, 'day');
            setOpenDate(false)
            // console.log(date, 'intopcond')
            onChange(date);
        } else {
            if (minDays == maxDays) {
                // console.log(date, 'before update');
                date[1] = dayjs(date[0]).add(minDays - 1, 'day');
                setOpenDate(false)
                // console.log(date, 'after update');
                onChange(date);
            } else {
                // Check if both formatted dates are equal
                if (dayjs(date[1]).format('YYYY-MM-DD') === dayjs(defaultDateRange[1]).format('YYYY-MM-DD')) {
                    date[1] = null;
                    setOpenDate(true);
                    onChange(date)
                } else {
                    onChange(date)
                }
            }
        }

    }


    const [dateSelectText, setDateSelectText] = useState('')

    const disableCustomDates = (date) => {
        return date.isSame(today, 'day')
            || (notAvailableDates.length > 0 && notAvailableDates.some(d => date.isSame(d, 'day')));
    };

    let dateHelperText = ''

    if (minDays == maxDays) {
        dateHelperText = `This package can be booked for only ${generatePhrase(minDays, 'day')}`
    } else if (minDays != maxDays) {
        dateHelperText = `This package can be booked for only ${minDays}-${maxDays} days`
    }
    useEffect(() => {

        let days = dayjs(defaultDateRange[1]).diff(defaultDateRange[0], 'days') + 1;

        // console.log(days)



        if (minDays == 1 && maxDays == 1) {
            setDateSelectText(`${defaultDateRange[0].format('MMM DD, YYYY')}`)
            setOpenDate(false)
        } else if (defaultDateRange[0].format('YYYY-MM-DD') !== defaultDateRange[1].format('YYYY-MM-DD')) {
            if (!`${defaultDateRange[0].format('MMM DD, YYYY')} - ${defaultDateRange[1].format('MMM DD, YYYY')}`.includes('Invalid'))
                setDateSelectText(`${defaultDateRange[0].format('MMM DD, YYYY')} - ${defaultDateRange[1].format('MMM DD, YYYY')}`)
            // setOpenDate(false)
        } else if (defaultDateRange[0].format('YYYY-MM-DD') == defaultDateRange[1].format('YYYY-MM-DD')) {
            if (!`${defaultDateRange[0].format('MMM DD, YYYY')} - ${defaultDateRange[1].format('MMM DD, YYYY')}`.includes('Invalid'))
                setDateSelectText(`${defaultDateRange[0].format('MMM DD, YYYY')}`)
            setOpenDate(false)
        }






        if (isNaN(days)) {
            setErrorText("Select valid date range for this package")
            setDateError(true)
        }

        if (dateRangeContainsUnavailable(defaultDateRange, notAvailableDates)) {
            setErrorText(`The selected date range includes some unavailable dates`)
            setDateError(true)
            // console.log(`The selected date range includes some unavailable dates`)
            return
        }


        if (days < minDays) {
            setErrorText(`You can not book this package for less than ${minDays} days.`)
            setDateError(true)
        } else if (days > maxDays) {
            setErrorText(`You can not book this package for more than ${maxDays} days`)
            setDateError(true)
        } else {
            // console.log(defaultDateRange)
            if (empty(defaultDateRange[0]) || empty(defaultDateRange[1]) || (`${defaultDateRange[0].format('MMM DD, YYYY')} - ${defaultDateRange[1].format('MMM DD, YYYY')}`.includes('Invalid'))) {
                setErrorText("")
            } else {
                setErrorText('These dates are available')
            }
            setDateError(false)
        }

    }, [defaultDateRange, dateError]);

    return (
        <>
            <Box sx={{
                height: 0,
                overflow: 'hidden',

                '& :hover': {
                    cursor: 'pointer',
                },
                "& .MuiFormHelperText-root": {
                    ml: 0,
                    color: 'green'
                },
                "&. MuiTextField-root": {
                    display: 'none'
                },
                mt: 2,
            }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {!isNineHundred ?
                        <Box sx={{
                            height: 0,
                            width: '100%'
                        }}>
                            <DesktopDateRangePicker
                                open={openDate}
                                // autoFocus
                                calendars={1}
                                clearable={true}
                                slots={{ field: SingleInputDateRangeField }}
                                // label="Select Dates"
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        // InputProps: { endAdornment: <Icon.CalendarMonth />, placeholder: 'Select Dates' },
                                        // error: !!dateError,
                                        // helperText: errorText
                                    },
                                    actionBar: { actions: ["clear"] },

                                }}
                                sx={{
                                    width: '100%',
                                    '& .MuiOutlinedInput-root': {
                                        backgroundColor: 'white',
                                    },
                                    "&. MuiInputBase-root": {
                                        display: 'none'
                                    },
                                    "& .MuiFormControl-root": {
                                        display: 'none'
                                    }
                                }}
                                format="DD MMM YYYY"
                                onOpen={() => setOpenDate(true)}
                                value={defaultDateRange}
                                onChange={(date) => setDates(date, 'onchange')}
                                disablePast
                                disableHighlightToday
                                shouldDisableDate={disableCustomDates}
                                onClose={() => setOpenDate(false)}
                            />
                        </Box>
                        :
                        <MobileDateRangePicker
                            open={openDate}
                            calendars={1}
                            clearable={true}
                            slots={{ field: SingleInputDateRangeField }}
                            // label="Select Dates"
                            slotProps={{
                                textField: {
                                    size: "small",
                                    InputProps: { endAdornment: <Icon.CalendarMonth />, placeholder: 'Select Dates' },
                                    error: !!dateError,
                                    helperText: errorText
                                },
                                actionBar: { actions: ["clear"] },

                            }}
                            sx={{
                                width: '100%',
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'white',

                                }
                            }}
                            format="DD MMM YYYY"
                            value={defaultDateRange}
                            onChange={(date) => setDates(date)}
                            disablePast
                            disableHighlightToday
                            shouldDisableDate={disableCustomDates}
                            onClose={() => setOpenDate(false)}
                        />}
                </LocalizationProvider>
            </Box>
            <TextField
                sx={{
                    // top: '28px',
                    minWidth: !isMobile ? '275px' : 0,
                    zIndex: '999',
                    marginTop: '-18px',
                    borderRadius: '6px',
                    // transition: 'border-color 0.3s', // Add transition for smooth effect
                    color: 'var(--black)',
                    '& :hover': {
                        cursor: 'pointer'
                    },
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: 'white',
                    },
                    "&. MuiInputBase-root": {
                        display: 'none',
                    },
                    "& .MuiFormControl-root": {
                        display: 'none'
                    }, "& .MuiFormHelperText-root": {
                        ml: 0,
                        color: 'var(--primary)'
                    }, "& .MuiInputBase-input": {
                    }, "& .Mui-error": {
                        color: '#d32f2f'
                    }
                }}
                InputProps={{
                    endAdornment: <Icon.CalendarToday />
                }}
                error={!!dateError}
                helperText={errorText}
                size="small"
                autoComplete="off"
                value={dateSelectText}
                onClick={(e) => {
                    e.preventDefault();
                    setOpenDate(!openDate)
                }}
                onKeyDown={(e) => e.preventDefault()} // Prevent typing inside TextField
            >
                {/* {dateSelectText} */}
            </TextField>

            {/* <Typography variant="caption">
                            {dateHelperText}
                        </Typography> */}
        </>
    );
};

export default CustomDateRangePicker;
