// ** MUI Imports
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import InputAdornment from '@mui/material/InputAdornment'

import * as Icon from '@mui/icons-material';
import { empty, extractDataFromArray } from '../../../utils/common';
import { FormHelperText } from '@mui/material';

const QuantityInput = ({ name, value, errors, setValue, min, max }) => {

    const incNum = () => {
        let newValue = parseInt(value) + 1;
        if (max === undefined || newValue <= max) {
            setValue(newValue);
        }
    };

    const decNum = () => {
        let newValue = parseInt(value) - 1;
        if (min === undefined || newValue >= min) {
            setValue(newValue);
        }
    };

    const handleChange = (e) => {
        let inputValue = e.target.value;

        if (inputValue.length > 1 && inputValue[0] === '0') {
            inputValue = inputValue.substring(1);
        }

        if (!inputValue || /^[1-9]\d*$/.test(inputValue)) {
            const numericValue = parseInt(inputValue || '0');
            if ((min === undefined || numericValue >= min) && (max === undefined || numericValue <= max)) {
                setValue(numericValue);
            } else if (min !== undefined && numericValue < min) {
                setValue(min);
            } else if (max !== undefined && numericValue > max) {
                setValue(max);
            }
        }
    };

    return (
        <Box>
            <TextField
                style={{ borderRadius: '6px', overflow: 'hidden' }}
                type='number'
                sx={{
                    textAlign: 'center',
                    background: '#fff',
                    '& .MuiInputBase-root': {
                        py: 0,
                    },
                    input: {
                        borderLeft: '1px solid var(--grey700)',
                        borderRight: '1px solid var(--grey700)',
                        textAlign: 'center',
                        p: "10px !important",
                        maxWidth: "40px",
                        minWidth: "24px"
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                    },
                    "& input[type=number]": {
                        MozAppearance: "textfield",
                    },
                    "& .MuiOutlinedInput-root": {
                        p: "0px !important"
                    },
                    "& .MuiInputAdornment-root": {
                        marginLeft: "0px",
                        marginRight: "0px"
                    }
                }}
                id={name}
                name={name}
                value={value}
                onInput={handleChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Box onClick={incNum} sx={
                                value >= max ?
                                    { cursor: "not-allowed", display: 'flex', alignItems: 'center', padding: "10px", backgroundColor: 'var(--grey300)',color:'var(--grey700)' }
                                    :
                                    { cursor: "pointer", display: 'flex', alignItems: 'center', padding: "10px" }
                            }>
                                <Icon.Add />
                            </Box>
                        </InputAdornment>
                    ),
                    startAdornment: (
                        <InputAdornment position="start">
                            <Box onClick={decNum} sx={
                                value <= min ?
                                    { cursor: "not-allowed", display: 'flex', alignItems: 'center', padding: "10px", backgroundColor: 'var(--grey300)',color:'var(--grey700)' }
                                    :
                                    { cursor: "pointer", display: 'flex', alignItems: 'center', padding: "10px" }
                            }>
                                <Icon.Remove />
                            </Box>
                        </InputAdornment>
                    )
                }}
            />
            {!empty(extractDataFromArray(errors, name, '')) && (
                <FormHelperText error={true}>{extractDataFromArray(errors, name, '')}</FormHelperText>
            )}
        </Box>
    )
}

export default QuantityInput;
