import * as React from "react";

import { Grid, Typography } from "@mui/material";

const Requirement = (props) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      className="mobmarginbottom"
      sx={{ mb: 10 }}
    >
      <Typography className="safety_title " sx={{ mb: 2.5 }}>
        {" "}
        Requirements & Exclusions
      </Typography>
      <Typography sx={{ mb: 2 }}>
        {" "}
        Due to the comprehensive nature of LandTrust’s attention to safety and
        insurance, there may be specific exclusions in different elements of
        coverage beyond our control. We encourage landowners to understand their
        own existing policies and obtain the additional agritainment or
        dedicated hunting policy as they deem appropriate.
      </Typography>
      <Typography sx={{ mb: 2 }}>
        For detailed questions on LandTrust’s liability coverage, please email
        insurance@landtrust.com or give us a call at (406) 709-8450.{" "}
      </Typography>
    </Grid>
  );
};

export default Requirement;
