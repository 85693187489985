
import LandingPageLayout from "../layouts/LandingPageLayout";
import FileManager from "../pages/filemanager";
import Home from "../pages/home";
import AboutUs from "../pages/aboutus";
import ContactUs from "../pages/contactus";
import Safety from "../pages/safety";
import Login from '../pages/login';
import Register from "../pages/register";
import { ROUTE_PACKAGE, ROUTE_PROPERTY } from "../constants/page";
import PropertyDetails from "../commonComponents/PropertyDetails";
import PackageDetails from "../pages/packageDetails";



const LandingPageRoutes = {
    path: '/',
    element: <LandingPageLayout />,
    children: [
        {
            path: '/',
            element: <Home />
        },

        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/register',
            element: <Register />
        },
        {
            path: '/home',
            element: <Home />
        },
        {
            path: '/aboutus',
            element: <AboutUs />
        },
        {
            path: '/contactus',
            element: <ContactUs />
        },
        {
            path: '/Safety',
            element: <Safety />
        },
        {
            path: '/filemanager',
            element: <FileManager />
        },
        
    ]
};

export default LandingPageRoutes;


