import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Slider,
    MenuItem,
    TextField,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,
    InputAdornment,
} from '@mui/material';
import * as Icon from '@mui/icons-material';
import { CommonContext } from '../../store/context/commonContext';


const PriceFilter = ({ open, setOpenPricingFilter, handleFilterChange, handleClose, count, initialValue, removeFilterFromUrlParams, priceRangeToShow, setPriceRangeToShow, setIsActivePrice, setCallGetCount, setShowClearFilterButton, calledClearfilter }) => {

    const [minValue, setMinValue] = useState(priceRangeToShow[0]);
    const [maxValue, setMaxValue] = useState(priceRangeToShow[1]);
    const hasUpdatedFromURL = useRef(false);
    const initialPriceRange = useRef(priceRangeToShow);
    const [hasChanged, setHasChanged] = useState(false);
    const { screenWidth } = CommonContext();
    // console.log("pricing range", priceRangeToShow);

    const isFilterApplied = () => {
        const url = new URL(window.location.href);
        const mnp = url.searchParams.get('mnp');
        const mxp = url.searchParams.get('mxp');
        return mnp !== null && mxp !== null && (Number(mnp) !== 10 || Number(mxp) !== 100000);
    };


    useEffect(() => {
        const url = new URL(window.location.href);
        const mnp = url.searchParams.get('mnp');
        const mxp = url.searchParams.get('mxp');

        if (mnp !== null && mxp !== null) {
            setMinValue(Number(mnp));
            setMaxValue(Number(mxp));
        } else {
            setMinValue(10);
            setMaxValue(100000);
        }
    }, []);

    useEffect(() => {

        if (!hasUpdatedFromURL.current &&
            (priceRangeToShow[0] !== initialPriceRange.current[0] ||
                priceRangeToShow[1] !== initialPriceRange.current[1])) {
            setMinValue(priceRangeToShow[0]);
            setMaxValue(priceRangeToShow[1]);
            setHasChanged(priceRangeToShow[0] !== 10 || priceRangeToShow[1] !== 100000);
            hasUpdatedFromURL.current = true;
        }
        // setTimeout(()=> {
        //     handleFilterChange({ min: minValue, max: maxValue });
        // },[500])
    }, [priceRangeToShow]);


    useEffect(() => {
        if (calledClearfilter === true) {
            setMinValue(10);
            setMaxValue(100000);
        }
    }, [calledClearfilter])

    // Snap to the nearest multiple of 10
    const snapToNearestTen = (value) => Math.round(value / 10) * 10;

    useEffect(() => {
        setPriceRangeToShow([minValue, maxValue])
    }, [minValue, maxValue]);

    const addFilterToUrlParams = () => {
        let url = ''
        url = new URL(window.location.href);

        url.searchParams.set('mnp', minValue)
        url.searchParams.set('mxp', maxValue)


        window.history.pushState({}, '', url);
    }



    // Converts slider value
    const handleSliderChange = (event, newValue) => {
        setCallGetCount(true)
        const [newMin, newMax] = newValue;
        setMinValue(newMin);
        setMaxValue(newMax);
        setHasChanged(newMin !== 10 || newMax !== 100000);
    };

    const handleInputChange = (index) => (event) => {
        setCallGetCount(true)

        const newValue = Number(event.target.value);
        if (index === 0) {
            setMinValue(newValue);
            setHasChanged(newValue !== 10 || maxValue !== 100000);
        } else {
            setMaxValue(newValue);
            setHasChanged(minValue !== 10 || newValue !== 100000);
        }
    };

    const handleInputBlur = (index) => () => {
        setCallGetCount(true)

        let newMinValue = minValue;
        let newMaxValue = maxValue;

        if (index === 0) {
            newMinValue = snapToNearestTen(Math.max(10, Math.min(newMinValue, 100000)));
            if (newMinValue > newMaxValue) {
                [newMinValue, newMaxValue] = [newMaxValue, newMinValue];
            }
        } else {
            newMaxValue = snapToNearestTen(Math.min(100000, Math.max(newMaxValue, 10)));
            if (newMaxValue < newMinValue) {
                [newMinValue, newMaxValue] = [newMaxValue, newMinValue];
            }
        }
        setMinValue(newMinValue);
        setMaxValue(newMaxValue);
    };

    const handleInputKeyPress = (index) => (event) => {
        if (event.key === 'Enter') {
            handleInputBlur(index)();
        }
    };

    const handleSelectChange = (index) => (event) => {
        const newValue = Math.round(Number(event.target.value) / 10) * 10; // Snap to the nearest multiple of 10

        if (index === 0) {
            // If minimum value is updated and is greater than the current maximum value, swap them
            if (newValue > maxValue) {
                setMinValue(maxValue);
                setMaxValue(newValue);
            } else {
                setMinValue(newValue);
            }
        } else {
            // If maximum value is updated and is less than the current minimum value, swap them
            if (newValue < minValue) {
                setMaxValue(minValue);
                setMinValue(newValue);
            } else {
                setMaxValue(newValue);
            }
        }
    };

    return (
        <Dialog open={open} onClose={() => { setOpenPricingFilter(false) }} sx={{ '& .MuiPaper-root': { maxWidth: '600px', borderRadius: 2 } }}>
            <DialogTitle id='customized-dialog-title' sx={{ py: 2, px: 3, justifyContent: "space-between", display: "flex" }}>
                <Typography variant='h6' fontWeight='700'>
                    Filters
                </Typography>
                <IconButton
                    onClick={() => { setOpenPricingFilter(false) }}
                    sx={{ padding: '0px' }}
                >
                    <Icon.Close />
                </IconButton>
            </DialogTitle>
            <Divider sx={{ width: '100%' }} />
            <DialogContent
                sx={{
                    width: screenWidth < 565 ? '100%' : 500,
                    pb: 8
                }}
            >
                <Box>
                    <Typography variant='subtitle2' gutterBottom >Price Range</Typography>
                    <Box
                        sx={{ maxWidth: 400, marginInline: 'auto', paddingInline: screenWidth < 555 ? '18px' : '' }}
                    >

                        <Slider
                            value={[minValue, maxValue]}
                            onChange={handleSliderChange}
                            min={10} // 1 hour
                            max={100000} // 20 days in hours
                            step={10}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `$${value}`}
                            color='black'
                            sx={{
                                '& .MuiSlider-thumb': {
                                    color: 'white'
                                }
                            }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-between" mt={2} sx={{ gap: 2 }}>
                        <Box
                            sx={{ width: '100%' }}
                        >
                            <Typography>Minimum</Typography>
                            <TextField
                                value={minValue}
                                onChange={handleInputChange(0)}
                                onBlur={handleInputBlur(0)}
                                onKeyDown={handleInputKeyPress(0)}
                                size='small'
                                sx={{ minWidth: 120, width: '100%' }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            >
                            </TextField>
                        </Box>
                        <Box
                            sx={{ width: '100%' }}
                        >
                            <Typography>Maximum</Typography>
                            <TextField
                                value={maxValue}
                                onChange={handleInputChange(1)}
                                onBlur={handleInputBlur(1)}
                                onKeyDown={handleInputKeyPress(1)}
                                size='small'
                                sx={{ minWidth: 120, width: '100%' }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            >
                            </TextField>

                        </Box>
                    </Box>
                </Box>
            </DialogContent>

            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', px: 3, pb: 3 }}>
                <Button variant="text" sx={{ color: 'var(--black)' }}
                    onClick={() => {
                        // setOpenPricingFilter(false);
                        if (isFilterApplied()) {
                            handleFilterChange({ min: '', max: '' });
                            setIsActivePrice(false);
                        }
                        removeFilterFromUrlParams('price');
                        setMinValue(10);
                        setMaxValue(100000);
                        setOpenPricingFilter(false);
                        // setDateRange([null, null])
                        // setTriggerDate(!triggerDate)
                    }} >
                    Clear All
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (minValue !== 10 || maxValue !== 100000) {
                            handleFilterChange({ min: minValue, max: maxValue });
                            addFilterToUrlParams('price');
                        }
                        setOpenPricingFilter(false)
                        // setShowClearFilterButton(true)
                        // addFilterToUrlParams('price')
                    }}
                    sx={{
                        paddingInline: screenWidth < 398 ? '16px !important' : '22px !important'
                    }}
                >
                    Show Results
                </Button>
            </Box>
        </Dialog>
    );
};

export default PriceFilter;
