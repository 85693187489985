import { createContext, useContext, useEffect, useRef, useState } from "react";
import { extractDataFromArray } from "../../utils/common";
import ExperienceService from "../../services/experience";

const defaultProvider = {
    scroll: 0,
    isProfile: false,
}


const experienceContext = createContext(defaultProvider);


const ExperienceContextProvider = ({ children }) => {
    const [experienceData, setExperienceData] = useState({})
    const [experienceDataObject, setExperienceDataObject] = useState({})
    const [experienceJsonArray, setExperienceJsonArray] = useState([])
    const [experienceSequence, setExperienceSequence] = useState('')
    const [addComponents, setAddComponents] = useState([]);
    const [loadingData, setLoadingData] = useState(false)
    const [hoveredId, setHoveredId] = useState(null);
    const [activeComponentId, setActiveComponentId] = useState(null);
    const [clickCount, setClickCount] = useState(0);
    const [disableButton, setDisableButton] = useState(true)
    const [isChecked, setIsChecked] = useState({});
    const [isValid, setIsValid] = useState(true);
    const [open, setOpen] = useState(false)
    const [showErrorText, setShowErrorText] = useState(false)
    const [openConfirmCancellation, setOpenConfirmCancellation] = useState(false)
    const [isCancellationPolicy, setIsCancellationPolicy] = useState(false)
    const [errorsObject, setErrorsObject] = useState({})
    const [isExperienceValid, setIsExperienceValid] = useState(true)
    const [openPublishPopUp, setOpenPublishPopUp] = useState(false)
    const [isButtonLoading, setIsButtonLoading] = useState(false)
    const [isReviewClicked, setisReviewClicked] = useState(false)
    const [showDeleteButton, setShowDeleteButton] = useState(false);
    const [openConfirmPopUp, setOpenConfirmPopUp] = useState(false)
    const [deleteComponentId, setDeleteComponentId] = useState('')
    const [experienceStatus, setExperienceStatus] = useState('D')





    const openDialog = (isChecked, setIsValid, setShowErrorText, setOpenConfirmCancellation) => {
        console.log("called");
        const allChecked = Object.values(isChecked).every(value => value);

        if (!allChecked) {
            setIsValid(false);
            setShowErrorText(true);
            return;
        }

        setOpenConfirmCancellation(true);
    };


    // Function to check if data key exists and update experienceDataObject
    useEffect(() => {
        setExperienceJsonArray(experienceData.experience_structure)
        setExperienceSequence(experienceData.experience_seq)
        if (experienceData.hasOwnProperty('experience_data')) {
            // console.log("Data key found");
            setExperienceDataObject(experienceData.experience_data);
        } else {
            // console.log("Data key not found");
        }
    }, [experienceData]);


    const saveExperienceComponentData = (componentData, onSuccess = () => { }, onErr = () => { }) => {

        ExperienceService.updateExperienceComponent(experienceSequence, activeComponentId, componentData, experienceJsonArray, (data) => {

            console.log('data', data);
            if (onSuccess) {
                onSuccess(data)
                setIsButtonLoading(false)
            }

            if (data === false) {
                setIsButtonLoading(false)
                // Data Not Updated
                return
            }

            let experienceStructure = extractDataFromArray(data, ['experience_structure'], '')
            let experienceData = extractDataFromArray(data, ['experience_data'], '')

            if (experienceData !== '' && experienceStructure !== '') {
                setExperienceDataObject(experienceData)
                setExperienceJsonArray(experienceStructure)
                setExperienceData(data) // added to update main exerience data for sending to backend when publishing experience
            }

            if (isReviewClicked) {
                // console.log("yes review was clicked");
                ExperienceService.validateAllComponents(experienceSequence, (data) => {
                    // console.log("validateAllComponents data from api", data);
                    setErrorsObject(data)
                }, (err) => {
                    setErrorsObject(err)
                    // console.log('error', err)
                });
            } else {
                // console.log("Nooooaaahhhhhh");

            }

        }, (err) => {
            // console.log('error', err)
            setIsButtonLoading(false)
            if (onErr) {
                onErr(err)
            }
        });
    }


    const values = {
        experienceData,
        setExperienceData,
        experienceDataObject,
        setExperienceDataObject,
        experienceJsonArray,
        setExperienceJsonArray,
        loadingData,
        setLoadingData,
        hoveredId,
        setHoveredId,
        activeComponentId,
        setActiveComponentId,
        addComponents,
        setAddComponents,
        experienceSequence,
        setExperienceSequence,
        clickCount,
        setClickCount,
        disableButton,
        setDisableButton,
        openDialog,
        isChecked,
        setIsChecked,
        showErrorText,
        setShowErrorText,
        openConfirmCancellation,
        setOpenConfirmCancellation,
        isValid,
        setIsValid,
        open, setOpen,
        saveExperienceComponentData,
        isCancellationPolicy, setIsCancellationPolicy,
        errorsObject, setErrorsObject,
        isExperienceValid, setIsExperienceValid,
        openPublishPopUp, setOpenPublishPopUp,
        isButtonLoading, setIsButtonLoading,
        isReviewClicked, setisReviewClicked,
        showDeleteButton, setShowDeleteButton,
        openConfirmPopUp, setOpenConfirmPopUp,
        deleteComponentId, setDeleteComponentId,
        experienceStatus, setExperienceStatus
    }


    return (<experienceContext.Provider value={values}>{children}</experienceContext.Provider>)
}

const ExperienceContext = () => {
    return useContext(experienceContext)
}

export { ExperienceContext, ExperienceContextProvider }
