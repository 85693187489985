import { useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import BasicSpeedDial from "../BasicSpeedDial";
import LeftPane from "./components/LeftPane";
import RightPane from "./components/RightPane";

import FileDetails from "./components/FileDetails";
import { extractDataFromArray } from "../../utils/common";



const FileManagerContent = (props) => {
    const [drawerSettings, setDrawerSettings] = useState({ open: false, selectedFile: {} })
    const handleDrawerOpen = (event, state, file) => {
        if (extractDataFromArray(event, ['target', 'type'], '') === "checkbox") {
            return
        }
        setDrawerSettings({ open: state, selectedFile: file })
    }
    const [refreshList, setRefreshList] = useState(1)
    return (
        <Grid container columnSpacing={2}>
            {/* <Grid item xs="auto">
                <Box sx={{width : "260px"}} >
                    <LeftPane refreshList={refreshList} setRefreshList={setRefreshList} />
                </Box>
            </Grid> */}

            <Grid item xs >
                <RightPane buttonText={props.buttonText} refreshList={refreshList} setRefreshList={setRefreshList} selectedCallback={props.selectedCallback} reqFileType={props.reqFileType || ''} handleDrawerOpen={handleDrawerOpen} />
            </Grid>

            <FileDetails drawerSettings={drawerSettings} toggleDrawer={handleDrawerOpen} />
            {/* <BasicSpeedDial refreshList={refreshList} setRefreshList={setRefreshList} /> */}
        </Grid>
    )
}


const FileManagerButton = ({ label, selectedCallback, fullWidth = false, buttonColor = "primary", buttonVariant = "light", handleModalClose, buttonText, reqFileType = "" }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        if (typeof handleModalClose === "function") {
            handleModalClose()
        }
        setOpen(false)
    }

    const handleSelection = (files) => {
        handleClose()
        let temp = []
        files.forEach(photo => {
            photo.type = extractDataFromArray(photo, ['file_type'], 'IMAGE')
            temp.push(photo)
        })
        selectedCallback(temp)
    }


    return (
        <>
            <Button fullWidth={fullWidth} variant={buttonVariant} color={buttonColor} onClick={handleClickOpen} sx={{ mb: 2 }}>
                {label}
            </Button>
            <Dialog fullScreen open={open} onClose={handleClose} sx={{ zIndex: 999998 }}>
                <DialogTitle id='customized-dialog-title' sx={{ py: 2, px: 3, justifyContent: "space-between", display: "flex" }}>
                    <Typography variant='h6' component='span' sx={{ fontWeight: 700 }}>
                        File Manager
                    </Typography>
                    <IconButton onClick={handleClose}><Icon.Close /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <FileManagerContent reqFileType={reqFileType} buttonText={buttonText} selectedCallback={handleSelection} />
                </DialogContent>
            </Dialog>
        </>
    )
}


export { FileManagerContent };
export default FileManagerButton;