//layout
import DashboardLayout from '../layouts/DashboardLayout';
import Bookings from '../pages/bookings';
import Wishlist from '../pages/wishlist';
import Profile from '../pages/profile';

//page components
import DashBoard from '../pages/dashboard';
import Listings from '../pages/listings';
// import Reviews from '../pages/reviews';
import Settings from '../pages/settings';
import Transactions from "../commonComponents/transactions"
import { ProtectedRoute } from './protectedRoutes';
import PropertyDetails from '../pages/propertyDetails';
import PackageDetails from '../pages/packageDetails';
import Chat from '../pages/chat';
import Checkout from '../pages/checkout';
import CreateReview from '../pages/createReview';
import BookingDetails from '../pages/bookingDetails';
import SubmitReview from '../pages/submitReview';
import { ROUTE_BOOKINGS, ROUTE_WISHLIST, ROUTE_PROFILE, ROUTE_CHAT, ROUTE_CHECKOUT, ROUTE_TRANSACTIONS, ROUTE_DASHBOARD, ROUTE_LISTING, ROUTE_PACKAGE_DETAILS, ROUTE_CREATEREVIEW, ROUTE_MANAGEBOOKINGS, ROUTE_PROPERTY_DETAILS, ROUTE_SUBMIT_REVIEW, ROUTE_SETTINGS, ROUTE_VERIFY_ID, ROUTE_PAYMENT, ROUTE_EXPERIENCE_REVIEW, ROUTE_REVIEW_SUCCEFULL } from '../constants/page';
import Payment from '../pages/payment';
import Transactionsdetails from '../commonComponents/transactiondetails';
import SubmitExpReview from '../pages/submitExpReview';
import ReviewSuccessFull from '../pages/reviewSuccessful';



const DashboardRoutes = {
    path: '/',
    element: <ProtectedRoute><DashboardLayout /></ProtectedRoute>,
    children: [
        {
            path: ROUTE_DASHBOARD,
            element: <DashBoard />
        },
        {
            path: ROUTE_LISTING,
            element: <Listings />
        },
        {
            path: ROUTE_BOOKINGS,
            element: <Bookings />
        },
        {
            path: ROUTE_PROFILE,
            element: <Profile />
        },
        {
            path: "/booking/:bookingId",
            element: <BookingDetails />
        },

        {
            path: ROUTE_WISHLIST,
            element: <Wishlist />
        },
        {
            path: ROUTE_TRANSACTIONS,
            children: [
                {
                    path: '',
                    element: <Transactions />
                }
            ]
        },
        {
            path: "/transactiondetails/:transaction_seq",
            children: [
                {
                    path: '',
                    element: <Transactionsdetails />
                }
            ]
        },
        {
            path: `${ROUTE_SUBMIT_REVIEW}`,
            element: <SubmitReview/>
        },
        {
            path: `${ROUTE_EXPERIENCE_REVIEW}`,
            element: <SubmitExpReview/>
        },
        {
            path: `${ROUTE_REVIEW_SUCCEFULL}`,
            element: <ReviewSuccessFull/>
        },
        {
            path: ROUTE_SETTINGS,
            element: <Settings />
        },
        {
            path: ROUTE_CREATEREVIEW,
            element: <CreateReview />
        },
        {
            path: ROUTE_PACKAGE_DETAILS,
            element: <PackageDetails />
        },
        {
            path: ROUTE_CHECKOUT,
            element: <Checkout />
        },
        {
            path: ROUTE_PAYMENT,
            element: <Payment />
        },
        {
            path: ROUTE_CHAT,
            element: <Chat />,
            children: [
                {
                  path: `${ROUTE_CHAT}/:connection/:seq`,
                  element: <Chat />,
                },
              ],
        },
    ]
};

export default DashboardRoutes;


