import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { extractDataFromArray } from "../../../utils/common";
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";

const ExpActivites = ({ formData, keyName, exptitle, isMobileButtonClicked = false }) => {
    const title = extractDataFromArray(formData, [exptitle], '');
    const activityList = extractDataFromArray(formData, [keyName], []);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING
        }}>
            <Typography variant="h5" sx={{ fontWeight: '600', fontSize: isMobileButtonClicked && '1.25rem' }}>{title}</Typography>
            <Grid container spacing={2}>
                {activityList.map((activity, index) => {
                    if (activity.label === '') {
                        return null
                    }
                    return (
                        <Grid item xs={6} key={index}>
                            <Box display="flex" alignItems="flex-start" sx={{ color: 'var(--black)' }}>
                                <span className="material-symbols-outlined iconColor" style={{ color: 'var(--black)' }}>
                                    {activity.icon}
                                </span>
                                <Typography variant="body1" ml={1}>{activity.label}</Typography>
                            </Box>
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    );
};

export default ExpActivites;
