import { useEffect, useState,useRef } from "react"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, TextField, CardContent, Button } from "@mui/material";
import { VerifiedUserOutlined } from "@mui/icons-material";
import { PersonOutline } from "@mui/icons-material";
import { Alert, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Snackbar } from "@mui/material"
import * as Icon from "@mui/icons-material";

import { empty, exponentialBackoff, extractDataFromArray } from "../../utils/common";
import UploadService from "../../services/upload";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import Typography from "@mui/material/Typography";

import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

import Avatar from "@mui/material/Avatar";

import TodayIcon from "@mui/icons-material/Today";

 import reviewService from "../../services/review";


 import { useLocation } from "react-router-dom";

 import DropZone from "../../commonComponents/FileManager/components/DropZone";




 

 



 const UploadedFile = ({ title, imgSrc, removeFile, status }) => {
  let statusIcon = <></>
  switch (status) {
      case "uploading":
          statusIcon = <CircularProgress size={30} color="success" />
          break
      case "uploaded":
          statusIcon = <Icon.CheckCircle fontSize="large" color="success" />
          break
      case "conflict":
          statusIcon = <></>
          break
      default:
          statusIcon = <IconButton onClick={removeFile}><Icon.Close /></IconButton>
          break
  }
  statusIcon = <Box className="uploaded_file_action">{statusIcon}</Box>

  return (
    <>
    

    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
  {/* Image */}
  <img
    src={imgSrc}
    alt="uploaded_image"
    style={{ height: "70px", width: "85px", borderRadius: "6px" }}
  />
  {/* Remove Icon */}
  <IconButton
    onClick={removeFile}
    sx={{
      position: 'absolute',
      top: -16,
      right: -9,
      background:'#fff',
      width: '24px', // Adjust the width as desired
      height: '24px', 
      // Set the background color of the button as needed
      borderRadius: '50%', // Optional: For a circular button
    }}
  >
    <Icon.Close />
  </IconButton>
</Box>







          {/* <Box className="uploaded_file_name">
              <Box>{title}</Box>
              {
                  status === 'conflict' ?
                      <Box sx={{ marginTop: 1 }}>
                          <Button variant="contained" color="primary" sx={{ mr: 1, borderRadius: theme => `${theme.card.borderRadiusMd} ` }} size="small">Replace</Button>
                          <Button variant="outlined" color="primary" size="small" sx={{ borderRadius: theme => `${theme.card.borderRadiusMd} ` }}>Keep Both Files</Button>
                          <Button variant="text" color="secondary" sx={{ ml: 1, borderRadius: theme => `${theme.card.borderRadiusMd} ` }} size="small">Skip</Button>
                      </Box> : <></>
              }

          </Box>
          {statusIcon} */}

    

    
    
      </>
  )
}
const UploadedFilepackage = ({ title, imgSrc, removeFile, status }) => {
  let statusIcon = <></>
  switch (status) {
      case "uploading":
          statusIcon = <CircularProgress size={30} color="success" />
          break
      case "uploaded":
          statusIcon = <Icon.CheckCircle fontSize="large" color="success" />
          break
      case "conflict":
          statusIcon = <></>
          break
      default:
          statusIcon = <IconButton onClick={removeFile}><Icon.Close /></IconButton>
          break
  }
  statusIcon = <Box className="uploaded_file_action">{statusIcon}</Box>

  return (
    <>
    

    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
  {/* Image */}
  <img
    src={imgSrc}
    alt="uploaded_image"
    style={{ height: "70px", width: "85px", borderRadius: "6px" }}
  />
  {/* Remove Icon */}
  <IconButton
    onClick={removeFile}
    sx={{
      position: 'absolute',
      top: -16,
      right: -9,
      background:'#fff',
      width: '24px', // Adjust the width as desired
      height: '24px', 
      // Set the background color of the button as needed
      borderRadius: '50%', // Optional: For a circular button
    }}
  >
    <Icon.Close />
  </IconButton>
</Box>







          {/* <Box className="uploaded_file_name">
              <Box>{title}</Box>
              {
                  status === 'conflict' ?
                      <Box sx={{ marginTop: 1 }}>
                          <Button variant="contained" color="primary" sx={{ mr: 1, borderRadius: theme => `${theme.card.borderRadiusMd} ` }} size="small">Replace</Button>
                          <Button variant="outlined" color="primary" size="small" sx={{ borderRadius: theme => `${theme.card.borderRadiusMd} ` }}>Keep Both Files</Button>
                          <Button variant="text" color="secondary" sx={{ ml: 1, borderRadius: theme => `${theme.card.borderRadiusMd} ` }} size="small">Skip</Button>
                      </Box> : <></>
              }

          </Box>
          {statusIcon} */}

    

    
    
      </>
  )
}
const UploadedFilehost = ({ title, imgSrc, removeFile, status }) => {
  let statusIcon = <></>
  switch (status) {
      case "uploading":
          statusIcon = <CircularProgress size={30} color="success" />
          break
      case "uploaded":
          statusIcon = <Icon.CheckCircle fontSize="large" color="success" />
          break
      case "conflict":
          statusIcon = <></>
          break
      default:
          statusIcon = <IconButton onClick={removeFile}><Icon.Close /></IconButton>
          break
  }
  statusIcon = <Box className="uploaded_file_action">{statusIcon}</Box>

  return (
    <>
    

    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
  {/* Image */}
  <img
    src={imgSrc}
    alt="uploaded_image"
    style={{ height: "70px", width: "85px", borderRadius: "6px" }}
  />
  {/* Remove Icon */}
  <IconButton
    onClick={removeFile}
    sx={{
      position: 'absolute',
      top: -16,
      right: -9,
      background:'#fff',
      width: '24px', // Adjust the width as desired
      height: '24px', 
      // Set the background color of the button as needed
      borderRadius: '50%', // Optional: For a circular button
    }}
  >
    <Icon.Close />
  </IconButton>
</Box>







          {/* <Box className="uploaded_file_name">
              <Box>{title}</Box>
              {
                  status === 'conflict' ?
                      <Box sx={{ marginTop: 1 }}>
                          <Button variant="contained" color="primary" sx={{ mr: 1, borderRadius: theme => `${theme.card.borderRadiusMd} ` }} size="small">Replace</Button>
                          <Button variant="outlined" color="primary" size="small" sx={{ borderRadius: theme => `${theme.card.borderRadiusMd} ` }}>Keep Both Files</Button>
                          <Button variant="text" color="secondary" sx={{ ml: 1, borderRadius: theme => `${theme.card.borderRadiusMd} ` }} size="small">Skip</Button>
                      </Box> : <></>
              }

          </Box>
          {statusIcon} */}

    

    
    
      </>
  )
}



const Rightcard = ({onChange,onClose,type, onFileChange, accept }) => {
  const fileInputRef = useRef(null);
 
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onFileChange(file);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const listingSeq = queryParams.get("listing_seq");
  const packageSeq = queryParams.get("package_seq");
 
  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
 
  };
  const handlepackage = (selectedRating) => {
  
    setPackagerating(selectedRating);
  };
  const handlehost = (selectedRating) => {
  
    setHostrating(selectedRating);
  };

  const renderpackageStars = (totalStars) => {
    const stars = [];
    for (let i = 1; i <= totalStars; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => handlepackage(i)}
          style={{
            fontSize:'21px',
            fontWeight:'700',
            cursor: 'pointer',
            color: i <= packagerating ? 'gold' : 'gray',
          }}
        >
          ★
        </span>
      );
    }
    return stars;
  };
  const renderhostStars = (totalStars) => {
    const stars = [];
    for (let i = 1; i <= totalStars; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => handlehost(i)}
          style={{
            fontSize:'21px',
            fontWeight:'700',
            cursor: 'pointer',
            color: i <= hostrating ? 'gold' : 'gray',
          }}
        >
          ★
        </span>
      );
    }
    return stars;
  };
  const renderStars = (totalStars) => {
    const stars = [];
    for (let i = 1; i <= totalStars; i++) {
      stars.push(
        <span
          key={i}
          onClick={() => handleStarClick(i)}
          style={{
            fontSize:'21px',
            fontWeight:'700',
            cursor: 'pointer',
            color: i <= rating ? 'gold' : 'gray',
          }}
        >
          ★
        </span>
      );
    }
    return stars;
  };
  const [rating, setRating] = useState(0);
  const [packagerating, setPackagerating] = useState(0);
  const [hostrating, setHostrating] = useState(0);
  // const [selectedImage, setSelectedImage] = useState(null);

  // const handleImageChange = (e) => {
  //   const file = e.target.files[0];
  //   setSelectedImage(file ? file.name : null);
  // };
  const [files, setFiles] = useState([]);
  const [packageimages, setPackageimages] = useState([]);
  const [hostimages, setHostimages] = useState([]);
  const [conflict, setConflict] = useState({ state: false });
  const [uploading, setUploading] = useState(false);

  const removeFile = (index) => {
    const currentFiles = [...files]; // Create a new copy of files array
    currentFiles.splice(index, 1);
    setFiles(currentFiles);
  };



  // Update the formData state whenever files change
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      listing_images: files,
      package_images: packageimages,
      host_images: hostimages,
    }));
  }, [files,packageimages]);
  const imageUrls1 = files.map(obj => obj.url?.url || ''); 

  const imageUrls = imageUrls1.map(url => url ? `https:${url}` : '');
  const imageUrls1package = packageimages.map(obj => obj.url?.url || ''); 

  const imageUrlspackage = imageUrls1package.map(url => url ? `https:${url}` : '');
  const imageUrls1host = hostimages.map(obj => obj.url?.url || ''); 

  const imageUrlshost = imageUrls1host.map(url => url ? `https:${url}` : '');
  const [formData, setFormData] = useState({
    listing_seq: listingSeq,
    listing_review: '',
    listing_rating: rating,
    listing_images: imageUrls,
    package_images: imageUrlspackage,
    package_seq: packageSeq,
    package_rating: packagerating,
    package_review: '',
    host_seq:'1',
    host_rating:hostrating,
host_review:'',
host_images:imageUrlshost,
    // Add more form fields here
  });


  // useEffect(() => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     listing_images: selectedImage,
  //   }));
  // }, [selectedImage]);

 // useEffect to update listing_images in formData when files change
  // useEffect to update listing_images in formData when files change


  const handleFileUpload = (uploadFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...uploadFiles]);

  };
  const handleFileUploadpackage = (uploadFiles) => {
   
    setPackageimages((prevFiles) => [...prevFiles, ...uploadFiles]);
  };
  const handleFileUploadhost = (uploadFiles) => {
   
    setHostimages((prevFiles) => [...prevFiles, ...uploadFiles]);
  };

  // useEffect to update listing_images in formData when files change
  useEffect(() => {
    // Create an array of URLs from the files
 
    setFormData((prevFormData) => ({
      ...prevFormData,
      listing_images: imageUrls,
      package_images: imageUrlspackage,
      host_images: imageUrlshost,
    }));
  }, [files,packageimages,hostimages]);
  
  // Rest of your code...


  // Rest of your code...

  // Rest of your code...


  // Rest of your code...








// Rest of your code...







  // Rest of your code...


  useEffect(() => {
    // Update the listing_rating value in the form data when the rating changes
    setFormData((prevFormData) => ({
      ...prevFormData,
      listing_rating: rating,
      package_rating:packagerating,
      host_rating:hostrating,
    }));
  }, [rating,packagerating,hostrating]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  
  const handleSubmit = async (e) => {
    e.preventDefault();
    let uploadedCount = 0;

    files.forEach((file, index) => {
      file.status = "uploading";
    
      UploadService.uploadPhotos(file, conflict.action, 0, (uploadedUrl) => {
        file.status = "uploaded";
        file.url = uploadedUrl;
        let currentFiles = [...files];
        currentFiles[index] = file;
        setFiles(currentFiles);
        uploadedCount++;
    
        if (uploadedCount === files.length) {
          // Log the URLs of the uploaded images
         
          currentFiles.forEach((f, index) => {
           
          });
    
          // Create your JSON data here
     
    
          // Now you can use jsonData for further processing or API call
         
        }
      });
    });
    packageimages.forEach((file, index) => {
      file.status = "uploading";
    
      UploadService.uploadPhotos(file, conflict.action, 0, (uploadedUrl) => {
        file.status = "uploaded";
        file.url = uploadedUrl;
        let currentFiles = [...packageimages];
        currentFiles[index] = file;
        setPackageimages(currentFiles);
        uploadedCount++;
    
        if (uploadedCount === packageimages.length) {
          // Log the URLs of the uploaded images
         
          currentFiles.forEach((f, index) => {
           
          });
    
          // Create your JSON data here
     
    
          // Now you can use jsonData for further processing or API call
         
        }
      });
    });
    hostimages.forEach((file, index) => {
      file.status = "uploading";
    
      UploadService.uploadPhotos(file, conflict.action, 0, (uploadedUrl) => {
        file.status = "uploaded";
        file.url = uploadedUrl;
        let currentFiles = [...hostimages];
        currentFiles[index] = file;
        setHostimages(currentFiles);
        uploadedCount++;
    
        if (uploadedCount === hostimages.length) {
          // Log the URLs of the uploaded images
         
          currentFiles.forEach((f, index) => {
           
          });
    
          // Create your JSON data here
     
    
          // Now you can use jsonData for further processing or API call
         
        }
      });
    });
    
    
 
    const { listing_seq, listing_rating, listing_review ,listing_images,package_seq,package_rating,package_review,package_images,host_seq,
      host_rating,
      host_review,
      host_images,} = formData;
  
    reviewService.saveReviews(listing_seq, listing_rating, listing_review, listing_images, package_seq,package_rating,package_review,package_images,host_seq,
      host_rating,
      host_review,
      host_images, (data) => {
      
      // Handle the API response here, if needed
    
    });
  };
  
  const [reviews, setReviews] = useState([])
  useEffect(() => {
      async function getReviews() {
          await reviewService.getReviews((data) => {
            setReviews(data);

          });
      }
      getReviews()
  }, [setReviews])


  // const [uploadedImages, setUploadedImages] = useState([]);

  // const handleImageChange = (e) => {
  //   const files = e.target.files;
  //   const uploadedImagesArray = [];

  //   for (let i = 0; i < files.length; i++) {
  //     const reader = new FileReader();

  //     reader.onloadend = () => {
  //       uploadedImagesArray.push(reader.result);
  //       if (uploadedImagesArray.length === files.length) {
  //         setUploadedImages(uploadedImagesArray);
  //       }
  //     };

  //     reader.readAsDataURL(files[i]);
  //   }

  //   // Pass the selected images to the onChange function
  //   if (onChange) {
  //     onChange(e);
  //   }
  // };

 



 
  return (
    <Box>
   
    
      <Box
        sx={{
          display: "flex",
          border: "1px solid #fff",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h3">
          <ArrowBackIcon /> Write Review
        </Typography>
      </Box>


      <form onSubmit={handleSubmit}>
      <Card
        style={{
          boxShadow: "none",
          border: " 1px solid #C5C5C7",
          width: "650px",
          borderRadius: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#1B1B1B",
            padding: "10px",

            fontSize: "21px",
            fontWeight: "800",
          }}
        >
          Property Review
        </Typography>
        <CardContent>
          <Box sx={{ display: "flex", gap: "10rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#85878A",
                marginRight: "1rem",
                marginBottom: "7px",
              }}
            >
              Rating
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBottom: "10px",
              }}
            >
      <Box sx={{display:'flex',gap:'10px' , alignItems:'center'}}> 
    
    <Box  style={{  fontWeight: "bold" }}>{renderStars(5)}</Box> 
    <Box>
      
      {rating}
      <input
      hidden
          type="text"
          name="listing_rating"
          value={formData.listing_rating}
          onChange={handleChange}
          placeholder="listing_rating"
        />
    
    </Box>
    {/* Change the argument to render the desired number of stars */}
  </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#85878A",
                marginRight: "1rem",
                marginBottom: "7px",
              }}
            >
              Review
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBottom: "10px",
              }}
            >
                 <input
                 hidden
          type="text"
          name="listing_seq"
          value={formData.listing_seq}
          onChange={handleChange}
          placeholder="Listing Sequence"
        />
              <TextField
               name="listing_review"
               value={formData.listing_review}
               onChange={handleChange}
                label="Review"
                style={{ borderRadius: "6px", width: "358px" }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#85878A",
                marginRight: "1rem",
                marginBottom: "7px",
              }}
            >
              Photos
            </Typography>
            <Box
              sx={{
                alignItems: "center",
                gap: "5px",
                marginBottom: "10px",
              }}
            >
           



 
<DropZone dropCallback={handleFileUpload} accept={"image/*"} />



<Box sx={{display:'flex',gap:'10px',    height: '70px',
    width: '85px',
    borderRadius: '6px'}}>
{
  
  files.map((file, index) => {
      return (
          <UploadedFile key={"uploaded_file_" + index} title={file.name} imgSrc={URL.createObjectURL(file)} removeFile={() => { removeFile(index) }} status={extractDataFromArray(file, ['status'], 'init')} />
      )
  })
}
</Box>
 
<Box>
</Box>
             
         
            </Box>
          </Box>
          <Divider />
        </CardContent>
        <Typography
          sx={{
            color: "#1B1B1B",
            padding: "10px",

            fontSize: "21px",
            fontWeight: "800",
          }}
        >
          Package Review
        </Typography>
        <CardContent>
          <Box sx={{ display: "flex", gap: "10rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#85878A",
                marginRight: "1rem",
                marginBottom: "7px",
              }}
            >
              Rating
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBottom: "10px",
              }}
            >
      <Box sx={{display:'flex',gap:'10px' , alignItems:'center'}}> 
    
    <Box  style={{  fontWeight: "bold" }}>{renderpackageStars(5)}</Box> 
    <Box>
      
      {packagerating}
      <input
      hidden
          type="text"
          name="package_rating"
          value={formData.package_rating}
          onChange={handleChange}
          placeholder="package rating"
        />
    
    </Box>
    {/* Change the argument to render the desired number of stars */}
  </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#85878A",
                marginRight: "1rem",
                marginBottom: "7px",
              }}
            >
              Review
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBottom: "10px",
              }}
            >
                 <input
                 hidden
          type="text"
          name="package_seq"
          value={formData.package_seq}
          onChange={handleChange}
          placeholder="Listing Sequence"
        />
              <TextField
               name="package_review"
               value={formData.package_review}
               onChange={handleChange}
                label="Review"
                style={{ borderRadius: "6px", width: "358px" }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#85878A",
                marginRight: "1rem",
                marginBottom: "7px",
              }}
            >
              Photos
            </Typography>
            <Box
              sx={{
                alignItems: "center",
                gap: "5px",
                marginBottom: "10px",
              }}
            >
          <DropZone dropCallback={handleFileUploadpackage} accept={"image/*"} />



<Box sx={{display:'flex',gap:'10px',    height: '70px',
    width: '85px',
    borderRadius: '6px'}}>
{
  
  packageimages.map((file, index) => {
      return (
          <UploadedFilepackage key={"uploaded_file_" + index} title={file.name} imgSrc={URL.createObjectURL(file)} removeFile={() => { removeFile(index) }} status={extractDataFromArray(file, ['status'], 'init')} />
      )
  })
}
</Box>
         
            </Box>
          </Box>
          <Divider />
          
        </CardContent>
        <Typography
          sx={{
            color: "#1B1B1B",
            padding: "10px",

            fontSize: "21px",
            fontWeight: "800",
          }}
        >
          Host Review
        </Typography>
        <CardContent>
          <Box sx={{ display: "flex", gap: "10rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#85878A",
                marginRight: "1rem",
                marginBottom: "7px",
              }}
            >
              Rating
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBottom: "10px",
              }}
            >
      <Box sx={{display:'flex',gap:'10px' , alignItems:'center'}}> 
    
    <Box  style={{  fontWeight: "bold" }}>{renderhostStars(5)}</Box> 
    <Box>
      
      {hostrating}
      <input
      hidden
          type="text"
          name="host_rating"
          value={formData.host_rating}
          onChange={handleChange}
          placeholder="host rating"
        />
    
    </Box>
    {/* Change the argument to render the desired number of stars */}
  </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#85878A",
                marginRight: "1rem",
                marginBottom: "7px",
              }}
            >
              Review
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                marginBottom: "10px",
              }}
            >
                 <input
                 hidden
          type="text"
          name="host_seq"
          value={formData.host_seq}
          onChange={handleChange}
          placeholder="Listing Sequence"
        />
              <TextField
               name="host_review"
               value={formData.host_review}
               onChange={handleChange}
                label="host"
                style={{ borderRadius: "6px", width: "358px" }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "10rem" }}>
            <Typography
              variant="body2"
              sx={{
                color: "#85878A",
                marginRight: "1rem",
                marginBottom: "7px",
              }}
            >
              Photos
            </Typography>
            <Box
              sx={{
                alignItems: "center",
                gap: "5px",
                marginBottom: "10px",
              }}
            >
                      <DropZone dropCallback={handleFileUploadhost} accept={"image/*"} />



<Box sx={{display:'flex',gap:'10px',    height: '70px',
    width: '85px',
    borderRadius: '6px'}}>
{
  
  hostimages.map((file, index) => {
      return (
          <UploadedFilehost key={"uploaded_file_" + index} title={file.name} imgSrc={URL.createObjectURL(file)} removeFile={() => { removeFile(index) }} status={extractDataFromArray(file, ['status'], 'init')} />
      )
  })
}
</Box>
              <Button
               type="submit"
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
      </form>
    </Box>
  );
};

const LeftCard = ({ menuItems, handleMenuItemClick }) => (
  <Box style={{ height: "100%", marginTop: "7px" }}>
    <Box
      sx={{
        display: "flex",
        border: "1px solid #fff",
        justifyContent: "end",
        alignItems: "center",
        marginBottom: "1rem",
      }}
    >
      <Typography
        sx={{
          color: "#26C77A",
          gap: "2px",
          fontSize: "15px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <VerifiedUserOutlined fontSize="small" style={{ color: "#00C853" }} />{" "}
        Submitted
      </Typography>
    </Box>

    <Card
      sx={{
        padding: "10px",
        height: "100%",
        width: "282px",
        borderRadius: "16px",
        border: "1px solid #E5E7EB",
        color: "#000",
        fontSize: "15px",
        boxShadow: "none",

        // Add margin-bottom
        // Add margin-right
      }}
    >
      <CardContent>
        <img
          src="/assets/images/property_detail_page/dummy_galler_image_1.png"
          alt="booking blank "
          style={{ height: "150px", width: "100%", borderRadius: "6px" }}
        />
        <Typography
          sx={{
            color: "#747579",
            marginBottom: "3px",

            fontSize: "16px",
          }}
        >
          {" "}
          Property name{" "}
        </Typography>

        <Typography
          sx={{
            color: "#1B1B1B",
            fontWeight: "bold",

            fontSize: "16px",
          }}
        >
          {" "}
          Mentor Deer Hunt{" "}
        </Typography>

        <Typography
          sx={{
            color: "#747579",
            marginBottom: "7px",

            fontSize: "15px",
          }}
        >
          {" "}
          200 Acres, Hunting at city, CA{" "}
        </Typography>

        <Box
          style={{ display: "flex", alignItems: "center", marginBottom: "7px" }}
        >
          <PersonOutline style={{ color: "#747579" }} />
          <Typography
            sx={{ color: "#1B1B1B", fontSize: "15px", marginLeft: "5px" }}
          >
            4 Guests
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <TodayIcon style={{ color: "#747579" }} />
          <Typography
            sx={{ color: "#1B1B1B", fontSize: "15px", marginLeft: "5px" }}
          >
            1 Day (6-7 Jun)
          </Typography>
        </Box>

        <Divider sx={{ marginBottom: "10px" }} />
        <Box display="flex" gap="5px">
          <Avatar></Avatar>
          <Box>
            <Typography sx={{ color: "#747579", fontSize: "12px" }}>
              Host
            </Typography>
            <Typography
              sx={{ color: "#1B1B1B", fontSize: "14px", fontWeight: "bold" }}
            >
              Henry
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  </Box>
);
const Createreview = () => {
  return (
    <>
      <div style={{ display: "flex", gap: "2rem", justifyContent: "center" }}>
        <Rightcard />
        <LeftCard />
      </div>
    </>
  );
};

export default Createreview;
