import { Box, Divider, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import { empty, extractDataFromArray, isValueEqual } from "../../utils/common";
import { styled } from '@mui/material/styles';
import CustomTooltip from "../CustomTooltip";


const ColumnBox = styled(Box)(
    ({ theme }) => `
        display: flex;
        gap: 0.5rem;
        flex: 50%;
        ${theme.breakpoints.down("sm")} {
            flex: 100%;
          }
        width: 100%;
        margin-bottom: 0.5rem;
  `,
);


const AddOns = ({ title, icon, value, tooltip, titleDelimiter = ":" }) => {

    return (
        <>
            <ColumnBox sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }} >
                <Icon.ArrowForward />
                <Typography sx={{}}> {title}{titleDelimiter} </Typography>
                <Typography sx={{color:'var(--grey900)'}}> {value}</Typography>
                {
                    !empty(tooltip) && <CustomTooltip content={tooltip} />
                }

            </ColumnBox>

        </>
    )
}
const FishingDetails = ({ packageData, activityData }) => {
    const isAmentitiesAvailable = isValueEqual(extractDataFromArray(activityData, ['canoes', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['fishing_pole', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['kayaks', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['tackle', 'available'], 'No'), 'Yes')

    const isSpeciesAvailable = !empty(extractDataFromArray(packageData, ['species'], []))

    if (!isAmentitiesAvailable && !isSpeciesAvailable) {
        return <></>
    }

    return (
        <>
            <Typography variant="h4">
                Fishing Details
            </Typography>
            <Divider sx={{ mb: 3, mt: 2 }} />
            {
                isAmentitiesAvailable &&

                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>Fishing Amenities</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            isValueEqual(extractDataFromArray(activityData, ['kayaks', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Kayaks"}
                                value={extractDataFromArray(activityData, ['kayaks', 'total'], [])}
                                tooltip={extractDataFromArray(activityData, ['kayaks', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['canoes', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Canoes"}
                                value={extractDataFromArray(activityData, ['canoes', 'total'], [])}
                                tooltip={extractDataFromArray(activityData, ['canoes', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['tackle', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Tackles"}
                                value={extractDataFromArray(activityData, ['tackle', 'total'], [])}
                                tooltip={extractDataFromArray(activityData, ['tackle', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['fishing_pole', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Fishing Poles"}
                                value={extractDataFromArray(activityData, ['fishing_pole', 'total'], [])}
                                tooltip={extractDataFromArray(activityData, ['fishing_pole', 'additional_info'], '')}
                            />
                        }
                    </Box>
                </Box>
            }
            {
                isSpeciesAvailable &&

                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>Included species</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            extractDataFromArray(packageData, ['species'], []).map((sp, i) => {
                                return <AddOns key={"species_" + i} title={sp} titleDelimiter={""} />
                            })
                        }
                    </Box>
                </Box>
            }
        </>
    )
}

export default FishingDetails;
