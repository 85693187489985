import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios } from "../utils/common";

const CommonService = {
    submitContactForm: async (name, email, phone, message, callback = () => { }) => {
        const formData = new FormData();
        formData.append('cmd', 'submitContactForm');
        formData.append('contactName', name);
        formData.append('contactEmail', email);
        formData.append('contactSubject', phone);
        formData.append('contactMessage', message);

        await axios.post(config.loginURL, formData)
            .then((response) => {
                response = parseResponseAxios(response);
                if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                    const data = extractDataFromArray(response, ['data'], []);
                    callback(data);
                }
            });
    }
}

export default CommonService