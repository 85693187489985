import React, { useEffect, useRef, useState } from "react";
import * as Icon from '@mui/icons-material';
import { empty, extractDataFromArray, getImageUrl, ucFirst, } from '../../utils/common'
import { Box, Button, ButtonGroup, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Popover, Tooltip, Typography } from '@mui/material';
import { Link, useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_CREATE_PROPERTY, ROUTE_PACKAGE, ROUTE_PROPERTY } from '../../constants/page';
import WishlistService from "../../services/wishlist";
import { UserAuth } from "../../store/context/userContext";
import { toast } from "react-toastify";
import config from "../../config";
import { generatePhrase } from "../../utils/common";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { CommonContext } from "../../store/context/commonContext";
import ToolTipWithIcon from '../TooltipWithIcon'
import { ACCESS, EXPERIENCE } from "../CommonConstants/commonKeys";

// below code is for if we don't want to show Days and Guests numbers 

const PackageKPI = (props) => {
    // console.log("package kpi", props)
    if (props.isProperty) {
        // If it's a property, return an empty fragment
        return null;
    }
    let lodgingAvailable = false
    let rvAvailable = false
    if (extractDataFromArray(props, ['lodging'], '') === "Yes" && props.lodging) {
        lodgingAvailable = true
    }
    if (extractDataFromArray(props, ['rv'], '') === "Yes" && props.lodging) {
        rvAvailable = true
    }
    // If it's not a property, return the Box with guest and days information
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
            <Chip icon={<Icon.PersonOutlineOutlined fontSize="small" color="var(--textGrey)" />} label={generatePhrase(props.guest, 'Guest')} sx={{ color: "var(--textGrey)" }} />
            {/* <Icon.PersonOutlineOutlined fontSize="small" sx={{ mr: 1 }} />
            {generatePhrase(props.guest, 'Guest')} */}
            <Chip icon={<Icon.CalendarTodayOutlined fontSize="small" color="var(--textGrey)" sx={{ p: "2px" }} />} label={generatePhrase(props.days, 'Day')} sx={{ color: "var(--textGrey)" }} />
            {lodgingAvailable ? <Chip icon={<Icon.NightShelterOutlined fontSize="small" color="var(--textGrey)" />} label='Lodging' sx={{ color: "var(--textGrey)" }} /> : ''}
            {rvAvailable ? <Chip icon={<Icon.AirportShuttleOutlined fontSize="small" color="var(--textGrey)" />} label='RV Access' sx={{ color: "var(--textGrey)" }} /> : ''}
            {/* <Icon.CalendarTodayOutlined fontSize="small" sx={{ ml: 2, mr: 1 }} />
            {generatePhrase(props.days, 'Day')} */}
        </Box>
    );
};



const PropertyViewMore = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [openPopup, setOpenPopup] = useState(false)

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {
                props.status !== 'D' ? <>

                    <IconButton onClick={handleClick} aria-describedby={id}>
                        <Icon.MoreVert />
                    </IconButton>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        className='viewMorePopupover'
                    >

                        <Link to={ROUTE_CREATE_PROPERTY + "?listing_seq=" + props.listing_seq} >
                            <ListItemButton >
                                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                                    <Icon.EditOutlined fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </ListItemButton>
                        </Link>
                        <Link
                            to={ROUTE_CREATE_PROPERTY + "?openStep=10&listing_seq=" + props.listing_seq}

                        >
                            <ListItemButton >
                                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                                    <Icon.EventOutlined fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Manage Availability" />
                            </ListItemButton>
                        </Link>
                        {/* <Link to={''}>
                            <ListItemButton >
                                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                                    <Icon.ContentCopy fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Make a Copy" />
                            </ListItemButton>
                        </Link> */}
                        <Link to={''}>
                            <ListItemButton onClick={() => { setOpenPopup(true) }}>
                                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                                    {
                                        props.status === 'A' ? <Icon.UnpublishedOutlined fontSize="small" /> : <Icon.PublishOutlined fontSize="small" />
                                    }
                                </ListItemIcon>
                                <ListItemText primary={props.status === "A" ? "Close" : "Activate"} />
                            </ListItemButton>
                        </Link>
                    </Popover>
                </>
                    : <></>
            }
            <Dialog open={openPopup}  >
                <Box >
                    <Box sx={{ p: 2.5 }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>Update Status</Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2.5 }}>
                        <Typography>Do you want to change the status of this listing?</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1, p: 2.5, width: '100%' }}>
                        <Button variant="text" color="secondary" onClick={() => { setOpenPopup(false) }}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={() => { props.changeListingStatus(); setOpenPopup(false); handleClose(); }}>Change</Button>

                    </Box>
                </Box>
            </Dialog>
        </>
    );
}
const ExpPackageViewMore = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const [openPopup, setOpenPopup] = useState(false)

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {
                props.status !== 'D' ? <>

                    <IconButton onClick={handleClick} aria-describedby={id} sx={{ height: '42px' }}>
                        <Icon.MoreVert />
                    </IconButton>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        className='viewMorePopupover'
                    >

                        <Link to={props.editLink} >
                            <ListItemButton >
                                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                                    <Icon.EditOutlined fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Edit" />
                            </ListItemButton>
                        </Link>
                        <Link
                            to={props.editLink + '&activecomponentid=' + props.availability_id}

                        >
                            <ListItemButton >
                                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                                    <Icon.EventOutlined fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Manage Availability" />
                            </ListItemButton>
                        </Link>
                        {/* <Link to={''}>
                            <ListItemButton >
                                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                                    <Icon.ContentCopy fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary="Make a Copy" />
                            </ListItemButton>
                        </Link> */}
                        <Link to={''}>
                            <ListItemButton onClick={() => { setOpenPopup(true) }}>
                                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                                    {
                                        props.status === 'A' ? <Icon.UnpublishedOutlined fontSize="small" /> : <Icon.PublishOutlined fontSize="small" />
                                    }
                                </ListItemIcon>
                                <ListItemText primary={props.status === "A" ? "Close" : "Activate"} />
                            </ListItemButton>
                        </Link>
                    </Popover>
                </>
                    : <></>
            }
            <Dialog open={openPopup}  >
                <Box >
                    <Box sx={{ p: 2.5 }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>Update Status</Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2.5 }}>
                        <Typography>Do you want to change the status of this listing?</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1, p: 2.5, width: '100%' }}>
                        <Button variant="text" color="secondary" onClick={() => { setOpenPopup(false) }}>Cancel</Button>
                        <Button variant="contained" color="primary" onClick={() => { props.changeListingStatus(); setOpenPopup(false); handleClose(); }}>Change</Button>

                    </Box>
                </Box>
            </Dialog>
        </>
    );
}

const PackageViewMore = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton onClick={handleClick} aria-describedby={id}>
                <Icon.MoreVert />
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className='viewMorePopupover'
            >

                <ListItemButton onClick={() => {
                    handleClose();
                    props.handlePackageEdit(props.package_seq)
                }}
                    sx={{ mt: '8px !important', }}>
                    <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                        <Icon.EditOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Edit" />
                </ListItemButton>
                <ListItemButton onClick={() => { handleClose(); props.duplicatePackage() }} >
                    <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                        <Icon.ContentCopy fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Make a Copy" />
                </ListItemButton>
                <ListItemButton onClick={() => { handleClose(); props.changeStatus() }}>
                    <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                        {
                            props.status === 'P' ? <Icon.UnpublishedOutlined fontSize="small" /> : <Icon.PublishOutlined fontSize="small" />
                        }
                    </ListItemIcon>
                    <ListItemText primary={props.status === 'P' ? "Move to Draft" : 'Publish'} />
                </ListItemButton>
                <ListItemButton sx={{ mb: '8px !important' }} onClick={() => { handleClose(); props.deletePackage() }}>
                    <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                        <Icon.DeleteOutline fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary="Delete" />
                </ListItemButton>

            </Popover>
        </div>
    );
}

const PackageCard = (props) => {
    const { wishlist = true, showMoreOptions = false, activity = 'H', showPropertyStatus = false, isWishlist = false, package_seq, nearest_town } = props
    const [shareAnchorEl, setShareAnchorEl] = useState(null);
    const [isClickable, setIsClickable] = useState(false)
    const location = useLocation();
    const handleShareClose = () => {
        setShareAnchorEl(null);
    };
    const openDeleteConfirmation = props.openDeleteConfirmation;
    const isProperty = extractDataFromArray(props, ['type'], '') === 'L';

    const [isSearchWishlist, setSearchWishlist] = useState(false)
    // const [showNavigationButtons, setShowNavigationButtons] = useState(false);
    const [isMouseOverCard, setIsMouseOverCard] = useState(false);
    const [hasUserInteracted, setHasUserInteracted] = useState(false);
    const handleClick = (e) => {

        if (props.onSelect) {
            props.onSelect();
        }

    };
    // handlewishlist for serach component
    //  console.log("addreeaa", props.address)
    const { isTablet, isMobile, screenWidth, isNineHundred } = CommonContext()
    const { user, authPopup, setAuthPopup } = UserAuth()
    const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
    const [showNavigation, setShowNavigation] = useState(false)

    let chipText = ''

    // console.log(checkin,checkout);

    if (location.pathname !== '/search' && props.cardType) {
        if (props.cardType === EXPERIENCE.value) {
            chipText = EXPERIENCE.string
        } else if (props.cardType === ACCESS.value) {
            chipText = ACCESS.string
        } else if (props.cardType === 'property') {
            chipText = 'Property'
        }
    }

    const iconPathRetrieveFunc = () => {
        if (extractDataFromArray(props, ['experienceType'], '') === 'Recreational Event') {
            return '../landowner/assets/images/event_exp_icon.svg'
        } else if (extractDataFromArray(props, ['experienceType'], '') === 'Outdoor Activity') {
            return '../landowner/assets/images/outdoor_activity_exp_icon.svg'
        } else if (extractDataFromArray(props, ['experienceType'], '') === 'Guided Tour') {
            return '../landowner/assets/images/tour_guide_exp_icon.svg'
        } else if (extractDataFromArray(props, ['experienceType'], '') === 'In-Person Training') {
            return '../landowner/assets/images/in-person.svg'
        }
    }
    const iconPath = iconPathRetrieveFunc()

    const divRef = useRef(null);
    const [height, setHeight] = useState(0);


    useEffect(() => {

        if (isNineHundred) {
            setShowNavigation(true)
        } else {
            setShowNavigation(false)
        }
    }, [isNineHundred])

    useEffect(() => {
        if (divRef.current) {
            const newHeight = divRef.current.clientHeight;
            setHeight(newHeight);
        }
        if (location.pathname === '/createProperty') {
            setIsClickable(false)
        } else {
            setIsClickable(true)
        }
    }, [screenWidth]);


    const handleWishlist = (event) => {
        event.stopPropagation();

        if (empty(user)) {
            setAuthPopup({
                ...authPopup,
                type: 'P',
                open: true,
                callback: () => {
                    if (isSearchWishlist) {
                        setOpenRemoveDialog(true);
                    } else {
                        toggleWishlist();
                    }
                }
            });
        } else {
            if (isSearchWishlist) {
                setOpenRemoveDialog(true);
            } else {
                toggleWishlist();
            }
        }
    };


    const handleRemoveConfirm = (event) => {
        // Logic to remove from wishlist in search page
        event.stopPropagation();
        setOpenRemoveDialog(false);
        toggleWishlist();
    };

    const handleRemoveCancel = (event) => {
        event.stopPropagation();
        setOpenRemoveDialog(false);
    };


    // Toggle Wishlist for Search component

    const toggleWishlist = () => {
        let wishlistType = ''

        if (props.type === 'listing' || props.type === 'access') {
            wishlistType = 'P'
        } else if (props.type === 'experience') {
            wishlistType = 'E'
        }

        const type = 'P'
        if (!isSearchWishlist) {

            setSearchWishlist(true)
            WishlistService.saveWishlist(wishlistType, package_seq, (data, errorMsg) => {
                if (!data) {
                    // toast.success("Package already exists in your Wishlist", {
                    //     theme: "colored",
                    // })
                } else {
                    toast.success(errorMsg, {
                        theme: "colored",
                    })
                    setSearchWishlist(true)
                }
            })
        } else {
            setSearchWishlist(false)
            WishlistService.removeFromWishlist(wishlistType, package_seq, (data, errorMsg) => {
                if (!data) {
                    // toast.warning("Something Went Wrong.", {
                    //     theme: "colored",
                    // })
                } else {
                    toast.success(errorMsg, {
                        theme: "colored",
                    })
                    setSearchWishlist(false)
                }
            })
        }
    }

    // useEffect for setting isWishlist in Search Component

    useEffect(() => {
        if (config.module === 'guest') {
            if (empty(user)) {
                setSearchWishlist(false);
            } else {
                let wishlistType = ''

                if (props.type === 'listing' || props.type === 'access') {
                    wishlistType = 'P'
                } else if (props.type === 'experience') {
                    wishlistType = 'E'
                }

                WishlistService.isWishlist(package_seq, wishlistType, (data) => {
                    setSearchWishlist(data);
                });
            }
        }
    }, [user, package_seq]);





    const handleShare = (socialMedia) => {
        // Get the current URL of your page
        const baseUrl = window.location.origin;

        const type = props.type

        let slug = '';
        const type_seq = props.type_seq;

        let listingType = '';

        if (type === 'P') {
            listingType = 'package'
            slug = props.package_slug
        }
        else if (type === 'L') {
            listingType = 'property'
            slug = props.slug
        }
        else if (type === 'E') {
            listingType = 'experience'
            slug = props.slug
        }
        // Replace with your actual order sequence

        // Construct the complete URL by combining the base URL, slug, and order_seq
        const currentUrl = `${baseUrl}/${listingType}/${slug}-${type_seq}`;

        // Construct the share URL based on the selected social media
        let shareUrl = "";
        if (socialMedia === "facebook") {
            shareUrl = `https://www.facebook.com/sharer/sharer.php?quote=Hey! I found this amazing place on FarWide. Check it out.&u=${encodeURIComponent(currentUrl)}`;
        } else if (socialMedia === "twitter") {
            shareUrl = `https://twitter.com/intent/tweet?text=Hey! I found this amazing place on FarWide. Check it out.&url=${encodeURIComponent(currentUrl)}`;
        }

        // Open a new window/tab with the share URL
        window.open(shareUrl, "_blank", "width=600,height=400");
    };

    const handleShareEmail = () => {
        // Get the current URL of your page
        const baseUrl = window.location.origin;

        const type = props.type

        let slug = '';
        const type_seq = props.type_seq;

        let listingType = '';

        if (type === 'P') {
            listingType = 'package'
            slug = props.package_slug
        }
        else if (type === 'L') {
            listingType = 'property'
            slug = props.slug
        }
        else if (type === 'E') {
            listingType = 'experience'
            slug = props.slug
        }

        // const listingType = type === "L" ? "property" : "package";
        // Replace with your actual order sequence

        // Construct the complete URL by combining the base URL, slug, and order_seq
        const currentUrl = `${baseUrl}/${listingType}/${slug}-${type_seq}`;

        // Construct the email subject and body
        const emailSubject = "Hey, I took an outdoor trip to this amazing property. ";
        const emailBody = `I think you will love it too.:\n\n${currentUrl}`;

        // Construct the "mailto" link
        const mailtoLink = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

        // Open the default email client with the mailto link
        window.location.href = mailtoLink;
    };

    const handleCopyLink = () => {
        // Get the current base URL from the browser's address bar
        const baseUrl = window.location.origin;
        const type = props.type;
        // console.log(type);
        let slug = '';
        const type_seq = props.type_seq;

        let listingType = '';

        if (type === 'P') {
            listingType = 'package'
            slug = props.package_slug
        }
        else if (type === 'L') {
            listingType = 'property'
            slug = props.slug
        }
        else if (type === 'E') {
            listingType = 'experience'
            slug = props.slug
        }
        // Replace with your actual order sequence

        // Construct the complete URL by combining the base URL, slug, and order_seq
        const customUrl = `${baseUrl}/${listingType}/${slug}-${type_seq}`;

        // Copy the custom URL to the clipboard
        navigator.clipboard.writeText(customUrl)

        toast.success("Link Copied!", {
            theme: "colored",
        })

    };

    const handleShareClick = (event) => {

        setShareAnchorEl(event.currentTarget);
    };

    const navigate = useNavigate()
    const defaultProp = {
        image: "/landowner/assets/images/property_detail_page/blank_property_image.png",
        title: "Property Title",
        size: "size",
        type: "type",
        city: "city",
        state: "state",
        guest: 0,
        days: 0,
        price: 0
    }


    let image = empty(props.image) ? defaultProp.image : props.image
    let starting_price = extractDataFromArray(props, ['starting_price'], 0)
    starting_price = empty(starting_price) ? extractDataFromArray(props, ['price'], 0) : starting_price
    starting_price = empty(starting_price) ? extractDataFromArray(props, ['price'], 0) : starting_price

    // console.log("props", image)

    const [showTooltip, setShowTooltip] = useState(false);
    let timeoutId;

    const handleMouseEnter = () => {
        setTimeout(() => {
            setShowTooltip(true);
        }, 300);
    };

    const handleMouseMove = () => {
        setShowTooltip(false);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };


    return (
        // <Tooltip
        //     title={extractDataFromArray(props, ['title'], defaultProp.title)}
        //     followCursor
        //     // onMouseEnter={handleMouseEnter}
        //     // onMouseMoveCapture={handleMouseMove}
        //     // onMouseLeave={handleMouseLeave}
        //     // open={showTooltip}
        //     placement="bottom-start"
        // >
        <Box
            id="packageCardall"
            sx={{
                p: { xs: 2, sm: 2, md: 3, lg: 3 },
                borderRadius: theme => `${theme.card.borderRadius} `,
                border: '1px solid ',
                width: '100%',
                display: 'flex',
                gap: { xs: 2, sm: 2, md: 3, lg: 3 },
                borderColor: props.isSelected ? "var(--primary)" : "var(--grey500)",
                ...(isTablet ? {} : {
                    "&:hover": {
                        borderColor: props.isSelected ? "" : "var(--black)",
                    }
                }),
                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                flexDirection: {
                    xs: 'column',
                    sm: 'column',
                    md: 'row'
                },
                flexWrap: 'nowrap',
                cursor: (props.search || props.enquiry) ? 'pointer' : '',
                position: 'relative',
            }}
            onClick={(event) => {

                // Check if the clicked element is a Swiper navigation button
                const isSwiperButton =
                    event.target.classList.contains("swiper-button-prev") ||
                    event.target.classList.contains("swiper-button-next");

                // If it's a Swiper button, do nothing (return early)
                if (isSwiperButton) {

                    return;
                }

                const isPropertyNameClicked = event.target.classList.contains('propertyName');

                if (isPropertyNameClicked) {
                    //   console.log("clicked prop name");
                    if (props.isPropertyPage === true && props.cardLocation === 'enquiryPopUp') {
                        // console.log("close dailog")
                        props?.handleClose?.()
                    } else if (props.isPropertyPage === true && props.cardLocation === undefined) {
                        // console.log("Scrolled to top, if on property page already")
                    } else {
                        //   console.log("open property page")
                        const locationToOpen = isMobile ? "_self" : "_blank";
                        config.module === "guest" ?
                            window.open('/property/' + props.listingSlug + '-' + props.listing_seq, locationToOpen) : window.open('/landowner/property/' + props.listingSlug + '-' + props.listing_seq, locationToOpen);
                    }
                    return;
                }

                if (props.search) {
                    // console.log("clicked card");
                    const locationToOpen = isMobile ? "_self" : "_blank";
                    if (props.cardType === 'package') {
                        window.open("/package/" + props.slug + "-" + props.package_seq, locationToOpen);
                    } else if (props.cardType === 'experience') {
                        window.open("/experience/" + props.slug + "-" + props.listing_seq, locationToOpen); // Added it to direct user to experience page
                    }
                }
                handleClick();

                // if (props.search) {
                //     const locationToOpen = isMobile ? "_self" : "_blank"
                //     window.open("/package/" + props.slug + "-" + props.package_seq, locationToOpen)
                // }
                // handleClick()
            }}
        >
            {/* if page is wishlist then image and title should be clickable and should open in new tab */}
            {isWishlist === true ?
                <Link to={props.link}
                    target={isMobile ? "" : "_blank"}
                    onClick={(event) => {
                        const isSwiperButton =
                            event.target.classList.contains("swiper-button-prev") ||
                            event.target.classList.contains("swiper-button-next");

                        // If it's a Swiper button, do nothing (return early)
                        if (isSwiperButton) {

                            return;
                        }
                    }}

                >
                    <Box
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '100%',
                                md: '230px',
                                lg: '230px',
                            },
                            // height: "175px",
                            minHeight: '175px',
                            // zIndex: 9999
                            height: height,
                        }}
                        {...(!isNineHundred && {
                            onMouseEnter: () => {
                                setShowNavigation(true);
                            },
                            onMouseLeave: () => {
                                setShowNavigation(false);
                            },
                        })}
                    >
                        {empty(props.image) ? <Box
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '100%',
                                    md: '230px',
                                    lg: '100%',
                                },
                                // height: '175px',
                                minHeight: '175px',
                                height: height,
                                borderRadius: '8px',
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                                backgroundSize: "cover",
                                backgroundImage: "url('" + image + "')",
                            }}
                        />
                            :

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                navigation={showNavigation}
                                modules={[Pagination, Navigation]}
                                initialSlide={0}
                                // style={{ width: "100%", height: "175px", borderRadius: '8px', zIndex: "unset !important" }}
                                style={{ width: "100%", borderRadius: '8px', zIndex: "unset !important", minHeight: '175px', }}
                                className="mySwiper customSwiper"

                            >
                                {Array.isArray(image) && image.map((image, index) => {
                                    // Check if the image type is 'IMAGE'
                                    // console.log(image, index)
                                    return (
                                        <>
                                            {
                                                image.type !== 'Unknown' &&
                                                <SwiperSlide key={index}>
                                                    {image.type === 'IMAGE' ? <Box
                                                        sx={{
                                                            width: {
                                                                xs: '100%',
                                                                sm: '100%',
                                                                md: '230px',
                                                                lg: '100%',
                                                            },
                                                            // height: '175px',
                                                            minHeight: '175px',
                                                            height: height,
                                                            borderRadius: '8px',
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundPosition: "center center",
                                                            backgroundSize: "cover",
                                                            backgroundImage: "url('" + image.url + "')",
                                                        }}
                                                    />
                                                        :
                                                        <>
                                                            {
                                                                !image.thumbnail_url ?
                                                                    <div className='package-card-video' style={{ position: 'relative', display: 'flex', height: height, }}>
                                                                        <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: height, minHeight: '175px', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
                                                                            <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                                                                        </div>
                                                                        <CardMedia
                                                                            component={'video'}
                                                                            className="package-card-video"
                                                                            image={extractDataFromArray(image, ['url'], '')}
                                                                            sx={{
                                                                                width: '100%',
                                                                                height: height,
                                                                                minHeight: '175px',
                                                                                backgroundRepeat: "no-repeat",
                                                                                backgroundPosition: "center center",
                                                                                backgroundSize: "cover",
                                                                                objectFit: 'cover',
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div className='package-card-video' style={{ position: 'relative', display: 'flex', height: height, minHeight: '175px', }}>
                                                                        <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: height, minHeight: '175px', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
                                                                            <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                                                                        </div>
                                                                        <Box
                                                                            sx={{
                                                                                width: {
                                                                                    xs: '100%',
                                                                                    sm: '100%',
                                                                                    md: '230px',
                                                                                    lg: '100%',
                                                                                },
                                                                                // height: '175px',
                                                                                minHeight: '175px',
                                                                                height: height,
                                                                                borderRadius: '8px',
                                                                                backgroundRepeat: "no-repeat",
                                                                                backgroundPosition: "center center",
                                                                                backgroundSize: "cover",
                                                                                backgroundImage: "url('" + image.thumbnail_url + "')",
                                                                            }}
                                                                        />
                                                                    </div>
                                                            }
                                                        </>
                                                    }
                                                </SwiperSlide>
                                            }
                                        </>
                                    )
                                }
                                )}

                            </Swiper>
                        }
                    </Box>

                </Link>
                :
                <Box
                    sx={{
                        width: {
                            xs: '100%',
                            sm: '100%',
                            md: '230px',
                            lg: '230px',
                        },
                        // height: "175px"
                        minHeight: '175px',
                        height: height,
                    }}

                    {...(!isNineHundred && {
                        onMouseEnter: () => {
                            setShowNavigation(true);
                        },
                        onMouseLeave: () => {
                            setShowNavigation(false);

                        },
                    })}
                >
                    {empty(props.image) ? <Box
                        sx={{
                            width: {
                                xs: '100%',
                                sm: '100%',
                                md: '230px',
                                lg: '100%',
                            },
                            // height: '175px',
                            minHeight: '175px',
                            // height: '100%',
                            height: height,
                            borderRadius: '8px',
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                            backgroundSize: "cover",
                            backgroundImage: "url('" + image + "')",
                        }}
                    />
                        :
                        <Swiper
                            pagination={{
                                dynamicBullets: true,
                            }}
                            navigation={showNavigation && image.length > 1}
                            modules={[Pagination, Navigation]}
                            initialSlide={0}
                            // style={{ width: "100%", height: "175px", borderRadius: '8px' }}
                            style={{ width: "100%", height: height, minHeight: '175px', borderRadius: '8px' }}
                            className="mySwiper customSwiper"
                        >
                            {Array.isArray(image) && image.map((image, index) => {
                                // Check if the image type is 'IMAGE'
                                // console.log(image, index)
                                return (
                                    <>
                                        {
                                            image.type !== 'Unknown' &&
                                            <SwiperSlide key={index}>
                                                {image.type === 'IMAGE' ? <Box
                                                    sx={{
                                                        width: {
                                                            xs: '100%',
                                                            sm: '100%',
                                                            md: '230px',
                                                            lg: '100%',
                                                        },
                                                        // height: '175px',
                                                        minHeight: '175px',
                                                        // height: 'inherit',
                                                        height: height,
                                                        borderRadius: '8px',
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: "center center",
                                                        backgroundSize: "cover",
                                                        backgroundImage: "url('" + image.url + "')",
                                                    }}
                                                />
                                                    :
                                                    <>
                                                        {
                                                            !image.thumbnail_url ?
                                                                <div className='package-card-video' style={{ position: 'relative', display: 'flex', height: height, minHeight: '175px', }}>
                                                                    <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: height, minHeight: '175px', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
                                                                        <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                                                                    </div>
                                                                    <CardMedia
                                                                        component={'video'}
                                                                        className="package-card-video"
                                                                        image={extractDataFromArray(image, ['url'], '')}
                                                                        sx={{
                                                                            width: '100%',
                                                                            // height: '100%',
                                                                            minHeight: '175px',
                                                                            height: height,
                                                                            backgroundRepeat: "no-repeat",
                                                                            backgroundPosition: "center center",
                                                                            backgroundSize: "cover",
                                                                            objectFit: 'cover',
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                <div className='package-card-video' style={{ position: 'relative', display: 'flex', height: height, minHeight: '175px', }}>
                                                                    <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: height, minHeight: '175px', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
                                                                        <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                                                                    </div>
                                                                    <Box
                                                                        sx={{
                                                                            width: {
                                                                                xs: '100%',
                                                                                sm: '100%',
                                                                                md: '230px',
                                                                                lg: '100%',
                                                                            },
                                                                            // height: '175px',
                                                                            minHeight: '175px',
                                                                            height: height,
                                                                            height: 'inherit',
                                                                            borderRadius: '8px',
                                                                            backgroundRepeat: "no-repeat",
                                                                            backgroundPosition: "center center",
                                                                            backgroundSize: "cover",
                                                                            backgroundImage: "url('" + image.thumbnail_url + "')",
                                                                        }}
                                                                    />
                                                                </div>
                                                        }
                                                    </>
                                                }
                                            </SwiperSlide>
                                        }
                                    </>
                                )
                            }
                            )}
                            {props.search ?
                                <Button sx={{ display: "block", ml: 'auto', p: 0, justifyContent: 'flex-end', minWidth: 'auto', zIndex: 1000, position: "absolute", top: "8px", right: "8px" }}>
                                    {config.module === 'guest' &&
                                        <IconButton onClick={handleWishlist} sx={{
                                            background: "#F5F5F6",
                                            borderRadius: 1,
                                            "&:hover": {
                                                backgroundColor: '#F5F5F6'
                                            },
                                            height: '34px',
                                            width: '34px'
                                        }}>
                                            {isSearchWishlist ? <Icon.Favorite sx={{ color: "#e94a4a" }} /> : <Icon.FavoriteBorderOutlined />}
                                        </IconButton>
                                    }
                                    {/* Add Dialog for confirmation */}
                                    <Dialog open={openRemoveDialog} onClose={handleRemoveCancel} >
                                        <Box >
                                            <Box sx={{ p: 2.5 }}>
                                                <Typography variant="h6" sx={{ fontWeight: 700 }}>Confirm the action</Typography>
                                            </Box>
                                            <Divider />
                                            <Box sx={{ p: 2.5 }}>
                                                <Typography>Are you sure you want to remove this package from your wishlist?</Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1, p: 2.5, width: '100%' }}>
                                                <Button variant="contained" color="error" onClick={handleRemoveConfirm}>REMOVE</Button>
                                                <Button variant="text" color="secondary" onClick={(event) => handleRemoveCancel(event)}>CANCEL</Button>
                                            </Box>
                                        </Box>
                                    </Dialog>
                                </Button>
                                : null
                            }

                        </Swiper>
                    }

                </Box>

            }

            {chipText !== '' && <Chip sx={{
                position: 'absolute',
                backgroundColor: 'white',
                border: '1px solid #949494',
                ml: 1,
                mt: 1,
                zIndex: 1
            }} label={chipText} />}

            <Box
                ref={divRef}
                className='checkkkksssss'
                sx={{
                    width: {
                        xs: '100%',
                        sm: '100%',
                        md: "calc(100% - 155px)",
                        lg: "calc(100% - 255px)",
                    },
                    display: 'flex',
                    flexDirection: 'column',
                    // justifyContent: 'space-between',
                    minWidth: "200px"
                }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                    <Box sx={{
                        width: {
                            // before changing to 100% it was set like this for false condition 'calc(100% - 100px)'
                            // xs: props.search ? '100%' : 'calc(100% - 100px)',
                            xs: '100%',
                            sm: props.search ? 'calc(100% - 40px)' : '100%',
                            md: props.search ? 'calc(100% - 40px)' : '100%',
                            lg: props.search ? '100%' : '100%'
                        },
                    }}>

                        {/* if page is wishlist then image and title should be clickable and should open in new tab */}

                        {
                            isWishlist === true ?
                                <Link target={isMobile ? "" : "_blank"} to={
                                    props.link
                                }>
                                    <Typography className="twoLinerEllipsis" variant='h6' sx={{ mb: 1, fontWeight: 700, display: '-webkit-box', maxWidth: '100%', overflow: "hidden", }}>
                                        {extractDataFromArray(props, ['title'], defaultProp.title)}
                                    </Typography>
                                </Link>
                                :
                                <Typography className="twoLinerEllipsis" variant='h6' sx={{ mb: 1, fontWeight: 700, display: 'block', maxWidth: '100%', display: '-webkit-box', maxWidth: '100%', overflow: "hidden", }}>
                                    {extractDataFromArray(props, ['title'], defaultProp.title)}
                                </Typography>
                        }
                        {/* </Tooltip> */}


                        {
                            // <Typography component={'span'} variant='body2' sx={{ display: 'block', mb: 1 }}>
                            //     {props.address}
                            // </Typography>
                            <Box sx={{ mb: 1 }}>
                                <Typography component={'span'} variant='body2' >
                                    {props.address}
                                </Typography>
                                {
                                    isClickable ?
                                        <Link className="propertyName" style={{ color: '#000000', textDecoration: 'underline', fontSize: '14px' }} component={'span'}>
                                            {props.propertyName}
                                        </Link> :
                                        <Typography component={'span'} sx={{ color: '#000000', textDecoration: 'underline' }}>{props.propertyName}</Typography>
                                }
                                <Typography component={'span'} variant='body2' >
                                    {props.address2}
                                </Typography>
                            </Box>
                        }


                    </Box>
                    {
                        showMoreOptions === true && (
                            extractDataFromArray(props, ['cardType'], '') === "property" &&
                            <Box>
                                <PropertyViewMore listing_seq={props.listing_seq} status={extractDataFromArray(props, ['status'], '')} changeListingStatus={props.changeListingStatus} />
                            </Box>
                        )
                    }
                    {
                        props.isPackageEditor &&
                        <PackageViewMore
                            package_seq={props.package_seq}
                            handlePackageEdit={props.handlePackageEdit}
                            deletePackage={props.deletePackage}
                            duplicatePackage={props.duplicatePackage}
                            status={extractDataFromArray(props, ['status'], 'P')}
                            changeStatus={props.changeStatus}
                        />
                    }
                    {
                        showMoreOptions &&
                        extractDataFromArray(props, ['type'], '') === "experience" &&
                        <ExpPackageViewMore
                            editLink={props.completeLink}
                            status={extractDataFromArray(props, ['status'], '')}
                            changeListingStatus={props.changeListingStatus}
                            listing_seq={props.listing_seq}
                            availability_id={props.availability_id}
                        />
                    }
                    {wishlist === false && (
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <Box>
                                <IconButton onClick={() => openDeleteConfirmation(props)} sx={{ background: "#F5F5F6", borderRadius: 1 }}>
                                    <Icon.Favorite sx={{ color: "red" }} />
                                </IconButton>
                            </Box>
                            <Box>
                                <IconButton sx={{ background: "#F5F5F6", borderRadius: 1 }} onClick={handleShareClick}>
                                    <Icon.Share />
                                </IconButton>
                            </Box>
                            {/* Share Menu */}
                            <Popover
                                open={Boolean(shareAnchorEl)}
                                anchorEl={shareAnchorEl}
                                onClose={handleShareClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                sx={{ marginLeft: "1rem" }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <Paper
                                    elevation={3}
                                    sx={{
                                        pt: 1,
                                        pb: 1,
                                        boxShadow: "0 0 10px 0 rgba(0,0,0,0.15)",
                                    }}
                                >
                                    <MenuItem
                                        onClick={handleShareClose}
                                        sx={{ fontSize: "16px", fontWeight: 500 }}
                                    >
                                        Share
                                    </MenuItem>
                                    <MenuItem onClick={() => handleShare("facebook")}>
                                        <Icon.FacebookRounded />
                                        &nbsp; Facebook
                                    </MenuItem>
                                    <MenuItem onClick={() => handleShare("twitter")}>
                                        <Icon.Twitter />
                                        &nbsp; Twitter
                                    </MenuItem>
                                    <MenuItem onClick={handleShareEmail}>
                                        <Icon.Email />
                                        &nbsp; Email
                                    </MenuItem>
                                    <MenuItem onClick={handleCopyLink}>
                                        <Icon.ContentCopyOutlined />
                                        &nbsp; Copy Link
                                    </MenuItem>
                                </Paper>

                            </Popover>
                        </Box>
                    )}



                </Box>
                {
                    extractDataFromArray(props, ['cardType'], '') !== "property" ?
                        <>
                            {
                                extractDataFromArray(props, ['cardType'], '') === 'experience' ?
                                    <>
                                        {

                                            <Box sx={{
                                                "& .MuiChip-root": {
                                                    width: 'fit-content',
                                                    height: '28px !important'
                                                },
                                                display: 'flex',
                                                gap: 1,
                                                flexWrap: 'wrap'
                                            }}>
                                                {props.durationObj && <Chip
                                                    icon={<Icon.AccessTimeOutlined />}
                                                    // label={`${extractDataFromArray(props, ['durationObj', 'value'], '')} ${extractDataFromArray(props, ['durationObj', 'unit'], '')}`}
                                                    label={`${generatePhrase(
                                                        parseInt(extractDataFromArray(props, ['durationObj', 'value'], 0)),
                                                        (extractDataFromArray(props, ['durationObj', 'unit'], 'hours') === 'hours' ? 'Hour' : 'Day')
                                                    )}`}
                                                />}
                                                {<Chip
                                                    icon={<img src={iconPath} style={{ height: '16px', width: '16px' }} />}
                                                    label={`${extractDataFromArray(props, ['experienceType'], '')}`}
                                                />}

                                            </Box>
                                        }
                                    </>
                                    :
                                    <PackageKPI guest={extractDataFromArray(props, ['guest'], defaultProp.guest)} days={extractDataFromArray(props, ['days'], defaultProp.days)} lodging={extractDataFromArray(props, ['lodging'], '')} rv={extractDataFromArray(props, ['rv'], '')} isProperty={isProperty} />
                            }
                        </>
                        :
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {extractDataFromArray(props, ['totalPackages'], 0)} Packages
                        </Box>
                }
                <Box>
                    {
                        !empty(props.reviewCount) &&
                        <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                <Icon.Star sx={{ color: "#F7C32E" }} />
                                <Typography variant="body1" fontWeight={'bold'}>
                                    {parseFloat(props.averageRating).toFixed(1)}
                                </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                <Typography variant="body1" color='var(--textGrey)'>
                                    {generatePhrase(props.reviewCount, 'review')}
                                </Typography>
                            </Box>
                        </Box>
                    }
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, gap: 1, flexDirection: screenWidth <= 768 ? 'column' : 'row' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {
                            empty(starting_price) ? <></> : <>
                                <Typography variant='h6' component={'span'} sx={{ fontWeight: 'bold' }}>
                                    ${starting_price}            </Typography>
                                <Typography component={'span'} variant='body2' sx={{ ml: 1 }}>/ Starting price</Typography>
                            </>
                        }
                    </Box>
                    {/* {wishlist === true && (
                    // discarded logic
                        extractDataFromArray(props, ['cardType'], '') !== "property" ? (!extractDataFromArray(props, ['hideOptions'], false) ?
                            <Link to={ROUTE_PACKAGE + "/" + props.slug + '-' + props.package_seq} target={props.viewTarget}>
                                <Button variant="contained">View Details</Button>
                            </Link> : <></>)
                            :
                            (extractDataFromArray(props, ['status'], '') === 'D' ? <></> :
                                !showPropertyStatus &&
                                <Link to={ROUTE_PROPERTY + "/" + props.slug + "-" + props.listing_seq}>
                                    <Button variant="contained"><Icon.RemoveRedEyeOutlined sx={{ mr: 1 }} fontSize='small' />View Listing</Button>
                                </Link>)
                    )} */}
                    {
                        props.showViewDetailButton &&
                        <Link to={ROUTE_PACKAGE + "/" + props.slug + '-' + props.package_seq} target={props.viewTarget}>
                            <Button variant="contained">View Details</Button>
                        </Link>
                    }

                </Box>
                {
                    showPropertyStatus &&
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                        <Box sx={{ display: 'flex' }}>
                            {
                                props.status === 'D' && props.verificationStatus !== 'D' && props.type === 'listing' &&
                                <Link to={ROUTE_CREATE_PROPERTY + "?listing_seq=" + props.listing_seq}>
                                    <Chip variant='outlined' color='red' label="Draft" sx={{ cursor: 'pointer' }} />
                                </Link>
                            }
                            {
                                props.status === 'D' && props.verificationStatus !== 'D' && props.type === 'experience' &&
                                <Link to={props.completeLink}>
                                    <Chip variant='outlined' color='red' label="Draft" sx={{ cursor: 'pointer' }} />
                                </Link>
                            }
                            {
                                props.status === 'D' && props.verificationStatus === 'D' &&
                                <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                                    <Link to={ROUTE_CREATE_PROPERTY + "?listing_seq=" + props.listing_seq}>
                                        <Chip variant='filled' color="error" label="Approval Declined" />
                                    </Link>
                                    {/* <Link to="https://help.farwide.com/hc/en-us/articles/25965938887437-I-created-a-listing-on-FarWide-and-submitted-for-approval-My-listing-approval-has-been-declined-Why-does-it-happen-and-what-can-I-do-to-fix-it" target='_blank' style={{ color: "var(--black) !important" }}><Typography variant="body2" sx={{ textDecoration: 'underline' }}>Know More</Typography></Link> */}
                                    {/* <IconButton color="error"> */}
                                    {/* <ToolTipWithIcon content={'anything'} icon={<HelpOutlineOutlinedIcon sx={{ color: open ? "#FFFFFF !important" : "var(--grey900) !important", height: "16px !important", margin: "0 !important" }} />} /> */}
                                    <ToolTipWithIcon
                                        linkText="Know More"
                                        // content={props.reason} 
                                        content={<>
                                            <Box>
                                                <Typography variant='subtitle2'>Reason :
                                                    <span variant="body2" style={{ color: "var(--grey900)", wordBreak: "break-word", fontWeight: '400' }}> {extractDataFromArray(props, ['listing_declined_reasons', 'reason'], 'others').toLowerCase() === 'others' ? extractDataFromArray(props, ['listing_declined_reasons', 'custom_reason'], '') : extractDataFromArray(props, ['listing_declined_reasons', 'reason'], '')}</span>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant='subtitle2'>Remarks :
                                                    <span variant="body2" style={{ color: "var(--grey900)", wordBreak: "break-word", fontWeight: '400' }}> {props.listing_declined_reasons?.remarks}</span>
                                                </Typography>
                                            </Box>
                                        </>}
                                        title={'Declination Reason'}
                                        link={"https://help.farwide.com/hc/en-us/articles/25965938887437-I-created-a-listing-on-FarWide-and-submitted-for-approval-My-listing-approval-has-been-declined-Why-does-it-happen-and-what-can-I-do-to-fix-it"} icon={<HelpOutlineOutlinedIcon sx={{ color: "error", height: '20px', width: '20px' }} />} />
                                    {/* </IconButton> */}
                                </Box>
                            }
                            {
                                props.status === 'A' && props.verificationStatus === 'V' &&
                                <Chip variant='filled' color='primary' label="Active" />
                            }
                            {
                                props.status === 'A' && props.verificationStatus !== 'V' &&
                                <Chip variant='outlined' color='primary' label="Waiting for Admin Approval" icon={<Icon.ScheduleOutlined />} />
                            }
                            {
                                props.status === 'C' &&
                                <Chip variant='filled' color='secondary' label="Closed" />
                            }
                        </Box>
                        <Box sx={{ display: 'flex', width: { sm: '100%', xs: '100%', md: 'auto', lg: 'auto' }, justifyContent: 'flex-start' }}>
                            {
                                props.status !== 'D' && props.type === 'listing' &&
                                <Link to={ROUTE_PROPERTY + "/" + props.slug + "-" + props.listing_seq}>
                                    <Button variant="contained"><Icon.RemoveRedEyeOutlined sx={{ mr: 1 }} fontSize='small' />View Listing</Button>
                                </Link>
                            }
                            {
                                props.status !== 'D' && props.type === 'experience' &&
                                <Link to={'/experience' + "/" + props.slug + "-" + props.listing_seq}>
                                    <Button variant="contained"><Icon.RemoveRedEyeOutlined sx={{ mr: 1 }} fontSize='small' />View Listing</Button>
                                </Link>
                            }
                            {
                                props.status === 'D' &&
                                <>
                                    {extractDataFromArray(props, ['cardType'], '') === "property" ?
                                        <Link to={ROUTE_CREATE_PROPERTY + "?listing_seq=" + props.listing_seq}>
                                            <Button variant="outlined" color="secondary"><Icon.EditOutlined sx={{ mr: 1 }} fontSize='small' />Complete</Button>
                                        </Link>
                                        :
                                        <Link to={props.completeLink}>
                                            <Button variant="outlined" color="secondary"><Icon.EditOutlined sx={{ mr: 1 }} fontSize='small' />Complete</Button>
                                        </Link>

                                    }
                                </>
                            }
                        </Box>

                    </Box>
                }
            </Box>
        </Box >
        // </Tooltip>
    )
}

export default PackageCard
