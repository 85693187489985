import { createContext, useContext, useEffect, useRef, useState } from "react";

const defaultProvider = {
    isMobile: null,
    isTablet: null,
    screenWidth: 0,
    scroll: 0,
    isMessagesDashboard: true,
    isShowMessages: false,
    isProfile: false,
}


const messageContext = createContext(defaultProvider);


const MessageContextProvider = ({ children }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [scroll, setScroll] = useState(0);
    const [isMessagesDashboard, setIsMessageDashboard] = useState(true);
    const [isShowMessages, setIsShowMessages] = useState(false);
    const [isProfile, setIsProfile] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [detail, setDetail] = useState({});
    const [start, setStart] = useState(0);
    const listContainerRef = useRef(null);
    const [seqValue, setSeqValue] = useState("");
    const [randomvalue, setRandomvalue] = useState(0)
    const [lastMessageSeqValue, setLastMessageSeqValue] = useState()
    const [senderSeqToSendChat, setSenderSeqToSendChat] = useState("")
    const [senderTypeToSendChat, setSenderTypeToSendChat] = useState("")
    const [newUserMessageReceivedStatus, setNewUserMessageReceivedStatus] = useState(false)
    const [isNewMessage, setIsNewMessage] = useState(false)
    const [lastMessageseq, setLastMessageseq] = useState()
    const [randomNumber, setRandomNumber] = useState(null)
    const [lastUserReadMessageSeq, setLastUserReadMessageSeq] = useState("")
    const [lastListReadMessageSeq, setLastListReadMessageSeq] = useState("")
    const [intervalTime, setIntervalTime] = useState(20000);
    const [profilePhoto, setProfilePhoto] = useState("")
    const [userDetail, setuserDetail] = useState({});

    // const [refDetected, setRefDetected] = useState("")
    const scrollableDivRef = useRef(null);
    const [toBottom, setToBottom] = useState(true)
    
    const [newMessageReceivedStatus, setNewMessageReceivedStatus] = useState("")
    const [updateConnectionListMessage, setUpdateConnectionListMessage] = useState({
        message: "",
        receiver_seq: ""
    })
    const [userData, setUserData] = useState({
        u_seq: "",
        u_type: ""
    })  

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    function handleScroll() {
        setScroll(window.scrollY);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const isMobile = width <= 1200;
    const mobileScreen = width <= 480;
    // const isTablet = width <= 768;

    const values = {
        isMobile,
        mobileScreen,
        // isTablet,
        scroll,
        screenWidth: width,
        isMessagesDashboard,
        setIsMessageDashboard,
        isShowMessages,
        setIsShowMessages,
        isProfile,
        setIsProfile,
        isLoading,
        setIsLoading,
        userData, setUserData,
        listContainerRef,
        start, setStart, senderSeqToSendChat, setSenderSeqToSendChat, setDetail, detail,
        newMessageReceivedStatus,
        setNewMessageReceivedStatus, scrollableDivRef,
        // refDetected,setRefDetected
        toBottom, setToBottom,
        updateConnectionListMessage, setUpdateConnectionListMessage, senderTypeToSendChat, setSenderTypeToSendChat, 
        seqValue, setSeqValue,
        newUserMessageReceivedStatus, setNewUserMessageReceivedStatus,
        isNewMessage, setIsNewMessage, randomvalue, setRandomvalue,
        lastMessageseq, setLastMessageseq,
        lastMessageSeqValue, setLastMessageSeqValue,
        randomNumber, setRandomNumber,
        lastUserReadMessageSeq, setLastUserReadMessageSeq,
        lastListReadMessageSeq, setLastListReadMessageSeq,
        intervalTime, setIntervalTime,
        profilePhoto, setProfilePhoto,
        userDetail, setuserDetail
    }
   
    return (<messageContext.Provider value={values}>{children}</messageContext.Provider>)
}

const MessageContext = () => {
    return useContext(messageContext)
}
// export { UserProvider };
export { MessageContext, MessageContextProvider }
