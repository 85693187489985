import * as React from "react";
import { Box, Grid, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';


const Testimonials = (props) => {
  const testimonialData = [
    {
      quoteimage: '../assets/images/homepage/quote_icon.svg',
      image: '../assets/images/homepage/testimonail_userpic_1_1.png',
      quote: ' As a hunter, I’m constantly looking for new areas and opportunities to chase wild turkeys. FarWide makes it much easier for me to find unique access and connects me with other like minded people. LOVE THIS – Thank you FarWide!',
      stars: 5,
      name: 'Steve W.',
      role: 'Oregon',
    },
    {
      quoteimage: '../assets/images/homepage/quote_icon.svg',
      image: '../assets/images/homepage/testimonail_userpic_2_1.png',
      quote: ' I sure enjoyed hunting with Marsh Creek. Large mature bucks get harvested in this area yearly. Heat and drought made the early season hunt pretty challenging this year and Jay and Maureen are easy to deal with and are friendly folks',
      stars: 5,
      name: 'Michael S.',
      role: 'Missouri',
    },
    // Add more testimonial data items here...
  ];
  return (
    <Grid
      item
      container
      maxWidth="lg"
      className="mobmarginbottom"
      m="auto"
      direction="row"
      justifyContent="center"
      sx={{
        pl: { xs: 2 },
        perspective: { xs: 2 },
        mb: 12
      }}
    >
      <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        navigation={true}
        className="custom-swiper"
      >

        {testimonialData.map((testimonial, index) => (
          <SwiperSlide key={index}>
            <Grid container spacing={6} alignItems="center">
              <Grid item xs={12} sm={12} md={5} sx={{ display: "flex", justifyContent: { sm: "center" } }}>
                <Box className="testimonial_img">
                  <img className="responsive_img" src={testimonial.image} alt="userpic" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={7} >
                <img className="responsive_img" src={testimonial.quoteimage} alt="quote" />
                <Typography variant="h6" sx={{ pr: { md: 8.5 }, mb: 3, mt: 3 }}>
                  {testimonial.quote}
                </Typography>
                {Array.from({ length: testimonial.stars }).map((_, starIndex) => (
                  <Icon.Star key={starIndex} className="star" />
                ))}
                <Typography variant="body1" sx={{ color: '#000', fontWeight:'600' }}>{testimonial.name}</Typography>
                <Typography>{testimonial.role}</Typography>
              </Grid>
            </Grid>
          </SwiperSlide>
        ))}

      </Swiper>
    </Grid>
  );
};

export default Testimonials;
