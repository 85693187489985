import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Icon } from '@mui/material';

const LoaderButton = ({
  isFullWidth,
  maxHeight,
  variantType,
  buttonColor,
  ButtonIcon,
  onClick,
  isLoading,
  buttonText,
  buttonBackGorundColor,
  textColor,
  padding,
  size,
  ml,
  mr,
  mt,
  mb, 
  sx={}
}) => {
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    setToggle(true); // Enable the button on component mount
  }, []);

  const handleClick = async () => {
    setToggle(false); // Disable the button on click
    await onClick();
    setToggle(true); // Enable the button after the API response
  };
  // console.log("fullwidth ?". isFullWidth)
  return (
    <Button
      fullWidth={isFullWidth}
      size={size}
      startIcon={ButtonIcon}
      disabled={!toggle || isLoading}
      variant={`${variantType}`}
      color={buttonColor}
      sx={{
        backgroundColor: buttonBackGorundColor,
        color: textColor,
        padding: padding,
        ml: ml,
        mr:mr,
        mt:mt,
        mb:mb,
        position: "relative",
        maxHeight:maxHeight,
        ...sx
          // "&:disabled": {
          //   backgroundColor: "rgba(38, 199, 122, 0.65)",
          //   color:"#FFFFFF"
          // }   
      }}
      onClick={handleClick}

    >
      {/* {buttonText}
      {isLoading && <CircularProgress size="20px" sx={{ ml: 2, color: '#fff' }} />} */}
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {buttonText}
        {isLoading && <CircularProgress size="20px" sx={{ color: 'var(--primary)', position: 'absolute' }} />}
      </Box>
    </Button>
  );
};

export default LoaderButton;

