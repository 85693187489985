import { createContext, useState, useEffect, useContext } from "react";
import { empty, extractDataFromArray, isEmpty, parseResponseAxios } from "../../utils/common";
import UserService from "../../services/user";
import { toast } from "react-toastify";
import { useDataGridPremiumProps } from "@mui/x-data-grid-premium/DataGridPremium/useDataGridPremiumProps";

const defaultProvider = {
    user: null,
    loading: true,
    authPopup: {},
    setAuthPopup: null,
    setUser: () => null,
    setLoading: () => Boolean,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    registerUser: () => Promise.resolve(),
    isUserLoggedIn: () => Promise.resolve(),
    loginUserWithToken: () => Promise.resolve(),
}


const userContext = createContext(defaultProvider);



const UserProvider = ({ children }) => {
    //fetch user data from localStorage and set it to state
    let userData = window.localStorage.getItem("userData")
    const searchParams = new URLSearchParams(window.location.search);
    const vToken = searchParams.get("tokenV1")
    userData = JSON.parse(userData)
    if (empty(userData)) {
        userData = defaultProvider.user
        window.localStorage.setItem("userData", JSON.stringify(defaultProvider.user))
    }


    // ** States
    const [user, setUser] = useState(userData)
    const [seqValue, setSeqValue] = useState("");
    const [authPopup, setAuthPopup] = useState({ open: false, type: 'signin', callback: null })
    const [loading, setLoading] = useState(defaultProvider.loading)

    //Ping server for user login validation.
    const isUserLoggedIn = async () => {
        setLoading(true)
        UserService.isLoggedIn((response) => {
            setLoading(false)
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                if (extractDataFromArray(response, ['data', 'loggedIn']) === 'Y') {
                    setUser({ ...response.data })
                    window.localStorage.setItem("userData", JSON.stringify(response.data))
                } else {
                    handleLogout()
                }
            } else {
                handleLogout()
            }
        }, () => {
            handleLogout()
        })
    }

    const loginUserWithToken = async (token) => {
        setLoading(true)

        UserService.loginUserWithToken(token, (response) => {
            setLoading(false)
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                if (!isEmpty(extractDataFromArray(response, ['data'], {}))) {
                    setUser({ ...response.data })
                    window.localStorage.setItem("userData", JSON.stringify(response.data))
                } else {
                    handleLogout()
                }
            } else {
                handleLogout()
            }
        }, () => {
            handleLogout()
        })
    }


    useEffect(() => {
        if (empty(vToken)) {
            isUserLoggedIn()
        } else {
            loginUserWithToken(vToken)
        }
        // NOTE:  Don't remove this line.    Check with Abishek B before doing this
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleLogin = (email, passwd, successCallback, errorCallback) => {
        UserService.loginUser(email, passwd, (userData) => {
            // console.log("userData?.user_seq", userData?.user_seq)
            // setSeqValue(userData?.user_seq)
            localStorage.setItem("userData", JSON.stringify(userData))
            setUser(userData)
            if (!empty(authPopup.callback)) {
                authPopup.callback()
            }
            UserService.loginUserInExp(
                extractDataFromArray(userData, ['email'], ''),
                extractDataFromArray(userData, ['fname'], ''),
                extractDataFromArray(userData, ['lname'], ''),
                extractDataFromArray(userData, ['profile_pic'], ''),
                "",
                extractDataFromArray(userData, ['phone'], ''),
                [],
                () => {
                    successCallback()
                },
                () => {
                    handleLogout()
                }
            )
        }, (errorCode, errorMsg = "") => {
            if(errorCode == undefined) {
                toast.error("You are offline!", {
                    theme: 'colored'
                })
            } else (
                    toast.error(errorMsg, {
                        theme: 'colored'
                    })
            )
            if (typeof errorCallback === "function") {
                errorCallback()
                // console.log("heyyyyyyyyyyy")
            }
        })
    }

    const handleLogout = (callback = null) => {
        setUser(null)
        window.localStorage.removeItem('userData')
        window.localStorage.clear()
        UserService.logoutUser()
        if (!empty(callback)) {
            callback()
        }
    }

    const handleRegister = (params, successCallback, errorCallback) => {
        UserService.registerUser(params, (userData) => {
            console.log(params)
            localStorage.setItem("userData", JSON.stringify(userData))
            setUser(userData)
            if (!empty(authPopup.callback)) {
                authPopup.callback()
            }
            UserService.loginUserInExp(
                extractDataFromArray(userData, ['email'], ''),
                extractDataFromArray(userData, ['fname'], ''),
                extractDataFromArray(userData, ['lname'], ''),
                extractDataFromArray(userData, ['profile_pic'], ''),
                "guest",
                params.phone,
                params.user_interest,
                () => {
                    successCallback()
                },
                () => {
                    handleLogout()
                }
            )
        }, (errorCode, errorMsg = "") => {
            if(errorCode == undefined) {
                toast.error("You are offline!", {
                    theme: 'colored'
                })
            } else (
                    toast.error(errorMsg, {
                        theme: 'colored'
                    })
            )
            if (typeof errorCallback === "function") {
                errorCallback()
            }
        })
    }

    const values = {
        user,
        loading,
        authPopup,
        setAuthPopup,
        setUser,
        setLoading,
        login: handleLogin,
        logout: handleLogout,
        registerUser: handleRegister,
        isUserLoggedIn,
        loginUserWithToken,
        seqValue, setSeqValue
    }

    return (<userContext.Provider value={values}>{children}</userContext.Provider>)
}

const UserAuth = () => {
    return useContext(userContext)
}
// export { UserProvider };
export { UserAuth, UserProvider }
