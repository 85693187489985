import { Box, FormHelperText } from "@mui/material";
import BorderedCheckbox from "../BorderedCheckbox";
import { empty, extractDataFromArray, isDefined, isEmpty } from "../../../utils/common";
import { useState } from "react";

export default function MultiSelectCheckboxes({ itemList, name, errors, selectedItems, handleSelect }) {
    let itemsChecked = {};
    selectedItems = empty(selectedItems) ? [] : selectedItems;
    itemList.forEach(ele => {
        itemsChecked[ele] = selectedItems.indexOf(ele) > -1
    })
    const [, setChecked] = useState(itemsChecked)

    let handleCheckSelect
    // let errorMsg = extractDataFromArray(errors, name, '');
    if (isDefined(handleSelect)) {
        handleCheckSelect = (data) => {
            let temp = { ...itemsChecked, ...data };
            setChecked(temp)
            let newSelect = Object.keys(temp).filter(ele => temp[ele])
            let select = {}
            select[name] = newSelect
            handleSelect(newSelect)
        }
    }


    return (
        <>
        <Box sx={{mt:2}}>
            {
                itemList.map((item, index) => {
                    return (
                        <BorderedCheckbox
                            key={name + "_" + index}
                            value={item}
                            name={name}
                            handleSelect={handleCheckSelect}
                            checked={selectedItems.indexOf(item) > -1 ? true : false}
                            
                        />
                    )
                })
            }             
        </Box>
            {
                !empty(extractDataFromArray(errors, name, '')) &&
                <FormHelperText 
                sx={{marginLeft: 0, marginTop: "-11px"}}
                error={true}>
                    {extractDataFromArray(errors, name, '')}
                </FormHelperText>
            }
        </>
    )
}
