import { Box } from "@mui/material"
import ExpActivites from "./activites";
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";

const ExpAmenties = ({ formData, isMobileButtonClicked = false }) => {
    // console.log(extractDataFromArray(formData, ['title_included'], ''));

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }}>
            <Box>
                <ExpActivites formData={formData} exptitle={'title_included'} keyName={'amenities_included'} isMobileButtonClicked={isMobileButtonClicked} />
            </Box>
            <Box>
                <ExpActivites formData={formData} exptitle={'title_excluded'} keyName={'amenities_not_included'} isMobileButtonClicked={isMobileButtonClicked} />
            </Box>
        </Box>
    )
}

export default ExpAmenties