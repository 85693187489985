import { Box, Grid, Typography } from "@mui/material"
import { extractDataFromArray } from "../../../utils/common";
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";

const ExpList = ({ formData, isMobileButtonClicked = false }) => {
    // console.log(formData);
    const activityList = extractDataFromArray(formData, ['list_items'], []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }}>
            <Typography variant="h5" sx={{ fontWeight: '600', fontSize: isMobileButtonClicked && '1.25rem' }}>{extractDataFromArray(formData, ['title'], '')}</Typography>
            <Grid container spacing={2}>
                {activityList.map((activity, index) => (
                    <Grid item xs={6} key={index}>
                        <Box display="flex" alignItems="flex-start">
                            <span className="material-symbols-outlined" style={{ color: 'var(--black)' }}>
                                {activity.icon}
                            </span>
                            <Typography variant="body1" ml={1}>{activity.label}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default ExpList;