
import { useEffect } from "react";
import ChatWrapper from "../../commonComponents/Chat"
import { exponentialBackoff, showZendeskLauncher } from "../../utils/common";
import { CommonContext } from "../../store/context/commonContext";

const Chat = () => {
    const { isTablet } = CommonContext();
    useEffect(() => {
        showZendeskLauncher(false)
        // Array.from(document.querySelectorAll('iframe')).forEach(iframe => iframe.title === "Close message" && iframe.contentDocument.querySelector('button'))
        // exponentialBackoff(() => {
        //     return Array.from(document.querySelectorAll('iframe')).forEach(iframe => iframe.title === "Close message" && iframe.contentDocument.querySelector('button')) != null
        // },5, 1000,()=> {
        //     Array.from(document.querySelectorAll('iframe')).forEach(iframe => iframe.title === "Close message" && iframe.contentDocument.querySelector('button')?.click())
        // })

        const timeoutId = setTimeout(() => {

            Array.from(document.querySelectorAll('iframe')).forEach(iframe => iframe.title === "Close message" && iframe.contentDocument.querySelector('button')?.click());
        }, 200)
        return () => clearTimeout(timeoutId);
    }, [])

    return (
        <>
            <ChatWrapper />
        </>
    )
}

export default Chat;