import { Outlet } from 'react-router-dom';
import LandingPageHeader from './Header';
import Footer from './Footer';


const LandingPageLayout = () => {
    return (
        <>
            <LandingPageHeader />
            <Outlet />
            <Footer />
        </>

    )

}

export default LandingPageLayout;


