import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { extractDataFromArray } from "../../../utils/common";
import ApplyStyles from "../../previewPageComponents/htmlParser/ApplyStyles";
import ExpereinceOverviewImages from "./expOverviewImages";
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";

const TripSchedule = ({ formData, isMobileButtonClicked = false }) => {
    const title = extractDataFromArray(formData, ['caption'], '')
    const scheduleList = extractDataFromArray(formData, ['schedule_list'], [])
    if (scheduleList.length === 1 && (scheduleList[0]?.label === '' || scheduleList[0]?.description === '' || scheduleList[0]?.description === '<p><br></p>')) {
        return null
    }

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }}>
                <Typography variant="h5" sx={{ fontWeight: '600', fontSize: isMobileButtonClicked && '1.25rem' }}>{title}</Typography>
                {scheduleList.map((schedule, index) => (
                    <Box key={index}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Box
                                sx={{
                                    backgroundColor: '#C5C5C7',
                                    height: '12px',
                                    width: '12px',
                                    borderRadius: '50%',
                                }}
                            />
                            <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
                                {schedule.label}
                            </Typography>
                        </Box>
                        <Box sx={{
                            borderLeft: scheduleList.length - 1 === index ? 'none' : '2px solid #C5C5C7',
                            p: 2,
                            pb: 5,
                            ml: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING,
                        }}>
                            <ApplyStyles
                                htmlString={schedule.description}
                            />
                            {schedule.photo_list?.length > 0 && <ExpereinceOverviewImages
                                images={schedule.photo_list}
                            />}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />

        </>

    );
}

export default TripSchedule;
