import { Box, Drawer, Skeleton, Typography, styled } from "@mui/material";
import MessagesDashboard from "./MessagesDashboard";
import ShowMessages from "./ShowMessages";
import { useEffect, useState } from "react";
import Profile from "./Profile";
import { UserAuth } from "../../store/context/userContext";
import { MessagesApi } from "../../services/messagesApi";
import { useLocation, useParams } from "react-router";
import { extractDataFromArray } from "../../utils/common";
import { MessageContext } from "../../store/context/messageContext";

const drawerWidth = 327;
const drawerWidth2 = 252;



const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    })
);

const Messages = () => {

    const [isChatHistoryAvailable, setIsChatHistoryAvailable] = useState({})
    const [openMessageDrawer, setOpenMessageDrawer] = useState(true);
    const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
    const [connections, setConnections] = useState([]);
    const { user } = UserAuth()
    const location = useLocation();

    const { isMobile, setIsMessageDashboard, isMessagesDashboard, isShowMessages, setIsShowMessages, isProfile, start, userData, detail, setDetail, setSeqValue, setuserDetail, } = MessageContext();
    const [active, setActive] = useState();
    const param = useParams();
    const [limit, setLimit] = useState();
    const [userMessages, setUserMessages] = useState([])
    const [userMessageLastSeqValue, setUserMessageLastSeqValue] = useState('')
    const [newMessageRecivedlastSeq, setNewMessageRecivedlastSeq] = useState();
    const [resetScroll, setResetScroll] = useState(false)
    const [loadingUser, setLoadingUser] = useState(true);
    const [loadingChatPage, setloadingChatPage] = useState(true)
    const [search, setSearch] = useState(null);
    const [toggleNewMessage, setToggleNewMessage] = useState()
    const [isComingFromContactPage, setIsComingFromContactPage] = useState(false);
    const [config, setConfig] = useState({
        start: 0,
        limit: ""
    });

    useEffect(() => {
        setIsMessageDashboard(true)
        setIsShowMessages(false)
        MessagesApi.getAllMessages(search, config.limit, config.start, (data) => {

            setConnections([...data])

        }, (error) => {
            console.log(error)
        });
        if (location.search.includes('?contact')) {
            setIsComingFromContactPage(true)
        } else {
            setIsComingFromContactPage(false)
        }
        // console.log("location", location);
    }, [])

    // CHAT AVAILABLE LOGIC STARTS
    useEffect(() => {
        setloadingChatPage(true)
        MessagesApi.isChatHistory((res) => {
            setIsChatHistoryAvailable(res)
            setloadingChatPage(false)
        })

        setSeqValue(extractDataFromArray(user, ['seq'], extractDataFromArray(user, ['user_seq'], 0)))

    }, []);



    let chatAvailabe = isChatHistoryAvailable.isChatHistoryAvailable

    // CHAT AVAILABLE LOGIC ENDS


    // FETCH USER INFO LOGIC STARTS


    useEffect(() => {

        MessagesApi.getUserInfo(userData.u_seq, userData.u_type, (res) => {
            setDetail(res)
            setuserDetail(res.data)
            setLoadingUser(false)
        }, (error) => {
            console.log(error)
            setLoadingUser(false)
        })

    }, [param]);

    // FETCH USER INFO LOGIC ENDS


    // FETCH USER MESSAGE LOGIC STARTS


    const fetchUserMessages = async (userData) => {

        await MessagesApi.getUserMessages(userData, limit, start, (res) => {
            setUserMessageLastSeqValue(res[0].seq)

            setUserMessages(res)
            if (res?.length > 0) {

                setNewMessageRecivedlastSeq(res[1].seq)
            }
        }, (error) => {
            console.log(error)
        })
    }
    // FETCH USER MESSAGE LOGIC ENDS
    // console.log("isComingFromContactPage", isComingFromContactPage);

    return (
        <Box
            sx={
                isMobile
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        borderRadius: 2.25,
                        backgroundColor: "white",
                        position: "relative",
                        // height: 'calc(100vh - 113px)' // 100vh change to 100svh for mobile view fix safari
                        height: 'calc(100svh - 113px)'
                    }
                    : {
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        borderRadius: 2.25,
                        backgroundColor: "white",
                        position: "relative",
                        // height: 'calc(100vh - 72px)'
                    }
            }
        >
            {
                !isComingFromContactPage &&
                <Box
                    sx={
                        isMobile && isMessagesDashboard
                            ? {
                                textAlign: "left",
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }
                            : isMobile && !isMessagesDashboard
                                ? {
                                    textAlign: "left",
                                    display: "none",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }
                                : { textAlign: "left" }
                    }
                >
                    <Typography
                        variant={isMobile ? "h3" : "h4"}
                        sx={
                            isMobile
                                ? { marginTop: "-5px", fontWeight: "bold" }
                                : { fontWeight: "bold" }
                        }
                    >
                        Messages
                    </Typography>

                </Box>
            }
            {isMobile ? (loadingChatPage && <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: "calc(100vh - 196px)", width: "100%" }} />) :
                (

                    loadingChatPage && <Box sx={{
                        display: "flex", justifyContent: "center", alignItems: "center", gap: 2, flexShrink: 0,
                    }}>
                        <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: "calc(100vh - 196px)", width: 327 }} />
                        <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: "calc(100vh - 196px)", width: 600 }} />
                    </Box>
                )
            }

            {!loadingChatPage && (
                !chatAvailabe ?
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 413,
                            width: "100%",
                            borderRadius: 2,
                            backgroundColor: "#F5F5F6",
                            margin: isMobile ? 0 : "1.438rem",
                            gap: 4
                        }}
                    >
                        <img
                            src={"chat_image.svg"}
                            alt="message"
                            width={277}
                            height={153}
                        />
                        <Typography variant="body1" color="var(--greyText)" >You don’t have any conversations yet.</Typography>
                    </Box>
                    :
                    <Box
                        sx={
                            isMobile
                                ? {
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 4,
                                }
                                : {
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 4,
                                    flex: 1,
                                }
                        }
                    >
                        {
                            (isMessagesDashboard && isMobile) || (connections.length > 0 && isMobile && !isShowMessages && isMessagesDashboard) ? (

                                <Drawer
                                    sx={
                                        isMobile
                                            ? {
                                                flexShrink: 0,
                                                transition: "transform 0.5s ease-in-out",
                                                transform: openMessageDrawer
                                                    ? "translateX(0)"
                                                    : `translateX(1000px)`,
                                                "& .MuiDrawer-paper": {
                                                    boxSizing: "border-box",
                                                    position: "relative",
                                                    border: "none",
                                                },
                                                display: openMessageDrawer ? "block" : "none",
                                            }
                                            : {
                                                width: drawerWidth,
                                                flexShrink: 0,
                                                transition: "transform 0.5s ease-in-out",
                                                transform: openMessageDrawer
                                                    ? "translateX(0)"
                                                    : `translateX(1000px)`,
                                                "& .MuiDrawer-paper": {
                                                    width: drawerWidth,
                                                    boxSizing: "border-box",
                                                    position: "relative",
                                                    border: "none",
                                                },
                                                display: openMessageDrawer ? "block" : "none",
                                            }
                                    }
                                    variant={isMobile ? "permanent" : "permanent"}
                                    open={openMessageDrawer}
                                    anchor="left"
                                >
                                    {
                                        !isComingFromContactPage ?

                                            <MessagesDashboard
                                                setResetScroll={setResetScroll}
                                                fetchUserMessages={fetchUserMessages}
                                                setSearch={setSearch}
                                                search={search}
                                                open={openMessageDrawer}
                                                connections={connections}
                                                setConnections={setConnections}
                                                active={active}
                                                setActive={setActive}
                                                setToggleNewMessage={setToggleNewMessage}
                                                toggleNewMessage={toggleNewMessage}
                                                loadingUser={loadingUser}
                                                setLoadingUser={setLoadingUser}
                                                isComingFromContactPage={isComingFromContactPage}
                                            /> :

                                            <Main open={openInfoDrawer}>
                                                <ShowMessages
                                                    loadingUser={loadingUser}
                                                    resetScroll={resetScroll}
                                                    userMessageLastSeqValue={userMessageLastSeqValue}
                                                    newMessageRecivedlastSeq={newMessageRecivedlastSeq}
                                                    setUserMessages={setUserMessages}
                                                    fetchUserMessages={fetchUserMessages}
                                                    userMessages={userMessages}
                                                    setOpenMessageDrawer={setOpenMessageDrawer}
                                                    openMessageDrawer={openMessageDrawer}
                                                    setOpenInfoDrawer={setOpenInfoDrawer}
                                                    openInfoDrawer={openInfoDrawer}
                                                    connections={connections}
                                                    setConnections={setConnections}
                                                    setToggleNewMessage={setToggleNewMessage}
                                                    toggleNewMessage={toggleNewMessage}
                                                    setLoadingUser={setLoadingUser}
                                                    isComingFromContactPage={isComingFromContactPage}
                                                    setIsComingFromContactPage={setIsComingFromContactPage}
                                                />
                                            </Main>
                                    }
                                </Drawer>
                            ) : (
                                !isMobile && (
                                    <Drawer
                                        sx={
                                            isMobile
                                                ? {
                                                    flexShrink: 0,
                                                    transition: "transform 0.5s ease-in-out",
                                                    transform: openMessageDrawer
                                                        ? "translateX(0)"
                                                        : `translateX(1000px)`,
                                                    "& .MuiDrawer-paper": {
                                                        boxSizing: "border-box",
                                                        position: "relative",
                                                        border: "none",
                                                    },
                                                    display: openMessageDrawer ? "block" : "none",
                                                }
                                                : {
                                                    width: drawerWidth,
                                                    flexShrink: 0,
                                                    transition: "transform 0.5s ease-in-out",
                                                    transform: openMessageDrawer
                                                        ? "translateX(0)"
                                                        : `translateX(1000px)`,
                                                    "& .MuiDrawer-paper": {
                                                        width: drawerWidth,
                                                        boxSizing: "border-box",
                                                        position: "relative",
                                                        border: "none",
                                                    },
                                                    display: openMessageDrawer ? "block" : "none",
                                                }
                                        }
                                        variant={isMobile ? "permanent" : "permanent"}
                                        open={openMessageDrawer}
                                        anchor="left"
                                    >
                                        <MessagesDashboard
                                            setResetScroll={setResetScroll}
                                            fetchUserMessages={fetchUserMessages}
                                            setSearch={setSearch}
                                            search={search}
                                            open={openMessageDrawer}
                                            connections={connections}
                                            setConnections={setConnections}
                                            active={active}
                                            setActive={setActive}
                                            setToggleNewMessage={setToggleNewMessage}
                                            toggleNewMessage={toggleNewMessage}
                                            setLoadingUser={setLoadingUser}
                                        />
                                    </Drawer>
                                )
                            )}
                        {isShowMessages && isMobile ? (
                            <Main open={openInfoDrawer}>
                                <ShowMessages
                                    loadingUser={loadingUser}
                                    resetScroll={resetScroll}
                                    userMessageLastSeqValue={userMessageLastSeqValue}
                                    newMessageRecivedlastSeq={newMessageRecivedlastSeq}
                                    setUserMessages={setUserMessages}
                                    fetchUserMessages={fetchUserMessages}
                                    userMessages={userMessages}
                                    setOpenMessageDrawer={setOpenMessageDrawer}
                                    openMessageDrawer={openMessageDrawer}
                                    setOpenInfoDrawer={setOpenInfoDrawer}
                                    openInfoDrawer={openInfoDrawer}
                                    connections={connections}
                                    setConnections={setConnections}
                                    setToggleNewMessage={setToggleNewMessage}
                                    toggleNewMessage={toggleNewMessage}
                                    setLoadingUser={setLoadingUser}
                                    setIsComingFromContactPage={setIsComingFromContactPage}
                                />
                            </Main>
                        ) : (
                            !isMobile && (

                                <Main open={openInfoDrawer}>
                                    <ShowMessages
                                        newMessageRecivedlastSeq={newMessageRecivedlastSeq}
                                        loadingUser={loadingUser}
                                        resetScroll={resetScroll}
                                        userMessageLastSeqValue={userMessageLastSeqValue}
                                        setUserMessages={setUserMessages}
                                        fetchUserMessages={fetchUserMessages}
                                        userMessages={userMessages}
                                        setOpenMessageDrawer={setOpenMessageDrawer}
                                        openMessageDrawer={openMessageDrawer}
                                        setOpenInfoDrawer={setOpenInfoDrawer}
                                        openInfoDrawer={openInfoDrawer}
                                        connections={connections}
                                        setConnections={setConnections}
                                        setToggleNewMessage={setToggleNewMessage}
                                        toggleNewMessage={toggleNewMessage}
                                        setLoadingUser={setLoadingUser}
                                        setIsComingFromContactPage={setIsComingFromContactPage}
                                    />
                                </Main>
                            )
                        )}
                        {isProfile && isMobile ? (
                            <Drawer
                                sx={
                                    isMobile
                                        ? {
                                            flexShrink: 0,
                                            "& .MuiDrawer-paper": {
                                                position: "relative",
                                                boxSizing: "border-box",
                                                border: "none",
                                            },
                                            display: openInfoDrawer ? "block" : "none",
                                        }
                                        : {
                                            width: drawerWidth2,
                                            flexShrink: 0,
                                            "& .MuiDrawer-paper": {
                                                width: drawerWidth2,
                                                position: "relative",
                                                boxSizing: "border-box",
                                                border: "none",
                                            },
                                            display: openInfoDrawer ? "block" : "none",
                                        }
                                }
                                variant="permanent"
                                open={openInfoDrawer}
                                anchor="right"
                            >
                                <Profile
                                    detail={detail}
                                    open={openInfoDrawer}
                                    openInfoDrawer={openInfoDrawer}
                                    setOpenInfoDrawer={setOpenInfoDrawer}
                                />
                            </Drawer>
                        ) : (
                            !isMobile && (
                                <Drawer
                                    sx={
                                        isMobile
                                            ? {
                                                flexShrink: 0,
                                                "& .MuiDrawer-paper": {
                                                    position: "relative",
                                                    boxSizing: "border-box",
                                                    border: "none",
                                                },
                                                display: openInfoDrawer ? "block" : "none",
                                            }
                                            : {
                                                width: drawerWidth2,
                                                flexShrink: 0,
                                                "& .MuiDrawer-paper": {
                                                    width: drawerWidth2,
                                                    position: "relative",
                                                    boxSizing: "border-box",
                                                    border: "none",
                                                },
                                                display: openInfoDrawer ? "block" : "none",
                                            }
                                    }
                                    variant="permanent"
                                    open={openInfoDrawer}
                                    anchor="right"
                                >
                                    <Profile
                                        open={openInfoDrawer}
                                        openInfoDrawer={openInfoDrawer}
                                        setOpenInfoDrawer={setOpenInfoDrawer}
                                    />
                                </Drawer>
                            )
                        )}
                    </Box>
            )
            }


        </Box>
    );
};

export default Messages;
