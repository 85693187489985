import * as React from "react";

import { Box, Grid, Typography } from "@mui/material";

const Insurance = (props) => {
  const insuranceData = [
    {
      title: "Indemnification",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
      title: "Guest Injury",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
      title: "Landowner Property Damage",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
      title: "Landowner Liability",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
      title: "LandTrust Liability",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
  ];
  return (
    <Grid
      container
      spacing={10}
      className="mobmarginbottom"
      sx={{ mb: 10, alignItems: "center" }}
    >
      <Grid item xs={12} md={6}>
        <Box sx={{ padding: { xs: 2, sm: 3 }, textAlign: "left" }}>
          <Typography className="safety_title" sx={{ mb: 2 }}>
            Insurance & Indemnification
          </Typography>
          {insuranceData.map((item, index) => (
            <Box key={index}>
              <Typography className="sub_title">{item.title}</Typography>
              <Typography sx={{ mb: 2 }}>{item.content}</Typography>
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <img
          alt=""
          style={{ width: "100%", maxWidth: "100%" }}
          src="../assets/images/safty_image_1.svg" // Replace with your image URL
        />
      </Grid>
    </Grid>
  );
};

export default Insurance;
