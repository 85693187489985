import * as React from "react";
import { Box, Button, Grid } from "@mui/material";
import * as Icon from "@mui/icons-material";

import 'swiper/css';

import TitleSection from "../aboutus/section/TitleSection";
import GallerySection from "../aboutus/section/GallerySection";
import OurStory from "../aboutus/section/OurStory";
import CardSection from "../aboutus/section/Cards";


import "./style.css";
import Footer from "../../layouts/LandingPageLayout/Footer";
import LandingPageHeader from "../../layouts/LandingPageLayout/Header";
import axios from "axios";
import config from "../../config";
// Install Swiper modules

const AboutUs = () => {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Grid
          container
          maxWidth="lg"
          sx={{ m: "auto" }}
          direction="row"
          justifyContent="center"
        >


          {/* header */}

          <TitleSection />

          <Grid
            item
            container
            className="mobmarginbottom"
            sx={{ mb: 10, justifyContent: "center" }}
          >
            <GallerySection />
            <OurStory />
          </Grid>

          <CardSection />

          {/* <Button
            variant="contained"
            size="large"
            sx={{ textTransform: "none", mb: 10, fontSize: "17px" }}
          >
            {" "}
            Let’s Begin Now
            <Icon.ArrowForward sx={{ mr: 1 }} />
          </Button> */}
        </Grid>
      </Box>

      <Button onClick={() => {
        console.log('btn clicked');
        axios.get(`${config.apiUrl}/services/api`, {
          params: {
            cmd: "DownloadInvoice"
          },
          responseType: 'blob' // Set the response type to 'blob'
        })
          .then((response) => {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);

            // Create a temporary anchor element
            const a = document.createElement('a');
            a.href = url;
            a.download = 'invoice.pdf'; // Set the download attribute with desired filename
            a.click(); // Simulate click to trigger download
            URL.revokeObjectURL(url); // Release the object URL
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }}>DOWNLOAD INSTEAD</Button>

    </>
  );
};

export default AboutUs;
