import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, IconButton, Chip, FormControlLabel } from "@mui/material";
import * as Icon from "@mui/icons-material";
import ProfileService from "../../../services/profile";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import UserService from "../../../services/user";
import { empty } from "../../../utils/common";
const MyInterest = () => {
  const [intrest, setIntrest] = useState([]);
  const [states, setState] = useState([])
  // console.log(states)

  const [formData, setFormData] = useState({ interest_seq: "0", interested_activities: [], hunting_states: [], choosen_weapons: [], hunting_species: [], });
  const [stateData, setStateData] = useState({})
  const optionsArray = Object.values(stateData);
  const [inputValue, setInputValue] = React.useState('');
  const hint = React.useRef('');

  useEffect(() => {
    async function getAllRegions() {
      await ProfileService.getAllRegions((data) => {
        setStateData(data)
      })
    }
    async function getInterestDetails() {
      await ProfileService.getInterestDetails((data) => {
        setIntrest(data);
      });
    }
    getAllRegions();
    getInterestDetails();
  }, []);

  useEffect(() => {
    if (intrest) {
      // Split the hunting_states string by &quot; and decode each segment
      setState(intrest.hunting_states && typeof intrest.hunting_states === "string"
        ? intrest.hunting_states.split(',').map(state => state.trim())
        : [])

      setFormData({
        interest_seq: "0",
        interested_activities: intrest.interested_activities,
        hunting_states: states,
        choosen_weapons: intrest.choosen_weapons,
        hunting_species: intrest.hunting_species,
      });
    }
  }, [intrest]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevFormData) => {
      if (!prevFormData.hasOwnProperty(name)) {
        return prevFormData;
      }

      if (type === "checkbox") {
        const updatedValues = prevFormData[name] || [];

        if (checked) {
          return {
            ...prevFormData,
            [name]: [...updatedValues, value],
          };
        } else {
          const filteredValues = updatedValues.filter((item) => item !== value);

          return {
            ...prevFormData,
            [name]: filteredValues,
          };
        }
      } else {
        return {
          ...prevFormData,
          [name]: value,
        };
      }
    });
  };

  const handleAppendState = (event, newValue) => {
    const newState = newValue?.name;
    if (newState) {
      const stateExists = states.includes(newState);
      if (!stateExists) {
        setState(prevStates => [...prevStates, newState]);
        // Filter out the selected state from stateData
        // setStateData(prevStateData => {
        //   const updatedStateData = prevStateData.filter(state => state.name !== newState);
        //   return updatedStateData;
        // });
        // Clear the input field after appending the state
        event.target.value = '';
      }
    }
  };


  const handleDelete = (stateToDelete) => {
    const updatedStates = states.filter(state => state !== stateToDelete);
    setState(updatedStates);

  };

  const activitiesList = [
    "Hunting", "Fishing", "Bird Watching", "Farm and Ranch Experiences"];
  const weaponsList = ["Rifle", "Muzzleloader", "Shotgun", "Bow & Arrow",];
  const huntingSpeciesList = [
    "Whitetails", "Elk", "Turkeys", "Mule Deer", "Upland", "Hogs", "Waterfowl"];


  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      interested_activities,
      hunting_states,
      choosen_weapons,
      hunting_species,
    } = formData;


    ProfileService.updateInterests(interested_activities, states, choosen_weapons, hunting_species, (data) => {
      // Show success toast
      toast.success("Profile updated successfully", {
        theme: "colored",
      });

      // Reset form fields after submission
      setFormData({
        interested_activities: [], // Clear the selected activities
        hunting_states: [], // Clear the selected states
        choosen_weapons: [], // Clear the selected weapons
        hunting_species: [], // Clear the selected species
      });

      // After successful submission, call the API to get updated interest details
      ProfileService.getInterestDetails((interestData) => {
        setIntrest(interestData);
      });
    }, (errData) => {
      toast.warning(errData, {
        theme: 'colored'
      })
    })
  };





  return (
    <Box sx={{ border: " 1px solid #E5E7EB", borderRadius: "16px", mb: 6 }}>
      <Box sx={{ borderBottom: "1px solid #C5C5C7", display: 'flex', p: { xs: 2, sm: 2, md: 3, lg: 3 } }}>
        <Typography variant="h4" sx={{ mt: 'auto', mb: 'auto', color: "#1B1B1B", fontSize: { xs: '1rem', sm: '1rem', md: '1.5rem', lg: '1.5rem' } }}>My Interests</Typography>
      </Box>
      <Box sx={{ p: { xs: 2, sm: 2, md: 3, lg: 3 } }} >
        <Typography className="activities" sx={{ mb: 1 }}>
          Which activities are you interested in?
        </Typography>
        <Box
          className="activity_type"
        >
          {activitiesList.map((activity) => (
            <Box className="check" key={activity}>
              <FormControlLabel
                sx={{
                  ml: 0,
                  '& .MuiTypography-root': {
                    color: '#96969A'
                  }
                }}
                label={activity}
                control={
                  <Checkbox
                    checked={
                      formData.interested_activities &&
                      formData.interested_activities.includes(activity)
                    }
                    onChange={handleChange}
                    name="interested_activities"
                    value={activity}
                  />
                }
              />
              {/* <Typography sx={{ fontSize: "14px", color: "#96969A", ml: -1 }}>
                {activity}&nbsp;&nbsp;
              </Typography> */}
            </Box>
          ))}
        </Box>
        <Typography sx={{ fontSize: "16px", color: "#85878A", mt: 4, }}>
          Tell us the states you like to hunt in
        </Typography>
        <Autocomplete
          options={optionsArray}
          selectOnFocus
          getOptionLabel={(option) => option.name}
          clearOnBlur
          freeSolo
          onChange={handleAppendState}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              if (hint.current) {
                setInputValue(hint.current);
                event.preventDefault();
                hint.current = '';
              }
            }
          }}
          onBlur={() => {
            hint.current = '';
          }}
          disablePortal
          inputValue={inputValue}
          filterOptions={(options, state) => {
            const displayOptions = options.filter((option) =>
              option.name
                .toLowerCase()
                .trim()
                .includes(state.inputValue.toLowerCase().trim()),
            );

            return displayOptions;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                placeholder="Enter a State Name"
                sx={{ mt: '8px', mb: '4px' }}
                name="hunting_states"
                onChange={(e) => {
                  const newValue = e.target.value;
                  setInputValue(newValue);
                  handleChange(e);
                }}
              />
            );
          }}
        />

        {
          states.map((state, index) => (
            <Box key={index} className="states" sx={{ m: '2px' }}>
              <Typography variant="body2" sx={{ color: 'white' }}>{state}</Typography>
              <IconButton aria-label={`Remove ${state}`} onClick={() => { handleDelete(state) }}>
                <Icon.Close sx={{ fontSize: "15px", color: "#fff" }} />
              </IconButton>
            </Box>
          ))
        }

        <Typography
          sx={{ fontSize: "16px", color: "#85878A", mb: 2, mt: 3, }}>
          What's your weapon of choice?
        </Typography>

        <Box
          className="activity_type"
        >
          {weaponsList.map((weapon) => (
            <Box className="check" key={weapon}>
              <FormControlLabel
                sx={{
                  ml: 0,
                  '& .MuiTypography-root': {
                    color: '#96969A'
                  }
                }}
                label={weapon}
                control={
                  <Checkbox
                    checked={
                      formData.choosen_weapons &&
                      formData.choosen_weapons.includes(weapon)
                    }
                    onChange={handleChange}
                    name="choosen_weapons"
                    value={weapon}
                  />
                }
              />
            </Box>
          ))}
        </Box>

        <Typography sx={{ fontSize: "16px", color: "#85878A", mb: 2, mt: 3 }}>
          Which species do you hunt?
        </Typography>

        <Box
          className="activity_type"
          sx={{
            mb: 2
          }}
        >
          {huntingSpeciesList.map((species) => (
            <Box className="check" key={species}>
              <FormControlLabel
                sx={{
                  ml: 0,
                  '& .MuiTypography-root': {
                    color: '#96969A'
                  }
                }}
                label={species}
                control={
                  <Checkbox
                    checked={
                      formData.hunting_species &&
                      formData.hunting_species.includes(species)
                    }
                    onChange={handleChange}
                    name="hunting_species"
                    value={species}
                  />
                }
              />
            </Box>
          ))}
        </Box>

        <Box style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Box>
      </Box>
    </Box >

  );
};
export default MyInterest;
