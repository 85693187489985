const filesConfig = {
    IMAGE : {
        icon : ''
    },
    SHEET : {
        icon : <img className="fm_file_icon" src="/landowner/assets/images/filemanager/fileIcons/xls.svg" alt="sheet"/>
    },
    PDF : {
        icon : <img className="fm_file_icon" src="/landowner/assets/images/filemanager/fileIcons/pdf.svg" alt="pdf"/>
    },
    DOC : {
        icon : <img className="fm_file_icon" src="/landowner/assets/images/filemanager/fileIcons/doc.svg" alt="doc"/>
    },
    VIDEO : {
        icon : <img className="fm_file_icon" src="/landowner/assets/images/filemanager/fileIcons/mp4.svg" alt="video"/>
    },
}


export default filesConfig;