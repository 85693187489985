import * as React from "react";
import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import LinkHref from '@mui/material/Link'
import config from "../../config";
import { Link } from "react-router-dom";


// import "../style.css";
// Install Swiper modules

const Footer = (props) => {

    const handleLogoClick = () => {
        // Scroll the page to the top when the logo is clicked
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <>
            <Divider sx={{ background: 'var(--primary)', height: 6 }} />
            <Box className="hm_footer" sx={{ backgroundColor: "#1a1a1a", p: 2, pb: 10 }}>

                <Grid container maxWidth={"lg"} sx={{ m: "auto", pt: 6 }}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Box sx={{ textAlign: 'center' }}>

                            <Link to='/' onClick={handleLogoClick}>
                                <img
                                    className="responsive_img"
                                    alt="logo"
                                    src="/assets/images/homepage/farwide-logo-white-footer.svg"
                                    style={{ marginBottom: "42px" }}
                                />
                            </Link>

                            <Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
                                <ul className="hm_ftr_list_inline">
                                    <li>
                                        <LinkHref style={{ color: '#fff', textDecoration: 'none' }} href={window.location.origin + "/aboutus"} >About Us</LinkHref>
                                    </li>
                                    <li>
                                        <LinkHref style={{ color: '#fff', textDecoration: 'none' }} href={config.magazineURL} target="_blank">FarWide Magazine</LinkHref>
                                    </li>
                                    <li>
                                        <LinkHref style={{ color: '#fff', textDecoration: 'none' }} href="https://help.farwide.com/" target="_blank">Help</LinkHref>
                                    </li>
                                    <li>
                                        <LinkHref style={{ color: '#fff', textDecoration: 'none' }} href={window.location.origin + "/contactus"} >Contact Us</LinkHref>
                                    </li>
                                    <li>
                                        <LinkHref style={{ color: '#fff', textDecoration: 'none' }} href={config.privacyTermsLink} target="_blank">Terms and Conditions</LinkHref>
                                    </li>
                                    <li>
                                        <LinkHref style={{ color: '#fff', textDecoration: 'none' }} href={config.privacyTermsLink} target="_blank">Privacy Policy</LinkHref>
                                    </li>
                                </ul>
                            </Grid>



                            <LinkHref href="mailto:experience@farwide.com" style={{ color: '#fff', textDecoration: 'none', alignItems: 'center', display: 'inline-flex', }} target="_blank">
                                <Chip sx={{ background: '#fff', color: "primary", cursor: 'pointer' }} icon={<Icon.EmailOutlined color="#1b1b1b" sx={{ fontSize: '20px' }} />} label="experience@farwide.com" />
                            </LinkHref>

                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>  <Divider sx={{ background: '#343434', mt: 5, mb: 4 }} /></Grid>



                    <Grid item xs={12} sm={12} md={12}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box className="hm_ftr_payment">
                                    {/* <Typography variant="h4">Payment & Security</Typography> */}
                                    <img
                                        src="../assets/images/homepage/payment_logo_1.png"
                                        alt="payments"
                                        className="responsive_img"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} sx={{ textAlign: 'center' }}>
                                <Typography variant="caption" sx={{ color: '#747579' }}>Copyrights ©2024 <Link to="/" style={{ color: 'grey' }}>farwide.com</Link></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Box className="hm_ftr_social_wrapper" sx={{ justifyContent: 'flex-end', mb: 1 }}>
                                    <Typography variant="body1" sx={{ color: '#fff', mr: 0.5 }}>Follow us on </Typography>
                                    <ul className="hm_ftr_social">
                                        {/* <li>
                                <img
                                    src="../assets/images/homepage/fb.svg"
                                    alt="facebook"
                                />
                            </li>
                            <li>
                                <img
                                    src="../assets/images/homepage/tw.svg"
                                    alt="twitter"
                                />
                            </li> */}
                                        <li>
                                            <LinkHref href="https://www.instagram.com/farwide_outdoors/" target="_blank">
                                                <img
                                                    src="../assets/images/homepage/insta.svg"
                                                    alt="instagram"
                                                />
                                            </LinkHref>
                                        </li>
                                    </ul>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Box>
        </>
    );
};

export default Footer;
