// ** React Imports
import { useEffect, useState } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { empty, isDefined } from '../../../utils/common';

const BorderedCheckbox = ({ name, value, checked, register, handleSelect }) => {
	const [isChecked, setChecked] = useState(checked);
	let handleChange = (e) => {
		setChecked(e.target.checked)
		if (isDefined(handleSelect)) {
			let temp = {}
			temp[value] = e.target.checked
			handleSelect(temp)
		}
	}

	let style = {
		borderRadius: 1.5,
		border: "1px solid #28c76f",
		borderColor: "var(--grey700)",
		padding: "0 10px 0 0",
		marginBottom: 1.5,
		marginRight: 3,
		background: "#fff",
	}
	if (isChecked) {
		style.border = "1px solid #28c76f"
		style.borderColor = "#28c76f"
	}

	useEffect(()=>{
		setChecked(checked)
	}, [checked])

	return (
		<>
			{
				empty(register) ?
					<FormControlLabel
						name={name}
						value={value}
						label={value}
						slotProps={{ typography: { style: { color: isChecked ? '#28c76f' : 'var(--grey900)' } } }}
						sx={style}
						control={
							<Checkbox onBlurCapture={handleChange} onBlur={handleChange}  sx={{ color: 'var(--grey900)' }} checked={isChecked} onChange={handleChange} />}
					/>
					:
					<FormControlLabel
						name={name}
						
						value={value}
						label={value}
						sx={style}
						slotProps={{ typography: { style: { color: isChecked ? '#28c76f' : 'var(--grey900)' } } }}
						control={<Checkbox onBlurCapture={handleChange} onBlur={handleChange} sx={{ color: 'var(--grey900)' }} {...register(`${name}`, { required: true })} checked={isChecked} onChange={handleChange} />} />
			}
		</>
	)
}

export default BorderedCheckbox;