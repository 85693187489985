// import { useEffect, useRef } from 'react';

// const ApplyStyles = ({ htmlString }) => {
//   // Create a ref to hold the div element
//   const divRef = useRef(null);

//   // Use useEffect to update the div content when htmlString changes
//   useEffect(() => {
//     if (divRef.current) {
//       // Clear any existing content
//       divRef.current.innerHTML = '';

//       // Create a temporary container to parse the HTML string
//       const tempContainer = document.createElement('div');
//       tempContainer.innerHTML = htmlString;

//       // Move all child elements from the temporary container to the div
//       while (tempContainer.firstChild) {
//         divRef.current.appendChild(tempContainer.firstChild);
//       }
//     }
//   }, [htmlString]);

//   return <div className='applyStyles' ref={divRef}></div>;
// };

// export default ApplyStyles;

import React, { useEffect, useRef } from 'react';

const addHttpsToLinks = (htmlString) => {
  // Create a temporary container to parse the HTML string
  const tempContainer = document.createElement('div');
  tempContainer.innerHTML = htmlString;

  // Select all anchor elements in the container
  const links = tempContainer.querySelectorAll('a');

  // Modify the href attribute of each link
  links.forEach(link => {
    let href = link.getAttribute('href');
    
    // Extract the actual domain if it's wrapped by an intermediary link processor
    const urlPattern = /https?:\/\/([^\/\s]+\/)+([^\/\s]+)$/;
    const matches = href.match(urlPattern);
    
    if (matches) {
      href = matches[2];
    }
    
    if (!href.startsWith('http://') && !href.startsWith('https://')) {
      link.href = 'https://' + href;
    }
  });

  // Return the modified HTML string
  return tempContainer.innerHTML;
};



const ApplyStyles = ({ htmlString }) => {
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      // Clear any existing content
      divRef.current.innerHTML = '';

      // Modify the HTML string to add https:// to URLs
      const modifiedHtmlString = addHttpsToLinks(htmlString);

      // Create a temporary container to parse the modified HTML string
      const tempContainer = document.createElement('div');
      tempContainer.innerHTML = modifiedHtmlString;

      // Move all child elements from the temporary container to the div
      while (tempContainer.firstChild) {
        divRef.current.appendChild(tempContainer.firstChild);
      }
    }
  }, [htmlString]);

  return <div className='applyStyles' ref={divRef}></div>;
};

export default ApplyStyles;
