import { Grid, Skeleton } from "@mui/material"

const SkeletonPackageCard = () => {
    return (
        <Grid container spacing={2} sx={{mb : 4}}>
            <Grid item md={4} xs={12}>
                <Skeleton variant="rectangular" width="100%" sx={{borderRadius : "8px"}}>
                    <div style={{ paddingTop: '57%' }} />
                </Skeleton>
            </Grid>
            <Grid item md={8} xs={12}>
                <Skeleton variant="rectangular" width="100%" sx={{borderRadius : "8px"}}>
                    <div style={{ paddingTop: "10%",  }} />
                </Skeleton>
                <Skeleton variant="rectangular" width="100%" sx={{borderRadius : "8px", mt : 2}}>
                    <div style={{ paddingTop: "5%",  }} />
                </Skeleton>
                <Skeleton variant="rectangular" width="50%" sx={{borderRadius : "8px", mt : 2}}>
                    <div style={{ paddingTop: "10%",  }} />
                </Skeleton>
                
            </Grid>
        </Grid>
    )
}

export default SkeletonPackageCard