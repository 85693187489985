import { Avatar, Box, Typography} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { MessageContext } from "../../../store/context/messageContext";
import { extractDataFromArray } from "../../../utils/common";
import { useEffect } from "react";

const Profile = ({ openInfoDrawer, setOpenInfoDrawer }) => {
  const {
    isMobile,
    setIsMessageDashboard,
    setIsShowMessages,
    setIsProfile,
    setIsLoading,
    detail,
    userDetail
  } = MessageContext();


  return (
    <Box
      sx={
        isMobile
          ? {
            paddingInline: 2,
            backgroundColor: "#FFFFFF",
            height: "calc(100vh - 104px)",
            borderRadius: 2,
            border: "1px solid #E5E7EB"
          }
          : {
            width: 252,
            paddingInline: 2,
            backgroundColor: "#FFFFFF",
            height: "calc(100vh - 196px)",
            borderRadius: 2,
            border: "1px solid #E5E7EB"

          }
      }
    >
      {isMobile && (
        <Typography
        variant="body1"
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            gap: 1,
            fontWeight: 600,
            pt: 2
          }}
        >
          <Icon.ArrowBackOutlined
            onClick={() => {
              setIsMessageDashboard(false);
              setIsShowMessages(true);
              setIsProfile(false);
              setOpenInfoDrawer(!openInfoDrawer);
              setIsLoading(true);              
            }}
            sx={{
              color: "#747579",
              cursor: "pointer",
              width: 24,
              height: 24,
            }}
          />
          Profile
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 3,
          gap: 2,
        }}
      >
        <Avatar
          src ={userDetail.profile_pic}
          sx={{ width: 88, height: 88 }}
          alt=""
        />
        <Typography variant="body1" sx={{ fontWeight: "600" }}>
          {extractDataFromArray(detail,['fname'], '') + " " + extractDataFromArray(detail, ['lname'], '')}
        </Typography>
      </Box>

      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "8px",
          textAlign: "left",
          padding: "16px 0",
        }}
      >

        {detail?.email !== 0 && (

          <a style={{ display: "flex", justifyContent: "center", alignItems: "center", textDecoration: "none", height: "42px", width: "42px", border: "1px solid #747579", borderRadius: "50%" }} href={`mailto:${detail?.email}`}>

            <EmailOutlinedIcon sx={{ color: "#747579" }} />
          </a>

        )}
        {detail?.phone && (
          <a style={{ display: "flex", justifyContent: "center", alignItems: "center", textDecoration: "none", height: "42px", width: "42px", border: "1px solid #747579", borderRadius: "50%" }} href={`tel:${detail?.phone}`}>

            <LocalPhoneOutlinedIcon sx={{ color: "#747579" }} />
          </a>
        )}
      </Box> */}
    </Box>
  );
};

export default Profile;
