import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import mapboxglcircle from 'mapbox-gl-circle'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { Box, Divider, Typography } from "@mui/material";

mapboxgl.accessToken = 'pk.eyJ1IjoiZmFyd2lkZSIsImEiOiJjajloOHc4MGozNWFnMnFsc2FueXJvbnZ3In0.7Cek0jLTdXkikf2ioduEvg';


const Location = ({ lat, lon }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [lon, lat],
            zoom: 9,
            attributionControl: false,
            dragPan: false,
			doubleClickZoom : false,
            scrollZoom: false,
            touchZoomRotate:false,
            dragRotate:false
        });

        // map.current.scrollZoom.disable();
        map.current.on('load', function () {
            new mapboxglcircle({ lat: lat, lng: lon }, 10000, {
                // editable: true,
                minRadius: 1500,
                fillColor: '#29AB87'
            }).addTo(map.current);
        });

    }, [lat,lon]);
    return (
        <>
            <Typography variant="h4">
                Location
            </Typography>
            <Divider sx={{ mb: 3, mt: 2 }} />
            <Box ref={mapContainer} sx={{ height: '400px', borderRadius: 4 }} />
        </>
    )
}

export default Location;
