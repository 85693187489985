import * as React from "react";

import { Box, Grid, Typography } from "@mui/material";

const TitleSection = (props) => {
  return (
    <Grid item container className="mobmarginbottom" sx={{ mb: 10, mt: 22 }}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          variant="h3"
          className="about_title text-center"
          sx={{ mb: 2.5 }}
        >
          {" "}
          Outdoors With FarWide{" "}
        </Typography>
        <Typography className="about_discription text-center" sx={{ mb: 4 }}>
          {" "}
          FarWide hosts a wide variety of Private Land properties that can
          provide outdoor access in a most mesmerising way. Our states are full
          of many hidden places with great opportunities, that will blow your
          mind. We have put them all together to for you to just pick the right
          one and begin exploring.{" "}
        </Typography>
        {/* <Box sx={{ display: "flex", justifyContent: "center", gap: 5 }}>
          <Box
            className="about_discription"
            sx={{
              color: "#1B1B1B",
              fontWeight: 500,
              display: { sm: "flex", md: "flex" },
            }}
          >
            <img
              alt="farwide"
              src="../assets/images/happy_customers.svg"
              style={{ textAlign: { xs: "center" } }}
            />
            <Typography sx={{ ml: { sm: 2, md: 2 } }}>
              3K+ Happy Customers
            </Typography>
          </Box>
          <Box
            className="about_discription"
            sx={{
              color: "#1B1B1B",
              fontWeight: 500,
              display: { sm: "flex", md: "flex" },
            }}
          >
            <img alt="farwide" src="../assets/images/600properties.svg" />
            <Typography sx={{ ml: { sm: 2, md: 2 } }}>
              600+ Properties
            </Typography>
          </Box>
        </Box> */}
      </Grid>
    </Grid>
  );
};

export default TitleSection;
