import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardMedia, Checkbox, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Skeleton, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material'
import { empty, extractDataFromArray, formatBytes } from "../../../utils/common";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import filesConfig from "../fileConfig";
import FileManagerService from "../../../services/fileManager";
import UploadPopup from "./UploadPopup";
import { useLocation } from "react-router-dom"
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';


// eslint-disable-next-line no-unused-vars
const FolderListing = (props) => {
    const [selectedFolder, setSelectedFolder] = useState({})
    const folders = extractDataFromArray(props, ['folders'], [])

    const onSelect = (index, ele) => {
        let temp = {};
        temp[index] = ele.target.checked
        setSelectedFolder({ ...selectedFolder, ...temp })
    }
    return (
        <>
            <Grid item xs={12} sm={12} md={2}>
                <Typography><b>Folder</b> <span>(3)</span>  <IconButton >
                    <Icon.AddCircleOutlined color="primary" />
                </IconButton></Typography>
            </Grid>
            {/* folder */}
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    {
                        folders.map((folder, index) => {
                            let selected = extractDataFromArray(selectedFolder, [index], 0);
                            let size = formatBytes(extractDataFromArray(folder, ['size'], 0))
                            return (
                                <Grid item xs={6} sm={4} md={3} key={"folder_" + index}>
                                    <Card className={"card_folder " + (selected ? "selected" : '')} variant="outlined" sx={{ p: 2, display: 'flex', borderRadius: theme => `${theme.card.borderRadius} ` }}>
                                        <Box className="folder_icon" sx={{ mr: 2 }}>
                                            <Box className="folder_icon_size"><Icon.FolderOutlined fontSize="small" /></Box>
                                            <Box className="select_checkbox"><Checkbox onChange={(ele) => { onSelect(index, ele) }} /></Box>
                                        </Box>
                                        <Box className="folder_name">
                                            <Typography variant="body1" sx={{ fontWeight: '600' }}>{extractDataFromArray(folder, ['name'], '')}</Typography>
                                            <Typography variant="caption">{size} MB. {extractDataFromArray(folder, ['count'], 0)} items</Typography>
                                        </Box>
                                        <Box className="fm_more">
                                            <IconButton>
                                                <Icon.MoreVert />
                                            </IconButton>
                                        </Box>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </>
    )
}

const FilesListing = ({ fileType, files, selectedFiles, setSelectedFiles, handleDrawerOpen, currentPath }) => {
    // let { toggleDrawer} = FileManagerContext();
    const location = useLocation()

    const onSelect = (index) => {
        let temp = {};
        temp[index] = !extractDataFromArray(selectedFiles, [index], false)
        setSelectedFiles(currentPath ? { ...temp } : { ...selectedFiles, ...temp })
    }
    return (
        <>
            {/* <Grid item xs={12} sm={12} md={2} sx={{ mt: 3 }}>
                <Typography><b>Files</b> <span>(3)</span>  <IconButton >
                    <Icon.AddCircleOutlined color="primary" />
                </IconButton></Typography>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12}>

                <Grid container spacing={2}>
                    {
                        files.map((file, index) => {
                            // console.log(file.thumbnail_url)
                            let selected = extractDataFromArray(selectedFiles, [file.url], 0);
                            if (!(fileType === "ALL" || fileType === extractDataFromArray(file, ['type'], 'IMAGE'))) {
                                return '';
                            }
                            return (
                                <Grid item xs={6} sm={4} md={3} key={"files_" + index} onClick={(ele) => { onSelect(file.url, ele) }}>
                                    <Card className={"card_file " + (selected ? "selected" : '')} variant="outlined" sx={{ display: 'flex', flexDirection: 'column', borderRadius: theme => `${theme.card.borderRadius} ` }}>
                                        {file.file_type === "VIDEO" && <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '160px', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px 16px 0 0 !important' }}>
                                            <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                        </div>}
                                        <Box className="fm_file" sx={{ backgroundImage: (extractDataFromArray(file, ['file_type'], '') === "IMAGE" ? "url('" + extractDataFromArray(file, ['url'], '') + "')" : "url('" + extractDataFromArray(file, ['thumbnail_url'], '') + "')") }}>
                                            <Box className="select_checkbox" sx={{ zIndex: 2 }}><Checkbox checked={extractDataFromArray(selectedFiles, [file.url], false)} onChange={(ele) => onSelect(file.url, ele)} /></Box>
                                            <Box className="fm_more" sx={{ display: 'none' }}>
                                                <IconButton>
                                                    <MoreVertOutlinedIcon />
                                                </IconButton>
                                            </Box>
                                            {
                                                (extractDataFromArray(file, ['type'], 'IMAGE') !== "IMAGE" ?
                                                    extractDataFromArray(filesConfig, [extractDataFromArray(file, ['type'], 'IMAGE'), 'icon'], '') : "")
                                            }
                                        </Box>
                                        <Box className="fm_file_name" sx={{ p: 1, textAlign: 'center' }}>
                                            <Typography variant="caption">{extractDataFromArray(file, ['name'], '')}</Typography>
                                        </Box>
                                        <Box className="fm_more" sx={{ display: 'none' }}>
                                            <IconButton>
                                                <Icon.MoreVert />
                                            </IconButton>
                                        </Box>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>

            </Grid>
        </>
    )
}

const SeletedItemsAction = (props) => {
    return (
        <Box className="fm_action" sx={{ p: { xs: "16px 8px", sm: "16px 16px", md: "24px 24px " }, display: (empty(Object.keys(props.selectedFiles).filter(fileIndex => props.selectedFiles[fileIndex])) ? 'none' : 'flex'), justifyContent: 'space-between', flexWrap: "wrap" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox defaultChecked />{Object.keys(props.selectedFiles).filter(fileIndex => props.selectedFiles[fileIndex]).length} <Typography variant="body1" sx={{ ml: 0.5 }}> Selected</Typography>
            </Box>
            {
                props.enableSelectCalback ?
                    <Button variant="contained" sx={{ ml: 2, mr: 1 }} onClick={props.onSelect} startIcon={<Icon.Add />}>
                        {props.buttonText ? props.buttonText : 'Add Photos / Videos'}
                    </Button> : <></>
            }
        </Box>
    )
}

const FilterAction = ({ fileType, setFileType, setRefreshList, selectFilesOnUpload, files, loading }) => {
    const [popupOpen, setPopupOpen] = useState(false)

    const handleClickOpen = () => {
        setPopupOpen(true);
    };

    const handleClose = (value) => {
        setPopupOpen(false);
        setRefreshList(1)
    };

    useEffect(() => {
        if (empty(files) && !loading) {
            setPopupOpen(true);
        }
    }, [loading])
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, flexWrap: 'wrap' }}>
                    <Button variant="light" startIcon={<Icon.UploadFileOutlined />} onClick={handleClickOpen} sx={{ minWidth: '190px' }}>
                        Upload Media
                    </Button>
                    {/* <OutlinedInput
                        variant="outlined"
                        size="small"
                        placeholder="Search File"
                        id="input-with-icon-adornment"
                        endAdornment={
                            <InputAdornment position="end">
                                <Icon.SearchOutlined fontSize="small" />
                            </InputAdornment>
                        }
                        sx={{ maxWidth: "180px" }}
                    /> */}
                    <UploadPopup popupOpen={popupOpen} onClose={handleClose} type="file" selectedCallback={selectFilesOnUpload} />
                </Box>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={8} sx={{ justifyContent: 'end', display: 'flex' }}>
                <FormControl sx={{ minWidth: 90, mr: 2 }} style={{ marginBottom: 0 }} size="small">
                    <InputLabel id="filetype">Type</InputLabel>
                    <Select
                        labelId="filetype"
                        id=""
                        autoWidth
                        label="Type"
                        size="small"
                        value={fileType}
                        onChange={(ele) => { setFileType(ele.target.value) }}
                    >
                        <MenuItem value={"ALL"}>All</MenuItem>
                        <MenuItem value={"IMAGE"}>Images</MenuItem>
                        <MenuItem value={"PDF"}>PDF</MenuItem>
                        <MenuItem value={"DOC"}>Doc</MenuItem>
                        <MenuItem value={"SHEET"}>Sheet</MenuItem>
                        <MenuItem value={"AUDIO"}>Audio</MenuItem>
                        <MenuItem value={"VIDEO"}>Video</MenuItem>
                        <MenuItem value={"CSS"}>CSS</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 90, mr: 2 }} style={{ marginBottom: 0 }} size="small">
                    <InputLabel id="filetype">Sort</InputLabel>
                    <Select
                        labelId="filetype"
                        id="filetype_filter"
                        autoWidth
                        label="Sort"
                        size="small"
                        value="NewestFirst"
                    >
                        <MenuItem value="NewestFirst">Newest First</MenuItem>
                        <MenuItem value="OldestFirst">Oldest First</MenuItem>
                        <MenuItem value="A-Z">A - Z</MenuItem>
                        <MenuItem value="Z-A">Z - A</MenuItem>
                        <MenuItem value="SmallestFirst">Smallest First</MenuItem>
                    </Select>
                </FormControl>

                <IconButton sx={{ mr: 1 }}>
                    <Icon.GridViewOutlined />
                </IconButton>
                <IconButton >
                    <Icon.ListOutlined />
                </IconButton>
            </Grid> */}

        </Grid>
    )
}

const RightPane = (props) => {

    const location = useLocation()
    const profilePath = location.pathname === "/account"
    const [loading, setLoading] = useState(true)

    // for listing
    const [files, setFiles] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [folders, setFolders] = useState([])

    // for handling selection
    const [selectedFiles, setSelectedFiles] = useState({})

    // select action
    const onSelect = () => {
        let selected = []
        files.forEach((file, index) => {
            if (extractDataFromArray(selectedFiles, [file.url], false)) {
                selected.push(file)
            }
        })
        if (typeof props.selectedCallback === "function") {
            props.selectedCallback(selected)
        }
    }

    // file type selection 
    const [fileType, setFileType] = useState("ALL");
    // fetch folders and files
    useEffect(() => {

        async function fetchAllAssets() {
            await FileManagerService.fetchAllAssets(props.reqFileType || '', (data) => {
                // setFolders(extractDataFromArray(data, ['folders'], []));
                setFiles(data);
                // console.log("dataaaa", data)
                props.setRefreshList(0)
                setLoading(false)
            });
        }
        fetchAllAssets()
    }, [props.refreshList])

    const selectFilesOnUpload = (uploadedFiles) => {
        let temp = {}
        uploadedFiles.forEach(file => {
            temp[file.url] = true
        })
        setSelectedFiles({ ...selectedFiles, ...temp })
        props.setRefreshList(1)
    }



    return (
        <Card variant="outlined" className="test" sx={{ borderRadius: theme => `${theme.card.borderRadius} `, p: 0, minHeight: { lg: "calc(100vh - 120px)", md: "calc(100vh - 120px)", xs: "calc(100vh - 120px)", sm: "calc(100vh - 120px)" }, position: 'relative', maxHeight: { xs: "calc(100vh - 114px)", sm: "calc(100vh - 114px)" } }} >
            <CardContent sx={{ p: 0 }}>
                <Box className="fm_header" sx={{ p: 3, display: 'flex', gap: 3, alignItems: 'center' }}>
                    {/* Filter Bar */}

                    <FilterAction fileType={fileType} setFileType={setFileType} setRefreshList={props.setRefreshList} selectFilesOnUpload={selectFilesOnUpload} files={files} loading={loading} />
                    {/* Selected items actions */}

                </Box>
                <Divider sx={{ m: '0 !important' }} />
                <Box sx={{ p: 3, maxHeight: "calc(100vh - 215px)", overflow: 'auto', paddingBottom: "82px" }}>
                    <Grid container sx={{ mb: 3 }}>
                        {/* <FolderListing folders={folders} /> */}
                        {
                            loading ?
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Skeleton sx={{ width: "100%", height: 250 }} />
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Skeleton sx={{ width: "100%", height: 250 }} />
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Skeleton sx={{ width: "100%", height: 250 }} />
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <Skeleton sx={{ width: "100%", height: 250 }} />
                                    </Grid>
                                </Grid>
                                :
                                <FilesListing files={files} selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} fileType={fileType} handleDrawerOpen={props.handleDrawerOpen} currentPath={profilePath} loading={loading} />}
                    </Grid>
                </Box>
            </CardContent>
            <SeletedItemsAction buttonText={props.buttonText} selectedFiles={selectedFiles} onSelect={onSelect} enableSelectCalback={(typeof props.selectedCallback === "function" ? 1 : 0)} />
        </Card>
    )
}


export default RightPane;