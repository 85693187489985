import { Button, Grid, Typography, Box } from "@mui/material";
// material icons
import * as Icon from '@mui/icons-material';
import { Link } from "react-router-dom";
// import PackageCard from "../../components/PackageCard";
import ListingCard from "./ListingCard";


const Listings = () => {
    return (
        <Grid spacing={0}>
            <Grid item md={12} sm={12} xs={12}>
                <Grid display="flex" justifyContent="space-between" >
                    <Box >
                        <Typography variant='h3'>Listing</Typography>
                    </Box>
                    <Box>
                        <Link to="/createProperty">
                            <Button variant="outlined">
                                <Icon.AddOutlined fontSize="16" /> Create New
                            </Button>
                        </Link>
                    </Box>
                </Grid>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
                <ListingCard />
            </Grid>
        </Grid>
    )
}

export default Listings;