import * as React from "react";

import { Box, Button, Grid, Link, Typography } from "@mui/material";


const Support = (props) => {
    const subFooterData = [
        {
            icon: '/assets/images/homepage/help_24x7icon.svg',
            title: '24x7 Help',
            description: 'If we fall short of your expectation in any way, let us know.',
        },
        {
            icon: '/assets/images/homepage/payment_trust_icon.svg',
            title: 'Payment Trust',
            description: 'All refunds come with no questions asked guarantee.',
        },
        // Add more subFooter data items here...
    ];
    return (
        <>

            <Box className="hm_sub_ftr" sx={{  pt: { xs: 6, md: 8 }, pb: { xs: 3, md: 8 }, px: { xs: 2, md: 2, lg:0 }, background: "#F1F1F1"}}>
            <Box maxWidth={'lg'} sx={{ m: 'auto' }}>
                <Grid container sx={{ justifyContent: 'center' }} maxWidth={'lg'} columnSpacing={{ xs: 1, sm: 1, md: 3 }}>

                    {subFooterData.map((item, index) => (
                        <Grid key={index} item xs={12} sm={12} md={4} sx={{ mb: { sm: 1 } }}>
                            <Box spacing={2} className="sub_footer help_box">
                                <Box className="sub_footer_icon">
                                    <img src={item.icon} alt={item.title} />
                                </Box>
                                <Box>
                                    <Typography variant="body1" sx={{fontWeight:'bold', mb:1}}>{item.title}</Typography>
                                    <Typography variant="body1" sx={{color:'#747579'}}>{item.description}</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>

        </Box >
    
    </>
  );
};

export default Support;
