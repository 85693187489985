import { useEffect, useState } from "react"
import { Card, CardContent, CardHeader, Divider } from "@mui/material"
import { Box } from "@mui/system"
import PackageCard from "../../commonComponents/PackageCard"
import ListingService from "../../services/listing"
import { extractDataFromArray } from "../../utils/common"

const ListingCard = () => {
    const [listings, setListings] = useState([])
    useEffect(() => {
        async function getAllListings() {
            await ListingService.getAllListings((data) => {
                setListings(data);

            });
        }
        getAllListings()
    }, [setListings])
    return (
        <Card variant="outlined" sx={{ borderRadius: theme => `${theme.card.borderRadius} ` }}>
            <CardHeader title='My Listings' titleTypographyProps={{ variant: 'h6' }} />
            <Divider sx={{ m: '0 !important' }} />
            <CardContent>
                {
                    listings.map((list, index) => {
                       
                        return (
                            <Box sx={{ mb: 2 }} key={"listing_" + index}>
                                <PackageCard 
                                //  image={extractDataFromArray(list?.listing_media?.media_library, [0, 'url'], '')} before image carousel in package card
                                    image={extractDataFromArray(list?.listing_media?.media_library, '')}
                                    title={list.title}
                                    listing_seq={extractDataFromArray(list, ["listing_seq"], 0)}
                                    size={extractDataFromArray(list?.listing_json?.location_info, ['property_area'], '')}
                                    type={extractDataFromArray(list?.listing_json?.location_info, ['property_address'], '')}
                                    city={extractDataFromArray(list?.listing_json?.location_info, ['nearest_town'], '')}
                                    state={list.state}
                                    totalPackages={list.total_packages}
                                    days={list.days}
                                    price={list.starting_price}
                                    cardType="property"
                                />
                            </Box>
                        )
                    })
                }
            </CardContent>
        </Card>
    )
}
export default ListingCard
