import * as React from "react";

import { Grid, Typography } from "@mui/material";

const TitleSection = (props) => {
  return (
    <Grid item container className="mobmarginbottom" sx={{ mb: 10, mt: 10 }}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          variant="h3"
          className="about_title text-center"
          sx={{ mb: 2.5 }}
        >
          {" "}
          Safety & Insurance Program
        </Typography>
        <Typography className="about_discription text-center" sx={{ mb: 2 }}>
          {" "}
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s{" "}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TitleSection;
