import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios } from "../utils/common";



const UploadService = {
 
    uploadPhotos: async (file, action, folderSeq = 0, callback = () => null) => {
        let data = new FormData();
        data.append('file', file);
        data.append("params", JSON.stringify([["l", 1024, 1024, 93, 0], ["m", 512, 512, 93, 0], ["t", 112, 112, 93, 0]]));//
        data.append("cmd", "uploadAsset");
        data.append("action", action);
        data.append("folder_seq", folderSeq);
        data.append("replace", 0);

        await axios({
            url: config.apiUrl + "/services/api",
            method: "post",
            data: data
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            }
        });
    },
  
    checkIfFileExists: async (name, folderSeq = 0, callback = async () => null) => {
        await axios({
            method : "post",
            url : config.apiUrl + "/services/api", 
            headers : {"Content-Type": "application/x-www-form-urlencoded" },
            data: {
                cmd: "checkIfFileExists",
                name,
                folderSeq
            }
        }).then(async (response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                await callback(extractDataFromArray(response, ['data'], false))
            }
        });

    },

}

export default UploadService;