import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableNoData from "../../../commonComponents/placeholders/TableNoData";
import Pagination from "../../../commonComponents/tableListingComponents/Pagination";
import { empty, extractDataFromArray, getPlaceName } from "../../../utils/common";
import WishlistService from "../../../services/wishlist";
import PackageCard from "../../../commonComponents/PackageCard";
import SkeletonPackageCard from "../../../commonComponents/placeholders/SkeletonPackageCard";
import { CommonContext } from "../../../store/context/commonContext";
import TablePagination from '@mui/material/TablePagination';
import { toast } from "react-toastify";


const WishlistBody = ({ filter, type_seq, guest_seq }) => {
  const wishlistLabel = {
    L: "Property",
    P: "Package",
    E: "Experience"
  };

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  // console.log(page)
  const [wishlist, setWishlist] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filterConfig, setFilterConfig] = useState({ sort: "", kw: "" });
  const [confirmationPopup, setConfirmationPopup] = useState({ open: false });
  const [loading, setLoading] = useState(true);
  const [removeAllConfirmation, setRemoveAllConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isMobile, isTablet } = CommonContext()
  // Ensure endIndex doesn't exceed totalRecords
  // const type = filter === 0 ? "P" : "L";
  let type = ''
  let cardType = ''
  if (filter === 0) {
    type = 'P'
    cardType = 'package'
  }
  else if (filter === 1) {
    type = 'L'
    cardType = 'property'
  }
  else if (filter === 2) {
    type = 'E'
    cardType = 'experience'
  }
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // }; 

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  useEffect(() => {
    setPage(1)
    setFilterConfig({ sort: "", kw: "" })
  }, [filter])

  useEffect(() => {
    setLoading(true);
    WishlistService.getWishlist(
      type,
      type_seq,
      filterConfig.sort,
      searchQuery,
      ((page - 1) * rowsPerPage),
      rowsPerPage,
      (data) => {
        setWishlist(extractDataFromArray(data, ["records"], []));
        setTotalRecords(extractDataFromArray(data, ["count"], []));
        setLoading(false);
      }
    );
  }, [type, type_seq, filterConfig.sort, searchQuery, page, rowsPerPage]);

  const handleRemoveItem = (type, type_seq) => {
    // Remove the item from the wishlist
    WishlistService.removeFromWishlist(type, type_seq, (data, errorMsg) => {
      // On successful removal, update the local state and close the confirmation popup
      setWishlist((prevWishlist) => {
        // Filter out the removed item by its type_seq
        return prevWishlist.filter((item) => item.type_seq !== type_seq);
      });
      setConfirmationPopup({ open: false });
      setTotalRecords(totalRecords - 1)
      toast.success(errorMsg, {
        theme: "colored",
      })
    });
  };

  const openDeleteConfirmation = (wishlist, event) => {
    setConfirmationPopup({
      open: true,
      type_seq: type_seq,
      title: "Confirm the action",
      body: (
        <Typography>
          Are you sure you want to remove this {cardType} from your wishlist?
        </Typography>
      ),
      buttons: (
        <>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleRemoveItem(wishlist.type, wishlist.type_seq)}
          >
            REMOVE
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={() => handleCancel()}
          >
            CANCEL
          </Button>
        </>
      ),
    });
  };

  const handleCancel = () => {
    setConfirmationPopup({ ...confirmationPopup, open: false });
  };

  //popover related code for morevert icon on mobile screen
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getActivityType = (type) => {
    // console.log(type, 'type')
    switch (type) {
      case 'H':
        return "Hunting"
      case 'F':
        return "Fishing"
      case 'FR':
        return "Farm"
      case 'BW':
        return "Bird Watching"
      default:
        return ""
    }
  }

  useEffect(() => {
    // Reset parameters when the filter changes
    setPage(1); // Reset page to 0
    setSearchQuery(""); // Reset searchQuery to an empty string
    // Reset sort to "R"
  }, [filter]);

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(1); // Reset to page 1 when rows per page changes
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearch = (event) => {
    const searchText = event.target.value;
    setSearchQuery(searchText); // Update the searchQuery state with the input value
  };

  const handleRemoveAllConfirmation = () => {
    setRemoveAllConfirmation(true);
    handleClose()
  };


  const handleRemoveAll = () => {
    // Determine the type based on the filter prop
    let removeAllType = ''
    if (filter === 0)
      removeAllType = 'P'
    else if (filter === 1)
      removeAllType = 'L'
    else if (filter === 2)
      removeAllType = 'E'

    // Remove all items from the wishlist
    WishlistService.clearWishlist(removeAllType, () => {
      // On successful removal, update the local state
      setWishlist([]);
      setRemoveAllConfirmation(false);
      setTotalRecords(0)

    });
  };

  // console.log("wishlist", wishlist)

  return (
    <>
      {!isTablet ?
        <>
          {!(searchQuery === '' && empty(wishlist)) && <Box
            sx={{
              p: 3,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row"
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 3,
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Search"
                value={filterConfig.kw}
                size="small"
                onInput={(e) => {
                  setFilterConfig({ ...filterConfig, kw: e.target.value });
                }}
                sx={{
                  borderRadius: 1.5, paddingInline: 0,
                  width: "204px",
                  "& .MuiOutlinedInput-input": {
                    paddingInline: 0
                  },
                  "& .MuiOutlinedInput-root": {
                    height: 42,
                    pl: "11px"
                  }
                }}
                InputProps={{
                  startAdornment: <InputAdornment position='start'><Icon.SearchOutlined sx={{ ml: 0, color: "grey900" }} /></InputAdornment>
                }}
                onChange={handleSearch}
              />
              <Box sx={{ minWidth: "20ch", borderRadius: 1.5 }}>
                <FormControl fullWidth>
                  <InputLabel id="wishlist_sort_label" size="small">
                    Sort by
                  </InputLabel>
                  <Select
                    labelId="wishlist_sort_label"
                    id="wishlist_sort"
                    name="wishlist_sort"
                    value={filterConfig.sort}
                    onChange={(e) => {
                      setFilterConfig({ ...filterConfig, sort: e.target.value });
                    }}
                    size="small"
                    label="Sort by"
                  >
                    <MenuItem value={"R"}>Recent</MenuItem>
                    <MenuItem value={"P"}>Most Popular</MenuItem>
                    <MenuItem value={"L"}>Low to High</MenuItem>
                    <MenuItem value={"H"}>High to Low</MenuItem>
                    <MenuItem value={"N"}>Nearer</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Button
              variant="outlined"
              color="error"
              startIcon={<Icon.Delete />}
              onClick={handleRemoveAllConfirmation}
            >
              Remove All
            </Button>
          </Box>}
        </>
        :
        // Mobile part wishlistbody code
        <>
          {!(searchQuery === '' && empty(wishlist)) && <Box
            sx={{
              p: { xs: 2, sm: 3, md: 3, lg: 3 },
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: { xs: "space-between" },
                width: "100%"
              }}
            >
              <Box sx={{
                display: 'flex',
                "& .MuiInputBase-root": { pl: 0 }
              }}>
                <TextField
                  variant="outlined"
                  placeholder="Search"
                  value={filterConfig.search}
                  size="small"
                  onInput={(e) => {
                    setFilterConfig({ ...filterConfig, kw: e.target.value });
                  }}
                  sx={{
                    width: "130px", borderRadius: 1.5, pl: 0, '& .MuiInputBase-input': {
                      ml: '-12px'
                    },
                    // '& .MuiInputAdornment-root': { mr: 0 }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Icon.Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                />
                <Box sx={{ minWidth: "130px", borderRadius: 1.5, px: 1 }}>
                  <FormControl fullWidth>
                    <InputLabel id="wishlist_sort_label" size="small">
                      Sort by
                    </InputLabel>
                    <Select
                      labelId="wishlist_sort_label"
                      id="wishlist_sort"
                      name="wishlist_sort"
                      value={filterConfig.sort}
                      onChange={(e) => {
                        setFilterConfig({ ...filterConfig, sort: e.target.value });
                      }}
                      size="small"
                      label="Sort by"
                    >
                      <MenuItem value={"R"}>Recent</MenuItem>
                      <MenuItem value={"P"}>Most Popular</MenuItem>
                      <MenuItem value={"L"}>Low to High</MenuItem>
                      <MenuItem value={"H"}>High to Low</MenuItem>
                      <MenuItem value={"N"}>Nearer</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Button
                aria-describedby={id}
                variant="outlined"
                onClick={handleClick}
                sx={{
                  padding: 0,
                  height: '42px',
                  minWidth: 'fit-content',
                  border: 'none',
                  color: 'var(--grey900)',
                  '&:hover': {
                    border: 'none',
                  }
                }}
              >
                <MoreVertIcon />
              </Button>

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{
                  '& .MuiPopover-paper': {
                    borderRadius: 2,
                  },
                }}
              >
                <List sx={{ p: 0 }}>
                  <ListItem
                    onClick={handleRemoveAllConfirmation}
                    sx={{
                      borderRadius: '4px',
                      "& .MuiTypography-root": { color: '#d32f2f' },
                      '&:hover': {
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <ListItemIcon sx={{ color: '#d32f2f' }}>
                      <Icon.Delete />
                    </ListItemIcon>
                    <ListItemText primary="Remove All" />
                  </ListItem>
                </List>
              </Popover>

            </Box>
            {/* <Button
          variant="outlined"
          color="error"
          sx={{
            mt: { xs: 2, md: 0 },
          }}
          startIcon={<Icon.Delete />}
          onClick={handleRemoveAllConfirmation}
        >
          Remove All
        </Button> */}
          </Box>}
        </>
      }
      <Divider />

      {
        loading && searchQuery === "" ?
          <>
            <Box p={3}>
              <SkeletonPackageCard />
              <SkeletonPackageCard />
              <SkeletonPackageCard />
            </Box>
          </>
          :
          <>

            {
              empty(wishlist) ?
                <>
                  {searchQuery === '' ? <Box sx={{ p: 3 }}>
                    <TableNoData
                      header="Saving Listings to Wishlist makes it easier to find them later."
                      title="Tap the heart to start saving your favorites."
                      buttonLink="/search"
                      buttonText="Show Me the Listings"
                      image="/assets/images/placeholders/wishlist_blank_image.svg"
                    />
                  </Box>
                    :
                    <Box variant="body1" sx={{ minHeight: "250px", textAlign: 'center', paddingTop: "100px", color: 'var(--grey900)', fontWeight: 700 }}>No results found</Box>
                  }
                </>
                :
                <Box sx={{ py: 1.5 }}>
                  {
                    wishlist !== null && wishlist.length > 0 ?
                      wishlist.map((wishlistItem, index) => {
                        if (type === 'P' || type === 'L') {
                          let address = filter === 1 ? `${extractDataFromArray(
                            wishlistItem,
                            ["listing_json", "location_info", "property_area"],
                            ""
                          )} Acres, ${getActivityType(extractDataFromArray(
                            wishlistItem,
                            ["package_json", "activity_type"],
                            ""
                          ))} Near ${extractDataFromArray(
                            wishlistItem,
                            ["listing_json", "location_info", "nearest_town"],
                            ""
                          )} ` : `${extractDataFromArray(
                            wishlistItem,
                            ["listing_json", "location_info", "property_area"],
                            ""
                          )} Acres, ${getActivityType(extractDataFromArray(
                            wishlistItem,
                            ["package_json", "activity_type"],
                            ""
                          ))} at `

                          let propertyName = filter === 0 ? extractDataFromArray(wishlistItem, ['listing_json', 'property_details', 'name'], '') : null

                          let address2 = filter === 0 ? ` Near ${extractDataFromArray(
                            wishlistItem,
                            ["listing_json", "location_info", "nearest_town"],
                            ""
                          )}` : null

                          let reviewCount = wishlistItem.review_count
                          let averageRating = wishlistItem.average_rating
                          let linkRoute = type === 'P' ? '/package/' : '/property/'
                          // let link = linkRoute + 
                          return (<Box sx={{ px: { xs: 2, sm: 3, md: 3, lg: 3 }, py: { xs: 1, sm: 1.5, md: 1.5 } }} key={"wishlist" + index}>
                            <PackageCard
                              key={"wishlist" + index}
                              title={extractDataFromArray(wishlistItem, ["title"], "")}
                              wishlistType={extractDataFromArray(
                                wishlistLabel,
                                [extractDataFromArray(wishlistItem, ["type"], "L")],
                                ""
                              )}
                              address={address}
                              address2={address2}
                              propertyName={propertyName}
                              listing_seq={extractDataFromArray(wishlistItem, ['listing_seq'], '')}
                              listingSlug={extractDataFromArray(wishlistItem, ['listing_json', 'property_details', 'slug'], '')}
                              size={extractDataFromArray(
                                wishlistItem,
                                ["listing_json", "location_info", "property_area"],
                                ""
                              )}
                              activity={extractDataFromArray(
                                wishlistItem,
                                ["package_json", "activity_type"],
                                ""
                              )}
                              guest={extractDataFromArray(
                                wishlistItem,
                                ["package_json", "min_guests"],
                                ""
                              )}
                              days={extractDataFromArray(wishlistItem, ["package_json", "min_days"], "")}
                              price={extractDataFromArray(wishlistItem, ["starting_price"], "")}
                              image={
                                extractDataFromArray(wishlistItem, ["type"], "P") === "P" ?
                                  extractDataFromArray(wishlistItem, ["package_json", "media_library"], '')
                                  :
                                  extractDataFromArray(wishlistItem, ["listing_json", "media_library"], '')
                              }
                              wishlist={false}
                              isWishlist={true}
                              slug={extractDataFromArray(wishlistItem, ["slug"], "")}
                              package_seq={extractDataFromArray(wishlistItem, ['package_seq'], 0)}
                              lodging={extractDataFromArray(wishlistItem, ['lodging_available'], '')}
                              rv={extractDataFromArray(wishlistItem, ['rv_available'], '')}
                              package_slug={extractDataFromArray(
                                wishlistItem,
                                ["package_slug"],
                                ""
                              )}
                              viewTarget="_blank"
                              hideOptions={true}
                              type_seq={extractDataFromArray(wishlistItem, ["type_seq"], "")}
                              guest_seq={extractDataFromArray(wishlistItem, ["guest_seq"], "")}
                              type={extractDataFromArray(wishlistItem, ["type"], "")}
                              openDeleteConfirmation={() => openDeleteConfirmation(wishlistItem)}
                              reviewCount={reviewCount}
                              averageRating={averageRating}
                              link={linkRoute + extractDataFromArray(wishlistItem, ["slug"], "") + "-" + extractDataFromArray(wishlistItem, ["type_seq"], "")}
                            />
                          </Box>)
                        }

                        if (type === 'E') {
                          const data = wishlistItem.experience_data
                          let photoList = []
                          let title = ''
                          let address = ''
                          let lat = 0
                          let lon = 0
                          let slug = ''
                          let starting_price = 'N/A'
                          let durationObj = {}
                          let experienceType = ''
                          let availability_id = ''
                          let link = ''
                          let reviewCount = wishlistItem.review_count
                          let averageRating = wishlistItem.average_rating

                          for (let key in data) {
                            if (data[key].type === 'photos') {
                              photoList = data[key].photo_list;
                            }
                            else if (data[key].type === 'general_info') {
                              title = data[key].title;
                              experienceType = data[key].experience_type
                              slug = data[key].slug
                              link = `/experience/${slug}-${wishlistItem.type_seq}`
                            }
                            else if (data[key].type === 'location') {
                              address = data[key].address;
                              address = getPlaceName(address)
                              if (address === 'Don’t see your address? Add GPS coordinates.') {
                                address = data[key].city
                                address = getPlaceName(address)
                              }
                              lat = data[key].lat;
                              lon = data[key].lon;
                            }
                            else if (data[key].type === 'pricing') {
                              durationObj = data[key].package_duration
                              const slotList = data[key].slot_list;

                              if (slotList.length === 0) {
                                starting_price = 'N/A';
                              } else {
                                starting_price = Math.min(...slotList.map(slot => parseInt(slot.price, 10)));
                              }
                            }
                            else if (data[key].type === 'availability') {
                              availability_id = data[key].id
                            }
                          }

                          return (
                            <Box sx={{ px: { xs: 2, sm: 3, md: 3, lg: 3 }, py: { xs: 1, sm: 1.5, md: 1.5 } }} key={"wishlist" + index}>
                              <PackageCard
                                key={"wishlist" + index}
                                title={title}
                                wishlistType={'experience'}
                                address={address}
                                // address2={address2}
                                // propertyName={propertyName}
                                listing_seq={wishlist.experience_seq}
                                listingSlug={slug}
                                size={extractDataFromArray(
                                  wishlistItem,
                                  ["listing_json", "location_info", "property_area"],
                                  ""
                                )}
                                durationObj={durationObj}
                                activity={extractDataFromArray(
                                  wishlistItem,
                                  ["package_json", "activity_type"],
                                  ""
                                )}
                                experienceType={experienceType}
                                guest={extractDataFromArray(
                                  wishlistItem,
                                  ["package_json", "min_guests"],
                                  ""
                                )}
                                days={extractDataFromArray(wishlistItem, ["package_json", "min_days"], "")}
                                price={starting_price}
                                image={photoList}
                                wishlist={false}
                                isWishlist={true}
                                slug={slug}
                                package_seq={extractDataFromArray(wishlistItem, ['package_seq'], 0)}
                                lodging={extractDataFromArray(wishlistItem, ['lodging_available'], '')}
                                rv={extractDataFromArray(wishlistItem, ['rv_available'], '')}
                                package_slug={extractDataFromArray(
                                  wishlistItem,
                                  ["package_slug"],
                                  ""
                                )}
                                viewTarget="_blank"
                                hideOptions={true}
                                type_seq={extractDataFromArray(wishlistItem, ["type_seq"], "")}
                                guest_seq={extractDataFromArray(wishlistItem, ["guest_seq"], "")}
                                type={extractDataFromArray(wishlistItem, ["type"], "")}
                                openDeleteConfirmation={() => openDeleteConfirmation(wishlistItem)}
                                cardType={'experience'}
                                reviewCount={reviewCount}
                                averageRating={averageRating}
                                link={link}
                              />
                            </Box>
                          )
                        }
                      }
                      )
                      :
                      <Box variant="body1" sx={{ minHeight: "250px", textAlign: 'center', paddingTop: "100px", color: 'var(--grey900)', fontWeight: 700 }}>No results found</Box>
                  }
                </Box>
            }
          </>
      }

      {/* <TablePagination
        component="div"
        sx={{ mr: 3 }}
        count={totalRecords}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}

      {totalRecords != 0 && <Pagination
        totalRecords={totalRecords}
        setRowsPerPage={handleRowsPerPageChange}
        setCurrentPage={handlePageChange}
        currentPage={page}
      />}

      <Dialog open={confirmationPopup.open}>
        <Box>
          <Box sx={{ p: 2.5 }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              {confirmationPopup.title}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ p: 2.5 }}>{confirmationPopup.body}</Box>
          <Box
            sx={{ display: "flex", justifyContent: "end", gap: 1, p: 2.5, width: "100%" }}
          >
            {confirmationPopup.buttons}
          </Box>
        </Box>
      </Dialog>
      <Dialog open={removeAllConfirmation}>
        <Box>
          <Box sx={{ p: 2.5 }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              Confirm Removal
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ p: 2.5 }}>
            <Typography>
              Are you sure you want to remove all items from your wishlist?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: 1,
              p: 2.5,
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleRemoveAll}
            >
              REMOVE ALL
            </Button>
            <Button
              variant="text"
              color="secondary"
              onClick={() => setRemoveAllConfirmation(false)}
            >
              CANCEL
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default WishlistBody;