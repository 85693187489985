import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ROUTE_LISTER } from "../../constants/page";
import AdminListingPopup from "../AdminLisitingPopup";
import { useState } from "react";

const getStatusChip = (status) => {
    switch (status) {
        case 'P':
            return <Chip variant="filled" color="secondary" label="Pending" />
        case 'V':
            return <Chip variant="filled" color="primary" label="Approved" />
        case 'D':
            return <Chip variant="filled" color="error" label="Declined" />
        case 'S':
            return <Chip variant="filled" color="warning" label="Spam" />
        default:
            break
    }
}
const AdminBanner = ({ lister_pic, lister_seq, lister_name, status, listing_seq, listing_type }) => {
    const [popupConfig, setPopupConfig] = useState({ open: false, type: '' })

    const openPopup = (type) => {
        setPopupConfig({ open: true, type: type })
    }
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 3, backgroundColor: '#E0F7EC', borderRadius: 4, flexWrap: 'wrap', mb: 3 }} >
                {/* <Link to={ROUTE_LISTER + "/" + lister_seq}> */}
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Avatar src={lister_pic} />
                    <Typography>{lister_name.trim()}</Typography>
                </Box>
                {/* </Link> */}
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                    {
                        status === 'P' ?
                            <>
                                <Button variant="outlined" size="small" color="secondary" startIcon={<Icon.BlockOutlined />} sx={{ backgroundColor: '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={() => { openPopup("spam") }}>{status !== "S" ? "Spam" : "Not Spam"}</Button>

                                <Button variant="contained" size="small" color="error" startIcon={<Icon.CloseOutlined />} onClick={() => { openPopup("decline") }}>{status !== "D" ? "Decline" : "Un Decline"}</Button>

                                <Button variant="contained" size="small" color="primary" startIcon={<Icon.Done />} onClick={() => { openPopup("approve") }}>{status !== "V" ? "Approve" : "Un Approve"}</Button>
                            </>
                            :
                            <Button variant="contained" size="small" color="secondary" startIcon={<Icon.AccessTimeOutlined />} onClick={() => { openPopup("pending") }}>Move to Pending</Button>
                    }
                </Box>
            </Box>

            {
                getStatusChip(status)
            }
            <AdminListingPopup open={popupConfig.open} handleClose={() => { setPopupConfig({ open: false, type: '' }) }} type={popupConfig.type} listing_seq={listing_seq} listing_type={listing_type} />
        </>
    )
}


export default AdminBanner;