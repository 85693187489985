import * as React from "react";
import { Box, Grid } from "@mui/material";

import TitleSection from "../safety/section/TitleSection";
import Safety from "../safety/section/Safety";
import Insurance from "../safety/section/Insurance";
import Requirement from "../safety/section/Requirement";



import "./style.css";
import Footer from "../../layouts/LandingPageLayout/Footer";
import LandingPageHeader from "../../layouts/LandingPageLayout/Header";

const AboutUs = () => {
	return (
		<>
			<Box className="home_wrapper" sx={{ p: 2 }}>
				<Grid
					container
					maxWidth="lg"
					sx={{ m: "auto" }}
					direction="row"
					justifyContent="center"
				>
					{/* header nav */}
					<LandingPageHeader />

					{/* header */}

					<TitleSection />
					<Safety />
					<Insurance />
					<Requirement />
				</Grid>
			</Box>

			<Footer />
		</>
	);
};

export default AboutUs;
