import { Box, Divider, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import { empty, extractDataFromArray, isValueEqual } from "../../utils/common";
import { styled } from '@mui/material/styles';
import CustomTooltip from "../CustomTooltip";


const ColumnBox = styled(Box)(
    ({ theme }) => `
        display: flex;
        gap: 0.5rem;
        flex: 50%;
        ${theme.breakpoints.down("sm")} {
            flex: 100%;
          }
        width: 100%;
        margin-bottom: 0.5rem;
  `,
);


const AddOns = ({ title, icon, value, tooltip, titleDelimiter = ":" }) => {

    return (
        <>
            <ColumnBox sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }} >
                <Icon.ArrowForward />
                <Typography color="grey"> {title}{titleDelimiter} </Typography>
                <Typography color="grey"> {value}</Typography>
                {
                    !empty(tooltip) && <CustomTooltip content={tooltip} />
                }

            </ColumnBox>

        </>
    )
}
const FarmRanchDetails = ({ packageData, activityData }) => {
    const isAmentitiesAvailable = isValueEqual(extractDataFromArray(activityData, ['amenities', 'atv', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['amenities', 'catering', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['amenities', 'swimming', 'available'], 'No'), 'Yes')

    const isActiveFarmAvailable = isValueEqual(extractDataFromArray(activityData, ['active_farm', 'available'], 'No'), 'Yes')

    return (
        <>
            <Typography variant="h4">
                Farm Ranch Details
            </Typography>
            <Divider sx={{ mb: 3, mt: 2 }} />
            {
                isAmentitiesAvailable &&

                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>Amenities</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            isValueEqual(extractDataFromArray(activityData, ['amenities', 'catering', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Catering"}
                                titleDelimiter=""
                                tooltip={extractDataFromArray(activityData, ['amenities', 'catering', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['amenities', 'swimming', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Swimming"}
                                titleDelimiter=""
                                tooltip={extractDataFromArray(activityData, ['amenities', 'swimming', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['amenities', 'atv', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"ATV"}
                                titleDelimiter=""
                                tooltip={extractDataFromArray(activityData, ['amenities', 'atv', 'additional_info'], '')}
                            />
                        }
                    </Box>
                </Box>
            }
            <Box sx={{ mb: 1 }}>
                <Typography sx={{ fontWeight: 700, mb: 1 }}>Experience</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {
                        <AddOns
                            title={"Interactive"}
                            value={extractDataFromArray(packageData, ['interactive', 'level'], '')}
                            tooltip={extractDataFromArray(packageData, ['interactive', 'additional_info'], '')}
                        />
                    }
                    {
                        <AddOns
                            title={"Kids Friendly"}
                            value={extractDataFromArray(packageData, ['kid_friendly', 'available'], '')}
                            tooltip={extractDataFromArray(packageData, ['kid_friendly', 'additional_info'], '')}
                        />
                    }
                    
                </Box>
            </Box>
            {
                isActiveFarmAvailable &&
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }} >
                    <Typography sx={{ fontWeight: 700 }}> {"Active Farm:"} </Typography>
                    <Typography> Available</Typography>
                    {
                        !empty(extractDataFromArray(activityData, ['active_farm', 'additional_info'], '')) && <CustomTooltip content={extractDataFromArray(activityData, ['active_farm', 'additional_info'], '')} />
                    }
                </Box>
            }

        </>
    )
}

export default FarmRanchDetails;
