import { useLocation } from "react-router";
import PackageCheckout from "./packageCheckout";
import ExpCheckout from "./expCheckout";

const Checkout = () => {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const type = queryParams.get('type')

    if (type === 'exp') {
        return <ExpCheckout />
    }
    return (
        <PackageCheckout />
    )

}

export default Checkout;