import { Box } from "@mui/material"
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';

const MediaCard = ({ imageObj, height, width, borderRadius }) => {
    if (!imageObj) {
        return null
    }

    if (imageObj.type === 'VIDEO') {
        return (
            <div className='' style={{ position: 'relative', display: 'flex' }}>
                <div className='' style={{ position: 'absolute', top: 0, left: 0, width: width, height: height, backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: borderRadius }}>
                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                </div>
                <Box sx={{
                    width: width,
                    height: height,
                    borderRadius: borderRadius,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    backgroundImage: "url('" + imageObj.thumbnail_url + "')"
                }} />
            </div>
        )
    }

    return (
        // Wrapped in div to fix image width issue in smallers screens
        <div className='' style={{ position: 'relative', display: 'flex' }}>
            <Box
                sx={{
                    width: width,
                    height: height,
                    borderRadius: borderRadius,
                    backgroundImage: "url('" +
                        imageObj.url
                        + "')",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                }} />
        </div>
    )
}

export default MediaCard