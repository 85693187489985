import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import ExperienceService from "../../services/experience";
import PackageExperience from "../../commonComponents/Experience/packageExperience"
import { Box } from "@mui/material";
import SkeletonPropertyPreview from "../../commonComponents/placeholders/SkeletonPropertyPreview";
import { RESPONSIVE_SPACING } from "../../commonComponents/CommonConstants/commonKeys";

const ExperienceView = () => {
    const [experienceData, setExperienceData] = useState(null)
    const navigate = useNavigate()

    const { exp_seq } = useParams()
    let expereinceSeq = exp_seq.split("-")
    expereinceSeq = expereinceSeq[expereinceSeq.length - 1]

    useEffect(() => {
        ExperienceService.getExperiencePackageData(expereinceSeq, (data) => {
            // console.log('ExpereinceCompleteData', data);
            setExperienceData(data)
        }, (error) => {
            navigate('/404')
        })
    }, [expereinceSeq])

    if (experienceData === null) {
        return (
            <Box sx={{
                p: 5,
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Box sx={{ maxWidth: 'lg', width: '100%' }}>
                    <SkeletonPropertyPreview />
                </Box>
            </Box>
        )
    }

    return (
        <Box sx={{
            mb: 10,
            pt: RESPONSIVE_SPACING
        }}>
            <PackageExperience
                showPackageFullSize={false}
                isMobileButtonClicked={false}
                isDesktop={true}
                isDesktopButtonClicked={false}
                completeExperienceData={experienceData}
                isPreview={false}
            />
        </Box>
    )
}

export default ExperienceView