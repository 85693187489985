import { Box, Divider, Typography } from "@mui/material"
import ApplyStyles from "../../previewPageComponents/htmlParser/ApplyStyles"
import { extractDataFromArray } from "../../../utils/common"
import ExpereinceOverviewImages from "./expOverviewImages"
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys"

const Faqs = ({ formData, isMobileButtonClicked = false }) => {
    if (extractDataFromArray(formData, ['display'], 0) == 0 || extractDataFromArray(formData, ['faq_list'], []).length === 0) {
        return null
    }
    const faqList = extractDataFromArray(formData, ['faq_list'], [])
    if (faqList.length === 1 && (faqList[0]?.query === '' || faqList[0]?.response === '')) {
        return null
    }
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }}>
                <Typography variant="h5" sx={{ fontWeight: '600', fontSize: isMobileButtonClicked && '1.25rem' }}>
                    {formData.title}
                </Typography>
                <Box sx={{ display: "flex", gap: 2, flexDirection: 'column' }}>
                    {faqList.map((faqs, index) => {
                        return (
                            <Box sx={{ display: "flex", gap: 1, flexDirection: 'column' }}>
                                <Typography variant="subtitle" sx={{ fontWeight: '600' }}>
                                    {extractDataFromArray(faqs, ['query'], '')}
                                </Typography>
                                {
                                    !(extractDataFromArray(faqs, ['response'], '') === '' ||
                                        extractDataFromArray(faqs, ['response'], '') === '<p><br></p>') &&
                                    <Box sx={{
                                        "& .applyStyles p": {
                                            m: 0
                                        }
                                    }}>
                                        <ApplyStyles htmlString={extractDataFromArray(faqs, ['response'], '')} />
                                    </Box>}
                                {extractDataFromArray(faqs, ['photo_list'], []).length > 0 && <ExpereinceOverviewImages images={extractDataFromArray(faqs, ['photo_list'], [])} />}
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            <Divider sx={{ my: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }} />
        </>
    )
}

export default Faqs
