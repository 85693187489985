import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import Moment from 'moment';
import * as Icon from "@mui/icons-material";
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter, extractDataFromArray } from '../../../../utils/common';
import config from '../../../../config';


const renderBookingRequest = (message, onchat2, isMobile, packageType) => {
    const days = extractDataFromArray(message, ['other_info', 'booking_record', 'total_days_booked'], '')
    const guests = extractDataFromArray(message, ['other_info', 'booking_record', 'total_guests_booked'], '')
    const checkInDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_in_date'], '');
    const checkOutDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_out_date'], '');

    const isSameDay = Moment(checkInDate, "YYYY-MM-DD").isSame(checkOutDate, "day");
    const isSameMonth = Moment(checkInDate, "YYYY-MM").isSame(checkOutDate, "month");

    const bookingDataText = isSameDay ?
        <>
            {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
            {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
        </>
        : isSameMonth ?
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>
            :
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>;

    return (
        <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'} style={{ width: "100%" }}>
            <Box sx={{ backgroundColor: onchat2 === true ? "#E0F7EC" : "#F5F5F6", padding: 2, borderRadius: 2, width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Chip

                        variant="Filled"
                        label="Booking Request"
                        sx={{
                            fontSize: "12px",
                            backgroundColor: "#1B1B1B",
                            borderRadius: 4,
                            width: "fit-content",
                            height: 20,
                            color: "#FFFFFF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                    />
                    <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: 4, width: "100%", pt: 1.25, pl: 2, pr: 2, pb: 2 }}>
                        {
                            packageType &&
                            <Chip variant="outlined" size="small" label={capitalizeFirstLetter(packageType)} sx={{ borderColor: '#979797', color: '#747579' }} />
                        }
                        <Typography variant="subtitle1" fontWeight="500">{extractDataFromArray(message, ['other_info', 'booking_record', 'package_title'], '')}</Typography>
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="subtitle1" color="#85878A">{bookingDataText}</Typography>
                        </Box>
                        <Typography variant="subtitle1" color="#85878A">{"$" + extractDataFromArray(message, ['other_info', 'booking_record', 'booking_amount'], '')
                        }</Typography>
                        <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'}>
                            <Typography variant='body1' style={{ color: "#26C77A", borderBottom: "1px solid #26C77A", width: "fit-content" }}>View Booking</Typography>
                        </Link>
                    </Box>
                    <Typography variant='subtitle1' sx={{

                        color: "#85878A",

                    }}>{extractDataFromArray(message, ['messages', 'message'], '')}</Typography>
                </Box>


            </Box>
        </Link>
    )
};



const renderBookingCompleted = (message, onchat2, isMobile, packageType) => {
    const days = extractDataFromArray(message, ['other_info', 'booking_record', 'total_days_booked'], '')
    const guests = extractDataFromArray(message, ['other_info', 'booking_record', 'total_guests_booked'], '')

    const checkInDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_in_date'], '');
    const checkOutDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_out_date'], '');

    const isSameDay = Moment(checkInDate, "YYYY-MM-DD").isSame(checkOutDate, "day");
    const isSameMonth = Moment(checkInDate, "YYYY-MM").isSame(checkOutDate, "month");

    const bookingDataText = isSameDay ?
        <>
            {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
            {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
        </>
        : isSameMonth ?
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>
            :
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>;

    return (
        <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'} style={{ width: "100%" }}>
            <Box sx={{ backgroundColor: onchat2 === true ? "#E0F7EC" : "#F5F5F6", padding: 2, borderRadius: 2, width: "100%", cursor: "pointer" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Chip

                        variant="outlined"
                        label="Trip Completed"
                        sx={{
                            fontSize: "12px",
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #26C77A",
                            borderRadius: 4,
                            width: "fit-content",
                            height: 20,
                            color: "#26C77A",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                    />
                    <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: 4, width: "100%", pt: 1.25, pl: 2, pr: 2, pb: 2 }}>
                        {
                            packageType &&
                            <Chip variant="outlined" size="small" label={capitalizeFirstLetter(packageType)} sx={{ borderColor: '#979797', color: '#747579' }} />
                        }
                        <Typography variant="subtitle1" fontWeight="500">{extractDataFromArray(message, ['other_info', 'booking_record', 'package_title'], '')}</Typography>
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="subtitle1" color="#85878A">{bookingDataText}</Typography>
                        </Box>
                        <Typography variant="subtitle1" color="#85878A">{"$" + extractDataFromArray(message, ['other_info', 'booking_record', 'booking_amount'], '')
                        }</Typography>
                        {
                            config.module === 'guest' &&
                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                                <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'}>
                                    <Typography variant='body1' style={{ color: "#26C77A", borderBottom: "1px solid #26C77A", width: "fit-content" }}>Booking Details</Typography>
                                </Link>
                                <Link to={window.location.origin + `/${packageType === 'experience' ? 'experiencereview' : 'review'}?bookingId=${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'}>                        <Typography variant='body1' style={{ color: "#26C77A", borderBottom: "1px solid #26C77A", width: "fit-content" }}>Write Review</Typography>
                                </Link>
                            </Box>

                        }
                        {
                            config.module === 'lister' && <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'}>
                                <Typography variant='body1' style={{ color: "#26C77A", borderBottom: "1px solid #26C77A", width: "fit-content" }}>Booking Details</Typography>
                            </Link>
                        }
                    </Box>
                    <Typography variant='subtitle1' sx={{

                        color: "#85878A",

                    }}>{extractDataFromArray(message, ['messages', 'message'], '')}</Typography>
                </Box>


            </Box>
        </Link>
    )
};


const renderBookingDeclined = (message, onchat2, isMobile, packageType) => {
    const days = extractDataFromArray(message, ['other_info', 'booking_record', 'total_days_booked'], '')
    const guests = extractDataFromArray(message, ['other_info', 'booking_record', 'total_guests_booked'], '')
    const checkInDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_in_date'], '');
    const checkOutDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_out_date'], '');

    const isSameDay = Moment(checkInDate, "YYYY-MM-DD").isSame(checkOutDate, "day");
    const isSameMonth = Moment(checkInDate, "YYYY-MM").isSame(checkOutDate, "month");

    const bookingDataText = isSameDay ?
        <>
            {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
            {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
        </>
        : isSameMonth ?
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>
            :
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>;


    return (
        <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'} style={{ width: "100%" }}>
            <Box sx={{ backgroundColor: onchat2 === true ? "#E0F7EC" : "#F5F5F6", padding: 2, borderRadius: 2, width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Chip

                        variant="Filled"
                        label="Booking Declined"
                        sx={{
                            fontSize: "12px",
                            backgroundColor: "#fff",
                            border: "1px solid #E94A4A",
                            borderRadius: 4,
                            width: "fit-content",
                            height: 20,
                            color: "#E94A4A",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                    />
                    <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: 4, width: "100%", pt: 1.25, pl: 2, pr: 2, pb: 2 }}>
                        {
                            packageType &&
                            <Chip variant="outlined" size="small" label={capitalizeFirstLetter(packageType)} sx={{ borderColor: '#979797', color: '#747579' }} />
                        }
                        <Typography variant="subtitle1" fontWeight="500">{extractDataFromArray(message, ['other_info', 'booking_record', 'package_title'], '')}</Typography>
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="subtitle1" color="#85878A">{bookingDataText}</Typography>
                        </Box>
                        <Typography variant="subtitle1" color="#85878A">{"$" + extractDataFromArray(message, ['other_info', 'booking_record', 'booking_amount'], '')
                        }</Typography>
                        <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}#refunddetails`} target={isMobile ? "" : '_blank'}>
                            <Typography variant='body1' style={{ color: "#26C77A", borderBottom: "1px solid #26C77A", width: "fit-content" }}>Refund Details</Typography>
                        </Link>
                    </Box>
                    <Typography variant='subtitle1' sx={{ color: "#85878A" }}>{extractDataFromArray(message, ['messages', 'message'], '')}</Typography>

                </Box>


            </Box>
        </Link>
    )
};


const renderBookingCanceled = (message, onchat2, isMobile, packageType) => {
    const days = extractDataFromArray(message, ['other_info', 'booking_record', 'total_days_booked'], '')
    const guests = extractDataFromArray(message, ['other_info', 'booking_record', 'total_guests_booked'], '')
    const checkInDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_in_date'], '');
    const checkOutDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_out_date'], '');

    const isSameDay = Moment(checkInDate, "YYYY-MM-DD").isSame(checkOutDate, "day");
    const isSameMonth = Moment(checkInDate, "YYYY-MM").isSame(checkOutDate, "month");

    const bookingDataText = isSameDay ?
        <>
            {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
            {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
        </>
        : isSameMonth ?
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>
            :
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>;

    return (
        <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'} style={{ width: "100%" }}>
            <Box sx={{ backgroundColor: onchat2 === true ? "#E0F7EC" : "#F5F5F6", padding: 2, borderRadius: 2, width: "100%" }}>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Chip

                        variant="Filled"
                        label="Booking Cancelled"
                        sx={{
                            fontSize: "12px",
                            backgroundColor: "#fff",
                            border: "1px solid #E94A4A",
                            borderRadius: 4,
                            width: "fit-content",
                            height: 20,
                            color: "#E94A4A",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                    />
                    <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: 4, width: "100%", pt: 1.25, pl: 2, pr: 2, pb: 2 }}>
                        {
                            packageType &&
                            <Chip variant="outlined" size="small" label={capitalizeFirstLetter(packageType)} sx={{ borderColor: '#979797', color: '#747579' }} />
                        }
                        <Typography variant="subtitle1" fontWeight="500">{extractDataFromArray(message, ['other_info', 'booking_record', 'package_title'], '')}</Typography>
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="subtitle1" color="#85878A">{bookingDataText}</Typography>
                        </Box>
                        <Typography variant="subtitle1" color="#85878A">{"$" + extractDataFromArray(message, ['other_info', 'booking_record', 'booking_amount'], '')
                        }</Typography>
                        <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}#refunddetails`} target={isMobile ? "" : '_blank'}>
                            <Typography variant='body1' style={{ color: "#26C77A", borderBottom: "1px solid #26C77A", width: "fit-content" }}>Refund Details</Typography>
                        </Link>
                    </Box>
                    <Typography variant='subtitle1' sx={{ color: "#85878A" }}>{extractDataFromArray(message, ['messages', 'message'], '')}</Typography>
                </Box>


            </Box>
        </Link>
    )
};




const renderBookingConfirmed = (message, onchat2, isMobile, packageType) => {
    const days = extractDataFromArray(message, ['other_info', 'booking_record', 'total_days_booked'], '')
    const guests = extractDataFromArray(message, ['other_info', 'booking_record', 'total_guests_booked'], '')
    const checkInDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_in_date'], '');
    const checkOutDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_out_date'], '');

    const isSameDay = Moment(checkInDate, "YYYY-MM-DD").isSame(checkOutDate, "day");
    const isSameMonth = Moment(checkInDate, "YYYY-MM").isSame(checkOutDate, "month");

    const bookingDataText = isSameDay ?
        <>
            {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
            {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
        </>
        : isSameMonth ?
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>
            :
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>;

    return (
        <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'} style={{ width: "100%" }}>
            <Box sx={{ backgroundColor: onchat2 === true ? "#E0F7EC" : "#F5F5F6", padding: 2, borderRadius: 2, width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Chip

                        variant="Filled"
                        // color="#26C77A"
                        label="Booking Confirmed"
                        sx={{
                            fontSize: "12px",
                            backgroundColor: "#26c77a",
                            borderRadius: 4,
                            width: "fit-content",
                            height: 20,
                            color: "#FFFFFF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                    />
                    <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: 4, width: "100%", pt: 1.25, pl: 2, pr: 2, pb: 2 }}>
                        {
                            packageType &&
                            <Chip variant="outlined" size="small" label={capitalizeFirstLetter(packageType)} sx={{ borderColor: '#979797', color: '#747579' }} />
                        }
                        <Typography variant="subtitle1" fontWeight="500">{extractDataFromArray(message, ['other_info', 'booking_record', 'package_title'], '')}</Typography>
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="subtitle1" color="#85878A">{bookingDataText}</Typography>
                        </Box>
                        <Typography variant="subtitle1" color="#85878A">{"$" + extractDataFromArray(message, ['other_info', 'booking_record', 'booking_amount'], '')
                        }</Typography>
                        <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'}>                        <Typography variant='body1' style={{ color: "#26C77A", borderBottom: "1px solid #26C77A", width: "fit-content" }}>View Booking</Typography>
                        </Link>
                    </Box>
                    <Typography variant='subtitle1' sx={{

                        color: "#85878A",

                    }}>{extractDataFromArray(message, ['messages', 'message'], '')}</Typography>
                </Box>


            </Box>
        </Link>
    )
};




const renderBookingEnquiry = (message, onchat2, isMobile, packageType) => {
    // console.log("messages", message)
    const days = extractDataFromArray(message, ['other_info', 'enquiry_record', 'booking_days'], '')
    const guests = extractDataFromArray(message, ['other_info', 'enquiry_record', 'guest_count'], '')
    const packageSeq = extractDataFromArray(message, ['other_info', 'enquiry_record', 'package_seq'], '')
    const slug = extractDataFromArray(message, ['other_info', 'enquiry_record', 'slug'], 'x')
    const checkInDate = extractDataFromArray(message, ['other_info', 'enquiry_record', 'check_in_date'], '');
    // console.log("check in", extractDataFromArray(message, ['other_info', 'enquiry_record', 'check_in_date'], ''))
    const checkOutDate = extractDataFromArray(message, ['other_info', 'enquiry_record', 'check_out_date'], '');
    // console.log("check Out", checkOutDate)

    const isSameDay = Moment(checkInDate, "YYYY-MM-DD").isSame(checkOutDate, "day");
    const isSameMonth = Moment(checkInDate, "YYYY-MM").isSame(checkOutDate, "month");

    const bookingDataText = isSameDay ?
        <>
            {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
            {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
        </>
        : isSameMonth ?
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>
            :
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>;


    const url = (config.module === 'lister' ? "/landowner" : '') + (packageType === 'experience' ? "/experience/" : "/package/") + slug + "-" + packageSeq
    //  console.log("url", window.location.origin + url)
    return (
        <Link to={window.location.origin + url} target={isMobile ? "" : '_blank'} style={{ width: "100%" }}>
            <Box sx={{ backgroundColor: onchat2 === true ? "#E0F7EC" : "#F5F5F6", padding: 2, borderRadius: 2, width: "100%" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Chip

                        variant="outlined"
                        label="Enquiry"
                        sx={{
                            fontSize: "12px",
                            backgroundColor: "#FFFFFF",
                            borderRadius: 4,
                            width: "fit-content",
                            height: 20,
                            color: "#1B1B1B",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1px solid #1B1B1B",
                            cursor: "pointer"
                        }}
                    />
                    <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: 4, width: "100%", pt: 1.25, pl: 2, pr: 2, pb: 2 }}>
                        {
                            packageType &&
                            <Chip variant="outlined" size="small" label={capitalizeFirstLetter(packageType)} sx={{ borderColor: '#979797', color: '#747579' }} />
                        }
                        <Typography variant="subtitle1" fontWeight="500">{extractDataFromArray(message, ["other_info", 'enquiry_record', 'package_title'])}</Typography>
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="subtitle1" color="#85878A">{bookingDataText}</Typography>
                        </Box>
                        <Typography variant="subtitle1" color="#85878A">{"$" + extractDataFromArray(message, ['other_info', 'enquiry_record', 'booking_amount'], '')
                        }</Typography>
                        <Link to={window.location.origin + url} target={isMobile ? "" : '_blank'}>
                            <Typography variant='body1' style={{ color: "#26C77A", borderBottom: "1px solid #26C77A", width: "fit-content" }}>{packageType === 'experience' ? "View Experience" : "View Package"}</Typography>
                        </Link>
                    </Box>
                    <Typography variant='subtitle1' sx={{

                        color: "#85878A",

                    }}>{extractDataFromArray(message, ['messages', 'message'], '')}</Typography>
                </Box>


            </Box>
        </Link>
    )
};


const renderReviewReceived = (message, onchat2, isMobile, packageType) => {

    const days = extractDataFromArray(message, ['other_info', 'booking_record', 'total_days_booked'], '')
    const guests = extractDataFromArray(message, ['other_info', 'booking_record', 'total_guests_booked'], '')
    const checkInDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_in_date'], '');
    const checkOutDate = extractDataFromArray(message, ['other_info', 'booking_record', 'check_out_date'], '');

    const isSameDay = Moment(checkInDate, "YYYY-MM-DD").isSame(checkOutDate, "day");
    const isSameMonth = Moment(checkInDate, "YYYY-MM").isSame(checkOutDate, "month");

    const bookingDataText = isSameDay ?
        <>
            {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
            {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
        </>
        : isSameMonth ?
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>
            :
            <>
                {`${days} ${days == 1 ? 'Day' : 'Days'}` + " " + `(${Moment(checkInDate, "YYYY-MM-DD").format("DD MMM")}-${Moment(checkOutDate, "YYYY-MM-DD").format("DD MMM, YYYY")})`} &nbsp;
                {" " + `${guests} ${guests === "1" ? 'Guest' : 'Guests'}`}
            </>;


    return (
        <Link to={config.bookingPageUrl + `/${extractDataFromArray(message, ['other_info', 'booking_id'], '')}`} target={isMobile ? "" : '_blank'} style={{ width: "100%" }}>
            <Box sx={{ backgroundColor: onchat2 === true ? "#E0F7EC" : "#F5F5F6", padding: 2, borderRadius: 2, width: "100%", cursor: "pointer" }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Chip
                        variant="outlined"
                        label="Booking Reviewed"
                        sx={{
                            fontSize: "12px",
                            backgroundColor: "#FFFFFF",
                            border: "1px solid #FD9933",
                            borderRadius: 4,
                            width: "fit-content",
                            height: 20,
                            color: "#FD9933",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer"
                        }}
                    />
                    <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: 4, width: "100%", pt: 1.25, pl: 2, pr: 2, pb: 2 }}>
                        {
                            packageType &&
                            <Chip variant="outlined" size="small" label={capitalizeFirstLetter(packageType)} sx={{ borderColor: '#979797', color: '#747579' }} />
                        }
                        <Typography variant="subtitle1" fontWeight="500">{extractDataFromArray(message, ['other_info', 'booking_record', 'package_title'], '')}</Typography>
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="subtitle1" color="#85878A">{bookingDataText}</Typography>
                        </Box>
                        <Typography variant="subtitle1" color="#85878A">{"$" + extractDataFromArray(message, ['other_info', 'booking_record', 'booking_amount'], '')
                        }</Typography>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                            '@media (min-width: 524px)': {
                                flexDirection: "row",
                            },
                        }}
                        >
                            {
                                message?.other_info?.property_rating &&
                                <Box
                                    sx={{
                                        fontSize: "12px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #E5E7EB",
                                        borderRadius: 4,
                                        width: "fit-content",
                                        height: 20,

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        gap: "2px",

                                        pt: 0.5, pl: 1.25, pr: 1.25, pb: 0.5
                                    }}
                                >
                                    <Typography variant='caption' sx={{ color: "#747579" }}>Property</Typography>
                                    <Icon.Star fontSize='12px' sx={{ color: "#F7C32E" }} /> <Typography variant='caption' sx={{ color: "#747579", fontWeight: "bold" }}>{message?.other_info?.property_rating
                                    }</Typography>
                                </Box>
                            }
                            {
                                message?.other_info?.package_rating &&
                                <Box
                                    sx={{
                                        fontSize: "12px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #E5E7EB",
                                        borderRadius: 4,
                                        width: "fit-content",
                                        height: 20,

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        gap: "2px",
                                        pt: 0.5, pl: 1.25, pr: 1.25, pb: 0.5
                                    }}
                                >
                                    <Typography variant='caption' sx={{ color: "#747579" }}>{packageType === 'experience' ? 'Experience' : 'Package'}</Typography>
                                    <Icon.Star fontSize='12px' sx={{ color: "#F7C32E" }} /> <Typography variant='caption' sx={{ color: "#747579", fontWeight: "bold" }}>{message?.other_info?.package_rating
                                    }</Typography>
                                </Box>
                            }
                            {
                                message?.other_info?.host_rating &&
                                <Box
                                    sx={{
                                        fontSize: "12px",
                                        backgroundColor: "#FFFFFF",
                                        border: "1px solid #E5E7EB",
                                        borderRadius: 4,
                                        width: "fit-content",
                                        height: 20,

                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        cursor: "pointer",
                                        gap: "2px",
                                        pt: 0.5, pl: 1.25, pr: 1.25, pb: 0.5
                                    }}
                                >
                                    <Typography variant='caption' sx={{ color: "#747579" }}>Host</Typography>
                                    <Icon.Star fontSize='12px' sx={{ color: "#F7C32E" }} /> <Typography variant='caption' sx={{ color: "#747579", fontWeight: "bold" }}>{message?.other_info?.host_rating
                                    }</Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Typography variant='subtitle1' sx={{

                        color: "#85878A",

                    }}>{extractDataFromArray(message, ['messages', 'message'], '')}</Typography>
                </Box>


            </Box>
        </Link>)
}

const renderRegularText = (message, onchat2) => (
    <Box sx={{ backgroundColor: onchat2 === true ? "#E0F7EC" : "#F5F5F6", pt: 1.25, pb: 1.25, pl: 2, pr: 2, borderRadius: 4 }}>
        <Typography
            variant='body1'
            sx={{
                width: "100%",
                color: "#85878A",
            }}
        >
            {extractDataFromArray(message, ['messages', 'message'], '')}
        </Typography>
    </Box>
);

const MessageComponent = ({ message, onchat2, isMobile }) => {

    let packageType = extractDataFromArray(message, ['other_info', 'package_type'], '')

    switch (extractDataFromArray(message, ['messages', 'message_type'], '')) {
        case "booking_request":
            return renderBookingRequest(message, onchat2, isMobile, packageType);
        case "booking_declined":
            return renderBookingDeclined(message, onchat2, isMobile, packageType);
        case "booking_confirmed":
            return renderBookingConfirmed(message, onchat2, isMobile, packageType);
        case "booking_enquiry":
            return "";
        case "booking_cancelled":
            return renderBookingCanceled(message, onchat2, isMobile, packageType);
        case "booking_completed":
            return renderBookingCompleted(message, onchat2, isMobile, packageType);
        case "review_received":
            return renderReviewReceived(message, onchat2, isMobile, packageType);
        case "enquiry":
            return renderBookingEnquiry(message, onchat2, isMobile, packageType);
        default:
            return renderRegularText(message, onchat2);
    }

};

export default MessageComponent;



