import { Box, Button, Dialog, Divider, Typography } from "@mui/material";
import BookingService from "../../services/bookings";
import { toast } from "react-toastify";


export function ConfirmationPopup({ open, handleClose, bookingID, loaderButton, setLoaderConfirmButton, getBookingDetails }) {

	const confirmBooking = () => {
		BookingService.confirmBooking(bookingID, setLoaderConfirmButton, () => {
			getBookingDetails()
			toast.success("Booking confirmed successfully", {
				theme: 'colored'
			})
			setLoaderConfirmButton(false)

			// window.location.reload()
		})

	}
	return (
		<Dialog open={open} onClose={(e, r) => { if (r && r === "backdropClick") return; handleClose() }} maxWidth="md">
			<Box sx={{ p: 2.5 }}>
				<Typography variant="h6" sx={{ fontWeight: 700 }}>Confirm Booking</Typography>
			</Box>
			<Divider />
			<Box sx={{ p: 2.5 }}>
				<Typography>Do you want to confirm this booking?</Typography>
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'end', gap: 1, p: 2.5, width: '100%' }}>
				<Button variant="text" color="secondary" onClick={() => { handleClose(); setLoaderConfirmButton(false); }}>Cancel</Button>
				<Button variant="contained" color="primary" onClick={() => { confirmBooking(); handleClose(); }}>Confirm</Button>

			</Box>
		</Dialog>
	)
}



export function DeclinePopup({ open, handleClose, bookingID }) {
	const declineBooking = () => {
		BookingService.declineBooking(bookingID, () => {
			toast.success("Booking declined successfully", {
				theme: 'colored'
			})
		})
	}
	return (
		<Dialog open={open} onClose={(e, r) => { if (r && r === "backdropClick") return; handleClose() }} maxWidth="md">
			<Box sx={{ p: 2.5 }}>
				<Typography variant="h6" sx={{ fontWeight: 700 }}>Decline Booking</Typography>
			</Box>
			<Box sx={{ p: 2.5 }}>
				<Typography>Do you want to decline this booking?</Typography>
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'end', gap: 1, p: 2.5, width: '100%' }}>
				<Button variant="text" color="secondary" onClick={() => { handleClose() }}>Cancel</Button>
				<Button variant="contained" color="error" onClick={() => { declineBooking(); handleClose(); }}>Decline</Button>

			</Box>
		</Dialog>
	)
}

export default ConfirmationPopup;