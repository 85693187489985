// import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, Typography } from "@mui/material";
// import * as Icon from '@mui/icons-material';

// const FilterPopup = ({ filterData = {}, type, value, setValue, open, handleClose, speciesFilterChange }) => {



//     return (
//         <Dialog
//             open={open}
//             onClose={handleClose}
//         >
//             <DialogTitle id='customized-dialog-title' sx={{ py: 2, px: 3, justifyContent: "space-between", display: "flex" }}>
//                 <Typography variant='h6' component='span'>
//                     Filters
//                 </Typography>
//                 <IconButton onClick={handleClose}><Icon.Close /></IconButton>
//             </DialogTitle>
//             <Divider />
//             <DialogContent>
//                 <Typography variant="body" sx={{ fontWeight: '600' }}>Select Species</Typography>
//                 <Box sx={{ mt: 1 }}>
//                     <Grid container>
//                         {
//                             Object.entries(filterData).map(([index, val]) => (
//                                 <Grid item xs={12} sm={6} md={6} key={index}>
//                                     <FormControlLabel control={<Checkbox />} label={`${index} (${val})`} />
//                                 </Grid>
//                             ))
//                         }
//                     </Grid>
//                 </Box>
//             </DialogContent>
//             <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', p: 3 }}>
//                 <Button variant="link">Clear All</Button>
//                 <Button variant="contained" onClick={speciesFilterChange}>
//                     Apply
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     )
// }

// export default FilterPopup;

import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import * as Icon from '@mui/icons-material';
import { CommonContext } from '../../store/context/commonContext';

const FilterPopup = ({ filterData = {}, type, value, setValue, open, handleClose, selectedFilters, setSelectedFilters, handleFilterChange, removeFilterFromUrlParams, setShowClearFilterButton }) => {

    const [initialSelectedFilters, setInitialSelectedFilters] = useState([]);
    const {screenWidth} = CommonContext();

    useEffect(() => {
        // Store the initial selection state when the popup opens
        setInitialSelectedFilters([...selectedFilters]);
    }, [open]);

    const handleCheckboxChange = (index) => {
        const updatedFilters = [...selectedFilters];
        const selectedIndex = updatedFilters.indexOf(index);

        if (selectedIndex === -1) {
            updatedFilters.push(index);
        } else {
            updatedFilters.splice(selectedIndex, 1);
        }

        setSelectedFilters(updatedFilters);
    };

    const addFilterToUrlParams = () => {
        let url = new URL(window.location.href);
        url.searchParams.set('sp', selectedFilters.join(','));
        window.history.pushState({}, '', url);
    };

    const handleApplyFilter = () => {
        // setShowClearFilterButton(true)
        const hasSelectionChanged = initialSelectedFilters.length !== selectedFilters.length || 
            !initialSelectedFilters.every(item => selectedFilters.includes(item));

        // Only call API if selection has changed or species were deselected
        if (hasSelectionChanged || (selectedFilters.length === 0 && initialSelectedFilters.length > 0)) {
            const selectedFiltersString = selectedFilters.join(',') || ''; // Empty string for no selection
            handleFilterChange(selectedFiltersString);  // API call with selected or empty data
            addFilterToUrlParams();  // Update URL with selected filters
        }

        // Close the dialog
        handleClose();
    };

    const handleClearAll = () => {
        if (selectedFilters.length > 0 || new URL(window.location.href).searchParams.get('sp')) {
            handleFilterChange('');  // API call to clear filters
            removeFilterFromUrlParams('species');  // Remove species filter from URL
            setSelectedFilters([]);  // Clear all selected species
        }
        handleClose();  // Close the dialog
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle id='customized-dialog-title' sx={{ py: 2, px: 3, justifyContent: "space-between", display: "flex" }}>
                <Typography variant='h6' component='span'>
                    Filters
                </Typography>
                <IconButton onClick={handleClose}><Icon.Close /></IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Typography variant="body" sx={{ fontWeight: '600' }}>Select Species</Typography>
                <Box sx={{ mt: 1 }}>
                    <Grid container>
                        {
                            Object.entries(filterData).map(([index, val]) => (
                                <Grid item xs={12} sm={6} md={6} key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedFilters.includes(index)}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                        }
                                        label={`${index} (${val})`}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', p: 3 }}>
                <Button variant="link" onClick={handleClearAll}>Clear All</Button>
                <Button
                    variant="contained"
                    onClick={handleApplyFilter}
                    sx={{
                        paddingInline: screenWidth < 398 ? '16px !important' : '22px !important'
                    }}
                >
                    Show Results
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterPopup;