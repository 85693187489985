import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios } from "../utils/common";



const FileManagerService = {
    folders: [
        {
            name: "Property Photos",
            size: 100000,
            count: 5
        },
        {
            name: "Farm Photos",
            size: 100005400,
            count: 10
        },
        {
            name: "Hunting",
            size: 20040000,
            count: 6
        },
    ],
    files: [
        {
            name: "hunting_photo_1.png",
            type: "IMAGE",
            url: "/assets/images/property_detail_page/dummy_galler_image_4.png"
        },
        {
            name: "property_1.png",
            type: "IMAGE",
            url: "/assets/images/property_detail_page/dummy_galler_image_2.png"
        },
        {
            name: "hunting_photo_2.png",
            type: "IMAGE",
            url: "/assets/images/property_detail_page/dummy_galler_image_3.png"
        },
        {
            name: "property_2.png",
            type: "IMAGE",
            url: "/assets/images/property_detail_page/dummy_galler_image_1.png"
        },
        {
            name: "Listing.xls",
            type: "SHEET",
            url: "/assets/images/property_detail_page/dummy_galler_image_4.png"
        },
    ],
    fetchAllFolders: async (callback = () => { }) => {
        callback(FileManagerService.folders)
    },
    fetchAllAssets: async (reqFileType = '', callback = () => { }) => {
        // callback(
        //     FileManagerService.files,



        // )


        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: "fetchAllAssets",
                limit: 250,
                type: reqFileType,
            }
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            }
        });
    },
    uploadAsset: async (file, action, folderSeq = 0, callback = () => null, errCb = () => { }) => {
        let data = new FormData();
        data.append('file', file);
        data.append("params", JSON.stringify([["l", 1024, 1024, 93, 0], ["m", 512, 512, 93, 0], ["t", 112, 112, 93, 0]]));//
        data.append("cmd", "uploadAsset");
        data.append("action", action);
        data.append("folder_seq", folderSeq);
        data.append("replace", 0);

        await axios({
            url: config.apiUrl + "/services/api",
            method: "post",
            data: data
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            } else {
                errCb(extractDataFromArray(response, ['errorMsg'], ''))
            }
        });
    },
    checkIfFileExists: async (name, folderSeq = 0, callback = async () => null) => {
        await axios({
            method: "post",
            url: config.apiUrl + "/services/api",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: {
                cmd: "checkIfFileExists",
                name,
                folderSeq
            }
        }).then(async (response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                await callback(extractDataFromArray(response, ['data'], false))
            }
        });

    },


}

export default FileManagerService;