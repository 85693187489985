import React, { useState, useEffect } from 'react';
import { Divider, Button, Typography, Card, CardContent, Select, MenuItem, Dialog, Box, Grid, TextField, IconButton } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { empty, getDateToString } from "../../utils/common";
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import dayjs from "dayjs";
import PackageCard from "../PackageCard"
import './style.css';
import { extractDataFromArray } from '../../utils/common';
import { toast } from 'react-toastify';
import { UserAuth } from '../../store/context/userContext';
import { MessagesApi } from '../../services/messagesApi';
import GreyLabel from '../formElement/GreyLabel';
import config from '../../config';
import { useLocation, useNavigate } from 'react-router';

// Assuming you have an array of MUI numbers
LicenseInfo.setLicenseKey('370e579ab4fef48a1739afecb9c68d3bTz04OTIyMyxFPTE3NDU3NTAwNTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')

const EnquiryPopUp = ({ open, handleClose, packages, list, listingData, minDays, maxDays, setOpenEnquiryPopup
}) => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [listerSeq, setListerSeq] = useState(extractDataFromArray(listingData, ['lister_seq'], ''))
  const location = useLocation();
  const tomorrow = dayjs().add(1, "day");
  const today = dayjs()
  const [toggle, setToggle] = useState(false)
  const defaultStartDate = null;
  const defaultEndDate = null;
  const [defaultDateRange, setDefaultDateRange] = useState([
    defaultStartDate,
    defaultEndDate,
  ]);
  const [addressPackage, setAddressPackage] = useState('')
  const [addressPackage2, setAddressPackage2] = useState('')
  const [propertyName, setPropertyName] = useState('')
  const [listingSlug, setListingSlug] = useState('')
  const [listingSeq, setListingSeq] = useState('')
  const [propertyArea, setPropertyArea] = useState('')
  const [isPropertyPage, setIsPropertyPage] = useState(false)
  const minGuests = 1;
  const maxGuests = 99;
  const [guests, setGuests] = useState(1);
  const { user, authPopup, setAuthPopup } = UserAuth();
  const navigate = useNavigate()

  const openAuthPopup = (type) => {
    setAuthPopup({
      ...authPopup,
      type: type,
      open: true,
      callback: () => {
        submitData();
      },
    });
  };

  const isDisabledDate = (date) => {
    return date.isSame(today, "day");
  };
  const handleCardClick = ({ package_seq }) => {

    setData((prevData) => ({
      ...prevData,
      package_seq: prevData.package_seq === package_seq ? selectedPackage.package_seq : package_seq,
    }));

    setSelectedPackage((prevSelectedPackage) => (
      prevSelectedPackage && prevSelectedPackage.package_seq === package_seq
        ? prevSelectedPackage // Unselect the card
        : { package_seq }  // Select the card
    ));


  };



  const handleGuestChange = (event) => {
    setGuests(event.target.value);
    setData((prevData) => ({
      ...prevData,
      guest_count: event.target.value
    }));
  };

  const handleDateChange = (date) => {
    setData((prevData) => ({
      ...prevData,
      check_in_date: dayjs(date[0]).format('YYYY-MM-DD'),
      check_out_date: dayjs(date[1]).format('YYYY-MM-DD')
    }));
  };

  const handleMessageChange = (event) => {
    const message = event.target.value;
    setData((prevData) => ({
      ...prevData,
      message: message
    }));
  };


  const getActivityTypePackage = (packages) => {


    switch (packages) {
      case 'H':
        return "Hunting"
      case 'F':
        return "Fishing"
      case 'FR':
        return "Farm"
      case 'BW':
        return "Bird Watching"
      default:
        return ""
    }
  }


  const getActivityType = (packages) => {

    let type = extractDataFromArray(packages, ['activity_type'], '')
    switch (type) {
      case 'H':
        return "Hunting"
      case 'F':
        return "Fishing"
      case 'FR':
        return "Farm"
      case 'BW':
        return "Bird Watching"
      default:
        return ""
    }
  }

  const [data, setData] = useState({
    package_seq: '',
    lister_seq: listerSeq,
    guest_count: 1,
    check_in_date: null,
    check_out_date: null,
    message: '',
    package_type: 'access'
  })

  useEffect(() => {
    if (Array.isArray(packages)) {
      setData({
        package_seq: packages[0].package_seq,
        lister_seq: listerSeq,
        guest_count: 1,
        check_in_date: null,
        check_out_date: null,
        message: '',
        package_type: 'access'
      })
    } else {
      setData({
        package_seq: packages.package_seq,
        lister_seq: listerSeq,
        guest_count: 1,
        check_in_date: null,
        check_out_date: null,
        message: '',
        package_type: 'access'
      })
    }
  }, [])

  const redirectToChat = () => {
    if (config.module === "guest") {
      navigate('/chat/' + (extractDataFromArray(listingData, ['fname'], "")) + '/' + (extractDataFromArray(listingData, ['lister_seq'])))
    } else {
      navigate('/landowner/chat/' + (extractDataFromArray(listingData, ['fname'])) + '/' + (extractDataFromArray(listingData, ['lister_seq'])))
    }
  }

  const [errors, setErrors] = useState('');


  const validateForm = () => {
    let valid = true;
    const newErrors = { ...errors };

    if (data.message.trim() === "") {
      newErrors.message = "Message is required";
      valid = false;
    } else {
      newErrors.message = "";
    }

    // Check check-in date
    if (data.check_in_date === "Invalid Date" || data.check_in_date === null) {
      newErrors.check_in_date = "Check-in date is required";
      valid = false;
    } else {
      newErrors.check_in_date = "";
    }

    // Check check-out date
    if (data.check_out_date === "Invalid Date" || data.check_out_date === null) {
      newErrors.check_out_date = "Check-out date is required";
      valid = false;
    } else {
      newErrors.check_out_date = "";
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    if (data.message != "") {
      validateForm()
    }
  }, [data.message])


  const submitData = () => {

    const isValid = validateForm();

    // Set errors even if the form is not submitted
    if (!isValid) {
      // Additional logic for date validation
      if (data.check_out_date === "Invalid Date" || data.check_out_date === null) {
        setToggle(true);
      }
      if (data.check_in_date === "Invalid Date" || data.check_in_date === null) {
        setToggle(true);
      }
    }

    // If form is not valid, do not submit
    if (!isValid) {
      return;
    }
    // console.log("user", user)
    if (empty(user)) {
      openAuthPopup("signup");
      return;
    }

    MessagesApi.sendEnquiry(
      data,
      (res) => {
        if (res != '') {
          redirectToChat()
          setOpenEnquiryPopup(false)
        }
      },
      (err) => {
        toast.error(err, {
          theme: 'colored'
        })
      }
    );

    // if (data.check_out_date === "Invalid Date" || data.check_out_date === null) {
    //   setToggle(true)
    // }
    // if (data.check_in_date === "Invalid Date" || data.check_in_date === null) {
    //   setToggle(true)
    // }

  };

  const [selectedDays, setSelectedDays] = useState(0);


  useEffect(() => {

    if ((data.check_in_date != "Invalid Date") && (data.check_out_date != "Invalid Date")) {
      setToggle(false)
    }
  }, [data.check_in_date, data.check_out_date])


  useEffect(() => {
    const days = dayjs(defaultDateRange[1]).diff(defaultDateRange[0], 'days') + 1;
    setSelectedDays(isNaN(days) ? 0 : days);
    handleDateChange(defaultDateRange);
  }, [defaultDateRange, handleDateChange]);

  if (isNaN(selectedDays)) {
    selectedDays = 0;
  }

  let addresForPackage = "";
  let propertyNameValue = "";
  let listingSlugValue = "";
  let listingSequenceValue = "";
  let addresForPackage2 = "";

  useEffect(() => {
    // console.log("package data", listingData)
    // addresForPackage = extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')

    setPropertyArea(extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], ''))

    let renderTitlepackage = getActivityTypePackage(extractDataFromArray(packages, ['package_json', 'activity_type'], ''))
    propertyNameValue = extractDataFromArray(listingData, ['listing_json', 'property_details', 'name'], '')
    listingSequenceValue = extractDataFromArray(listingData, ['listing_seq'], '')
    listingSlugValue = extractDataFromArray(listingData, ['listing_json', 'property_details', 'slug'], '')

    // addresForPackage = addresForPackage === '-' ? extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], 0) + " Acres, " + renderTitlepackage + extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '') : extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], 0) + " Acres, " + renderTitlepackage + " at " + addresForPackage

    addresForPackage = extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], 0) + " Acres, " + renderTitlepackage + " at "
    addresForPackage2 = " near " + extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')

    setAddressPackage(addresForPackage)
    setListingSlug(listingSlugValue)
    setListingSeq(listingSequenceValue)
    setPropertyName(propertyNameValue)
    setAddressPackage2(addresForPackage2)
  }, [addressPackage])

  useEffect(() => {
    if (Array.isArray(packages)) {

      setSelectedPackage(packages[0])
    } else {
      setSelectedPackage(packages)
    }

    if (location.pathname.includes('/property')) { //added it to check if it is property page or not deending on which we either redirect user to property page or close the popup if alread on property page
      setIsPropertyPage(true)
    } else {
      setIsPropertyPage(false)
    }

  }, [])





  return (

    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth sx={{}}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        sx={{ borderBottom: "1px solid #C5C5C7", backgroundColor: "#FFFFFF", zIndex: 2 }}
      >
        <Typography variant="h6" sx={{
          fontWeight: "bold"
        }}>{"Contact " + extractDataFromArray(listingData, ['fname'], '')} </Typography>
        <IconButton onClick={handleClose}>
          <Icon.Close />
        </IconButton>
      </Box>


      <Box p={2} >
        <Grid container spacing={5}>

          <Grid item xs={12} sm={12} md={8} lg={8} sx={{ maxHeight: 600, overflowY: "scroll", '&::-webkit-scrollbar': { display: "none" }, scrollBehavior: "smooth", alignItems: "center" }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {Array.isArray(packages) ? packages?.map((list, index) => {
                // console.log("List", list)
                // let address = extractDataFromArray(list, ['address'], '')
                let renderTitle = getActivityType(packages[index])
                let listing_seq = extractDataFromArray(list, ['listing_seq'], '')
                let propertyName = extractDataFromArray(list, ['listing_json', 'property_details', 'name'], '')
                let listingSlug = extractDataFromArray(list, ['listing_json', 'slug'], '')
                let address = extractDataFromArray(list, ['listing_json', 'location_info', 'property_area'], 0) + " Acres, " + renderTitle + ' near '
                let address2 = " at " + extractDataFromArray(list, ['listing_json', 'location_info', 'nearest_town'], '')
                // address = address === '-' ? extractDataFromArray(list, ['listing_json', 'location_info', 'property_area'], 0) + " Acres, " + renderTitle + extractDataFromArray(list, ['listing', 'nearest_town'], '') : extractDataFromArray(list, ['listing_json', 'location_info', 'property_area'], 0) + " Acres, " + renderTitle + " at " + address
                return (
                  <PackageCard
                    key={index}
                    cardType='package'
                    cardLocation='enquiryPopUp'
                    isPropertyPage={isPropertyPage}
                    handleClose={handleClose}
                    image={extractDataFromArray(list, ['package_json', 'media_library'], '')}
                    title={extractDataFromArray(list, ['package_title'], '')}
                    guest={extractDataFromArray(list, ['package_json', 'min_guests'], 1)}
                    days={extractDataFromArray(list, ['package_json', 'min_days'], 1)}
                    address={address}
                    address2={address2}
                    listing_seq={listing_seq}
                    listingSlug={listingSlug}
                    propertyName={propertyName}
                    package_seq={extractDataFromArray(list, ['package_seq'], 0)}
                    slug={extractDataFromArray(list, ['package_slug'], 0)}
                    size={extractDataFromArray(list, ['listing_json', 'location_info', 'property_area'], 0)}
                    price={extractDataFromArray(list, ['package_json', 'price_per_guest'], '')}
                    viewTarget="_blank"
                    hideOptions={true}
                    search={false}
                    enquiry={true}
                    clickable={false}
                    type={extractDataFromArray(list, ["type"], "")}
                    nearestTown={extractDataFromArray(list, ['listing', 'nearest_town'], '')}
                    isSelected={selectedPackage && selectedPackage.package_seq === extractDataFromArray(list, ['package_seq'], 0)}
                    onSelect={() => handleCardClick({
                      package_seq: extractDataFromArray(list, ['package_seq'], 0),
                    })}
                  />

                )
              }
              ) :
                <PackageCard
                  cardType='package'
                  cardLocation='enquiryPopUp'
                  isPropertyPage={isPropertyPage}
                  handleClose={handleClose}
                  image={extractDataFromArray(packages, ['package_media'], '')}
                  title={extractDataFromArray(packages, ['title'], '')}
                  guest={extractDataFromArray(packages, ['package_json', 'min_guests'], 1)}
                  days={extractDataFromArray(packages, ['package_json', 'min_days'], 1)}
                  address={addressPackage}
                  address2={addressPackage2}
                  propertyName={propertyName}
                  listingSlug={listingSlug}
                  listing_seq={listingSeq}
                  package_seq={extractDataFromArray(packages, ['package_seq'], 0)}
                  slug={extractDataFromArray(packages, ['slug'], 0)}
                  size={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
                  price={extractDataFromArray(packages, ['package_json', 'price_per_guest'], '')}
                  viewTarget="_blank"
                  hideOptions={true}
                  search={false}
                  enquiry={true}
                  clickable={false}
                  type={extractDataFromArray(list, ["type"], "")}
                  nearestTown={extractDataFromArray(list, ['listing', 'nearest_town'], '')}
                  isSelected={selectedPackage && selectedPackage.package_seq === extractDataFromArray(packages, ['package_seq'], 0)}
                  onSelect={() => handleCardClick({
                    package_seq: extractDataFromArray(packages, ['package_seq'], 0),
                  })}
                />}
            </Box>

          </Grid>


          <Grid item xs={12} sm={12} md={4} lg={4}  >
            <Box
              sx={{
                border: "1px solid var(--grey700)",
                borderRadius: (theme) => `${theme.card.borderRadiusMd} `,
                mb: 3,
                display: "flex",
                flexDirection: "column", gap: 2,
                pt: 3, pb: 3
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Box sx={{ display: "flex", pl: 3, pr: 3, alignItems: "center" }}>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}>

                    <Icon.PersonOutlineOutlined fontSize='small' sx={{ color: "var(--grey900)" }} />


                    {/* <Typography>
                      {guests === 1 ? "1 Guest" : guests + " Guests"}
                    </Typography>  */}
                    <Typography variant='body1' sx={{ color: "var(--grey900)" }}>
                      Guest
                    </Typography>

                  </Box>
                </Box>
                <Box sx={{ pl: 3, pr: 3, }}>
                  <Select
                    fullWidth
                    displayEmpty
                    value={data.guest_count}
                    IconComponent={Icon.KeyboardArrowDownOutlined}
                    onChange={handleGuestChange}
                    sx={{
                      height: "42px",
                    }}

                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: {
                            xs: 200,
                            sm: 180,
                            md: 250,
                            lg: 470,
                            xl: 400
                          }
                        }
                      },
                      MenuListProps: { style: { width: '100%' } }
                    }}
                  >
                    {Array.from({ length: maxGuests - minGuests + 1 }, (_, index) => (
                      <MenuItem
                        key={index + parseInt(minGuests)}
                        value={index + parseInt(minGuests)}
                      >
                        {index + parseInt(minGuests)}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Box sx={{ display: "flex", pl: 3, pr: 3, alignItems: "center", gap: 0.5, color: "var(--grey900)" }}>

                    <Icon.CalendarTodayOutlined fontSize='small' sx={{ color: "var(--grey900)" }} />



                    <Typography variant='body1' sx={{ color: "var(--grey900)" }}>Dates</Typography>



                  </Box>
                  <Box sx={{ pl: 3, pr: 3, alignItems: "center" }}>

                    <Box >
                      <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                        <DateRangePicker
                          calendars={1}
                          clearable={true}
                          slots={{ field: SingleInputDateRangeField }}
                          PopperProps={{
                            sx: {
                              // '& .MuiPickersPopper-paper': {
                              //   height: '269px !important',
                              //   maxHeight: '269px !important',
                              // },
                              // '& .css-138poq7-MuiPickersSlideTransition-root-MuiDayCalendar-slideTransition': {
                              //   minHeight: '200px !important',
                              //   // Add any other styles you want to apply to this class
                              // },
                            },
                          }}
                          slotProps={{
                            textField: {
                              size: "small",
                              placeholder: "Select Dates",
                              InputProps: { endAdornment: <Icon.CalendarMonth /> },
                            },
                            actionBar: { actions: ["clear", "accept"] },
                            dialog: {
                              maxWidth: 'md'
                            },
                            popper: { // Added this to show clear and ok button on smaller screen
                              sx: {
                                '& .MuiPickersPopper-paper': {
                                  height: '269px !important',
                                  maxHeight: '269px !important',
                                  overflowY: 'auto !important',
                                },
                                '& .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition': {
                                  minHeight: '212px !important',
                                  // Add any other styles you want to apply to this class
                                },
                                "& .MuiPickersCalendarHeader-root": {
                                  marginTop: '4px !important',
                                  marginBottom: '4px !important'
                                }
                              },
                            }
                          }}
                          format="DD MMM"
                          value={defaultDateRange}
                          onChange={(date) => setDefaultDateRange(date)}
                          disablePast
                          disableHighlightToday
                          closeOnSelect={false}
                        // shouldDisableDate={isDisabledDate}
                        />
                      </LocalizationProvider>
                    </Box>
                    {toggle && <Typography variant='caption' color="error">Please select dates</Typography>}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <GreyLabel label='Message' required variant="body1" sx={{ color: "var(--textGrey)", mb: 0 }}>

              </GreyLabel>
              <TextField fullWidth
                placeholder="Enter your message"
                maxRows={2}
                multiline
                value={data.message}
                onChange={handleMessageChange}
                error={Boolean(errors.message)}
                helperText={errors.message}>
              </TextField>
            </Box>

            <Button variant="contained" color="primary" sx={{ mt: 3, borderRadius: "8px", height: "42px", fontSize: "1rem" }} fullWidth onClick={() => {
              submitData();
            }}>
              Send Message
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default EnquiryPopUp;
