import { Box, Link, Typography } from "@mui/material";
import { extractDataFromArray } from "../../../utils/common";
import ApplyStyles from "../../previewPageComponents/htmlParser/ApplyStyles";
import { CommonContext } from "../../../store/context/commonContext";

const MeetingAndPickup = ({ formData, isMobileButtonClicked = false }) => {
    const title = extractDataFromArray(formData, ['caption'], '');
    const pickupAddress = extractDataFromArray(formData, ['pickup_address'], '');
    const dropLocation = extractDataFromArray(formData, ['drop_address'], '');
    const description = extractDataFromArray(formData, ['other_details'], '');

    const pickupLat = Number(extractDataFromArray(formData, ['pickup_lat'], ''));
    const pickupLon = Number(extractDataFromArray(formData, ['pickup_lon'], ''));
    const dropLat = Number(extractDataFromArray(formData, ['drop_lat'], ''));
    const dropLon = Number(extractDataFromArray(formData, ['drop_lon'], ''));
    const { isNineHundred } = CommonContext()

    const getGoogleMapsLink = (lat, lon) => `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;

    if (extractDataFromArray(formData, ['drop_address'], '') === '' &&
        extractDataFromArray(formData, ['pickup_address'], '') === '' &&
        (extractDataFromArray(formData, ['other_details'], '') === '' ||
            extractDataFromArray(formData, ['other_details'], '') === '<p><br></p>')) {
        return null
    }


    return (
        <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: '600', mb: 1 }}>
                {title}
            </Typography>
            <Box>
                <Box sx={{ display: 'flex', gap: 2, mb: 2, flexDirection: isNineHundred ? 'column' : isMobileButtonClicked ? 'column' : 'row' }}>
                    {!(pickupAddress === '' && pickupLat === 0 && pickupLon === 0) && <Box sx={{
                        border: '1px solid #E5E7EB',
                        borderRadius: 2,
                        p: 2.5,
                        width: '100%',
                    }}>
                        <Typography variant="subtitle1">
                            Pickup Location
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                            {pickupAddress === '-' ? `Lat Long: (${pickupLat}, ${pickupLon})` : pickupAddress}
                        </Typography>
                        {!(pickupLat === 0 && pickupLon === 0) && <Link href={getGoogleMapsLink(pickupLat, pickupLon)} target="_blank" rel="noopener">
                            Get Direction
                        </Link>}
                    </Box>}
                    {!(dropLocation === '' && dropLat === 0 && dropLon === 0) && <Box sx={{
                        border: '1px solid #E5E7EB',
                        borderRadius: 2,
                        p: 2.5,
                        width: '100%',
                    }}>
                        <Typography variant="subtitle1">
                            Drop Location
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                            {dropLocation === '-' ? `Lat Long: (${dropLat}, ${dropLon})` : dropLocation}
                        </Typography>
                        {!(dropLat === 0 && dropLon === 0) && <Link href={getGoogleMapsLink(dropLat, dropLon)} target="_blank" rel="noopener">
                            Get Direction
                        </Link>}
                    </Box>}
                </Box>
                {!(description === '' || description === '<p><br></p>') && <ApplyStyles htmlString={description} />}
            </Box>
        </Box>
    );
};

export default MeetingAndPickup;
