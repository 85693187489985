/* 
    put common keynames here 
    Put the keys in following structure
    Lister or Guest => PageName 
    Put the keys in common group to be identifiable lator
*/

// Lister => Create Experience common Keys

export const FROM_TIME = 'from_time'
export const TO_TIME = 'to_time'
export const RESPONSIVE_SPACING = { xs: 2, sm: 2, md: 3, lg: 3 }

export const EXPERIENCE = {
    string: 'Experience',
    value: 'experience'
}

export const ACCESS = {
    string: 'Access',
    value: 'access',
}