

import { createTheme } from '@mui/material/styles';
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: "Poppins, sans-serif",
    },
    palette: {
        primary: {
            light: '#30E990',
            main: '#26C77A',
            dark: '#17AC65',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff7961',
            main: '#1B1B1B',
            dark: '#ba000d',
            contrastText: '#fff',
        },
        grey300: {
            main: '#F5F5F6',
            contrastText: '#1b1b1b',
        },
        grey500: {
            main: '#E5E7EB',
            contrastText: '#1b1b1b',
        },
        grey900: {
            main: '#747579',
            contrastText: '#1b1b1b',
        },
        black: {
            main: '#121212',
            contrastText: '#fff',
        },
        warning: {
            main: '#E97D4A',
            contrastText: '#fff',
        },

    },
    card: {
        borderRadius: "16px !important;",
        borderRadiusMd: "8px !important;",
        borderRadiusSm: "4px",
        borderRadiusSx: "2px"
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: "var(--black)",
                },
                h1: {
                    fontSize: '4.5rem',
                    "@media (max-width:600px)": {
                        fontSize: '2.25rem',
                    },
                },
                h2: {
                    fontSize: '3.875rem',
                    "@media (max-width:600px)": {
                        fontSize: '1.875rem',
                    },
                },
                h3: {
                    fontWeight: "700",
                    "@media (max-width:768px)": {
                        fontSize: '2.25rem',
                    },
                    "@media (max-width:600px)": {
                        fontSize: '1.625rem',
                    },

                },
                h4: {
                    fontWeight: "700",
                    "@media (max-width:600px)": {
                        fontSize: '1.5rem',
                    },
                },
                h5: {
                    "@media (max-width:600px)": {
                        fontSize: '1.25rem',
                    },
                },
                h6: {
                    "@media (max-width:600px)": {
                        fontSize: '1.125rem',
                    },
                },
                caption: {
                    lineHeight: '1.125rem',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '1rem'
                },

            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    padding: '7px 22px',
                    textTransform: 'none',
                    borderRadius: '8px'
                },
                sizeLarge: {
                    fontSize: '1.125rem',
                    padding: '9px 28px',
                    textTransform: 'none'
                },
                sizeSmall: {
                    fontSize: '0.975rem',
                    padding: '5px 16px',
                    textTransform: 'none'
                },
                light: {
                    backgroundColor: '#E0F7EC',
                    color: 'var(--primary)',
                    '&:hover': {
                        backgroundColor: 'var(--primary)',
                        color: '#fff'

                    }
                },
                grey: {
                    backgroundColor: 'var(--grey500)',
                    color: 'var(--black)',
                    '&:hover': {
                        // backgroundColor: 'var(--primary)',
                        // color: '#fff'

                    }
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    color: "var(--grey900)",
                    // transition : 'all 0.3s ease'
                },

            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 'unset',
                    marginRight: '0.5rem'
                },

            },
        },
        MuiOutlinedInput: {
            adornedStart: {
                root: {
                    paddingLeft: '0px',
                    // marginRight : '0.5rem'
                },

            },
            styleOverrides: {
                root: {
                    borderRadius: '6px',
                    "&.Mui-focused fieldset": {
                        borderColor: 'var(--black) !important'
                    }
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-focused": {
                        color: 'var(--black) !important'
                    }
                },
            },
        },

        MuiRating: {
            styleOverrides: {
                icon: {
                    color: '#F7C32E!important;',
                },

            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    padding: '0.825rem 1rem !important',
                    color: "black",
                },

            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    height: '3px'
                },

            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '1rem',
                },

            },
        },
        // MuiInputBase: {
        //     styleOverrides: {
        //         root: {
        //             fontSize: '0.957rem',
        //             padding: '5px',
        //             input: {
        //                 padding: '5px 10px',
        //             }
        //         },
        //         sizeSmall: {
        //             fontSize: '0.957rem',
        //             padding: '5px',
        //             input: {
        //                 padding: '3px 8px',
        //             }
        //         },
        //     },
        // },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    borderTop: "transparent",
                    "& .MuiDataGrid-columnHeaderTitleContainer": {
                        background: "#F5F5F6"
                    },
                    // '& .super-app-theme--header': {
                    //     backgroundColor: 'red',
                    //   },
                    "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
                    {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        borderBottom: "transparent"
                    }, "& .MuiDataGrid-columnHeader": {
                        padding: "0px",
                        //    minWidth:"115px !important",

                    }, "& .MuiDataGrid-cell": {
                        padding: "0px"
                    }, "& .MuiDataGrid-menuIcon": {
                        display: "none",
                        border: "1px solid red"
                    }, "& .MuiDataGrid-columnSeparator": {
                        display: "none"
                    }

                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: "0px"
                }
            }
        }

    },
});


export default theme;