import React, { useEffect } from 'react';
// ** MUI Imports
import PropertyDetails from "../../commonComponents/PropertyDetails";
import { Box, Grid } from '@mui/material';
import { CommonContext } from '../../store/context/commonContext';
import { showZendeskLauncher } from '../../utils/common';









const Property = () => {
	const { isTablet } = CommonContext();
    useEffect(() => {
        showZendeskLauncher(!isTablet)
    }, [isTablet])


	return (
		<>
			<Box maxWidth={'lg'} sx={{ mx: 'auto', mt: 3 }}>
				<PropertyDetails />
			</Box>
		</>
	)
}

export default Property