import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';



const GreyLabel = ({ label, required, sx = {} }) => {
  return (
    <Typography

      sx={{
        color: "var(--grey900)",
        marginBottom: "0.5rem",
        ...sx,
      }}
    >
      {label}
      {required && <Typography variant="span" color="error" sx={{ display: "inline" }}>&nbsp;*&nbsp;</Typography>}
    </Typography>
  );
};

export default GreyLabel;