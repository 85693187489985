import { Box, Button, CardMedia, Chip, Dialog, Divider, FormControl, FormHelperText, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { empty, extractDataFromArray, findExperienceDataByType, generatePhrase, getActivityType, roundNumber, validateJSON } from "../../utils/common";
import * as Icon from '@mui/icons-material';
import { useEffect, useState } from "react";
import BookingService from "../../services/bookings";
import dayjs from "dayjs";
import GreyLabel from "../formElement/GreyLabel";
import { toast } from "react-toastify";
import config from "../../config";
import { CommonContext, CommonContextProvider } from "../../store/context/commonContext";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import PackageCardMobile from "../PackageCardMobile";
import { Link } from "react-router-dom";
import PackageService from "../../services/package";
import { capitalizeFirstLetter } from "../../utils/common";
import { ACCESS, EXPERIENCE } from "../CommonConstants/commonKeys";


export function PackageCard({ bookingData, clickable = false }) {

	const navigate = useNavigate()
	const { isMobile } = CommonContext();
	const propertyName = `at ${extractDataFromArray(bookingData, ['listing', 'title'], '')}`
	const listingSeq = extractDataFromArray(bookingData, ['listing', 'seq'], '')
	const listingSlug = extractDataFromArray(bookingData, ['listing', 'slug'], '')
	let address = `${roundNumber(extractDataFromArray(bookingData, ['listing', 'area'], ''))} Acres, ${getActivityType(extractDataFromArray(bookingData, ['package', 'activity_type'], ''))} `
	const address2 = ` near ${extractDataFromArray(bookingData, ['listing', 'nearest_town'], '')}`
	const packageType = extractDataFromArray(bookingData, ['package_type'], '')

	let mediaType, url, thumbnailUrl, photosData, packageTitle, listingTitle, generalData, locationData, pricingData;
	let durationObj = {}


	if (packageType === 'experience') {
		// console.log('findExperienceDataByType(bookingData,', findExperienceDataByType(bookingData, 'photos'));
		photosData = findExperienceDataByType(bookingData.package, 'photos')
		generalData = findExperienceDataByType(bookingData.package, 'general_info')
		locationData = findExperienceDataByType(bookingData.package, 'location')
		pricingData = findExperienceDataByType(bookingData.package, 'pricing')
		mediaType = extractDataFromArray(photosData, ['photo_list', 0, 'type'], '');
		packageTitle = extractDataFromArray(generalData, ['title'], '')
		address = ` Nearest Town, ${extractDataFromArray(locationData, ['city'], '')}`
		durationObj = extractDataFromArray(pricingData, ['package_duration'], {})
	} else if (packageType === 'access') {
		mediaType = extractDataFromArray(bookingData, ['package', 'media_url', 'type'], '')
		packageTitle = extractDataFromArray(bookingData, ['package', 'title'], '')
	} else {
		mediaType = extractDataFromArray(bookingData, ['package', 'media_url', 'type'], '')
	}


	if (mediaType === 'IMAGE') {
		if (packageType === 'experience') {
			url = extractDataFromArray(photosData, ['photo_list', 0, 'url'], '');
			thumbnailUrl = extractDataFromArray(photosData, ['photo_list', 0, 'thumbnail_url'], '');
		} else {
			url = extractDataFromArray(bookingData, ['package', 'media_url', 'url'], '');
			thumbnailUrl = extractDataFromArray(bookingData, ['package', 'media_url', 'thumbnail_url'], '');
		}
	} else if (mediaType === 'VIDEO') {
		if (packageType === 'experience') {
			url = extractDataFromArray(photosData, ['photo_list', 0, 'url'], '');
			thumbnailUrl = extractDataFromArray(photosData, ['photo_list', 0, 'thumbnail_url'], '');
		} else {
			url = extractDataFromArray(bookingData, ['package', 'media_url', 'url'], '');
			thumbnailUrl = extractDataFromArray(bookingData, ['package', 'media_url', 'thumbnail_url'], '');
		}
	} else {
		// Default values if mediaType is neither 'image' nor 'video'
		url = '';
		thumbnailUrl = '';
	}


	const iconPathRetrieveFunc = () => {
		if (extractDataFromArray(generalData, ['experience_type'], '') === 'Recreational Event') {
			return '../assets/images/event_exp_icon.svg'
		} else if (extractDataFromArray(generalData, ['experience_type'], '') === 'Outdoor Activity') {
			return '../assets/images/outdoor_activity_exp_icon.svg'
		} else if (extractDataFromArray(generalData, ['experience_type'], '') === 'Guided Tour') {
			return '../assets/images/tour_guide_exp_icon.svg'
		} else if (extractDataFromArray(generalData, ['experience_type'], '') === 'In-Person Training') {
			return '../assets/images/in-person.svg'
		}
	}
	const iconPath = iconPathRetrieveFunc()

	return (
		<Box sx={{
			p: 3, mb: 3, display: 'flex', gap: 2, border: '1px solid var(--grey500)', position: 'relative', borderRadius: (theme) => `${theme.card.borderRadius} `, cursor: 'pointer',
			":hover": {
				borderColor: "var(--black)"
			}
		}} onClick={
			(event) => {
				const isPropertyName = event.target.classList.contains('propertyName')
				if (clickable) {
					if (isPropertyName) {
						//    console.log("clicked name")
					} else {

						// console.log("card clicked")
						let locationToOpen = isMobile ? "_self" : "_blank"
						// console.log("/package/" + extractDataFromArray(bookingData, ['package', 'slug'], '') + "-" + extractDataFromArray(bookingData, ['package', 'seq'], ''))
						if (packageType === 'access') {
							window.open("/package/" + extractDataFromArray(bookingData, ['package', 'slug'], '') + "-" + extractDataFromArray(bookingData, ['package', 'seq'], ''), locationToOpen)
						} else if (packageType === 'experience') {
							window.open("/experience/" + extractDataFromArray(generalData, ['slug'], '') + "-" + extractDataFromArray(bookingData, ['package', 'seq'], ''), locationToOpen)
						}
					}
				}
			}}>
			<Box className='ChipContainer' sx={{ position: 'absolute', top: 32, left: 32, zIndex: 2 }}>
				<Chip label={capitalizeFirstLetter(packageType)} variant="contained" size="small" sx={{ border: '1px solid #1B1B1B', borderColor: '#1B1B1B', color: '#1B1B1B', backgroundColor: '#ffffff' }} />
			</Box>
			{mediaType === 'IMAGE' ?
				<Box
					className="AAAAAAAA"
					sx={{
						width: {
							xs: '100%',
							sm: '100%',
							md: '160px',
						},
						height: 'auto',
						borderRadius: '8px',
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center center",
						backgroundSize: "cover",
						backgroundImage: "url('" + url + "')"

					}} />
				:
				<>
					{
						thumbnailUrl === '' ?
							<div className='' style={{ position: 'relative', display: 'flex' }}>
								<div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
									<PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
								</div>
								<CardMedia
									component='video'
									sx={{
										width: {
											xs: '100%',
											sm: '100%',
											md: '160px',
										},
										backgroundSize: 'cover',
										borderRadius: '8px',
										position: 'relative',
										objectFit: 'cover'
									}}
									image={url}
								/>
							</div>
							:
							<div className='' style={{ position: 'relative', display: 'flex' }}>
								<div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
									<PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
								</div>
								<Box sx={{
									width: {
										xs: '100%',
										sm: '100%',
										md: '160px',
									},
									height: 'auto',
									borderRadius: '8px',
									backgroundRepeat: "no-repeat",
									backgroundPosition: "center center",
									backgroundSize: "cover",
									backgroundImage: "url('" + thumbnailUrl + "')"

								}} />
							</div>
					}
				</>
			}
			<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: 'calc(100% - 180px)' }}>
				<Box>
					<Typography noWrap >{extractDataFromArray(bookingData, ['listing', 'title'], '')}</Typography>
					<Typography noWrap variant="h6" sx={{ fontWeight: 700 }}>{packageTitle}</Typography>
					{/* <Typography component={'span'} variant='body2' sx={{ color: 'var(--grey900)' }}>
						{address}{address2}
					</Typography> */}
					{
						packageType === 'access' ?
							<Box>
								<Typography component={'span'} variant='body2' sx={{ color: 'var(--grey900)' }}>
									{address}
								</Typography>
								<Link className="propertyName" target={isMobile ? "_self" : "_blank"} to={`/property/${listingSlug}-${listingSeq}`} style={{ color: '#000000', textDecoration: 'underline' }} component={'span'} variant='body2'>
									{propertyName}
								</Link>
								<Typography component={'span'} variant='body2' sx={{ mb: 1, color: 'var(--grey900)' }} >
									{address2}
								</Typography>
							</Box>
							:
							<Typography component={'span'} variant='body2' sx={{ color: 'var(--grey900)' }}>
								{address}
							</Typography>
					}

					{
						packageType === 'experience' &&

						<Box sx={{
							"& .MuiChip-root": {
								width: 'fit-content',
								height: '28px !important'
							},
							display: 'flex',
							gap: 1,
							flexWrap: 'wrap',
							mt: 4
						}}>
							{durationObj && <Chip
								icon={<Icon.AccessTimeOutlined />}
								// label={`${extractDataFromArray(props, ['durationObj', 'value'], '')} ${extractDataFromArray(props, ['durationObj', 'unit'], '')}`}
								label={`${generatePhrase(
									parseInt(extractDataFromArray(durationObj, ['value'], 0)),
									(extractDataFromArray(durationObj, ['unit'], 'hours') === 'hours' ? 'Hour' : 'Day')
								)}`}
							/>}
							{<Chip
								icon={<img src={iconPath} style={{ height: '16px', width: '16px' }} />}
								label={`${extractDataFromArray(generalData, ['experience_type'], '')}`}
							/>}

						</Box>
					}

				</Box>
			</Box>
		</Box>
	)
}

const validationSchema = {
	reason: {
		type: 'string',
		required: true,
		requiredMsg: 'Reason is required',
	},
	more_info: {
		type: 'string',
		required: true,
		requiredMsg: 'Field is required',
		max: 5000,
		maxMsg: "Maximum 5000 characters allowed.",
	},
	other_reason: {
		type: 'string',
		required: true,
		requiredMsg: 'Reason is required',
		checkIf: {
			field: 'reason',
			is: 'Others',
		},
	},

}

const CancellationPopup = ({ open, handleClose, bookingData, type = 'cancel', getBookingDetails, setLoaderDeclineButton }) => {
	// console.log("bookingData", bookingData);
	const [cancelReasons, setCancelReasons] = useState([])
	const [errors, setErrors] = useState({})
	const [selectedOptions, setSelectedOptions] = useState({
		reason: '',
		more_info: '',
		other_reason: '',
	})

	const [paymentDetails, setPaymentDetails] = useState({})
	const [listerSeq, setListerSeq] = useState('')

	const currentDate = dayjs()
	const amount = extractDataFromArray(bookingData, ['booking', 'booking_amount'], 0)
	const checkInDate = extractDataFromArray(bookingData, ['booking', 'check_in_date'], 0)
	const confirmationDate = extractDataFromArray(bookingData, ['booking', 'confirmation_date'], '0000-00-00 00:00:00')

	const differenceInMinutes = currentDate.diff(dayjs(confirmationDate), 'minute')

	const isEligibleForRefund = (confirmationDate !== '0000-00-00 00:00:00' && differenceInMinutes < 2880) // If guest is eligible for refund.. checking 48 hours of difference in minutes\

	const { isMobile, isNineHundred } = CommonContext();

	const handleCancelSubmit = () => {
		let errorArray = validateJSON(selectedOptions, validationSchema)
		setErrors(errorArray)
		if (!empty(errorArray)) {
			return;
		}

		handleClose();

		if (type === 'cancel') {
			// console.log(bookingData.booking.id)
			BookingService.cancelBooking(bookingData.booking.id, selectedOptions.reason, selectedOptions.other_reason, selectedOptions.more_info, setLoaderDeclineButton, getBookingDetails, () => {
				getBookingDetails()
				toast.success("Booking cancelled successfully", {
					theme: 'colored'
				})
				setLoaderDeclineButton(false)
				// navigate(`/booking/${bookingData.booking.id}`)
			})
		} else {
			BookingService.declineBooking(bookingData.booking.id, selectedOptions.reason, selectedOptions.other_reason, selectedOptions.more_info, setLoaderDeclineButton, () => {
				getBookingDetails()
				setLoaderDeclineButton(false)
				toast.success("Booking declined successfully", {
					theme: 'colored'
				})
				// navigate(`/booking/${bookingData.booking.id}`)
			})
		}

	}

	useEffect(() => {
		BookingService.getCancellationReasons((data) => {
			setCancelReasons(data)
		})
	}, [])

	useEffect(() => {
		if (open) {
			BookingService.getCancelationData(bookingData.booking.id, (data) => {
				setPaymentDetails(data)
				// setCancelReasons(data.cancelationResaons)
			})
		} else {

		}
	}, [open])


	// useEffect(()=> {

	// 	setListerSeq(extractDataFromArray(bookingData,['lister','seq'],''))

	// },[bookingData])

	// useEffect(()=> {

	// 	PackageService.getCancellationPolicyData('lister',listerSeq, (data) => {
	// 		setPaymentDetails(data)
	// 		// setCancelReasons(data.cancelationResaons)
	// 	})
	// },[listerSeq])

	const action = type === 'cancel' ? 'Cancel' : 'Decline'
	const label = type === 'cancel' ? 'Reason for Cancellation' : 'Decline Reason'

	let packageCardMobileData = {
		image: '',
		thumbnail_url: '',
		title: '',
		area: '',
		address: '',
		propertyName: '',
		listingSlug: '',
		listing_seq: '',
		slug: '',
		seq: '',
		nearest_town: '',
		showBorder: false,
		activity_type: '',
		type: '',
	}

	if (bookingData.package_type === EXPERIENCE.value) {
		let data = extractDataFromArray(bookingData, ['package', 'experience_data'], {})
		for (let key in data) {
			if (data[key].type === 'photos') {
				packageCardMobileData.image = data[key].photo_list[0]
			}
			if (data[key].type === 'general_info') {
				packageCardMobileData.title = data[key].title
				packageCardMobileData.slug = data[key].slug
				packageCardMobileData.seq = data[key].seq
			}
			if (data[key].type === 'location') {
				packageCardMobileData.address = data[key].address
				if (packageCardMobileData.address === 'Don’t see your address? Add GPS coordinates.') {
					packageCardMobileData.address = 'Nearest Town, ' + data[key].city
				}
			}
		}
	} else if (bookingData.package_type === ACCESS.value) {
		packageCardMobileData.image = extractDataFromArray(bookingData, ['package', 'media_url'], '')
		packageCardMobileData.title = extractDataFromArray(bookingData, ['package', 'title'], '')
		packageCardMobileData.area = extractDataFromArray(bookingData, ['listing', 'area'], '')
		packageCardMobileData.address = extractDataFromArray(bookingData, ['listing', 'address'], '')
		packageCardMobileData.propertyName = extractDataFromArray(bookingData, ['listing', 'title'], '')
		packageCardMobileData.listingSlug = extractDataFromArray(bookingData, ['listing', 'slug'], '')
		packageCardMobileData.listing_seq = extractDataFromArray(bookingData, ['listing', 'seq'], '')
		packageCardMobileData.seq = extractDataFromArray(bookingData, ['package', 'seq'], '')
		packageCardMobileData.slug = extractDataFromArray(bookingData, ['package', 'slug'], '')
		packageCardMobileData.nearest_town = extractDataFromArray(bookingData, ['listing', 'nearest_town'], '')
		packageCardMobileData.activity_type = extractDataFromArray(bookingData, ['package', 'activity_type'], '')

	}

	return (
		<Dialog open={open && cancelReasons?.length > 0} onClose={(e, r) => { if (r && r === "backdropClick") return; handleClose() }} maxWidth="md">
			<Box >
				<Box sx={{ py: 1.5, px: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Typography variant="h6" sx={{ fontWeight: 700 }}>{action} Booking</Typography>
					<IconButton onClick={() => { handleClose(); setLoaderDeclineButton(false); }}><Icon.Close /> </IconButton>
				</Box>
				<Divider />
				<Box sx={{ p: 2.5, maxWidth: "800px" }}>
					{!isNineHundred ?
						<PackageCard bookingData={bookingData} />
						:
						<PackageCardMobile
							image={packageCardMobileData.image}
							title={packageCardMobileData.title}
							area={packageCardMobileData.area}
							address={packageCardMobileData.address}
							propertyName={packageCardMobileData.propertyName}
							listingSlug={packageCardMobileData.listingSlug}
							listing_seq={packageCardMobileData.listing_seq}
							slug={packageCardMobileData.slug}
							seq={packageCardMobileData.seq}
							nearest_town={packageCardMobileData.nearest_town}
							showBorder={false}
							activity_type={packageCardMobileData.activity_type}
							listingType={bookingData.package_type}
						/>
					}
					<FormControl fullWidth sx={{ my: 2 }} size="small">
						<GreyLabel label={label} required></GreyLabel>
						<Select
							IconComponent={Icon.KeyboardArrowDownOutlined}
							value={selectedOptions.reason}
							onChange={(e) => {
								setSelectedOptions({ ...selectedOptions, reason: e.target.value })
							}}

						>
							{
								cancelReasons?.map((reason, index) => {
									return <MenuItem key={"reason_" + index} value={reason} style={{ whiteSpace: 'normal' }}>{reason}</MenuItem>
								})
							}
						</Select>
						{
							!empty(extractDataFromArray(errors, 'reason', '')) &&
							<FormHelperText sx={{ marginLeft: 0 }} error={true}>{extractDataFromArray(errors, 'reason', '')}</FormHelperText>
						}
					</FormControl>
					{
						selectedOptions.reason === "Others" &&
						<FormControl fullWidth sx={{ mb: 2 }}>
							<GreyLabel label="Other Reason" required></GreyLabel>
							<TextField
								fullWidth
								value={selectedOptions.other_reason}
								onChange={(e) => {
									setSelectedOptions({ ...selectedOptions, other_reason: e.target.value })
								}}
								error={errors.other_reason ? true : false}
								helperText={errors.other_reason ? errors.other_reason : ''}
							/>
						</FormControl>
					}
					<FormControl fullWidth sx={{ mb: 2 }}>
						<GreyLabel label="Any other details" required></GreyLabel>
						<TextField
							fullWidth
							multiline
							rows={4}
							value={selectedOptions.more_info}
							onChange={(e) => {
								setSelectedOptions({ ...selectedOptions, more_info: e.target.value })
							}}
							sx={{ mb: 2 }}
							placeholder="If there is something specific you want us to know regarding your cancellation, add that here"
							error={errors.more_info ? true : false}
							helperText={errors.more_info ? errors.more_info : ''}
						/>
					</FormControl>
					<Button variant="contained" color="error" fullWidth sx={{ mb: 3 }} onClick={handleCancelSubmit}>{action} Booking</Button>
					{type === 'cancel' && config.module === 'guest' &&
						<>
							<Typography variant="h6" sx={{ fontWeight: 700 }}>Cancellation Policy</Typography>
							{/* <Typography sx={{ mb: 2, color: 'var(--grey900)' }}>
								To be eligible for a full refund, guests must cancel within 48 hours
								of confirming their booking. Cancellations made after this period are
								non-refundable. Please note that cancellations are not permitted for
								bookings made less than 24 hours before the check-in time. In the
								event that a booking is declined or cancelled by the host at any
								point, a full refund will be issued.</Typography> */}
							<Typography sx={{ mb: 2, color: 'var(--grey900)' }}>{extractDataFromArray(paymentDetails, ['cancelationPolicy', 'policyDetails'], '')}</Typography>
							<Typography variant="h6" sx={{ fontWeight: 700 }}>Payment Details</Typography>
							<Typography sx={{ mb: 2, color: 'var(--grey900)' }} >The amount paid is inclusive of the FarWide fee.</Typography>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
								<Typography>{extractDataFromArray(paymentDetails, ['refunds', 'booking_amount', 'caption'], '')}</Typography>
								<Typography>${extractDataFromArray(paymentDetails, ['refunds', 'booking_amount', 'value'], '')}</Typography>
							</Box>
							{/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
								<Typography>{extractDataFromArray(paymentDetails, ['refunds', 'deductions', 'deduction_amount',], '')}</Typography>
								<Typography>${extractDataFromArray(paymentDetails, ['refunds', 'deductions', 'deduction_amount', 'value'], '')}</Typography>
							</Box> */}
							{
								paymentDetails.refunds?.deductions?.map((deductions) => {
									return (
										<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
											<Typography>{extractDataFromArray(deductions, ['caption'], '')}</Typography>
											<Typography>${extractDataFromArray(deductions, ['value'], '')}</Typography>
										</Box>
									)
								})
							}
							<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
								<Typography>{extractDataFromArray(paymentDetails, ['refunds', 'refund_amount', 'caption'], '')}</Typography>
								<Typography>${extractDataFromArray(paymentDetails, ['refunds', 'refund_amount', 'value'], '')}</Typography>
							</Box>
						</>
					}
				</Box>
			</Box>
		</Dialog>
	)
}

export default CancellationPopup;