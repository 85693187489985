import { Box, Typography } from "@mui/material"
import { extractDataFromArray } from "../../../utils/common";
import ApplyStyles from "../../previewPageComponents/htmlParser/ApplyStyles";
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";

const RulesAndSafety = ({ formData, isMobileButtonClicked = false }) => {
    const title = extractDataFromArray(formData, ['caption'], '');
    const isKidAllowed = extractDataFromArray(formData, ['is_kid_allowed'], false)
    const isKidAllowedInfo = extractDataFromArray(formData, ['kids_allowed_additional_info'], '')
    const isPetAllowed = extractDataFromArray(formData, ['is_pet_allowed'], false)
    const isPetAllowedInfo = extractDataFromArray(formData, ['pet_allowed_additional_info'], '')
    const restrictions = extractDataFromArray(formData, ['restrictions'], [])
    const notAllowedDesc = extractDataFromArray(formData, ['not_allowed_description'], [])


    function areAllValuesEmpty(data) {
        return data.every(item => item.min_val === "" && item.max_val === "");
    }

    let showActivityRestrictionTitle = !areAllValuesEmpty(restrictions);


    return (
        <Box sx={{ mb: 3 }}>
            <Typography variant="h5" sx={{
                fontWeight: '600',
                mb: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING,
                fontSize: isMobileButtonClicked && '1.25rem'
            }}>
                {title}
            </Typography>
            <Box>
                <Box sx={{ mb: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }}>
                    <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                        Kids : {isKidAllowed === 'Yes' ? 'Allowed' : 'Not Allowed'}
                    </Typography>
                    {isKidAllowed === 'Yes' && <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                        {isKidAllowedInfo}
                    </Typography>}
                </Box>
                <Box sx={{ mb: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }}>
                    <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                        Pets : {isPetAllowed === 'Yes' ? 'Allowed' : 'Not Allowed'}
                    </Typography>
                    {isPetAllowed === 'Yes' && <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                        {isPetAllowedInfo}
                    </Typography>}
                </Box>
                {showActivityRestrictionTitle && restrictions.length > 0 &&
                    <>
                        <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                            Activity Restrictions:
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            gap: 1,
                            mb: 3,
                            flexWrap: 'wrap'
                        }}>
                            {restrictions.map((restriction, index) => {
                                if (restriction.min_val === '' && restriction.max_val === '') {
                                    return null
                                }

                                if (restriction.min_val === '') {
                                    return (
                                        <Box key={index} sx={{ display: 'flex', gap: 1 }}>
                                            <Typography variant="subtitle1">
                                                {restriction.label}:
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                                                {restriction.max_label} {restriction.max_val}{(restrictions.length - 1) === index ? '' : ','}
                                            </Typography>
                                        </Box>
                                    )
                                }

                                if (restriction.max_val === '') {
                                    return (
                                        <Box key={index} sx={{ display: 'flex', gap: 1 }}>
                                            <Typography variant="subtitle1">
                                                {restriction.label}:
                                            </Typography>
                                            <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                                                {restriction.min_label} {restriction.min_val}{(restrictions.length - 1) === index ? '' : ','}
                                            </Typography>
                                        </Box>
                                    )
                                }

                                return (
                                    <Box key={index} sx={{ display: 'flex', gap: 1 }}>
                                        <Typography variant="subtitle1">
                                            {restriction.label}:
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                                            {restriction.min_val} - {restriction.max_val}{(restrictions.length - 1) === index ? '' : ','}
                                        </Typography>
                                    </Box>
                                )
                            })}
                        </Box>
                    </>
                }
                {notAllowedDesc !== '' && notAllowedDesc !== '<p><br></p>' &&
                    <>
                        <Typography variant="subtitle1" fontWeight={600}>
                            Not Allowed
                        </Typography>
                        <Box
                            sx={{
                                mb: 4
                            }}
                        >
                            <ApplyStyles
                                htmlString={notAllowedDesc}
                            />
                        </Box>
                    </>
                }
            </Box>
        </Box>
    )
}
export default RulesAndSafety
