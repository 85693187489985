import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios} from "../utils/common";

const ActivityTypeHash = {
	'hunting': 'H',
	'fishing': 'F',
	'farmRanch': 'FR',
	'birdWatching': 'BW',
}

const PackageService = {

    getAllListingPackagesData: async (listing_seq, activity_type, callback = () => { }) => {
		await axios.get(config.apiUrl + "/services/" + (config.module === 'lister' ? 'api' : 'openapi'), {
			params: {
				cmd: 'getAllListingPackagesData',
				listing_seq,
				activity_type: extractDataFromArray(ActivityTypeHash, [activity_type], '')
			}
		}).then((response) => {
			response = parseResponseAxios(response)
			if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
				callback(extractDataFromArray(response, ['data', 'records'], []))
			}
		});
	},

    getAllPackagesData: async (callback = () => { }) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: "getAllPackagesData"
            }
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            }
        });
    },

    getPackageData: async (package_seq, callback = () => { },errorCallback = () => { }) => {
        const formData = new FormData();
        formData.append('cmd', 'getPackageData');
        formData.append('package_seq', package_seq);
      
        await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: "getPackagePublicData",
                package_seq
            }
        })
          .then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
              const data = extractDataFromArray(response, ['data'], []);
              callback(data);
            }
            if(extractDataFromArray(response, ['errorCode'], 0) === 1){
                const data = extractDataFromArray(response, ['errorMsg'], []);
                errorCallback(data);
            }
          });
      },

      getCancellationPolicyData: async (type,type_seq, successCB = () => { }, errorCB = () => { }) => {
		await axios.get(config.apiUrl + "/services/openapi", {
			params: {
                cmd: "getCancellationPolicyData",
                type:type,
				type_seq: type_seq,
			}
		}).then((response) => {
			response = parseResponseAxios(response);
			if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
				const data = extractDataFromArray(response, ['data'], []);
				successCB(data);
			} else {
				errorCB(extractDataFromArray(response, ['errorMsg'], ''))
			}
		});
	},

}

export default PackageService;