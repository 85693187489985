import * as React from "react";
import { Box, Grid } from "@mui/material";
import 'swiper/css';
import TitleSection from "../home/section/Titlesection";
import GetReadySection from "../home/section/GetreadySection";
import FeatureSection from "../home/section/FeatureSection";
import ExploreNearby from "../home/section/ExploreNearby";
import Support from "../home/section/Support";
import Footer from "../../layouts/LandingPageLayout/Footer";
import Testimonials from "../home/section/Testimonial";
import "./style.css";
import LandingPageHeader from "../../layouts/LandingPageLayout/Header";
import ListPropertySection from "./section/ListPropertySection";
import DownloadSection from "./section/DownloadSection";
import FarwideSearch from "./section/FarwideSearch";
import { useEffect } from "react";
import { CommonContext } from "../../store/context/commonContext";

const Home = () => {
	const { setSearchFilters, setShowSearchType } = CommonContext()
	useEffect(() => {
		setSearchFilters({ 'activity': "access" });
		setSearchFilters({ 'kw': "" });
		setShowSearchType('')
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);
	return (
		<>
			<Box
				className="home_wrapper"
				style={{
					backgroundImage: "url('../assets/images/homepage/homepage_bg.png')",
				}}
			>
				<Grid
					container
					maxWidth="lg"
					sx={{ m: "auto" }}
					direction="row"
					justifyContent="center"
				>
					{/* header */}
					<TitleSection />
				</Grid >
			</Box>

			<Box>
				<Grid
					item
					container
					maxWidth="lg"
					sx={{ m: "auto", px: { xs: 2, md: 2, lg: 0 } }}
					direction="row"
					justifyContent="center"

				>
					{/* Features */}

					<GetReadySection />
					{/* Featured Properties */}
					<ListPropertySection />


					<Box sx={{ mb: '72px' }}>
						<FeatureSection />
					</Box>
					<DownloadSection />
					<FarwideSearch />
				</Grid >
			</Box>





			{/* sub footer */}
			<Support />
			{/*  footer */}
		</>
	);
};

export default Home;
