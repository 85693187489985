import { Box, Button, Grid, IconButton, LinearProgress, TextField, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserAuth } from "../../../store/context/userContext";
import { useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import MultiSelectCheckboxes from "../../formElement/MultiSelectCheckBoxes";
import config from "../../../config";
import { CommonContext } from "../../../store/context/commonContext";
import GreyLabel from "../../../commonComponents/formElement/GreyLabel";
import LoaderButton from "../../LoaderButton";
import * as Icon from "@mui/icons-material";


const getCharacterValidationError = (str) => {
    return `Your password must have at least 1 ${str} character`;
};
const validationSchema = Yup.object().shape({
    fname: Yup.string().required('First name is required').max(50, "Maximum 50 characters allowed."),
    lname: Yup.string().required('Last name is required').max(50, "Maximum 50 characters allowed."),
    email: Yup.string().required('Email required').email('Invalid email').matches(/@[^.]*\./, 'Invalid email'),
    passwd: Yup.string()
        .required("Please enter a password")
        // check minimum characters
        .min(8, "Password must have at least 8 characters")
        // different error messages for different requirements
        .matches(/[0-9]/, getCharacterValidationError("digit"))
        .matches(/[a-z]/, getCharacterValidationError("lowercase"))
        .matches(/[A-Z]/, getCharacterValidationError("uppercase")),
    phone: Yup.string()
        .matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, {
            message: "Phone number is not valid",
            excludeEmptyString: true
        }),
    user_interest: Yup.array()
        .transform(ids => {
            if (Array.isArray(ids)) {
                return ids.filter(id => {
                    return id === 0 || id;
                });
            }
            else {
                ids = (ids === false) ? [] : [ids];
                return ids;
            }
        })
});

const SignUp = ({ handleOpen, handleClose }) => {
    let { authPopup, setAuthPopup, user } = UserAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const closePopup = () => {
        setAuthPopup({ ...authPopup, open: false })
        if (
            !(location.pathname.includes('/search') ||
                location.pathname.includes('/package') ||
                location.pathname.includes('/property') ||
                location.pathname.includes('/aboutus') ||
                location.pathname.includes('/contactus'))
        ) {
            if (!user) {
                navigate('/')
            }
        }
    }

    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        passwd: '',
        phone: '',
        user_interest: [config.module === 'lister' ? 'Landowner' : 'Guest']
    });
    let { registerUser } = UserAuth()
    const [loading, setLoading] = useState(false)
    const { isMobile, isNineHundred } = CommonContext();

    const handleRegister = (data) => {
        setLoading(true);

        // Convert user_interest array to JSON string
        const jsonProfileType = JSON.stringify(formData.user_interest.map(item => item === 'Landowner' ? 'L' : (item === 'Guest' ? 'G' : item)));

        // Update the form data with the modified user_interest
        const updatedFormData = { ...formData, user_interest: jsonProfileType };

        registerUser(updatedFormData, () => {
            handleClose();
        }, () => {
            setLoading(false);
        });
    }




    const handleSelect = (value) => {
        setFormData({ ...formData, user_interest: value })
    }

    const onInput = (e) => {
        let temp = {}
        temp[e.target.name] = e.target.value
        setFormData({ ...formData, ...temp })
    }

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {}
    });

    return (
        <>
            {<Box sx={{ float: "right", m: 1, position: 'absolute', top: 0, right: 0 }}>
                <IconButton onClick={closePopup}><Icon.Close /></IconButton>
            </Box>}
            <Grid container maxWidth="lg" sx={{ m: "auto", overflow: 'auto', color: 'var(--grey900)' }}
                direction="row"
                justifyContent="center"
                alignItems="stretch">
                {!isNineHundred && <Grid item xs={12} sm={8} md={6}>
                    <Box sx={{ backgroundColor: "#212121", height: "100%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <img className="responsive_img" src="/assets/images/login/login_image.png" alt="FarWide Login" />
                    </Box>
                </Grid>}
                <Grid item xs={12} sm={8} md={6}>
                    {loading && <LinearProgress color="success" />}
                    {/* {<Box sx={{ float: "right", m: 1, }}>
                        <IconButton onClick={closePopup}><Icon.Close /></IconButton>
                    </Box>} */}
                    <Box sx={{ p: isNineHundred ? 2 : 6 }}>
                        <Box sx={{ mb: 3 }}>
                            <img src='../assets/images/farwide-new-logo.svg' alt='FarWide' />
                        </Box>

                        <Typography variant="h4" color="secondary" sx={{ fontWeight: 700, mb: 1 }}>Create New Account</Typography>
                        <Typography sx={{ mb: 2, color: 'var(--grey900)' }}>Already a member? <Link onClick={() => handleOpen("signin")} color='secondary' >Sign In</Link></Typography>

                        {/* <Grid container spacing={2} sx={{ mb: 3, mt: 1 }}>
                        <Grid ite m>
                            <Button variant="contained" color="grey300" size="large"
                                style={{ textTransform: "none" }}
                                startIcon={<img src="/lister/assets/images/login/fb_color_icon.svg" alt="" />}>
                                Sign Up with Facebook
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button variant="contained" color="grey300" size="large"
                                style={{ textTransform: "none" }}
                                startIcon={<img src="/lister/assets/images/login/google_color_icon.svg" alt="" />}>
                                Sign Up with Google
                            </Button>
                        </Grid>

                    </Grid>
                    <div className="linethrough"><span>or sign up with your email </span></div> */}

                        <Grid container spacing={3}>

                            <Grid item xs={12} sm={6} md={6}>
                                <GreyLabel label={'First Name'} required />
                                <TextField
                                    fullWidth
                                    name="fname"
                                    sx={{ "& .MuiOutlinedInput-root": { height: '42px' } }}
                                    label=""
                                    variant="outlined"
                                    value={formData.fname}
                                    onInput={onInput}
                                    {...register("fname")}
                                    error={errors.fname ? true : false}
                                    helperText={errors.fname?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <GreyLabel label={'Last Name'} required />
                                <TextField
                                    fullWidth
                                    name="lname"
                                    sx={{ "& .MuiOutlinedInput-root": { height: '42px' } }}
                                    label=""
                                    variant="outlined"
                                    value={formData.lname}
                                    onInput={onInput}
                                    {...register("lname")}
                                    error={errors.lname ? true : false}
                                    helperText={errors.lname?.message}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <GreyLabel label={'Email'} required />
                                <TextField
                                    fullWidth
                                    name="email"
                                    sx={{ "& .MuiOutlinedInput-root": { height: '42px' } }}
                                    label=""
                                    type={"email"}
                                    variant="outlined"
                                    value={formData.email}
                                    onInput={onInput}
                                    {...register("email")}
                                    error={errors.email ? true : false}
                                    helperText={errors.email?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <GreyLabel label={'Password'} required />
                                <TextField
                                    fullWidth
                                    name="passwd"
                                    sx={{ "& .MuiOutlinedInput-root": { height: '42px' } }}
                                    label=""
                                    type={"Password"}
                                    variant="outlined"
                                    value={formData.passwd}
                                    onInput={onInput}
                                    {...register("passwd")}
                                    error={errors.passwd ? true : false}
                                    helperText={errors.passwd?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography sx={{ color: 'var(--grey900)', mb: 1 }} >Phone Number</Typography>
                                <TextField
                                    fullWidth
                                    name="phone"
                                    sx={{ "& .MuiOutlinedInput-root": { height: '42px' } }}
                                    label=""
                                    variant="outlined"
                                    value={formData.phone}
                                    onInput={onInput}
                                    {...register("phone")}
                                    error={errors.phone ? true : false}
                                    helperText={errors.phone?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{ mb: 1 }}>
                                <Typography sx={{ color: 'var(--grey900)', mb: 1 }}>How do you plan to use FarWide Experience?</Typography>
                                <Box className="bordered_wrapper">
                                    <MultiSelectCheckboxes
                                        handleSelect={handleSelect}
                                        name={"user_interest"}
                                        sx={{ "& .MuiOutlinedInput-root": { height: '42px' } }}
                                        itemList={['Guest', 'Landowner']}
                                        selectedItems={formData.user_interest}
                                        register={register}
                                        error={errors.user_interest ? true : false}
                                        helperText={errors.user_interest?.message}
                                        module={config.module}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                {/* <Button fullWidth variant='contained' onClick={() => { handleSubmit(handleRegister)() }}>
                                Sign Up
                            </Button> */}
                                <LoaderButton
                                    variantType='contained'
                                    onClick={() => { handleSubmit(handleRegister)() }}
                                    isLoading={loading}
                                    buttonText="Sign Up"
                                    isFullWidth={true}
                                />
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="caption">By creating your account, you agree to accept our <Link to="https://search.farwide.com/privacy-terms" color='secondary' target={isMobile ? "" : "_blank"} >terms and conditions.</Link></Typography>
                                </Box>

                            </Grid>
                        </Grid>
                    </Box>


                </Grid>
            </Grid>
        </>
    )
}
export default SignUp