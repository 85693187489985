import { UserAuth } from "../../store/context/userContext";
import AuthPopup from "../../commonComponents/authComponents/AuthPopup";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { empty } from "../../utils/common";

const Login = () => {
    const [searchParams,] = useSearchParams();
    const {  authPopup, setAuthPopup } = UserAuth()

    let navigate = useNavigate()
    let redirect = searchParams.get('done')
    redirect = empty(redirect) ? '/' : redirect

    const openAuthPopup = (type) => {
        setAuthPopup({ ...authPopup, type: type, open: true })
    }
    const closeAuthPopup = () => {
        setAuthPopup({ ...authPopup, open: true })
        // navigate('/')
    }
    const [lock, setLock] = useState(false)
    useEffect(()=>{
        if(!lock){
            setAuthPopup({ ...authPopup, type: "signin", open: true, callback: () => { navigate(redirect) }  })
            setLock(1)
        }
    }, [])

    return (<>
        <AuthPopup open={authPopup.open} type={authPopup.type} handleClose={() => { closeAuthPopup(false) }} handleOpen={openAuthPopup} />
    </>)
}

export default Login;