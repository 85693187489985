import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { UserAuth } from "../../../store/context/userContext";
import GreyLabel from "../../../commonComponents/formElement/GreyLabel";
import { toast } from "react-toastify";
import UserService from "../../../services/user";
import config from "../../../config";

const ChangePassword = () => {
  const { user } = UserAuth();
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [cnfpasswordError, setcnfPasswordError] = useState("");
  const [passwordsMatchError, setPasswordsMatchError] = useState(false);


  const handlePasswordChange = () => {
    // Validate password using regex (minimum 8 characters)
    const passwordRegex = /^(?=.*[a-zA-Z0-9]).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setPasswordError('Password must be at least 8 characters long');
      return;
    }

    // Check if passwords match
    if (newPassword !== confirmNewPassword) {
      // Handle password mismatch
      setPasswordsMatchError(true);

      // setcnfPasswordError('Passwords do not match');
      return;
    } else {
      setPasswordsMatchError(false);
    }


    // Reset password error if validation passes
    // setPasswordError("");

    UserService.updatePassword(newPassword, (data) => {
      toast.success('Password Changed Successfully', {
        theme: 'colored'
      })
    })

    // Reset the password fields after successful change
    setNewPassword("");
    setConfirmNewPassword("");
  };

  return (
    <Box sx={{ border: " 1px solid #E5E7EB", borderRadius: "16px", mb: 6 }}>
      <Box>
        <Typography
          sx={{
            color: "#1B1B1B",
            fontSize: "28px",
            fontWeight: "800",
            borderBottom: "1px solid #C5C5C7",
            p: { xs: 2, sm: 2, md: 3, lg: 3 },
          }}
        >
          Change Password
          <Typography
            sx={{
              color: "#1B1B1B",
              marginBottom: "10px",
              fontSize: "14px",
            }}
          >
            Your current email address is{" "}
            <span style={{ color: "#26C77A" }}>{user.email}</span>
          </Typography>
        </Typography>
      </Box>

      <Box sx={{  p: { xs: 2, sm: 2, md: 3, lg: 3 } }}>
        <GreyLabel label={"New password"} sx={{ mb: 0.5 }} />
        <TextField
          fullWidth
          value={newPassword}
          autoComplete="new-password" //Added this to avoid password and email or any suggestions by browser
          onChange={(e) => { setNewPassword(e.target.value); setPasswordError(""); }}
          sx={{ mb: 2 }}
          size="small"
          type="password"
          error={passwordError !== ""}
          helperText={passwordError}
          InputProps={{
            autoComplete: 'new-password'
          }}
        />

        <GreyLabel label={"Confirm Password"} sx={{ mb: 0.5 }} />
        <TextField
          fullWidth
          value={confirmNewPassword}
          autoComplete="new-password" //Added this to avoid password and email or any suggestions by browser
          onChange={(e) => { setConfirmNewPassword(e.target.value); setcnfPasswordError(""); setPasswordsMatchError(false); }}
          type="password"
          error={cnfpasswordError !== "" || passwordsMatchError}
          helperText={cnfpasswordError || (passwordsMatchError && "Passwords do not match")}
          InputProps={{
            autoComplete: 'new-password'
          }}
          size="small"
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 3,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handlePasswordChange}
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
