import { Outlet, useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import { UserAuth } from '../../store/context/userContext';
import LocalDB from '../../utils/localStorage';
import Header from "./Header";
import SideBar from "./Sidebar";
import { useEffect, useRef, useState } from 'react';
import { ROUTE_PAYMENT } from '../../constants/page';
import { CommonContext } from '../../store/context/commonContext';
import { empty, exponentialBackoff } from '../../utils/common';

const DashboardLayout = () => {
    const location = useLocation();
    const { isTablet } = CommonContext();
    const { seqValue, setSeqValue } = UserAuth()
    const seqFromLocalStorage = LocalDB.get('userData', 'JSON');

    // setSeqValue(seqFromLocalStorage.user_seq);
    // console.log("seqFromLocalStorage.seq", seqFromLocalStorage)
    const showSidebar = ['/checkout', ROUTE_PAYMENT].includes(
        location.pathname
    );
    const [open, setOpen] = useState(!isTablet);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const params = useParams()
    const topRef = useRef(null);

  
    useEffect(() => {
      
        exponentialBackoff(() => {
         
            return topRef?.current !== null
        },  5 , 1000, () => {
          
            if(!empty(params)) {
                topRef?.current?.scrollIntoView();
            } else {

            }
        })
 
    }, [params]); 

    return (<Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header toggleDrawer={toggleDrawer} open={open} />
        {!showSidebar && <SideBar toggleDrawer={toggleDrawer} open={open} />}
        
        <Box       
            component="main"
            sx={{
                flexGrow: 1,
                height: '100svh',
                overflow: 'auto',
            }}
        >
                <Toolbar ref={topRef} sx={{
                "& .MuiToolbar-root": {
                    height: '72px'
                },
            }} />
            <Grid container maxWidth="lg" sx={{ m: "auto"}}>
                <Grid item md={12} sm={12} xs={12} sx={{ pl: { xs: 2, md: 3, lg: 3 }, pr: { xs: 2, md: 3, lg: 3 },pb: { xs: location.pathname.includes('/chat') ? 0 : 2, md: 3, lg: 3 },  paddingTop: location.pathname.includes('/chat') ? '30px' : 5}}>
                    <Outlet />
                </Grid>

            </Grid>
        </Box>
    </Box>)
}

export default DashboardLayout