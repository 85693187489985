import { Box, Grid, Typography } from "@mui/material";
import { FileManagerContent } from "../../commonComponents/FileManager";

const FileManager = () => {
    return (<>
        <Box sx={{p:3}}>
            <Typography variant='h3' sx={{pb :2}}>FileManager </Typography>
            <FileManagerContent label={"Add photos"} />
        </Box>
    </>)
}

export default FileManager;