import { Box, Divider, Typography } from "@mui/material";
import { empty } from "../../utils/common";
import { CommonContext } from "../../store/context/commonContext";
const About = ({ desc }) => {
    const { isMobile } = CommonContext()

    return (
        <>
            {
                empty(desc) ? <></> :
                    <Box>
                        <Typography variant="h4">About</Typography>
                        <Divider sx={{ mt: isMobile ? 1 : 2, mb: isMobile ? 2 : 3 }} />
                        <Typography color="grey" >
                            {desc}
                        </Typography>
                    </Box>
            }
        </>
    )
}

export default About;