import React, { useEffect } from 'react';

import PackageDetail from "../../commonComponents/PackageDetails";
import { Box } from '@mui/material';
import { CommonContext } from '../../store/context/commonContext';
import { showZendeskLauncher } from '../../utils/common';






const PackageDetails = () => {
	const { isTablet } = CommonContext();
    useEffect(() => {
        showZendeskLauncher(!isTablet)
    }, [isTablet])

	return (
		<Box maxWidth={'lg'} sx={{ mx: 'auto', mt : 3 }}>
			<PackageDetail style={{}} />
		</Box>
	)
}

export default PackageDetails