import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { ImagePreview } from "./ExpHeaderGallary";
import MediaCard from "../../previewPageComponents/mediaCard";

const ExpereinceOverviewImages = ({ images }) => {
    const maxDisplayImages = 3;

    const [open, setOpen] = useState(false);
    const [imageIndex, setSelectedImageIndex] = useState(0);

    const handleOpen = (imageIndex) => {
        setOpen(true);
        setSelectedImageIndex(imageIndex);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
            {Array.isArray(images) && images.slice(0, maxDisplayImages).map((media, index) => (
                <Box
                    key={index}
                    sx={{
                        width: '138px',
                        height: '97px',
                        borderRadius: '16px',
                        position: 'relative',
                        cursor: 'pointer'
                    }}
                    onClick={() => { handleOpen(index) }}
                >
                    <MediaCard
                        imageObj={media}
                        width='138px'
                        height='97px'
                        borderRadius={'16px'}
                    />
                </Box>
            ))}
            {Array.isArray(images) && images.length > maxDisplayImages && (
                <Box
                    sx={{
                        width: '138px',
                        height: '97px',
                        borderRadius: 4,
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        backgroundImage: `url('${images[maxDisplayImages].type === 'IMAGE'
                                ? images[maxDisplayImages].url
                                : images[maxDisplayImages].thumbnail_url
                            }')`
                    }}
                    onClick={() => { handleOpen(0) }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            width: '100%',
                            height: '100%',
                            borderRadius: 4,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography sx={{ color: 'white' }}>
                            {`View All`}
                        </Typography>
                    </Box>
                </Box>
            )}

            <ImagePreview
                images={images}
                open={open}
                imageIndex={imageIndex}
                setSelectedImageIndex={setSelectedImageIndex}
                handleClose={handleClose}
            />
        </Box>
    );
};

export default ExpereinceOverviewImages;
