import * as React from "react";

import { Box, Grid, Typography } from "@mui/material";

const Safety = (props) => {
  const safetyData = [
    {
      title: "Guest ID Verification",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
      title: "Two-way Ratings",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
    {
      title: "Landowner Acceptance/Approval",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
    },
  ];
  return (
    <Grid container spacing={10} className="mobmarginbottom" sx={{ mb: 10 }}>
      <Grid item xs={12} md={6}>
        <img
          alt=""
          style={{ width: "100%", maxWidth: "100%" }}
          src="../assets/images/safty_image.svg" // Replace with your image URL
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ padding: { xs: 2, sm: 3 }, textAlign: "left" }}>
          <Typography className="safety_title" sx={{ mb: 2 }}>
            Safty
          </Typography>
          {safetyData.map((item, index) => (
            <Box key={index}>
              <Typography className="sub_title">{item.title}</Typography>
              <Typography sx={{ mb: 2 }}>{item.content}</Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Safety;
