import * as React from "react";

import { Grid, Typography } from "@mui/material";

const OurStory = (props) => {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <Typography className="about_sub_title text-center" sx={{ mb: 2.5 }}>
        {" "}
        Our Story{" "}
      </Typography>
      <Typography className="about_discription text-center" sx={{ mb: 2 }}>
        As hardcore outdoor enthusiasts, we are always on a lookout for new and
        better places and activities to content the adventurer within us. Often,
        the overall experience is diminished by the hours of time and energy we
        have to spend on basic planning and scheduling. In the end, most places
        are often overcrowded, chaotic and take away the pleasure. FarWide
        Experience is what we wanted for ourselves. An opportunity to just do
        what we wanted to and in an easier, less chaotic manner, making every
        trip a fond memory forever.
      </Typography>

      <Typography className="about_discription text-center">
        We are really delighted to share this dream of ours with all of you.
        Let's Book memories!!{" "}
      </Typography>
    </Grid>
  );
};

export default OurStory;
