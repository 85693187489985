import { Box, Divider, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import { empty, extractDataFromArray, isValueEqual } from "../../utils/common";
import { styled } from '@mui/material/styles';
import CustomTooltip from "../CustomTooltip";


const ColumnBox = styled(Box)(
    ({ theme }) => `
        display: flex;
        gap: 0.5rem;
        flex: 50%;
        ${theme.breakpoints.down("sm")} {
            flex: 100%;
          }
        width: 100%;
        margin-bottom: 0.5rem;
  `,
);


const AddOns = ({ title, icon, value, tooltip, titleDelimiter = ":" }) => {

    return (
        <>
            <ColumnBox sx={{ display: 'flex', alignItems: 'flex-start', gap: 1, mb: 1 }} >
                <Icon.ArrowForward />
                <Typography color="grey"> {title}{titleDelimiter} {value}</Typography>
                {
                    !empty(tooltip) && <CustomTooltip content={tooltip} />
                }

            </ColumnBox>

        </>
    )
}
const BirdWatchingDetails = ({ packageData, activityData }) => {
    const isAmentitiesAvailable = isValueEqual(extractDataFromArray(activityData, ['bird_blinds', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['bird_feeders', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['catering', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['activities', 'available'], 'No'), 'Yes')
    const isNearbyLocationAvailable = isValueEqual(extractDataFromArray(activityData, ['national_parks_available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['national_wildlife_refuges_available'], 'No'), 'Yes')

    const isHabitatAvailable = !empty(extractDataFromArray(activityData, ['habitat', 'list'], []))

    const isSpeciesAvailable = !empty(extractDataFromArray(activityData, ['bird_species'], ''))

    if (!isAmentitiesAvailable && !isHabitatAvailable && !isSpeciesAvailable && !isNearbyLocationAvailable) {
        return <></>
    }

    return (
        <>
            <Typography variant="h4">
                Bird Watching Details
            </Typography>
            <Divider sx={{ mb: 3, mt: 2 }} />
            {
                isSpeciesAvailable &&

                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>Most Popular Bird Species</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            extractDataFromArray(activityData, ['bird_species'], '').split(",").map((sp, i) => {
                                return <AddOns key={"species_" + i} title={sp.trim()} titleDelimiter={""} />
                            })
                        }
                    </Box>
                </Box>
            }
            {
                isHabitatAvailable &&

                <Box sx={{ mb: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                        <Typography sx={{ fontWeight: 700 }}>Included Species</Typography>
                        {
                            !empty(extractDataFromArray(activityData, ['habitat', 'additional_info'], '')) && <CustomTooltip content={extractDataFromArray(activityData, ['habitat', 'additional_info'], '')} />
                        }
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            extractDataFromArray(activityData, ['habitat', 'list'], []).map((sp, i) => {
                                return <AddOns key={"h_species_" + i} title={sp} titleDelimiter={""} />
                            })
                        }
                    </Box>
                </Box>
            }
            {
                isAmentitiesAvailable &&

                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>Amenities</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            isValueEqual(extractDataFromArray(activityData, ['bird_blinds', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Bird Blinds"}
                                value={extractDataFromArray(activityData, ['bird_blinds', 'total'], [])}
                                tooltip={extractDataFromArray(activityData, ['bird_blinds', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['bird_feeders', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Bird Feeders"}
                                value={extractDataFromArray(activityData, ['bird_feeders', 'total'], [])}
                                tooltip={extractDataFromArray(activityData, ['bird_feeders', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['catering', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Catering"}
                                titleDelimiter={""}
                                tooltip={extractDataFromArray(activityData, ['catering', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['activities', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Activities"}
                                titleDelimiter={""}
                                tooltip={extractDataFromArray(activityData, ['activities', 'additional_info'], '')}
                            />
                        }
                    </Box>
                </Box>
            }
            {
                isNearbyLocationAvailable &&

                <Box sx={{ mb: 1, }}>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>Nearby Location</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            isValueEqual(extractDataFromArray(activityData, ['national_parks_available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"National Park"}
                                value={"Located within 25 miles"}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['national_wildlife_refuges_available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"National Wildlife Refuge"}
                                value={"Located within 25 miles"}
                            />
                        }
                    </Box>
                </Box>
            }

        </>
    )
}

export default BirdWatchingDetails;
