import React, { useContext, useEffect } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as Icon from '@mui/icons-material';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Link, useLocation } from 'react-router-dom';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { Button, Divider, Tooltip } from '@mui/material';
import { CommonContext } from '../../../store/context/commonContext';
import { MessagesApi } from '../../../services/messagesApi';
import { MessageContext } from '../../../store/context/messageContext';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const sideBarItems = [
	{
		link: '/dashboard',
		label: 'Dashboard',
		icon: <Icon.HomeOutlined />
	},
	{
		link: '/bookings',
		label: 'Bookings',
		icon: <Icon.EventOutlined />
	},
	{
		link: '/chat',
		label: 'Messages',
		icon: <Icon.ChatOutlined />,
		status: <FiberManualRecordIcon sx={{ width: 10, color: "#26C77A" }} />
	},
	{
		link: '/wishlist',
		label: 'Wishlist',
		icon: <FavoriteBorderOutlinedIcon />
	},
	{
		link: '/transactions',
		label: 'Transactions',
		icon: <Icon.MonetizationOnOutlined />
	},
	{
		link: '/account',
		label: 'Account Settings',
		icon: <SettingsOutlinedIcon />
	},
	{
		type: 'divider'
	},
	{
		link: 'https://help.farwide.com/',
		label: 'Help',
		target: '_blank',
		icon: <Icon.InfoOutlined />
	},
];

const SidebarItems = ({ currentItem, handleItemClick, isSidebarOpen }) => {
	// console.log(isSidebarOpen)
	const location = useLocation();
	const { randomNumber, setRandomNumber, newMessageReceivedStatus, setNewMessageReceivedStatus } = MessageContext();

	const fetchNewMessageStatus = async () => {
		await MessagesApi.getNewMessageReceivedStatus(
			(res) => {
				const timestamp = new Date().getTime();
				const newRandomNumber = Math.floor(Math.random() * timestamp);
				setRandomNumber(newRandomNumber);
				setNewMessageReceivedStatus(res.received_new_message);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			fetchNewMessageStatus();
		}, 20000);
		return () => clearInterval(intervalId);
	}, []);

	if (!location.pathname.includes("/bookings") && !location.pathname.includes("/bookingdetails") && !location.pathname.includes("/booking")) {
		localStorage.removeItem("tab");
		localStorage.removeItem("bookingStatus");
	}
	//   console.log("pathname", !location.pathname.includes("/bookings") && !location.pathname.includes("/bookingdetails") && !location.pathname.includes("/booking"))

	return (
		<React.Fragment>
			{sideBarItems.map((item, index) => {
				if (item.type === 'divider') {
					return <Divider sx={{ my: 2.5 }} key={"sidebar_" + index} />;
				}

				currentItem = currentItem === "/" ? currentItem + "dashboard" : currentItem;

				if (currentItem.startsWith("/chat")) {
					currentItem = "/chat";
				}
				if (currentItem.startsWith("/transaction")) {
					currentItem = "/transactions"
				}
				if (currentItem.startsWith("/booking")) {
					currentItem = "/bookings"
				}
				return (
					<Link
						to={item.link}
						style={{ textDecoration: "none" }}
						key={"sidebar_" + index}
						target={item.target || ''}
					>
						{!isSidebarOpen && <Tooltip title={item.label} placement="right">
							<ListItemButton
								selected={currentItem === item.link}
								sx={{ mb: 0.5, height: 40, display: 'flex', justifyContent: 'center', }}
								onClick={() => handleItemClick(item.link)}
							>
								<ListItemIcon sx={{ mr: 0 }}>{item.icon}</ListItemIcon>
								{isSidebarOpen && < ListItemText primary={item.label} sx={{ ml: 1, "& .MuiListItemText-primary": { fontWeight: 500 } }} />}

								{newMessageReceivedStatus && <ListItemIcon sx={{ mr: 0 }}> {item.status} </ListItemIcon>}

							</ListItemButton>
						</Tooltip>}
						{isSidebarOpen && <ListItemButton
							selected={currentItem === item.link}
							sx={{ mb: 0.5, height: 40, display: 'flex', justifyContent: 'center', }}
							onClick={() => handleItemClick(item.link)}
						>
							<ListItemIcon sx={{ mr: 0 }}>{item.icon}</ListItemIcon>
							{isSidebarOpen && < ListItemText primary={item.label} sx={{ ml: 1, "& .MuiListItemText-primary": { fontWeight: 500 } }} />}

							{newMessageReceivedStatus && <ListItemIcon sx={{ mr: 0 }}> {item.status} </ListItemIcon>}

						</ListItemButton>}

					</Link>
				);
			})}
		</React.Fragment>
	);
};

export default SidebarItems;
