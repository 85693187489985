import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { UserProvider } from './store/context/userContext';
import { CommonContextProvider } from './store/context/commonContext';
import { MessageContextProvider } from './store/context/messageContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<UserProvider>

		<CommonContextProvider>
			<BrowserRouter basename='' >
				<MessageContextProvider>
					<App />
					<ToastContainer autoClose={10000} />
				</MessageContextProvider>
			</BrowserRouter>
		</CommonContextProvider>
	</UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals()
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
