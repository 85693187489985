
import { ROUTE_SEARCH } from "../constants/page";
import SearchLayout from "../layouts/SearchLayout";
import ExperienceView from "../pages/experience";
import PackageDetails from "../pages/packageDetails";
import Property from "../pages/propertyDetails";
import SearchListing from '../pages/search';

const SearchPageRoutes = {
    path: '/',
    element: <SearchLayout />,
    children: [
        {
            path: ROUTE_SEARCH,
            element: <SearchListing />
        },
        {
            path: "/property/:property_id",
            element: <Property />
        },
        {
            path: "/experience/:exp_seq",
            element: <ExperienceView />
        },
        {
            path: "/package/:package_id",
            element: <PackageDetails />
        },
    ]
};

export default SearchPageRoutes;


