import { Box, Button, Chip, CircularProgress, IconButton, LinearProgress, TextField, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useEffect, useState } from "react";
import UserService from "../../services/user";
import config from "../../config";
import { empty, extractDataFromArray } from "../../utils/common";
import { Link, useLocation } from "react-router-dom";
import IDVerificationPopup from "../IdVerificationPopup";
import { UserAuth } from "../../store/context/userContext";
import { toast } from "react-toastify";
import { CommonContext } from '../../../src/store/context/commonContext'
import LoaderButton from "../LoaderButton";
import LoaderChip from "../LoaderChip";

const AccountVerification = ({ subtitle = "Please click on each of the actions below to complete the setup:", location, sx = {}, completedCallback = () => { }, inCompleteCallback = () => { }, emailCompletedCallback = () => { }, emailVerified = null, skipIDVerification = false, disableSubmit, setDisableSubmit = () => { } ,setOpenIdPopup}) => {
    const [status, setStatus] = useState({})
    const [completionPercentage, setCompletionPercentage] = useState()
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(true)
    const [emailLoadingButton, setEmailLoadingButton] = useState(false)
    const [verifyEmailLoadingButton, setVerifyEmailLoadingButton] = useState(false)
    const [stripeLoadingButton, setStripeLoadingButton] = useState(false)
    const { user } = UserAuth();
    const [open, setOpen] = useState(false)
    const [showEmailVerification, setShowEmailVerification] = useState(false)
    const [otp, setOtp] = useState("")
    const [otpError, setOtpError] = useState("");
    const { isMobile, isStripeAdded, setIsStripeAdded } = CommonContext();
    const locationRoute = useLocation()
    // console.log(disableSubmit);


    const refreshStatus = (refreshToast = false) => {
        setLoader(true)
        UserService.getAccountCompletionStatus((data) => {
            // console.log(data)
            setLoader(false)
            setLoading(false)
            setStatus(data)
            setIsStripeAdded(extractDataFromArray(data, ['stripe_connect'], ''))
            let statusCompleted = 0;
            Object.keys(data).forEach(key => {
                // if (key === 'id_verified') {
                //     if ((data[key] === "P") || data[key] === 'V') {
                //         statusCompleted++;
                //     }
                // } else if (data[key]) {
                //     statusCompleted++;
                // }
                if (config.module === 'guest') {
                    if (key === 'id_verified') {
                        if (data[key] === 'V') {
                            statusCompleted++;
                        }
                    } else if (data[key]) {
                        statusCompleted++;
                    }
                } else {
                    if (key === 'id_verified') {
                        if ((data[key] === "P") || data[key] === 'V') {
                            statusCompleted++;
                        }
                    } else if (data[key]) {
                        statusCompleted++;
                    }
                }
            })
            // console.log('POP up statusCompleted',statusCompleted);
            if (statusCompleted === 2) {
                setDisableSubmit(false)
            }

            // console.log(statusCompleted)
            setCompletionPercentage((config.module === 'lister' ? 70 : 80) + (statusCompleted * 10))

            if (refreshToast) {
                toast.success("Refreshed successfully", { theme: 'colored' })
            }
        })
    }
    useEffect(() => {
        if (loading) {
            refreshStatus()
        }
    }, [loading])





    useEffect(() => {
        if (emailVerified !== null && status.email_verified === false) {
            setStatus({ ...status, email_verified: emailVerified })
            if (emailVerified && completionPercentage < 100) {
                setCompletionPercentage((completionPercentage + 10))
            }
        }
    }, [emailVerified])
    if (loading) {
        return

    }

    if (completionPercentage === 100) {
        completedCallback()
        return
    } else {
        inCompleteCallback()
    }

    const sendVerification = () => {
        setVerifyEmailLoadingButton(true)
        UserService.sendVerificationCode((data) => {
            setShowEmailVerification(true)
            setVerifyEmailLoadingButton(false)
        }, (err) => {
            setShowEmailVerification(false)
            setVerifyEmailLoadingButton(false)
        })
    }

    const verifyEmail = () => {
        if (empty(otp)) {
            setOtpError("OTP can not be empty")
        } else {
            setEmailLoadingButton(true)
            UserService.verifyEmail(otp, user.email, () => {
                setShowEmailVerification(false)
                setStatus({ ...status, email_verified: true })
                setCompletionPercentage((completionPercentage + 10))
                emailCompletedCallback()
                toast.success("Email verified successfully", { theme: 'colored' })
                setEmailLoadingButton(false)
            },
                (errMsg) => {
                    // Handle error here, e.g., show an error message
                    console.error("Error during email verification:", errMsg);
                    toast.error(errMsg, {
                        theme: "colored",
                    });
                    setOtpError(errMsg);
                    setEmailLoadingButton(false)
                }
            )
        }
    }
    // console.log("LOCATION", locationRoute.pathname);

    return (
        <Box>
            <Box sx={{ background: 'var(--grey300)', borderRadius: (theme) => `${theme.card.borderRadius} `, p: isMobile ? 2 : 3, ...sx }}>
                {location === "Experience" ?

                    <Typography sx={{ fontWeight: 700 }} >Complete account setup to submit and publish this experience
                    </Typography>
                    :
                    <Typography sx={{ fontWeight: 700 }} >{config.module === 'guest' ? "Complete your account setup to start booking properties." : "Complete your account setup to publish your listing"}</Typography>
                }
                <Box sx={{ textAlign: "right", width: "100%" }}>
                    <Typography color="gray" variant="caption" sx={{ mb: 1 }}>{completionPercentage}%</Typography>
                </Box>
                <LinearProgress value={completionPercentage} variant="determinate" sx={{ height: 6, borderRadius: 1, backgroundColor: '#E0F7EC !important', mb: 2 }} />
                <Typography color="gray" sx={{ mb: 2 }}>{subtitle}</Typography>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {
                            extractDataFromArray(status, ['email_verified'], false) ?
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                    <Icon.Verified color="primary" fontSize="16" />
                                    <Typography color="primary">Email Verified</Typography>
                                </Box>
                                :
                                // <Button variant="outlined" startIcon={<Icon.MailOutlined />} sx={{ cursor: 'pointer', backgroundColor: "#fff" }} color="secondary" onClick={sendVerification} >Verify Email</Button>
                                <LoaderButton
                                    variantType='outlined'
                                    buttonColor="secondary"
                                    onClick={() => { sendVerification() }}
                                    buttonBackGorundColor="#fff"
                                    ButtonIcon={<Icon.MailOutlined />}
                                    isLoading={verifyEmailLoadingButton}
                                    buttonText={
                                        <>
                                            Verify Email
                                            <span style={{ color: 'red', marginLeft: '1ch' }}>*</span>
                                        </>
                                    }
                                    isFullWidth={false}
                                />


                        }
                        {
                            config.module === 'lister' &&
                            (
                                extractDataFromArray(status, ['stripe_connect'], false) ?
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                        <Icon.Verified color="primary" fontSize="16" />
                                        <Typography color="primary" sx={{ fontWeight: 500 }}>Stripe Connect</Typography>
                                    </Box> :
                                    <Link to="/account?s=payout" target={"_blank"} ><Button variant="outlined" startIcon={<Icon.MonetizationOnOutlined />} sx={{ cursor: 'pointer', backgroundColor: "#fff" }} color="secondary" >Add Payout Details<span style={{ color: 'red', marginLeft: '1ch' }}>*</span> </Button></Link>
                            )
                        }
                        {
                            extractDataFromArray(status, ['id_verified'], "I") === "V" ?
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                    <Icon.Verified color="primary" fontSize="16" />
                                    <Typography color="primary" sx={{ fontWeight: 500 }}>ID Verified</Typography>
                                </Box>
                                :

                                extractDataFromArray(status, ['id_verified'], "I") === "P" ?
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                        <Icon.Verified color="primary" fontSize="16" />
                                        <Typography color="primary" sx={{ fontWeight: 500 }}>Awaiting ID Approval</Typography>
                                    </Box>
                                    :

                                    extractDataFromArray(status, ['id_verified'], "I") === "F" ?
                                        <>
                                            <Button startIcon={<Icon.ErrorOutlineOutlined />} variant="contained" color="error" sx={{ cursor: 'pointer', }} onClick={() => { setOpen(true) }} >Verification Failed. Retry</Button>
                                            <IDVerificationPopup open={open} handleClose={() => { setOpen(false) }} callback={() => { setStatus({ ...status, id_verified: true }); refreshStatus() }} source="diff" />
                                        </>

                                        // <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                        //     <Icon.Verified color="error" fontSize="16" />
                                        //     <Typography color="error" sx={{ fontWeight: 500 }}></Typography>
                                        // </Box>
                                        :
                                        <>
                                            {/* <Button startIcon={<Icon.PermContactCalendarOutlined />} variant="outlined" color="secondary" onClick={() => { setOpen(true) }} sx={{ cursor: 'pointer', backgroundColor: "#fff" }} >Verify IDDDD</Button> */}
                                            <LoaderButton
                                                variantType='outlined'
                                                buttonColor="secondary"
                                                onClick={() => { setOpen(true); setStripeLoadingButton(true); }}
                                                buttonBackGorundColor="#fff"
                                                ButtonIcon={<Icon.PermContactCalendarOutlined />}
                                                isLoading={stripeLoadingButton}
                                                buttonText="Verify ID"
                                                isFullWidth={false}
                                            />
                                            <IDVerificationPopup open={open} handleClose={() => { setOpen(false); }} callback={() => { setStatus({ ...status, id_verified: true }); refreshStatus() }} setStripeLoadingButton={setStripeLoadingButton} />
                                        </>

                        }


                    </Box>
                    <Chip variant="outlined" sx={{ background: '#fff' }} disabled={loader} label="Refresh" icon={loader ? <CircularProgress size="16px" sx={{ color: '#333' }} /> : <Icon.Sync sx={{ fontSize: '16px' }} />} onClick={() => { refreshStatus(true) }} />
                    {/* <LoaderChip
                  variantType='outlined'
                  onClick={() => { refreshStatus(true); }}
                  buttonBackGorundColor="#fff"
                  ButtonIcon={<Icon.Sync />}
                  isLoading={true}
                  chipText="Refresh"
                /> */}

                </Box>
                {
                    showEmailVerification &&
                    <Box sx={{ background: '#fff', borderRadius: (theme) => `${theme.card.borderRadius} `, p: 3, mt: 3 }}>
                        <IconButton sx={{ float: 'right', p: 0 }} onClick={() => { setShowEmailVerification(false) }}><Icon.Close /></IconButton>
                        <Typography sx={{ mb: 2 }}>A verification code has been sent to <Typography variant="span" sx={{ color: 'var(--grey900)' }}>{user.email}</Typography></Typography>
                        <Typography sx={{ mb: 1 }}>Please check your inbox and enter the verification code below to verify your email address.</Typography>
                        <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                            <TextField
                                variant="outlined"
                                value={otp}
                                onInput={(e) => {
                                    setOtp(e.target.value);
                                    setOtpError("");
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        verifyEmail();
                                    }
                                }}
                                placeholder="Verification Code"
                                sx={{
                                    width: '20ch',
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                autoComplete="new-password"
                                error={otpError !== ""}
                                helperText={otpError}
                            />
                            {/* <Button variant="contained" sx={{ maxHeight: '42px' }} onClick={verifyEmail}>Ver</Button> */}
                            <LoaderButton
                                variantType='contained'
                                onClick={() => { verifyEmail() }}
                                isLoading={emailLoadingButton}
                                buttonText="Verify"
                                isFullWidth={false}
                                maxHeight={42}
                            />

                        </Box>
                        <Button variant="text" color="secondary" sx={{ textDecoration: 'underline', p: 0, fontSize: '12px', minWidth: 'unset', '&:hover': { backgroundColor: 'unset' } }} onClick={sendVerification} >Resend</Button>
                    </Box>
                }

            </Box>
            {config.module === 'lister' && locationRoute.pathname !== '/dashboard' && locationRoute.pathname !== '/account' && <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" disabled={disableSubmit} onClick={() => { setOpenIdPopup(false) }} sx={{ mt: 2, ml: 'auto' }}>
                    Submit
                </Button>
            </Box>}
        </Box>
    )
}



export default AccountVerification;

