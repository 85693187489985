import * as React from "react";

import { Box, Grid } from "@mui/material";

const GallerySection = (props) => {
  return (
    <Box
      className="mobmarginbottom"
      sx={{ display: "flex", mb: 10, textAlign: "center" }}
    >
      <Grid item xs={12} sm={12} md={12}>
        <img
          alt="farwide"
          src="../assets/images/about_image.png"
          style={{ maxWidth: "100%" }}
        />
      </Grid>
    </Box>
  );
};

export default GallerySection;
