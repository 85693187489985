import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Slider,
    Select,
    MenuItem,
    TextField,
    Typography,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Divider,
} from '@mui/material';
import * as Icon from '@mui/icons-material';
import { CommonContext } from '../../store/context/commonContext';


const DurationFilter = ({ open, setOpenDurationFilter, handleFilterChange, handleClose, count, initialValue, removeFilterFromUrlParams, setDurationRangeToShow, durationRangeToShow, setIsActiveduration, setCallGetCount, setShowClearFilterButton, calledClearfilter }) => {
    const { filterChanged, setFilterChanged, screenWidth } = CommonContext();

    const [minValue, setMinValue] = useState(durationRangeToShow[0]);
    const [maxValue, setMaxValue] = useState(durationRangeToShow[1]);
    const hasUpdatedFromURL = useRef(false);
    const initialDurationRange = useRef(durationRangeToShow);

    const initialMinValue = 1;
    const initialMaxValue = 480;

    const isFilterApplied = () => {
        const url = new URL(window.location.href);
        const mnd = url.searchParams.get('mnd');
        const mxd = url.searchParams.get('mxd');
        return mnd !== null && mxd !== null && (Number(mnd) !== initialMinValue || Number(mxd) !== initialMaxValue);
    };


    useEffect(() => {
        const url = new URL(window.location.href);
        const mnd = url.searchParams.get('mnd');
        const mxd = url.searchParams.get('mxd');

        if (mnd !== null && mxd !== null) {
            setMinValue(Number(mnd));
            setMaxValue(Number(mxd));
        } else {
            setMinValue(initialMinValue);
            setMaxValue(initialMaxValue);
        }
    }, []);

    useEffect(() => {
        if (calledClearfilter === true) {
            setMinValue(initialMinValue);
            setMaxValue(initialMaxValue);
        }
    }, [calledClearfilter])

    //    s"duration range in filter", durationRangeToShow);
    useEffect(() => {
        if (!hasUpdatedFromURL.current &&
            (durationRangeToShow[0] !== initialDurationRange.current[0] ||
                durationRangeToShow[1] !== initialDurationRange.current[1])) {
            setMinValue(durationRangeToShow[0])
            setMaxValue(durationRangeToShow[1])
            hasUpdatedFromURL.current = true;
        }
        // setTimeout(()=> {
        //     handleFilterChange({ min: minValue, max: maxValue });
        // },[500])
    }, [durationRangeToShow]);

    useEffect(() => {
        // console.log("min :- ", minValue, ' max :- ', maxValue);
        setDurationRangeToShow([minValue, maxValue])

    }, [minValue, maxValue])

    const addFilterToUrlParams = (filterType) => {
        let url = ''
        url = new URL(window.location.href);

        url.searchParams.set('mnd', minValue)
        url.searchParams.set('mxd', maxValue)


        window.history.pushState({}, '', url);
    }


    // Generate options for the select components
    const generateOptions = () => {
        const options = [];
        for (let i = 1; i <= 23; i++) {
            options.push({ value: i, label: `${i} Hour${i > 1 ? 's' : ''}` });
        }
        for (let i = 1; i <= 20; i++) {
            options.push({ value: i * 24, label: `${i} Day${i > 1 ? 's' : ''}` });
        }
        return options;
    };

    const options = generateOptions();



    // Converts value to display format
    const formatDuration = (value) => {
        if (value < 24) return `${value} hr`;
        const days = Math.floor(value / 24);
        return `${days} day${days > 1 ? 's' : ''}`;
    };

    // Converts hours to the nearest option value
    const hoursToNearestOption = (hours) => {
        if (hours < 24) return hours;
        const days = Math.floor(hours / 24);
        return days * 24;
    };

    // Converts slider value to hours
    const handleSliderChange = (event, newValue) => {
        setCallGetCount(true)
        const min = hoursToNearestOption(newValue[0]);
        const max = hoursToNearestOption(newValue[1]);
        if (min !== minValue || max !== maxValue) {
            setCallGetCount(true);
            setMinValue(min);
            setMaxValue(max);
        }
    };

    const handleSelectChange = (index) => (event) => {
        setCallGetCount(true)

        const newValue = hoursToNearestOption(Number(event.target.value));

        if ((index === 0 && newValue !== minValue) || (index === 1 && newValue !== maxValue)) {
            setCallGetCount(true);
            if (index === 0) {
                if (newValue > maxValue) {
                    setMinValue(maxValue);
                    setMaxValue(newValue);
                } else {
                    setMinValue(newValue);
                }
            } else {
                if (newValue < minValue) {
                    setMaxValue(minValue);
                    setMinValue(newValue);
                } else {
                    setMaxValue(newValue);
                }
            }
        }
    };

    return (
        <Dialog open={open} onClose={() => { setOpenDurationFilter(false) }} sx={{ '& .MuiPaper-root': { maxWidth: '600px', borderRadius: 2 } }}>
            <DialogTitle id='customized-dialog-title' sx={{ py: 2, px: 3, justifyContent: "space-between", display: "flex" }}>
                <Typography variant='h6' fontWeight='700'>
                    Filters
                </Typography>
                <IconButton
                    onClick={() => { setOpenDurationFilter(false) }}
                    sx={{ padding: '0px' }}
                >
                    <Icon.Close />
                </IconButton>
            </DialogTitle>
            <Divider sx={{ width: '100%' }} />
            <DialogContent
                sx={{
                    width: screenWidth < 565 ? '100%' : 500,
                    pb: 8
                }}
            >
                <Box>
                    <Typography variant='subtitle2' gutterBottom >Duration</Typography>
                    <Box
                        sx={{ maxWidth: 400, marginInline: 'auto', paddingInline: screenWidth < 555 ? '18px' : '' }}
                    >

                        <Slider
                            value={[minValue, maxValue]}
                            onChange={handleSliderChange}
                            min={1} // 1 hour
                            max={480} // 20 days in hours
                            valueLabelDisplay="auto"
                            valueLabelFormat={formatDuration}
                            color='black'
                            sx={{
                                '& .MuiSlider-thumb': {
                                    color: 'white'
                                }
                            }}
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-between" mt={2} sx={{ gap: 2 }}>
                        <Box
                            sx={{ width: '100%' }}
                        >
                            <Typography>Minimum</Typography>
                            <Select
                                value={hoursToNearestOption(minValue)}
                                onChange={handleSelectChange(0)}
                                size='small'
                                sx={{ minWidth: 120, width: '100%' }}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box
                            sx={{ width: '100%' }}
                        >
                            <Typography>Maximum</Typography>
                            <Select
                                value={hoursToNearestOption(maxValue)}
                                onChange={handleSelectChange(1)}
                                size='small'
                                sx={{ minWidth: 120, width: '100%' }}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>

                        </Box>
                    </Box>
                </Box>
            </DialogContent>

            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', px: 3, pb: 3 }}>
                <Button variant="text" sx={{ color: 'var(--black)' }}
                    onClick={() => {
                        // setOpenDurationFilter(false);
                        // handleFilterChange({ min: '', max: '' }); // Commented out to avoid api call 
                        if (isFilterApplied()) {
                            handleFilterChange({ min: '', max: '' });
                            removeFilterFromUrlParams('duration');
                            setIsActiveduration(false);
                        }
                        setMinValue(initialMinValue);
                        setMaxValue(initialMaxValue);
                        setOpenDurationFilter(false);
                        // setDateRange([null, null])
                        // setTriggerDate(!triggerDate)
                    }} >
                    Clear All
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (minValue !== initialMinValue || maxValue !== initialMaxValue) {
                            handleFilterChange({ min: minValue, max: maxValue });
                            addFilterToUrlParams('duration');
                        }
                        setOpenDurationFilter(false)
                        // setShowClearFilterButton(true)
                        // addFilterToUrlParams('duration')
                    }}
                    sx={{
                        paddingInline: screenWidth < 398 ? '16px !important' : '22px !important'
                    }}
                >
                    {/* Show {count} Properties */}
                    Show Results
                </Button>
            </Box>
        </Dialog>
    );
};

export default DurationFilter;
