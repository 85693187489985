import { useRoutes } from 'react-router-dom';
import BaseRoutes from './LandingPageRoutes';

// routes
import DashboardRoutes from './DashboardRoutes';
import SearchPageRoutes from './SearchPageRoutes';
import DefaultRoutes from './defaultRoutes';
import UnauthorisedRoutes from './unauthorisedRoutes';
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([DefaultRoutes, UnauthorisedRoutes,BaseRoutes, SearchPageRoutes, DashboardRoutes]);
}