import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SidebarItems from './SideBartems';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { useLocation } from 'react-router-dom'
import { CommonContext } from '../../../store/context/commonContext';
import { Backdrop, Box, Button } from '@mui/material';

const drawerWidth = 267;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            className: 'fw-drawer-paper',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                // width: theme.spacing(7),
                width: 0,
                [theme.breakpoints.up('md')]: {
                    width: theme.spacing(10),
                },
            }),
        },
    }),
);


const Sidebar = (props) => {
    const location = useLocation();
    const { isTablet, isNineHundred } = CommonContext()
    const handleItemClick = () => {
        if (!isTablet)
            return
        else
            props.toggleDrawer()
    }


    return (
        <>

            <Drawer className='fw_left_nav' sx={{ borderRight: isNineHundred && !props.open && 'none !important', '& .MuiDrawer-paper': { overflow: 'unset' } }} anchor="left" variant="permanent" open={props.open} >
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: 2.5,
                        height: '72px'
                    }}
                >
                    <div className="fw_hdr_leftnav_logo"><img src='/assets/images/farwide-new-logo.svg' alt='FarWide' /></div>
                    <IconButton onClick={props.toggleDrawer}>
                        {/* <MenuIcon /> */}
                        <CloseOutlinedIcon />
                    </IconButton>
                </Toolbar>
                {!isNineHundred && <Box
                    onClick={props.toggleDrawer}
                    sx={{
                        borderRadius: '50%',
                        border: '1px solid var(--grey500)',
                        height: 24,
                        width: 24,
                        backgroundColor: 'white',
                        alignSelf: 'end',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        "& .MuiSvgIcon-root": {
                            width: '12px',
                            height: '12px',
                            color: 'var(--grey900)',
                            marginRight: props.open && '-3px'
                        },
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 100,
                        right: '-12px', zIndex: 2,
                    }}>
                    {props.open ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                </Box>}
              {!(isNineHundred && !props.open) &&  <List component="nav" sx={{ p: 2.5 }}>
                    <SidebarItems currentItem={location.pathname} handleItemClick={handleItemClick} isSidebarOpen={props.open} />
                </List>}
            </Drawer>
            {isTablet && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={props.open}
                onClick={props.toggleDrawer}
            >
            </Backdrop>}
        </>
    )
}


export default Sidebar