import {  Skeleton } from "@mui/material"

const SkeletonPropertyPreview = () => {
    return (
        <>
        <Skeleton variant="rectangular" sx={{ width: "80%", pb: '50px', mb: 2, borderRadius : 2 }} />
        <Skeleton variant="rectangular" sx={{ width: "80%", pb: '25px', mb: 2, borderRadius : 2 }} />
        <Skeleton variant="rectangular" sx={{ width: "100%", pb: '400px', mb: 2, borderRadius : 2 }} />
        <Skeleton variant="rectangular" sx={{ width: "70%", pb: '400px', mb: 2, borderRadius : 2 }} />
    </>
    )
}

export default SkeletonPropertyPreview