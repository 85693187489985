import { Dialog } from "@mui/material";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ForgotPassword from "../ForgotPassword";

const AuthPopup = ({ open, handleClose, type, handleOpen, admin}) => {
    let modalBody = '';
    switch (type) {
        case "signin":
            modalBody = <SignIn handleOpen={handleOpen} handleClose={handleClose} admin={admin}/>
            break;
        case "signup":
            modalBody = <SignUp handleOpen={handleOpen} handleClose={handleClose} />
            break;
        case "forgotpassword":
            modalBody = <ForgotPassword handleOpen={handleOpen} handleClose={handleClose} />
            break;
        default:
            modalBody = <SignIn handleOpen={handleOpen} handleClose={handleClose} />
            break;
    }

    return (
        <Dialog className="authPopUpP" open={open} onClose={handleClose} maxWidth="lg" slotProps={{ backdrop: { style: { backgroundColor: '#1B1B1Be5',  } } }} >
            {modalBody}
        </Dialog >
    )
}


export default AuthPopup;