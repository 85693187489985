import * as Icon from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import "../style.css";
import config from "../../../config";
import { CommonContext } from "../../../store/context/commonContext";
import { useEffect, useRef, useState } from "react";


const DownloadSection = () => {
    const { isNineHundred, screenWidth } = CommonContext()
    const heightRef = useRef()
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (heightRef.current) {
            const newHeight = heightRef.current.clientHeight;
            setHeight(newHeight);
        }
    }, [screenWidth]);

    return (
        // <Grid container spacing={1}>
        //     <Grid item xs={12} sm={12} md={6} >
        //         {/* <img className="download_img" src="../assets/images/homepage/download_section_grid_img.png" style={{ maxWidth: '100%' }} /> */}
        //     </Grid>
        //     <Grid item xs={12} sm={12} md={6}>
        //         <Box sx={{ backgroundColor: 'var(--grey300)', backgroundImage: 'url(../assets/images/homepage/bg_wave.png)', backgroundPosition: 'bottom center', backgroundRepeat: 'no-repeat', height: 'calc(100% - 8px)', borderRadius: 4, p:{xs:4, md:6, lg:8} }}>
        //             <Typography variant="h4" sx={{mb:4}}>
        //                 Earn additional income from your land for recreational access.
        //             </Typography>
        //             <Box className="download_list" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }} >
        //                 <Icon.Verified />
        //                 <Typography sx={{color:'#747579'}}>Host verified guest on your property. </Typography>
        //             </Box>
        //             <Box className="download_list" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }} >
        //                 <Icon.Verified />
        //                 <Typography sx={{color:'#747579'}}>List for free and earn up to $25,000 per year.</Typography>
        //             </Box>
        //             <Box className="download_list" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 4 }} >
        //                 <Icon.Verified />
        //                 <Typography sx={{color:'#747579'}}>Stay 100% in control of your property.</Typography>
        //             </Box>
        //             <Button variant="contained" href={config.landownerPdf} target="_blank">
        //                 Download Landowner Guide
        //             </Button>

        //         </Box>
        //     </Grid>
        // </Grid>
        <Box sx={{
            display: 'flex',
            flexDirection: isNineHundred ? 'column' : 'row',
            gap: 1,
            height: isNineHundred ? 'auto' : '465px'
        }}>
            <Box sx={{ width: isNineHundred ? '100%' : '50%', display: 'flex', gap: 1 }}>
                <Box sx={{ width: '50%', height: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <Box sx={{ width: '100%', height: '100%', borderRadius: 2, minHeight: '225px', backgroundImage: 'url(../assets/images/homepage/image_1.png)', backgroundPosition: 'bottom center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>

                    </Box>
                    <Box sx={{ width: '100%', height: '100%', borderRadius: 2, minHeight: '225px', backgroundImage: 'url(../assets/images/homepage/image_2.png)', backgroundPosition: 'bottom center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>

                    </Box>
                </Box>
                <Box sx={{ width: '50%', height: '100%', borderRadius: 2, backgroundImage: 'url(../assets/images/homepage/image_3.png)', backgroundPosition: 'bottom center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>

                </Box>
            </Box>
            <Box sx={{ width: isNineHundred ? '100%' : '50%', }} ref={heightRef}>
                <Box sx={{ backgroundColor: 'var(--grey300)', backgroundImage: 'url(../assets/images/homepage/bg_wave.png)', height: '100%', backgroundPosition: 'bottom center', backgroundRepeat: 'no-repeat', borderRadius: 4, p: { xs: 4, md: 6, lg: 8 } }}>
                    <Typography variant="h4" sx={{ mb: 4 }}>
                        Earn additional income from your land for recreational access.
                    </Typography>
                    <Box className="download_list" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }} >
                        <Icon.Verified />
                        <Typography sx={{ color: '#747579' }}>Host verified guest on your property. </Typography>
                    </Box>
                    <Box className="download_list" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }} >
                        <Icon.Verified />
                        <Typography sx={{ color: '#747579' }}>List for free and earn up to $25,000 per year.</Typography>
                    </Box>
                    <Box className="download_list" sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 4 }} >
                        <Icon.Verified />
                        <Typography sx={{ color: '#747579' }}>Stay 100% in control of your property.</Typography>
                    </Box>
                    <Button variant="contained" href={config.landownerPdf} target="_blank">
                        Download Landowner Guide
                    </Button>

                </Box>
            </Box>
        </Box>
    )
}


export default DownloadSection;
