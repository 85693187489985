import { Box, Typography } from "@mui/material"
import { useState, useEffect } from "react";
import { extractDataFromArray } from "../../../utils/common";
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";

const CancellationPolicyPreview = ({ formData, isMobileButtonClicked = false }) => {
    // console.log(isMobileButtonClicked);
    

    const [selectedPolicyMessage, setSelectedPolicyMessage] = useState('');

    // Add this effect to update the selectedPolicyMessage when formData changes
    useEffect(() => {
        const selectedPolicy = Object.values(formData.cancellation_policy.policies).find(policy => policy.isSelected);
        if (selectedPolicy) {
            setSelectedPolicyMessage(selectedPolicy.messageToguest);
        } else {
            // If no policy is selected, use the default (policy with isDefault: true)
            const defaultPolicy = Object.values(formData.cancellation_policy.policies).find(policy => policy.isDefault);
            if (defaultPolicy) {
                setSelectedPolicyMessage(defaultPolicy.messageToguest);
            }
        }
    }, [formData]);


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING
        }}>
            <Typography variant="h5" sx={{ fontWeight: '600', fontSize: isMobileButtonClicked && '1.25rem' }}>{extractDataFromArray(formData, ['title'], '')}</Typography>
            <Typography variant="subtitle" sx={{ color: 'var(--textGrey)' }}>{selectedPolicyMessage}</Typography>
        </Box>
    )
}
export default CancellationPolicyPreview