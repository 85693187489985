import { Box, Typography } from "@mui/material"
import ExpereinceOverviewImages from "./expOverviewImages";
import { extractDataFromArray } from "../../../utils/common";
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";

const ExpPhotos = ({ formData, isMobileButtonClicked = false }) => {
    // console.log(formData);

    return (
        <Box>
            <Typography variant="h5" sx={{ mb: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING, fontWeight: '600', fontSize: isMobileButtonClicked && '1.25rem' }}>{extractDataFromArray(formData, ['caption'], '')}</Typography>
            <ExpereinceOverviewImages images={extractDataFromArray(formData, ['photo_list'], '')} />
        </Box>
    )
}

export default ExpPhotos;