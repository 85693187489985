import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { extractDataFromArray } from '../../../utils/common';
import ExpereinceOverviewImages from './expOverviewImages';
import { RESPONSIVE_SPACING } from '../../CommonConstants/commonKeys';

const OptionalAddOns = ({ formData, isMobileButtonClicked = false }) => {
    const addonList = extractDataFromArray(formData, ['addon_list'], []);

    return (
        <Box>
            <Typography variant='h5' sx={{ fontWeight: '600', mb: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING, fontSize: isMobileButtonClicked && '1.25rem' }}>{extractDataFromArray(formData, ['caption'], [])}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {addonList.map((addon, index) => (
                    <Box key={index} sx={{
                        width: '100%',
                        borderRadius: 2,
                        border: '1px solid #E5E7EB',
                        p: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING,
                        display: 'flex', flexDirection: 'column', gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING,
                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                    <span className="material-symbols-outlined" style={{ color: 'var(--black)' }}>
                                        {addon.icon}
                                    </span>
                                    <Typography variant="subtitle1">
                                        {addon.title}
                                    </Typography>
                                </Box>
                                <Typography variant="subtitle1" fontWeight={600} >
                                    ${addon.price}
                                </Typography>
                            </Box>
                            {(addon.description !== '' || addon.photo_list?.length > 0) && <Divider />}
                            {addon.description !== '' && < Typography variant="subtitle1" color={'var(--textGrey)'}>
                                {addon.description}
                            </Typography>}
                            {addon.photo_list?.length > 0 && <ExpereinceOverviewImages images={addon.photo_list} />}
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box >
    );
};

export default OptionalAddOns;
