import { useEffect, useState } from "react";
import { Box, Divider, MenuItem, Select, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import PackageCard from "../PackageCard";
import { empty, extractDataFromArray, getActivityType } from "../../utils/common";
import { useLoaderData, useLocation } from "react-router";

const packageOrder = [
    {
        type: 'H',
        label: 'Hunting'
    },
    {
        type: 'F',
        label: 'Fishing'
    },
    {
        type: 'BW',
        label: 'Bird Watching'
    },
    {
        type: 'FR',
        label: 'Farm and Ranch'
    },
];

const PackageList = ({ sectionRef, packages, listingData }) => {
    const [packageActivity, setPackageActivty] = useState('A')
    const [packageGroupedCount, setPackageGroupedCount] = useState({})
    const [packageGrouped, setPackageGrouped] = useState({})
    const [isPropertyPage, setIsPropertyPage] = useState(false)
    const location = useLocation();


    const filterPackages = (setFuntion) => {
        let temp = {}
        packages.forEach(packageData => {
            if (packageActivity !== 'A') {
                if (packageData.activity_type !== packageActivity) {
                    return
                }
            }
            if (typeof temp[packageData.activity_type] === "undefined") {
                temp[packageData.activity_type] = [];
            }
            temp[packageData.activity_type].push(packageData)
        })
        setFuntion(temp)
    }

    useEffect(() => {
        filterPackages(setPackageGrouped)
        if (location.pathname.includes('/property')) { // Added this to check which page is showing packagelist depending on that we use it direct to property or package page
            // console.log("true")
            setIsPropertyPage(true)
        } else {
            setIsPropertyPage(false)
            // console.log("false")
        }
    }, [packages, packageActivity])


    useEffect(() => {
        filterPackages(setPackageGroupedCount)
    }, [packages])


    return (
        <Box ref={sectionRef}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",

                }}
            >
                <Typography variant="h4">Packages</Typography>
                {/* <Select
                    value={packageActivity}
                    onChange={(e) => { setPackageActivty(e.target.value) }}
                    IconComponent={Icon.KeyboardArrowDownOutlined}
                    sx={{ width: '20ch' }}
                    size="small"
                >
                    <MenuItem value="A">All ({packages.length})</MenuItem>
                    <MenuItem value="H">Hunting ({extractDataFromArray(packageGroupedCount, ['H'], []).length})</MenuItem>
                    <MenuItem value="F">Fishing ({extractDataFromArray(packageGroupedCount, ['F'], []).length})</MenuItem>
                    <MenuItem value="BW">Bird Watching ({extractDataFromArray(packageGroupedCount, ['BW'], []).length})</MenuItem>
                    <MenuItem value="FR">Farm and Ranch ({extractDataFromArray(packageGroupedCount, ['FR'], []).length})</MenuItem>
                </Select> */}
                <Select
                    value={packageActivity}
                    onChange={(e) => { setPackageActivty(e.target.value) }}
                    IconComponent={Icon.KeyboardArrowDownOutlined}
                    sx={{ width: '20ch' }}
                    size="small"
                >
                    <MenuItem value="A">All ({packages.length})</MenuItem>
                    {packageOrder.map((activity) => {
                        const activityType = activity.type;
                        const itemCount = extractDataFromArray(packageGroupedCount, [activityType], []).length;

                        // Only render the MenuItem if the itemCount is greater than 0
                        if (itemCount > 0) {
                            return (
                                <MenuItem key={activityType} value={activityType}>
                                    {`${activity.label} (${itemCount})`}
                                </MenuItem>
                            );
                        }

                        return null; // Don't render the MenuItem if the itemCount is 0
                    })}
                </Select>

            </Box>
            <Divider sx={{ mt: 3, mb: 2 }} />
            {
                packageOrder.map((activity, i) => {
                    if (typeof packageGrouped[activity.type] !== "undefined") {
                        return (
                            <Box sx={{ mb: 3 }} key={"package_list_" + activity.type + "_" + i}>
                                <Typography variant="h6" sx={{ mb: 1, fontWeight: 'normal' }}>
                                    {activity.label}
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: { xs: 2, sm: 2, md: 3, lg: 3 } }}>
                                    {
                                        packageGrouped[activity.type].map((packageData, index) => {
                                            // console.log("packageGrouped", packageData)
                                            let renderTitle = getActivityType(packages[index])
                                            return (
                                                <PackageCard
                                                    elevation={2}
                                                    key={"package_list_" + activity.type + "_" + i + "_" + index}
                                                    image={extractDataFromArray(packageData, ['package_json', 'media_library'], '/landowner/assets/images/property_detail_page/dummy_galler_image_1.png')}
                                                    title={extractDataFromArray(packageData, ["package_title"], '')}
                                                    listing_seq={extractDataFromArray(packageData, ["listing_seq"], '')}
                                                    package_seq={extractDataFromArray(packageData, ["package_seq"], '')}
                                                    lat={extractDataFromArray(listingData, ["listing_json", 'location_info', "property_ltln", 0])}
                                                    long={extractDataFromArray(listingData, ["listing_json", 'location_info', "property_ltln", 1])}
                                                    address={`${extractDataFromArray(
                                                        listingData,
                                                        ["listing_json", "location_info", "property_area"],
                                                        ""
                                                    )} Acres, ${renderTitle} at `}
                                                    propertyName={`${extractDataFromArray(listingData, ['listing_json', 'property_details', 'name'], '')}`}
                                                    address2={` near ${extractDataFromArray(
                                                        listingData,
                                                        ["listing_json", "location_info", "nearest_town"],
                                                        ""
                                                    )}`}
                                                    isPropertyPage={isPropertyPage}
                                                    slug={extractDataFromArray(packageData, ["package_slug"], '')}
                                                    activity={extractDataFromArray(packageData, ["activity_type"], 'H')}
                                                    size={extractDataFromArray(listingData, ["listing_json", "location_info", "property_area"], '')}
                                                    cardType="package"
                                                    guest={extractDataFromArray(packageData, ["package_json", "min_guests"], 1)}
                                                    days={extractDataFromArray(packageData, ["package_json", "min_days"], 1)}
                                                    price={extractDataFromArray(packageData, ["package_json", "price_per_guest"], 1)}
                                                    nearest_town={extractDataFromArray(packageData, ['listing_json', 'location_info', 'nearest_town'], '')}
                                                    rv={extractDataFromArray(packageData, ['listing_json', 'ground_rule', 'rv', 'available'], '')}
                                                    lodging={extractDataFromArray(packageData, ['listing_json', 'lodging_details', 'available'], '')}
                                                    showViewDetailButton={true}
                                                />
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                        )
                    }
                    return <div key={"package_list_" + activity.type + "_" + i}></div>
                })
            }

        </Box >
    )
}

export default PackageList;