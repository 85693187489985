import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import mapboxglcircle from 'mapbox-gl-circle'; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { Box, Grid, Typography } from "@mui/material";
import { extractDataFromArray } from '../../../utils/common';
import ApplyStyles from '../../previewPageComponents/htmlParser/ApplyStyles';
import { RESPONSIVE_SPACING } from '../../CommonConstants/commonKeys';

mapboxgl.accessToken = 'pk.eyJ1IjoiZmFyd2lkZSIsImEiOiJjajloOHc4MGozNWFnMnFsc2FueXJvbnZ3In0.7Cek0jLTdXkikf2ioduEvg';


const ExpLocation = ({ formData, isMobileButtonClicked = false }) => {

    const [lat, setLat] = useState(
        isNaN(parseFloat(extractDataFromArray(formData, ['lat'], 38.8951)))
            ?
            38.8951
            :
            parseFloat(extractDataFromArray(formData, ['lat'], 38.8951)
            )
    )
    const [lon, setLon] = useState(
        isNaN(parseFloat(extractDataFromArray(formData, ['lon'], -77.0364)))
            ?
            38.8951
            :
            parseFloat(extractDataFromArray(formData, ['lon'], -77.0364)
            )
    )

    const markerRef = useRef(null); // Use ref to store the marker
    const circleRef = useRef(null);  // Added circleRef

    const customMarker = document.createElement('div');
    customMarker.className = 'custom-marker';
    customMarker.style.backgroundImage = 'url("/assets/images/property_detail_page/map_pointer_active.svg")';
    customMarker.style.width = '50px';
    customMarker.style.height = '50px';
    customMarker.style.backgroundRepeat = 'no-repeat';


    const title = 'Outdoor opportunities Near by'
    const activityList = extractDataFromArray(formData, ['outdoor_opportunity_list'], 0)
    const isSpecific = extractDataFromArray(formData, ['is_specific_location'], 0) === 1 ? true : false

    const mapContainer = useRef(null);
    const map = useRef(null);
    useEffect(() => {
        let loadCount = 0;

        const loadMap = () => {
            if (loadCount >= 3) return;  // Stop after loading 3 times .... This is added to avoid map break view at preview page in create experience.

            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v12',
                center: [lon, lat],
                zoom: 9,
                attributionControl: false,
                dragPan: false,
                doubleClickZoom: false,
                scrollZoom: false,
                touchZoomRotate: false,
                dragRotate: false
            });

            map.current.on('load', function () {
                map.current.setCenter([lon, lat]);
                map.current.setZoom(9);

                if (markerRef.current && !isSpecific) {
                    markerRef.current.remove();
                    markerRef.current = null;
                }
                if (circleRef.current && isSpecific) {
                    circleRef.current.remove();
                    circleRef.current = null;
                }

                if (isSpecific) {
                    markerRef.current = new mapboxgl.Marker({ element: customMarker })
                        .setLngLat([lon, lat])
                        .addTo(map.current);
                } else {
                    circleRef.current = new mapboxglcircle({ lat: lat, lng: lon }, 10000, {
                        fillColor: '#29AB87'
                    }).addTo(map.current);
                }
            });

            loadCount += 1;

            // Call the function again after a delay if the map hasn't been loaded 5 times
            setTimeout(loadMap, 100);
        };

        loadMap();  // Initial call to load the map
    }, [lat, lon, isSpecific, isMobileButtonClicked]);  // Add dependencies to rerender when these values change

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING,
        }}>
            <Typography variant="h5" sx={{ fontWeight: '600', fontSize: isMobileButtonClicked && '1.25rem' }}>
                {extractDataFromArray(formData, ['title'], '')}
            </Typography>
            <Box ref={mapContainer} sx={{ height: '400px', width: isMobileButtonClicked ? '320px' : 'unset', borderRadius: 4 }} />
            {
                !(extractDataFromArray(formData, ['how_to_reach'], '') === '' ||
                    extractDataFromArray(formData, ['how_to_reach'], '') === '<p><br></p>')
                &&
                <ApplyStyles htmlString={extractDataFromArray(formData, ['how_to_reach'], '')} />
            }
            {
                !(activityList.length === 1 && (activityList[0].label === '' || activityList[0].label === ' ')) && <Box>
                    <Typography variant="subtitle1" sx={{ mb: 2.5, fontWeight: '600' }}>{title}</Typography>
                    <Grid container spacing={2}>
                        {activityList.map((activity, index) => {
                            if (activity.label === '') {
                                return null
                            }
                            return (
                                <>
                                    {
                                        !(activity.label === '' || activity.label === ' ') &&
                                        <Grid item xs={6} key={index}>
                                            <Box display="flex" alignItems="center">
                                                <span className="material-symbols-outlined" style={{ color: 'var(--black)' }}>
                                                    {activity.icon}
                                                </span>
                                                <Typography variant="body1" ml={1}>{activity.label}</Typography>
                                            </Box>
                                        </Grid>}
                                </>
                            )
                        })}
                    </Grid>
                </Box>}
            {
                !(extractDataFromArray(formData, ['other_details'], '') === '' ||
                    extractDataFromArray(formData, ['other_details'], '') === '<p><br></p>')
                &&
                <ApplyStyles htmlString={extractDataFromArray(formData, ['other_details'], '')} />}
        </Box>
    )
}

export default ExpLocation;
