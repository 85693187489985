import * as React from "react";
import { useState } from "react";
import { Divider, IconButton, InputAdornment, Tab, Tabs } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { Box, Grid, TextField, Typography, FormGroup, Select, MenuItem } from "@mui/material";
import "../style.css";
import { CommonContext } from "../../../store/context/commonContext";
import { Link } from "react-router-dom";
import activityData from './titleSection.json'

const Titlesection = (props) => {
    const { isTablet, clikedBanner, setClikedBanner, setSearchFilters, searchFilters } = CommonContext();
    const [searchFiltersHome, setSearchFiltersHome] = useState(
        {
            activity: 'access', // Changed it from "A" to "activitySelected" while working on new search drop down menu
            kw: ''
        }
    );
    const [tabIndex, setTabIndex] = useState(0);
    const [selectedTab, setSelectedTab] = useState('access')

    // Get the keys of activities dynamically from activityData
    const tabs = Object.keys(activityData).map(key => ({
        id: key,
        label: activityData[key].label,
        icon: `/assets/images/icon_images/${key}.svg`, // Assuming you have appropriate icons named by the tab keys
    }));

    const handleChange = (e) => {
        // console.log("e.target.value", e.target.value);
        setSearchFiltersHome({ ...searchFiltersHome, 'activity': e.target.value });
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        const tabs = ['access', 'experience', 'academy'];
        const newSelectedTab = tabs[newValue];
        setSelectedTab(newSelectedTab);

        // Update the searchFiltersHome.activity based on the selected tab
        setSearchFiltersHome(prevFilters => ({
            ...prevFilters,
            activity: newSelectedTab
        }));
    };


    const isLoading = !activityData[selectedTab];

    return (
        <>
            <Grid container sx={{ p: { xs: 0, md: 0 } }}>
                <Grid item xs={12} sm={12} md={12} sx={{ m: "auto", mt: { xs: 16, md: 20 } }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 5 }}>
                        <Box className="homePageTabs">
                            <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                                value={tabIndex}
                                onChange={handleTabChange}
                            >
                                {tabs.map((tab, index) => (
                                    <Tab
                                        key={tab.id}
                                        icon={<Box>
                                            <img src={tab.icon} alt={tab.label} />
                                        </Box>}
                                        aria-label={tab.label}
                                        label={tab.label}
                                        iconPosition={isTablet ? "top" : "start"}
                                    />
                                ))}
                            </Tabs>
                            <Divider />
                        </Box>
                    </Box>
                    {!isLoading && (
                        <>
                            <Box sx={{ textAlign: "center" }}>
                                <TitleText text={activityData[selectedTab].title.text} imp={activityData[selectedTab].title.imp} />
                                <CaptionText text={activityData[selectedTab].caption.text} imp={activityData[selectedTab].caption.imp} />
                            </Box>

                            <Box sx={{ margin: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ mx: 2, width: '100%' }}>
                                    <FormGroup sx={{ alignItems: 'center', flexWrap: 'nowrap', flexDirection: 'row', justifyContent: 'center', border: 'solid 1px #C5C5C7', background: '#fff', borderRadius: '16px', maxWidth: '680px', m: 'auto', }}>
                                        <Select
                                            name="listing_sort"
                                            size="large"
                                            value={searchFiltersHome.activity}
                                            onChange={handleChange}
                                            IconComponent={Icon.KeyboardArrowDownOutlined}
                                            sx={{ border: 'none', "& fieldset": { border: 'none' }, m: 0, pt: 0, width: "19ch", mb: 0, mt: "0px", display: { xs: 'none', sm: 'none', md: 'block' } }}
                                        >
                                            {/* <MenuItem value={"A"}>All Activities</MenuItem> */}
                                            {Object.keys(activityData[selectedTab].activities).map((key, index) => {
                                                const value = activityData[selectedTab].activities[key].label;
                                                return (
                                                    <MenuItem key={key} value={key}>{activityData[selectedTab].activities[key].label}</MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <Divider orientation="vertical" variant="middle" flexItem sx={{ mt: 0, mb: 0, display: { xs: 'none', sm: 'none', md: 'block' } }} />
                                        <TextField
                                            className='top_hdr_search_input'
                                            placeholder={isTablet ? 'Search..' : 'Where would you like to go?'}
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton variant="contained" style={{ background: "var(--primary)", color: "#fff" }} onClick={() => {
                                                            window.location.href = window.location.origin + "/search?kw=" + (searchFiltersHome.kw || "") + "&at=" + (searchFiltersHome.activity === "A" ? '' : searchFiltersHome.activity || "") + "&type=" + activityData[selectedTab].label.toLowerCase()
                                                        }}>
                                                            <Icon.SearchOutlined />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            sx={{ border: 'none', "& fieldset": { border: 'none' }, m: 0, pr: '8px', width: { xs: '100%', md: '520px', }, "& .MuiInputBase-root": { paddingRight: '0px' } }}
                                            size='large'
                                            value={searchFiltersHome.kw || ""}
                                            onInput={(e) => { setSearchFiltersHome({ ...searchFiltersHome, 'kw': e.target.value }); setSearchFilters({ ...searchFilters, 'kw': e.target.value }) }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    if (window.location.pathname !== "/search") {
                                                        window.location.href = window.location.origin + "/search?kw=" + (searchFiltersHome.kw || "") + "&at=" + (searchFiltersHome.activity === "A" ? '' : searchFiltersHome.activity || "")
                                                    }
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                    <Box sx={{ maxWidth: '680px', textAlign: 'right', margin: 'auto', mb: 5 }}>
                                        <Link
                                        //  to={`/search?at=${activityData[selectedTab].label.toLowerCase()}&type=${activityData[selectedTab].label.toLowerCase()}`}
                                            onClick={() => { 
                                                setClikedBanner(true); 
                                                setSearchFilters({ 'activity': activityData[selectedTab].label.toLowerCase() }); 
                                                window.location.href = window.location.origin + "/search?kw=" + (searchFiltersHome.kw || "") + "&at=" + (searchFiltersHome.activity === "A" ? '' : searchFiltersHome.activity || "") + "&type=" + activityData[selectedTab].label.toLowerCase()
                                            }}
                                            style={{ color: 'var(--black)', fontSize: '12px', borderBottom: 'solid 1px var(--black)', textDecoration: 'none' }}
                                        >
                                            Explore All
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ overflow: 'auto', display: 'flex', pl: 2 }}>
                                <Box sx={{ margin: 'auto', display: 'flex', gap: 2, justifyContent: { xs: 'initial', md: 'center' }, mb: 1 }}>
                                    {Object.keys(activityData[selectedTab].activities).map((key) => {
                                        if (key === 'access' || key === 'experience' || key === 'academy') return (
                                            null
                                        );

                                        return (
                                            <Box key={key} className="search_img_link" sx={{ textAlign: 'center' }}>
                                                <Link
                                                //  to={`/search?at=${key}&type=${activityData[selectedTab].label.toLowerCase()}`}
                                                    onClick={() => { 
                                                        setClikedBanner(true); 
                                                        setSearchFilters({ 'activity': key }); 
                                                        window.location.href = window.location.origin + `/search?at=${key}&type=${activityData[selectedTab].label.toLowerCase()}`
                                                    }}

                                                >
                                                    <Box className="search_img">
                                                        <img src={activityData[selectedTab].activities[key].thumbnail_url} style={{ maxWidth: '100%' }} />
                                                    </Box>
                                                    <Typography>{activityData[selectedTab].activities[key].label}</Typography>
                                                </Link>
                                            </Box>
                                        )
                                    })}
                                </Box>
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default Titlesection;

const TitleText = ({ text, imp }) => {
    const containsImp = text.includes(imp);

    let content;
    if (containsImp) {
        const parts = text.split(imp);
        content = (
            <p style={{ padding: 0, margin: 0 }}>
                {parts[0]}
                <strong style={{ color: 'var(--primary)' }}>{imp}</strong>
                {parts[1]}
            </p>
        );
    } else {
        content = <p>{text}</p>;
    }

    return (
        <Typography variant="h3" sx={{ mb: 2 }}>
            {content}
        </Typography>
    )
}

const CaptionText = ({ text, imp }) => {
    const containsImp = text.includes(imp);

    let content;
    if (containsImp) {
        const parts = text.split(imp);
        content = (
            <p style={{ padding: 0, margin: 0 }}>
                {parts[0]}
                <span style={{
                    fontWeight: 'bold',
                    color: 'var(--black)'
                }}>{imp}</span>
                {parts[1]}
            </p>
        );
    } else {
        content = <p>{text}</p>;
    }

    return (
        <Typography variant="body1" sx={{ mb: 5, color: 'var(--gray)', fontSize: '18px' }}>
            {content}
        </Typography>
    )
}
 
  // structure for academy, if in futute it is added.

  // "academy": {
  //     "label": "Academy",
  //     "title": {
  //     "text": "Recreational Access...Unlocked",
  //     "imp": "Unlocked"
  //     },
  //     "caption": {
  //       "text": "Explore in-the-Field & online classes for those with outdoor skils or interests.",
  //       "imp": "classes"
  //     },
  //     "activities": {
  //       "academy":
  //       {
  //         "label": "All Classes",
  //         "thumbnail_url": "/assets/images/homepage/fishing_search_img.png"
  //       },
  //       "IP":
  //       {
  //         "label": "In-Person Training",
  //         "thumbnail_url": "/assets/images/homepage/in-person.png"
  //       },
  //       "ON":
  //       {
  //         "label": "Online",
  //         "thumbnail_url": "/assets/images/homepage/online.png"
  //       }
  //     }
      
  //   }