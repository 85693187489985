import { useEffect, useState } from "react";
import { Button, Grid, } from "@mui/material";
import LeftPane from "./components/LeftPane";
import RightPane from "./components/RightPane";

import FileDetails from "./components/FileDetails";
import { extractDataFromArray } from "../../utils/common";
import UploadPopup from "./components/UploadPopup";



const FileManagerContent = (props) => {
    const [drawerSettings, setDrawerSettings] = useState({ open: false, selectedFile: {} })
    const handleDrawerOpen = (event, state, file) => {
        if (extractDataFromArray(event, ['target', 'type'], '') === "checkbox") {
            return
        }
        setDrawerSettings({ open: state, selectedFile: file })
    }
    const [refreshList, setRefreshList] = useState(1)
    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={12} md={2} >
                <LeftPane refreshList={refreshList} setRefreshList={setRefreshList} />
            </Grid>
            <Grid item xs={12} sm={12} md={10}>
                <RightPane refreshList={refreshList} setRefreshList={setRefreshList} selectedCallback={props.selectedCallback} handleDrawerOpen={handleDrawerOpen} />
            </Grid>

            <FileDetails drawerSettings={drawerSettings} toggleDrawer={handleDrawerOpen} />
            {/* <BasicSpeedDial refreshList={refreshList} setRefreshList={setRefreshList} /> */}
        </Grid>
    )
}


const UploadImagePopup = ({ startIcon = null, label, selectedCallback, limit, openPopup = null, buttonText, hideButton = false, reqFileType = '', closePopup = () => { } }) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        closePopup()
    }


    useEffect(() => {
        if (openPopup !== null) {
            setOpen(openPopup)
        }
    }, [openPopup])

    const handleSelection = (files) => {
        handleClose()
        let temp = []
        files.forEach(photo => {
            photo.type = extractDataFromArray(photo, ['file_type'], 'IMAGE')
            temp.push(photo)
        })
        selectedCallback(temp)
    }


    return (
        <div>
            {
                !hideButton &&
                <Button startIcon={startIcon} variant="light" color='primary' onClick={handleClickOpen} sx={{ mb: 1 }}>
                    {label}
                </Button>
            }
            {/* <Dialog fullScreen open={open} onClose={handleClose}>
                <DialogTitle id='customized-dialog-title' sx={{ py: 2, px: 3, justifyContent: "space-between", display: "flex" }}>
                    <Typography variant='h6' component='span'>
                        File Manger
                    </Typography>
                    <IconButton onClick={handleClose}><Icon.Close /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <FileManagerContent selectedCallback={handleSelection} />
                </DialogContent>
                <DialogActions sx={{ mb: 3, mr: 3 }}>
                    <Button onClick={handleClose} variant="contained">Add Selected Photos</Button>
                </DialogActions> 
            </Dialog> */}
            <UploadPopup buttonText={buttonText} onClose={handleClose} popupOpen={open} type={"file"} reqFileType={reqFileType} showFilemanageButton={true} limit={limit} selectedCallback={selectedCallback} />
        </div>
    )
}


export { FileManagerContent };
export default UploadImagePopup;